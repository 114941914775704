// put columns here

import { GridCellProps } from '@progress/kendo-react-grid'
import { Link } from 'react-router-dom'
import { isDesktopMode } from 'services/axios/axios-sfc'
import globals from 'services/global/globals'
import { Duty } from 'types/HazardClassCase'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { FormattedHazardClassPatternDutyMetricCell } from 'views/Common/Kendo/FormattedMetricCell'

const getColumns = () => {
    const api = globals.getApi()

    const columns: KendoGridColumn[] = [
        {
            title: 'Scenario Name',
            field: 'scenarioName',
        },
        {
            title: 'Schedule ID',
            field: 'scheduleName',
            cell: (cellProps: GridCellProps) => {
                const duty = cellProps.dataItem as Duty
                if (isDesktopMode()) {
                    return (
                        <td>
                            <TransparentButton
                                onClick={() => {
                                    api.launchScheduleDetails(duty.scheduleId)
                                }}
                            >
                                {duty.scheduleName}
                            </TransparentButton>
                        </td>
                    )
                }
                return (
                    <td>
                        <Link className="linkStyle" to={`/schedule/${duty.scheduleId}`}>
                            {duty.scheduleName}
                        </Link>
                    </td>
                )
            },
        },
        {
            title: 'Duty Index',
            field: 'dutyIndex',
        },
        {
            title: 'Duty Day',
            field: 'dutyDay',
        },
        {
            title: 'Duty Route',
            field: 'route',
        },
        {
            title: 'Duty Start',
            field: 'startTimeString',
        },
        {
            title: 'Duty Duration',
            field: 'duration',
        },
        {
            title: 'WOCL Duration',
            field: 'woclDuration',
        },
        {
            title: 'Prior Sleep 24hrs',
            field: 'priorSleep24',
        },
        {
            title: 'Prior Day Sleep 24hrs',
            field: 'priorDaySleep24',
        },
        {
            title: 'Prior Rest',
            field: 'priorRest',
        },
        {
            title: 'Effectiveness',
            field: 'insightsScoreEffectiveness',
            format: '{0:n1}',
            cell: (cellProps: GridCellProps) => {
                return FormattedHazardClassPatternDutyMetricCell(cellProps, 'effectiveness')
            },
        },
        {
            title: 'Reservoir',
            field: 'insightsScoreReservoir',
            format: '{0:n1}',
            cell: (cellProps: GridCellProps) => {
                return FormattedHazardClassPatternDutyMetricCell(cellProps, 'reservoir')
            },
        },
        {
            title: '% FHA',
            field: 'insightsScoreFHA',
            format: '{0:n1}',
        },
        {
            title: 'Workload',
            field: 'insightsScoreWorkload',
            format: '{0:n1}',
        },
        {
            title: 'Insights Score',
            field: 'insightsScore',
            format: '{0:n1}',
            cell: (cellProps: GridCellProps) => {
                return FormattedHazardClassPatternDutyMetricCell(cellProps, 'insights')
            },
        },
    ]

    return columns
}

export default getColumns
