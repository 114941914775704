import { CompositeFilterDescriptor, getter, process, SortDescriptor, State } from '@progress/kendo-data-query'
import { ExcelExport, ExcelExportColumnProps } from '@progress/kendo-react-excel-export'
import {
    getSelectedState,
    Grid,
    GridColumn,
    GridColumnReorderEvent,
    GridColumnResizeEvent,
    GridDataStateChangeEvent,
    GridDetailRowProps,
    GridExpandChangeEvent,
    GridFilterChangeEvent,
    GridFilterOperators,
    GridHeaderSelectionChangeEvent,
    GridNoRecords,
    GridProps,
    GridSelectableMode,
    GridSelectionChangeEvent,
} from '@progress/kendo-react-grid'
import { GridSelectableSettings } from '@progress/kendo-react-grid/dist/npm/interfaces/GridSelectableSettings'
import useLocalStorage from 'hooks/useLocalStorage'
import React, { Ref, useCallback, useState } from 'react'
import getOverriddenColumns, {
    DefaultFilter,
    parseFilterFieldsFromGridDataState,
} from 'services/utilities/kendoGridUtils'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import ColumnPickerDialog from '../GenericDialogs/ColumnPickerDialog'
// KendoGridCustom.module.css wasn't working to change kendo grid styles,
// not sure what is going on there, so this is the solution.
import './KendoStyleOverrides.css'

export const CustomHeaderClass = 'customHeader'
export const getHeaderCellClass = (fieldName: string, filteredFields: string[], defaultClass?: string) => {
    const defaultVal = defaultClass ?? ''
    return filteredFields.includes(fieldName) ? `activeFilter ${defaultVal}` : defaultClass
}

export interface SelectionState {
    [id: string]: boolean | number[]
}

export const getSelectedIds = (state: SelectionState): number[] => {
    const entries = Object.keys(state)
    return entries.filter((x) => state[x] === true).map((x) => parseInt(x))
}

const DefaultPageSize = 100
const SELECTED_FIELD: string = 'selected'

export interface KendoGridCustomProps extends GridProps {
    gridSelectionMode?: GridSelectableMode
    showColumnPicker?: boolean
    onColumnPickerHide?: () => void
    /**
     * Center cell content in all cells
     */
    centeredContent?: boolean
    /**
     * Make the grid content smaller; smaller margins, font size, etc
     */
    small?: boolean
    /**
     * Using ellipsis menu in columns for filtering?
     */
    columnMenuFiltering?: boolean
    /**
     * Default filter
     */
    defaultFilters?: CompositeFilterDescriptor
    /**
     * Grid height
     */
    height?: string
    /**
     * Enable sorting
     */
    sortable?: boolean
    /**
     * Default sort
     */
    defaultSort?: SortDescriptor | false
    /**
     * Text to display if no records
     */
    defaultEmptyGridText?: string
    /**
     * Css class for the grid
     */
    className?: string
    /**
     * Grid data
     */
    data: any[]
    /**
     * Optional row index to scroll into view in the grid
     */
    scrollToRowIndex?: number
    /**
     * Unique field name for each row; defaults to "id"
     */
    dataItemKey?: string
    /**
     * If it is a grid that uses expandable rows (optional), provide the detail component here
     */
    detailComponent?: React.ComponentType<GridDetailRowProps>
    /**
     * For expandable rows
     */
    expandField?: string
    /**
     * Row expansion changed
     */
    onExpandChange?: (event: GridExpandChangeEvent) => void
    /**
     * GridColumns
     */
    columns: KendoGridColumn[]
    /**
     * Callback for setting shown/hidden columns
     */
    setColumnVisibility: (columns: KendoGridColumn[]) => void
    /**
     * Array of columns that can never be hidden (SFC options)
     */
    alwaysShowColumns?: string[]
    /**
     * Key that is used to store the column state in local storage
     */
    localStorageKeyForColumnState?: string
    /**
     * Key that is used to store the grid data state in local storage
     */
    localStorageKeyForGridDataState?: string
    /**
     * Boolean that is used to show a loading indicator in Grid.
     * Set to true before data on your page is loaded. Set to
     * false after data is loaded. Set to false in catch to
     * assure loading indicator is hidden in error scenario
     * when loading data.
     */
    isLoading?: boolean
    /**
     * Do not have the checkbox column for selecting rows
     */
    noSelection?: boolean
    // nice to have: refactor selectedRowsState to be inside KendoGridCustom; need to deal with a couple of special override cases
    selectedRowsState: SelectionState
    onSetSelectedRowsState: (newState: SelectionState) => void
    gridRef?: Ref<Grid>
    onFilterChange?: (event: GridFilterChangeEvent) => void

    /**
     * Optional Excel export options
     */
    excelExportRef?: React.MutableRefObject<ExcelExport | null>
    /**
     * Optional Excel export options
     */
    excelExportFilename?: string
    /**
     * Optional Excel export options
     */
    excelExportColumns?: ExcelExportColumnProps[]

    /**
     * Optional data state managed in parent component
     */
    dataState?: State
}

const generateGridColumnElements = (
    columns: KendoGridColumn[],
    onColumnsSubmit: (cols: KendoGridColumn[]) => void,
    enableFilters: boolean,
): JSX.Element[] => {
    return columns
        .filter((x) => !x.hide)
        .map((column, idx) => {
            const childColumns: JSX.Element[] = column.children
                ? generateGridColumnElements(column.children, onColumnsSubmit, enableFilters)
                : []

            // if filtering not enabled, then discard the columnMenu property
            let columnProps = column
            if (!enableFilters) {
                let columnMenu
                    // eslint-disable-next-line
                ;({ columnMenu, ...columnProps } = column)
            }

            return (
                <GridColumn
                    key={idx}
                    filterable={column.field !== SELECTED_FIELD}
                    filterCell={column.filterCell}
                    className={column.cellClassName}
                    headerClassName={column.headerClassName}
                    {...columnProps}
                >
                    {childColumns}
                </GridColumn>
            )
        })
}

const KendoGridCustom = (props: KendoGridCustomProps) => {
    const keyField = props.dataItemKey ?? 'id'
    const idGetter = getter(keyField)
    const [allRowsSelected, setAllRowsSelected] = useState(false)

    const [storedColumnSettings, setStoredColumnSettings] = useLocalStorage<KendoGridColumn[]>(
        props.localStorageKeyForColumnState,
        [],
    )

    // All grids by default will be in multiple selection mode
    const initialGridSelectionMode: GridSelectableSettings = {
        enabled: true,
        drag: false,
        cell: false,
        mode: props.gridSelectionMode ? props.gridSelectionMode : 'multiple',
    }

    let initialDefaultSort: { field: string; dir?: 'asc' | 'desc' } = {
        field: 'name',
        dir: 'asc',
    }

    if (props.defaultSort === false) {
        initialDefaultSort = { field: '' }
    } else if (props.defaultSort) {
        initialDefaultSort = props.defaultSort
    }

    // default to use paging
    const isPaged = props.pageable === undefined || props.pageable === true
    const pageSize = isPaged ? DefaultPageSize : undefined

    const defaultDataState = {
        sort: [initialDefaultSort],
        skip: 0,
        take: pageSize,
        filter: props.defaultFilters,
    }
    if (!props.columnMenuFiltering) {
        defaultDataState.filter = DefaultFilter
    }

    const [dataState, setDataState] = useLocalStorage<State>(props.localStorageKeyForGridDataState, defaultDataState)
    const dataStateToUse = props.dataState || dataState

    const onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {
            setAllRowsSelected(false)
            const targetEl = event.nativeEvent.target as HTMLElement
            if (targetEl && Array.from(targetEl.classList).find((x) => x === 'grid-button')) {
                // user clicked a button in the grid, don't change the row selection
                return
            }

            // filter selected items whose key no longer exist in dataset
            const selectedStateTrim = Object.fromEntries(
                Object.entries(props.selectedRowsState).filter(([key]) =>
                    props.data.some((ev) => ev.id.toString() === key),
                ),
            )

            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedStateTrim,
                dataItemKey: keyField,
            })

            props.onSetSelectedRowsState(newSelectedState)
        },
        [props, keyField],
    )

    // apply row selection state
    const dataWithSelectionState = props.data.map((item: any) => ({
        ...item,
        [SELECTED_FIELD]: props.selectedRowsState[idGetter(item)],
    }))

    // apply "processing" (filtering, sorting, etc)
    const processedData = process(dataWithSelectionState, dataStateToUse)

    const onHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target
            const checked = checkboxElement.checked
            const newSelectedState: SelectionState = {}

            if (!allRowsSelected) {
                props.data.forEach((item: any) => {
                    // When the user clicks the header checkbox, check all the rows that are not filtered out.
                    // `processedData` contains rows that are not filtered out.  I'm assuming all objects will have an `id` field here.
                    if (processedData.data.find((x) => x.id === item.id)) {
                        newSelectedState[idGetter(item)] = checked
                    }
                })
            }
            setAllRowsSelected(!allRowsSelected)
            props.onSetSelectedRowsState(newSelectedState)
        },
        [props, idGetter, processedData, allRowsSelected],
    )

    // add in the checkbox column
    let columnDefinitions = [...props.columns]
    if (props.noSelection !== true && !columnDefinitions.map((x) => x.field).includes(SELECTED_FIELD)) {
        columnDefinitions.unshift({
            field: SELECTED_FIELD,
            width: '50px',
            headerClassName: 'center-aligned checkbox-cell',
            cellClassName: 'center-aligned',
            headerSelectionValue:
                dataWithSelectionState.findIndex((item: any) => !props.selectedRowsState[idGetter(item)]) === -1,
        })
    }

    // optionally bring in column settings from local storage, override the defaults
    columnDefinitions = getOverriddenColumns(storedColumnSettings, columnDefinitions)

    // map our custom column defintions to GridColumn items
    const onColumnsSubmit = (columnsState: KendoGridColumn[]) => props.setColumnVisibility(columnsState)
    const gridColumns = generateGridColumnElements(
        columnDefinitions,
        onColumnsSubmit,
        props.columnMenuFiltering === true,
    )

    let gridClass = props.centeredContent ? 'center-content' : ''
    if (props.className) {
        gridClass += ' ' + props.className
    }
    if (props.small !== false) {
        gridClass += ' small-kendo-grid'
    }

    // Grid Filter Operators
    const filterOperators: GridFilterOperators = {
        text: [
            { text: 'grid.filterContainsOperator', operator: 'contains' },
            { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
            { text: 'grid.filterEqOperator', operator: 'eq' },
            { text: 'grid.filterNotEqOperator', operator: 'neq' },
            { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
            { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
            { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
        ],
        numeric: [
            { text: 'grid.filterEqOperator', operator: 'eq' },
            { text: 'grid.filterNotEqOperator', operator: 'neq' },
            { text: 'grid.filterGteOperator', operator: 'gte' },
            { text: 'grid.filterGtOperator', operator: 'gt' },
            { text: 'grid.filterLteOperator', operator: 'lte' },
            { text: 'grid.filterLtOperator', operator: 'lt' },
        ],
        date: [
            { text: 'grid.filterEqOperator', operator: 'eq' },
            { text: 'grid.filterNotEqOperator', operator: 'neq' },
            { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
            { text: 'grid.filterAfterOperator', operator: 'gt' },
            { text: 'grid.filterBeforeOperator', operator: 'lt' },
            { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
        ],
        boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data, columns, className, ...propsWithoutData } = props

    return (
        <>
            {props.showColumnPicker && (
                <ColumnPickerDialog
                    columns={columns}
                    filteredColumns={parseFilterFieldsFromGridDataState(dataStateToUse)}
                    alwaysShownColumns={props.alwaysShowColumns ?? []}
                    closeCallback={() => props.onColumnPickerHide?.()}
                    updateColumns={(updatedColumns) => {
                        props.setColumnVisibility(updatedColumns)
                        setStoredColumnSettings(updatedColumns, { setState: true })
                    }}
                />
            )}
            {props.excelExportRef && props.excelExportFilename && props.excelExportColumns && (
                <ExcelExport
                    ref={props.excelExportRef}
                    fileName={props.excelExportFilename}
                    data={processedData.data}
                    columns={props.excelExportColumns}
                />
            )}
            <Grid
                ref={props.gridRef}
                className={gridClass}
                reorderable={props.reorderable !== false}
                resizable
                sortable={props.sortable === undefined || props.sortable === true}
                pageable={isPaged}
                pageSize={pageSize}
                total={props.data.length}
                skip={dataStateToUse.skip}
                sort={dataStateToUse.sort}
                filter={dataStateToUse.filter}
                filterOperators={filterOperators}
                onFilterChange={(e) => {
                    setDataState(
                        { ...dataState, filter: e.filter || props.defaultFilters },
                        { setState: true, dispatchToOtherComponents: true },
                    )
                }}
                rowRender={props.rowRender}
                fixedScroll
                style={{ height: props.height }}
                expandField={props.expandField}
                detail={props.detailComponent}
                onExpandChange={props.onExpandChange}
                data={processedData}
                dataItemKey={keyField}
                selectable={initialGridSelectionMode}
                selectedField={SELECTED_FIELD}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                onDataStateChange={(e: GridDataStateChangeEvent) => {
                    setDataState(e.dataState, { setState: true, dispatchToOtherComponents: true })
                }}
                onColumnReorder={(e: GridColumnReorderEvent) => {
                    const updatedColumns = [...columnDefinitions]
                    updatedColumns.forEach((columnDefinition) => {
                        const updatedColumn = e.columns.find((x) => x.field === columnDefinition.field)
                        if (updatedColumn) {
                            columnDefinition.orderIndex = updatedColumn.orderIndex
                        }
                    })
                    setStoredColumnSettings(updatedColumns, { setState: true })
                }}
                onColumnResize={(e: GridColumnResizeEvent) => {
                    const updatedColumns = [...columnDefinitions]
                    // if the grid has detailed row expansion, then there is an extra column that we don't
                    // track, so offset the index by 1
                    const columnIndex = props.detailComponent ? e.index - 1 : e.index
                    // when the user double-clicks to auto-resize, the e.newWidth is 0 (for some reason)
                    // and we need to get the width from the e.columns[] object.  Otherwise, use the width
                    // from e.newWidth.
                    if (columnIndex < 0) {
                        // this will be the case when adjusting the width of the expand/collapse row details
                        return
                    }
                    const newWidth = e.newWidth === 0 && e.oldWidth === 0 ? e.columns[columnIndex].width : e.newWidth
                    updatedColumns[columnIndex].width = newWidth
                    setStoredColumnSettings(updatedColumns)
                }}
                {...propsWithoutData}
            >
                {gridColumns}
                {props.isLoading && <GridNoRecords>Loading...</GridNoRecords>}
                {props.data.length === 0 && !props.isLoading && (
                    <GridNoRecords>{props.defaultEmptyGridText ?? 'No Records Available'}</GridNoRecords>
                )}
            </Grid>
        </>
    )
}

export default KendoGridCustom
