import { cloneDeep } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deserializeObjectDates, serializeObjectDates } from 'services/utilities/objectUtils'
import { globalActions } from 'store/globalStore'
import { RootState } from 'store/store'
import { User } from 'types/interfaces'
import useUser from './useUser'

const getLocalValue = <T>(user?: User, key?: string, initialValue?: T) => {
    try {
        // Get from local storage by key
        if (!user || !key) {
            return initialValue
        }
        const userKey = `user_${user?.id}_${key}`
        const item = window.localStorage.getItem(userKey)
        // Parse stored json or if none return initialValue
        if (item) {
            const parsedItem = JSON.parse(item)
            // return any date strings back to object form
            deserializeObjectDates(parsedItem)
            return parsedItem
        }
        return initialValue
    } catch (error) {
        // If error also return initialValue
        return initialValue
    }
}

export type UpdateMode = {
    setState?: boolean
    dispatchToOtherComponents?: boolean
}
// Hook
const useLocalStorage = <T>(key?: string, initialValue?: T): [T, (value: T, updateStateMode?: UpdateMode) => void] => {
    const dispatch = useDispatch()
    const user = useUser()
    const itemUpdatedTime = useSelector<RootState, number>((x) => x.app.updatedLocalStorageTime)

    useEffect(() => {
        // user may not be set in the store by the time this hook is called, so we need to update the state of what we
        // know is in local storage if the user object changes
        if (user) {
            setStoredValue(getLocalValue(user, key, initialValue))
        }

        // if I include initialValue in the deps array below, it causes infinite loop;
        // not sure why initialValue is changing when setStoredValue is updated, so ignoring
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, key, itemUpdatedTime])

    const userKey = `user_${user?.id}_${key}`
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        return getLocalValue(user, key, initialValue)
    })

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback(
        (value: any, updateMode?: UpdateMode) => {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore = value instanceof Function ? value(storedValue) : value
                // Save state

                if (updateMode?.setState) {
                    setStoredValue(valueToStore)
                }

                // this allows an update of the local storage state in one component to update another component.
                // Eg, updating filtering in KendoGridCustom needs to trigger an update of useLocalStorage in ScenariosPage
                // to update the color of the filter icons on the grid
                if (updateMode?.dispatchToOtherComponents) {
                    dispatch(globalActions.setUpdatedLocalStorageTime())
                }

                // Save to local storage
                if (user && key) {
                    // use special date serialization that allows us to know what
                    // properties need to be deserialized back to date objects
                    const copy = cloneDeep(valueToStore)
                    serializeObjectDates(copy)
                    window.localStorage.setItem(userKey, JSON.stringify(copy))
                }
            } catch (error) {
                throw Error(`Error setting localStorage item ${userKey}: ${error}`)
            }
        },
        [key, storedValue, userKey, user, dispatch],
    )

    return [storedValue, setValue]
}

export default useLocalStorage
