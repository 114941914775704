import { FormEvent, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import globals from 'services/global/globals'
import { handleApiError } from 'services/utilities/toastrUtils'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'

const RenamePatternDialog = (props: {
    hazardClassId: number
    patternId: number
    originalName: string
    isHidden: boolean
    closeCallback: (result: DialogResultEnum, newName?: string) => void
}) => {
    const insightsApi = globals.getApi().getReportingApi()
    const patternNameRef = useRef<HTMLInputElement>(null)
    const [validated, setValidated] = useState(false)

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()
        const form = event.target as HTMLFormElement

        if (form.checkValidity() === false) {
            setValidated(true)
            // keep the form open, let the user fix the issues
            return
        }

        try {
            const newName = patternNameRef.current!.value
            await insightsApi.updatePatternProperties(props.hazardClassId, props.patternId, newName, props.isHidden)
            props.closeCallback(DialogResultEnum.Completed, newName)
        } catch (err: any) {
            handleApiError(err)
        }
    }

    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <Form id="importScenarioDialog" noValidate validated={validated} onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Rename &#39;{props.originalName}&#39;</Modal.Title>
                </Modal.Header>
                <Modal.Body key="dialogBody">
                    <Form.Control
                        ref={patternNameRef}
                        id="txtRename"
                        name="txtRename"
                        autoFocus
                        autoComplete="off"
                        type="text"
                        defaultValue={props.originalName}
                        placeholder="New pattern name"
                        required
                    />
                    <Form.Control.Feedback type="invalid">Please provide a pattern name</Form.Control.Feedback>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCustom isLarge type="submit" variant="primary">
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </ModalWrapper>
    )
}

export default RenamePatternDialog
