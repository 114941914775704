import * as d3 from 'd3'
import { D3DragEvent, D3Selection } from 'types/d3TypeHelpers'
import { DetailData, DetailDataWithPosition } from 'types/DetailItem'
import { DashboardInfo, XScale } from './EffectivenessGraphTypes'
import { getGraphXOffset } from './EffectivenessGraphUtils'

/**
 * Manages Inspector Lines for graph dashboards.
 */
class InspectorLines {
    private lastDraggedDashboardDetailItem: DetailData | null = null

    constructor(
        private graphContentHeight: number,
        private xScale: XScale,
        private addDashboard: (dashboard: DashboardInfo) => void,
        private updateDashboard: (dashboardId: string, detailData: DetailData, updateInspectorLine: boolean) => void,
        private closeDashboard: (dashboard: DashboardInfo) => void,
        private isReadOnly: boolean,
        private isPrintPreview: boolean,
    ) {}

    /**
     * Handle click on the graph to create a dashboard at the clicked location
     * @param e
     * @param orderNumber
     * @param scaledData
     */
    createDashboardAtClickLocation = (
        e: PointerEvent,
        existingDashboards: DashboardInfo[],
        scaledData: DetailDataWithPosition[],
    ) => {
        const graphOffset = getGraphXOffset()
        const shiftOffset = e.shiftKey ? this.getShiftClickOffset(e.pageX, graphOffset, scaledData) : 0

        const graphX = Math.round(e.pageX) + shiftOffset - graphOffset

        if (this.isCloseToOtherDashboardInspector(graphX, existingDashboards)) {
            // is too close to another inspector line, so assume the user meant to drag
            // the other line.  So don't add it.
            return
        }

        // detailed data point for the clicked time
        const detailDataItem = this.getDatumForScreenXPosition(graphX, scaledData)

        const inspectorPosition = this.xScale(detailDataItem.utcTime.getTime())
        const rightEdgeOfGraph = this.xScale.range()[1]

        // place the dashboard *just* to the right of the inspector line; but if it is too close
        // to the right edge of the graph, then put it on the left side of the inspector
        const approximateDashboardWidth = 250
        const offsetFromEdgeOfDashboard = 10
        const dashboardInitialOffsetFromInspector =
            rightEdgeOfGraph - inspectorPosition > approximateDashboardWidth
                ? offsetFromEdgeOfDashboard
                : -(approximateDashboardWidth + offsetFromEdgeOfDashboard)

        // new dashboard info object
        const dashboardId = `dashboard-id-${new Date().getTime()}`
        const dashboardObj: DashboardInfo = {
            dashboardId,
            dashboardOrderNumber: existingDashboards.length + 1,
            detailItem: detailDataItem,
            dashboardLeftX: inspectorPosition + dashboardInitialOffsetFromInspector + getGraphXOffset(),
            isPinned: false,
        }

        // will cause a re-render with the dashboards in place
        this.addDashboard(dashboardObj)
    }

    /**
     * Create the SVG for an inspector line
     * @param group
     * @param dashboard
     * @param scaledData
     */
    createInspectorLine = (group: D3Selection, dashboard: DashboardInfo, scaledData: DetailDataWithPosition[]) => {
        // position the entire group so all the items below can be dragged together
        const groupXPosition = this.xScale(dashboard.detailItem.utcTime.getTime())
        const lineXPosition = 0

        const inspectorGroup = group.append('svg').attr('x', groupXPosition)

        // dashed line that is the visible inspector line
        inspectorGroup
            .append('line')
            .attr('class', 'inspector-dashed-line')
            .attr('x1', lineXPosition)
            .attr('x2', lineXPosition)
            .attr('y1', 0)
            .attr('y2', this.graphContentHeight)

        // circle that contains the dashboard number
        const numberCircleXPosition = lineXPosition + 28
        const numberCircleRadius = 22
        const numberCircleYPosition = 22
        inspectorGroup
            .append('circle')
            .attr('class', 'inspector-line-number-circle')
            .attr('r', numberCircleRadius)
            .attr('cx', numberCircleXPosition)
            .attr('cy', numberCircleYPosition)

        // dashboard number in the above circle
        inspectorGroup
            .append('text')
            .text(dashboard.dashboardOrderNumber)
            .attr('class', 'inspector-line-number-circle-text')
            .attr('x', numberCircleXPosition - 8)
            .attr('y', numberCircleYPosition + 10)

        // circle for closing the inspector/dashboard
        const numberCircleCloseCircleXPosition = numberCircleXPosition + 18
        const closeCircleRadius = 8
        const closeCircleY = 8
        if (!this.isPrintPreview) {
            inspectorGroup
                .append('circle')
                .attr('class', 'inspector-line-number-circle-close-circle')
                .attr('r', 8)
                .attr('cx', numberCircleCloseCircleXPosition)
                .attr('cy', closeCircleY)
                .on('click', () => {
                    this.closeDashboard(dashboard)
                })

            const createXStroke = (direction: 'TopToBottom' | 'BottomToTop') => {
                const multiplier = direction === 'TopToBottom' ? 1 : -1
                inspectorGroup
                    .append('line')
                    .attr('class', 'inspector-line-number-circle-close-circle-x')
                    .attr('x1', numberCircleCloseCircleXPosition - closeCircleRadius / 2)
                    .attr('x2', numberCircleCloseCircleXPosition + closeCircleRadius / 2)
                    .attr('y1', closeCircleY - (multiplier * closeCircleRadius) / 2)
                    .attr('y2', closeCircleY + (multiplier * closeCircleRadius) / 2)
                    .on('click', () => {
                        this.closeDashboard(dashboard)
                    })
            }

            createXStroke('TopToBottom')
            createXStroke('BottomToTop')

            // invisible rectangle that gives the user more to grab to drag
            const rectangleWidth = 14
            inspectorGroup
                .append('rect')
                .attr('class', 'inspector-line-container')
                .attr('x', lineXPosition - rectangleWidth / 2)
                .attr('y', 0)
                .attr('width', rectangleWidth)
                .attr('height', this.graphContentHeight)

            // drag function applied to the invisible rectangle (which is essentially the inspector line)
            const inspectorDragFn = d3
                .drag<SVGSVGElement, any>()
                .subject(Object)
                .on('drag', (e: D3DragEvent) => {
                    this.onInspectorLineDrag(e, dashboard.dashboardId, inspectorGroup, scaledData, 'NoSnap')
                })
                .on('end', (e: D3DragEvent) => {
                    if (!this.lastDraggedDashboardDetailItem) {
                        return
                    }
                    this.onInspectorLineDrag(e, dashboard.dashboardId, inspectorGroup, scaledData, 'SnapWithShiftKey')
                    // update the container so re-renders due to resize, etc, will keep the correct inspector position
                    this.updateDashboard(dashboard.dashboardId, this.lastDraggedDashboardDetailItem, true)
                })

            inspectorGroup.call(inspectorDragFn)
        }
    }

    /**
     * Is this time "close" to another dashboard inspector.
     */
    private isCloseToOtherDashboardInspector(targetXPosition: number, dashboards: DashboardInfo[]) {
        const minimumPixelGap = 10
        return (
            dashboards.filter(
                (db) => Math.abs(this.xScale(db.detailItem.utcTime.getTime()) - targetXPosition) < minimumPixelGap,
            ).length > 0
        )
    }

    /**
     * How far from the mouse position is the nearest "edge" that we might want to snap to.
     * @param pageX
     * @param graphOffset
     * @param scaledData
     * @returns
     */
    private getShiftClickOffset = (pageX: number, graphOffset: number, scaledData: DetailDataWithPosition[]) => {
        const getDetailItemLabelsString = (d: DetailData) => {
            const getValWithSlash = (s: string) => (s ? s + '/' : '')
            return getValWithSlash(d.workLabel) + getValWithSlash(d.markerLabel) + d.sleepLabel
        }

        const getDataItemComparableDescriptionAtIndex = (index: number) => {
            const dataItem = scaledData[index]
            return dataItem.activity + getDetailItemLabelsString(dataItem)
        }

        const clickLocationDescription = getDataItemComparableDescriptionAtIndex.call(this, pageX - graphOffset)
        const clickIndex = pageX - graphOffset
        let activityStartIndex = 0
        let activityEndIndex = 0

        for (let i = pageX - graphOffset; i < scaledData.length; i++) {
            if (getDataItemComparableDescriptionAtIndex(i) !== clickLocationDescription) {
                break
            }
            activityEndIndex = i
        }

        for (let i = clickIndex; i > 0; i--) {
            if (getDataItemComparableDescriptionAtIndex(i) !== clickLocationDescription) {
                break
            }
            activityStartIndex = i
        }
        if (activityEndIndex < scaledData.length && activityStartIndex > 0) {
            const clickWasCloserToTheEnd = clickIndex - activityStartIndex > activityEndIndex - clickIndex
            if (clickWasCloserToTheEnd === true) {
                // add 1 to place the line at the very start of the next event instead of the end of this event.
                return activityEndIndex - clickIndex + 1
            }
            return activityStartIndex - clickIndex
        }
        return 0
    }

    private getDatumForScreenXPosition = (graphX: number, scaledData: DetailDataWithPosition[]) => {
        const x = Math.round(graphX)
        let sleepDataItemIdx = 0
        // There seems to be a bug somewhere, possibly in graphOffset.  I changed > 0 to > 1 below
        // so that users can shift+click to get the very first minute of the schedule.
        if (x > 1) {
            sleepDataItemIdx = x
            sleepDataItemIdx = Math.min(sleepDataItemIdx, scaledData.length - 1)
        } else {
            sleepDataItemIdx = 0
        }
        return scaledData[sleepDataItemIdx]
    }

    /**
     * Handles inspector line drag event (fires repeatedly during drag)
     */
    private onInspectorLineDrag = (
        e: D3DragEvent,
        dashboardId: string,
        inspectorGroup: d3.Selection<SVGSVGElement, unknown, null, undefined>,
        scaledData: DetailDataWithPosition[],
        snapMode: 'SnapWithShiftKey' | 'NoSnap',
    ) => {
        const mouseEvent = e.sourceEvent as MouseEvent
        const usingShiftKey = mouseEvent.shiftKey
        if (snapMode === 'SnapWithShiftKey' && !usingShiftKey) {
            return
        }

        let newXPosition: number = 0
        if (snapMode === 'SnapWithShiftKey') {
            const shiftOffset = this.getShiftClickOffset(mouseEvent.pageX, getGraphXOffset(), scaledData)
            newXPosition = e.x + shiftOffset
        } else {
            newXPosition = parseFloat(inspectorGroup.attr('x')) + e.dx
        }
        const xMax = this.xScale.range()[1]

        // constrain within the graph
        if (newXPosition < 0) {
            newXPosition = 0
        }
        if (newXPosition > xMax) {
            newXPosition = xMax
        }

        // move the inspector line
        inspectorGroup.attr('x', newXPosition)

        const updatedDetailDataItem = this.getDatumForScreenXPosition(newXPosition, scaledData)
        this.updateDashboard(dashboardId, updatedDetailDataItem, false)
        // track this for end-of-drag event which needs to update the container with the state of the inspector lines
        this.lastDraggedDashboardDetailItem = updatedDetailDataItem
    }
}

export default InspectorLines
