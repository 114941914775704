import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RecalculateItemType } from 'services/api/sfProcessingApi'
import globals from 'services/global/globals'
import {
    insightsRecalculationActions,
    RecalculationState,
    RecalculationStatusComplete,
    reportRecalculationActions,
} from 'store/recalculationStore'
import { RootState } from 'store/store'
import useSessionStatus from './useSessionStatus'

/**
 * Check the status of items known to be recalculating now.  List of items is in the redux store, populated by checkExistingRecalculationRequests.
 * @param recalculateItemType
 * @param intervalInMs
 * @returns
 */
const useRecalculateItemsStatus = (
    recalculateItemType: RecalculateItemType,
    intervalInMs: number,
): [Date | null, RecalculationState, RecalculationState] => {
    const dispatch = useDispatch()
    const api = globals.getApi()
    const processingApi = api.getProcessingApi()
    const userState = useSessionStatus()
    const [recalculationCompletedTime, setRecalcCompletedTime] = useState<Date | null>(null)
    const reportsRecalculationState = useSelector<RootState, RecalculationState>((x) => x.reportsRecalculation)
    const insightsRecalculationState = useSelector<RootState, RecalculationState>((x) => x.insightsRecalculation)

    const requestIds = useSelector<RootState, number[]>((x) => {
        return recalculateItemType === 'Insights'
            ? x.insightsRecalculation.recalculationRequestIds
            : x.reportsRecalculation.recalculationRequestIds
    })

    useEffect(() => {
        let intervalId = 0
        const stopPolling = () => window.clearInterval(intervalId)

        const fetchRecalculationStatus = async () => {
            const statusRecords = await processingApi.getRecalculationRequestStatus(requestIds)

            if (statusRecords.find((x) => x.status === RecalculationStatusComplete)) {
                // this state is returned, so the view will refresh the data
                setRecalcCompletedTime(new Date())
            }

            if (recalculateItemType === 'Insights') {
                dispatch(insightsRecalculationActions.setItemRecalculationStatus(statusRecords))
            } else if (recalculateItemType === 'Report') {
                dispatch(reportRecalculationActions.setItemRecalculationStatus(statusRecords))
            }
        }

        // there are pending requests, so start polling for completion
        if (requestIds.length) {
            intervalId = window.setInterval(() => {
                if (userState === 'LoggedIn') {
                    fetchRecalculationStatus()
                }
            }, intervalInMs)
            fetchRecalculationStatus()
        }

        return () => {
            stopPolling()
        }
    }, [userState, requestIds, dispatch, recalculateItemType, api, processingApi, intervalInMs])

    return [recalculationCompletedTime, reportsRecalculationState, insightsRecalculationState]
}

export default useRecalculateItemsStatus
