import sfLogo from 'assets/SFLogoColorWithTag.svg'
import useUser from 'hooks/useUser'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import ErrorDialog from 'views/Common/GenericDialogs/ErrorDialog'
import classes from './ResetPassword.module.css'

/**
 * Component handles both the request to reset the password (which generates an email) and the setting of the new password.
 */
const ResetPassword = (props: { mode: 'RequestReset' | 'SetNewPassword'; logout?: () => void }) => {
    const history = useHistory()
    const params = useParams() as any
    const userIdRef = useRef<HTMLInputElement>(null)
    const password1Ref = useRef<HTMLInputElement>(null)
    const password2Ref = useRef<HTMLInputElement>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [invalidSetPasswordToken, setInvalidResetToken] = useState(false)
    const user = useUser()
    const api = globals.getApi()

    useEffect(() => {
        const validateSetPasswordToken = async () => {
            // check that the reset-password token is still valid
            const token = params.token as string
            try {
                await api.validatePasswordResetToken(token)
            } catch (error: any) {
                setInvalidResetToken(true)
            }
        }

        if (props.mode === 'SetNewPassword') {
            validateSetPasswordToken()
        }
        document.title = 'SAFTE-FAST Password Reset'
    }, [params.token, props, api])

    const logout = () => {
        props.logout?.()
        history.push('/')
    }

    /**
     * Mode where the user is requesting a password reset link
     */
    const sendResetRequest = async () => {
        const userId = userIdRef.current!.value
        try {
            await api.passwordResetRequest(userId)
            toast.success('Please check your email in the next few minutes for your password reset link.')
            if (props.logout) {
                logout()
                return
            }
            userIdRef.current!.value = ''
        } catch (error: any) {
            setErrorMessage(error.message)
        }
    }

    /**
     * Mode where the user is setting a new password
     */
    const setNewPassword = async () => {
        const password1 = password1Ref.current!.value
        const password2 = password2Ref.current!.value
        if (password1 !== password2) {
            toast.error('The passwords you have entered do not match')
            return
        }

        try {
            const token = params.token as string
            await api.setNewPassword(token, password1, password2)
            if (props.logout) {
                logout()
                toast.success('Your password has been reset. Please sign in again.')
                return
            }

            toast.success('Your password has been reset. Please go to the login page to sign in again.')

            password1Ref.current!.value = ''
            password2Ref.current!.value = ''
        } catch (error: any) {
            setErrorMessage(error.message)
        }
    }

    /**
     * User clicked OK.
     */
    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()

        if (props.mode === 'RequestReset') {
            await sendResetRequest()
            return
        }

        if (props.mode === 'SetNewPassword') {
            await setNewPassword()
        }
    }

    const heading = props.mode === 'RequestReset' ? 'SAFTE-FAST Password Reset' : 'Set your SAFTE-FAST Password'
    const submitButtonText = props.mode === 'RequestReset' ? 'Reset My Password' : 'Set Password'
    const preFilledEmailAddress = props.logout && user ? user.loginId : ''

    if (invalidSetPasswordToken) {
        return (
            <Alert variant="danger">
                The password reset link that you followed either is invalid or has expired. Please{' '}
                <Link to="/">click here</Link> to return to the login page.
            </Alert>
        )
    }

    return (
        <>
            {errorMessage && (
                <ErrorDialog
                    headerText="Error"
                    message={errorMessage}
                    closeCallback={() => {
                        setErrorMessage(null)
                    }}
                />
            )}
            <div className={classes.container}>
                {!props.logout && <img className={classes.logo} alt="logoc" src={sfLogo} />}
                <h4>{heading}</h4>
                <div className={classes.formContainer}>
                    <Form onSubmit={submitHandler}>
                        {props.mode === 'RequestReset' && (
                            <>
                                <Form.Control
                                    ref={userIdRef}
                                    id="txtLogin"
                                    type="email"
                                    placeholder="Enter your email address"
                                    defaultValue={preFilledEmailAddress}
                                    disabled={!!props.logout}
                                    required
                                />
                                <small className="text-muted">
                                    You will receive an email with a link to set your new password.
                                </small>
                            </>
                        )}

                        {props.mode === 'SetNewPassword' && (
                            <>
                                <Form.Control
                                    className={classes.password1}
                                    ref={password1Ref}
                                    type="password"
                                    placeholder="Enter your new password"
                                    required
                                />
                                <Form.Control
                                    ref={password2Ref}
                                    type="password"
                                    placeholder="Enter your new password"
                                    required
                                />
                            </>
                        )}

                        <Button type="submit" variant="primary" className="btn-block mt-3 mb-3">
                            {submitButtonText}
                        </Button>
                        {!props.logout && <Link to="/">Go to the Login Page</Link>}
                    </Form>
                </div>
            </div>
        </>
    )
}

export default ResetPassword
