import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { isDesktopMode } from 'services/axios/axios-sfc'

/**
 * Register window-level functions that get called from the desktop c# code
 */
const registerDesktopCalls = (
    setCurrentScheduleId: (newScheduleId: string) => void,
    setIsReadonlyFromSfc: (isReadonly: boolean) => void,
) => {
    const w = window as any
    w.sfcSetCurrentScheduleId = (newScheduleId: string) => {
        setCurrentScheduleId(newScheduleId)
    }

    w.sfcSetScheduleEditable = (editable: string) => {
        setIsReadonlyFromSfc(editable.toLocaleLowerCase() === 'false')
    }

    w.sfcDisplayPopup = (message: string, timeoutMs: number) => {
        toast.success(message, {
            autoClose: timeoutMs,
        })
    }
}

const useRegisterDesktopToFrontEndCalls = (
    setCurrentScheduleId: (newScheduleId: string) => void,
    setIsReadonlyFromSfc: (isReadonly: boolean) => void,
) => {
    useEffect(() => {
        // register calls from SFC
        if (isDesktopMode()) {
            registerDesktopCalls(setCurrentScheduleId, setIsReadonlyFromSfc)
        }
    }, [setCurrentScheduleId, setIsReadonlyFromSfc])
}

export default useRegisterDesktopToFrontEndCalls
