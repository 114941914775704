import { Grid, GridCellProps, GridItemChangeEvent, GridRowProps } from '@progress/kendo-react-grid'
import { flatten } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { getLowestId } from 'services/utilities/arrayUtils'
import { scrollToRow } from 'services/utilities/kendoGridUtils'
import {
    createShift,
    getShiftsHighestDayNumber,
    Pattern,
    Shift,
    sortShifts,
    updateShiftToMatchSegment,
} from 'types/Shifts'
import IconButton from 'views/Common/Buttons/IconButton'
import SplitIconButton from 'views/Common/Buttons/SplitIconButton'
import ToolbarDropdownButton from 'views/Common/Buttons/ToolbarDropdownButton'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import KendoGridCustom, { SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import { CellRender, RowRender } from 'views/Common/Kendo/KendoGridEditInPlaceRenderers'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'
import InfoIcon from 'views/Common/Widget/InfoIcon'
import AddMultipleShiftsOptionsDialog, { AddMultipleShiftsValue } from './AddMultipleShiftsOptionsDialog'
import ShiftsGrid from './ShiftsGrid'

type ShownDialogType = 'None' | 'ConfirmDeletePattern' | 'AddMultipleShiftsDialog'

const patternsColumns: KendoGridColumn[] = [
    {
        title: 'Pattern',
        headerClassName: 'left-aligned',
        cellClassName: 'left-aligned',
        field: 'name',
        editable: true,
        editor: 'text',
    },
    { title: 'Duration', editable: false, field: 'duration' },
]

interface EditablePattern extends Pattern {
    inEdit?: string
}

const PatternsTab = (props: {
    patterns: Pattern[]
    shiftSegments: Shift[]
    setDialogPatterns: (patterns: Pattern[]) => void
}) => {
    const patternsGridRef = useRef<Grid>(null)
    const shiftsGridRef = useRef<Grid>(null)
    // state of the patterns grid
    const patterns = [...props.patterns]
    const defaultPatternsGridState: SelectionState = {}
    let defaultShifts: Shift[] = []
    let defaultIsAddShiftButtonDisabled = true

    // if any patterns, select the first one in the grid automatically for initial render
    if (patterns.length > 0) {
        const firstPattern = patterns[0]
        defaultPatternsGridState[firstPattern.id] = true
        defaultShifts = firstPattern.shifts
        // On load if there are patterns then enable action buttons (Copy Pattern, Delete Pattern, Add Shift)
        defaultIsAddShiftButtonDisabled = false
    }
    const [selectedPatternRowsState, setSelectedPatternRowsState] = useState<SelectionState>(defaultPatternsGridState)

    // state of the shifts grid
    const [editField, setEditField] = useState<string | undefined>(undefined)
    const [selectedPatternShifts, setSelectedPatternShifts] = useState<Shift[]>(defaultShifts)
    const [shiftsGridSelectedIds, setShiftsGridSelectedIds] = useState<number[]>([])
    const [showDialog, setShowDialog] = useState<ShownDialogType>('None')
    const editablePatterns = patterns.map((x) => x as EditablePattern)
    // Action Buttons (Copy Pattern, Delete Pattern, Add Shift) are
    // disabled by default (true value) and enabled(false value) when a Pattern is selected
    const [isActionButtonsDisabled, setIsActionButtonsDisabled] = useState<boolean>(defaultIsAddShiftButtonDisabled)
    // update Is Action Buttons (Copy Pattern, Delete Pattern, Add Shift) disabled when selectedPatternRowsState changes
    useEffect(() => {
        setIsActionButtonsDisabled(anyPatternSelected(selectedPatternRowsState))
        recalculatePatternDuration()
    }, [selectedPatternRowsState]) // eslint-disable-line react-hooks/exhaustive-deps

    const recalculatePatternDuration = () => {
        // Calculate Pattern duration based on shifts in a pattern array length
        patterns.forEach((item) => {
            item.duration = getShiftsHighestDayNumber(item.shifts)
        })
    }

    /**
     * Enter into Edit mode
     * @param dataItem
     * @param field
     */
    const enterEditHandler = (dataItem: any, field: string | undefined) => {
        const patternItem = dataItem as Pattern
        // const shiftItem = dataItem as Shift
        // set the appropriate item to be "inEdit"
        props.setDialogPatterns([
            ...editablePatterns.map((pattern) => ({
                ...pattern,
                inEdit: pattern.id === patternItem.id ? field : undefined,
            })),
        ])
        setEditField(field)
    }

    /**
     * Exit from Edit mode
     */
    const exitEditHandler = () => {
        props.setDialogPatterns([...editablePatterns.map((pattern) => ({ ...pattern, inEdit: undefined }))])
        // setShifts([...editableShifts.map((shift) => ({ ...shift, inEdit: undefined }))])
        setEditField(undefined)
    }

    const getSelectedPattern = (patternGridRowState: SelectionState): Pattern | undefined => {
        const selectedPatternId = parseInt(Object.keys(patternGridRowState)[0]) || -1
        return patterns.find((x) => x.id === selectedPatternId)
    }

    const anyPatternSelected = (patternGridRowState: SelectionState): boolean => {
        const num: any[] = Object.values(patternGridRowState)
        return num.every((item) => item === false)
    }

    const updateShifts = (shifts: Shift[]) => {
        const selectedPattern = getSelectedPattern(selectedPatternRowsState)
        // update the shifts in the pattern object
        let updatedPatterns = [...patterns]
        updatedPatterns = updatedPatterns.map((pattern) => {
            if (pattern.id !== selectedPattern?.id) {
                return pattern
            }
            pattern.duration = getShiftsHighestDayNumber(shifts)
            // update the shifts in the selected pattern object
            return { ...pattern, shifts }
        })
        // return updated
        props.setDialogPatterns(updatedPatterns)

        // update the tracked shifts
        setSelectedPatternShifts(shifts)
    }

    // Add Single Shift Handler
    const addShiftHandler = () => {
        const highestDayNumber = getShiftsHighestDayNumber(selectedPatternShifts)
        const shiftId = getLowestId(selectedPatternShifts) - 1
        const updated = [...selectedPatternShifts, createShift(shiftId, highestDayNumber + 1)]
        scrollToRow(shiftsGridRef, updated.length)
        updateShifts(updated)
    }

    /**
     * Handle when the Add Multiple Shifts dialog is OK'd
     * @param scheduleDate
     * @param shifts
     * @param multipleShifts
     */
    const addMultipleShiftsHandler = (multipleShifts: AddMultipleShiftsValue) => {
        setSelectedPatternShifts((previous) => {
            const shifts = [...previous]
            const highestDayNumber = getShiftsHighestDayNumber(shifts)
            let shiftId = getLowestId(shifts) - 1
            let dayNumber = highestDayNumber + 1
            for (let i = 0; i < multipleShifts.numberOfDays; i++) {
                for (let j = 0; j < multipleShifts.numberOfShiftsPerDay; j++) {
                    shifts.push(createShift(shiftId--, dayNumber))
                }
                dayNumber++
            }
            scrollToRow(shiftsGridRef, shifts.length)
            return shifts
        })
    }

    const deleteShiftsHandler = () => {
        const updatedShifts = [...selectedPatternShifts.filter((x) => !shiftsGridSelectedIds.includes(x.id))]
        updateShifts(updatedShifts)
        setShiftsGridSelectedIds([])
        recalculatePatternDuration()
    }

    const setSelectedGridShiftsToMatchShiftSegmentDefinition = (shiftSegment: Shift) => {
        const updated = [...selectedPatternShifts]
        for (let i = 0; i < updated.length; i++) {
            const shift = updated[i]
            if (shiftsGridSelectedIds.includes(shift.id)) {
                updateShiftToMatchSegment(shift, shiftSegment)
            }
        }
        updateShifts(updated)
    }

    const selectPatternById = (patternId: number) => {
        const newRowState: SelectionState = {}
        newRowState[patternId] = true
        setSelectedPatternRowsState(newRowState)
    }

    const addPatternClickHandler = () => {
        const newPatternId = getLowestId(patterns) - 1
        const newPatterns = [...patterns, { id: newPatternId, shifts: [], name: 'New Pattern', uuid: '', duration: 0 }]
        props.setDialogPatterns(newPatterns)
        scrollToRow(patternsGridRef, newPatterns.length)
        setSelectedPatternShifts([])
        selectPatternById(newPatternId)
    }

    const copyPatternClickHandler = () => {
        const selectedPattern = getSelectedPattern(selectedPatternRowsState)
        if (!selectedPattern) {
            // nothing to copy
            return
        }
        const newPatternId = getLowestId(patterns) - 1
        const newFirstShiftId = getLowestId(flatten(patterns.map((x) => x.shifts))) - 1
        const sourcePattern = { ...selectedPattern, id: newPatternId, name: `${selectedPattern.name} - copy` }
        sourcePattern.shifts.forEach((shift, index: number) => {
            shift.id = newFirstShiftId - index
        })
        props.setDialogPatterns([...patterns, sourcePattern])
        selectPatternById(newPatternId)
    }

    const deletePatternClickHandler = () => {
        const selectedPattern = getSelectedPattern(selectedPatternRowsState)
        if (!selectedPattern) {
            // nothing to delete
            return
        }
        setShowDialog('ConfirmDeletePattern')
    }

    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, propsThe: GridCellProps) => (
        <CellRender originalProps={propsThe} td={td} enterEdit={enterEditHandler} editField={editField} />
    )

    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, propsThe: GridRowProps) => (
        <RowRender originalProps={propsThe} tr={tr} exitEdit={exitEditHandler} editField={editField} />
    )

    /**
     * Editing has completed, so update the underlying value and apply additional logic as needed.
     * @param event
     */
    const itemEditedHandler = (event: GridItemChangeEvent) => {
        const field = event.field
        const editedPattern = event.dataItem as Pattern
        if (!(field === 'name')) {
            // not an editable field
            return
        }

        props.setDialogPatterns([
            ...editablePatterns.map((pattern) => {
                if (pattern.id !== editedPattern.id) {
                    // no changes to this one
                    return pattern
                }
                pattern[field] = event.value
                return pattern
            }),
        ])
    }

    // sort the shifts before binding to the grid
    sortShifts(selectedPatternShifts)
    return (
        <>
            {showDialog === 'ConfirmDeletePattern' && (
                <ConfirmationDialog
                    headerText="Confirm Delete Pattern"
                    closeCallback={() => {
                        setShowDialog('None')
                    }}
                    confirmedCallback={() => {
                        const selectedPattern = getSelectedPattern(selectedPatternRowsState)
                        setIsActionButtonsDisabled(!anyPatternSelected(selectedPatternRowsState))
                        if (selectedPattern) {
                            const patternId = selectedPattern.id
                            props.setDialogPatterns([...patterns.filter((x) => x.id !== patternId)])
                            setSelectedPatternShifts([])
                            setShowDialog('None')
                        }
                    }}
                >
                    <>
                        <p>Deleting this Pattern will unlink it from any Shift Schedules where it has been used.</p>
                    </>
                </ConfirmationDialog>
            )}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '5px',
                    alignItems: 'flex-end',
                }}
            >
                <FormLabelCustom style={{ marginBottom: '0px' }}>Patterns</FormLabelCustom>
                <div>
                    <IconButton tooltip="Add Pattern" onClick={addPatternClickHandler} icon="bi-file-plus" />
                    <IconButton
                        disabled={isActionButtonsDisabled}
                        tooltip="Copy Pattern"
                        onClick={copyPatternClickHandler}
                        toolbarLeftMargin
                        icon="bi-files"
                    />
                    <IconButton
                        disabled={isActionButtonsDisabled}
                        tooltip="Delete Pattern"
                        onClick={deletePatternClickHandler}
                        toolbarLeftMargin
                        icon="bi-trash"
                    />
                </div>
            </div>

            <KendoGridCustom
                gridSelectionMode="single"
                gridRef={patternsGridRef}
                onItemChange={itemEditedHandler}
                centeredContent
                noSelection
                navigatable
                cellRender={customCellRender}
                rowRender={customRowRender}
                localStorageKeyForColumnState="patternsGrid"
                localStorageKeyForGridDataState="patternsGridDataState"
                defaultEmptyGridText="Click 'Add' to create a new Pattern"
                defaultSort={{ field: 'name', dir: 'asc' }}
                filterable={false}
                pageable={false}
                height="175px"
                reorderable={false}
                editField="inEdit"
                data={editablePatterns}
                columns={patternsColumns}
                selectedRowsState={selectedPatternRowsState}
                onSetSelectedRowsState={(newState: SelectionState) => {
                    // show selected pattern shifts
                    const selectedPattern = getSelectedPattern(newState)
                    const selectedShifts = selectedPattern?.shifts || []
                    setSelectedPatternShifts(selectedShifts)
                    setSelectedPatternRowsState(newState)
                }}
                setColumnVisibility={() => {}}
            />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '15px',
                    marginBottom: '5px',
                    alignItems: 'flex-end',
                }}
            >
                <FormLabelCustom style={{ marginBottom: '0px' }}>Shifts in Pattern</FormLabelCustom>
                <div>
                    <SplitIconButton
                        disabled={isActionButtonsDisabled}
                        tooltip="Add a Shift to the Pattern"
                        icon="bi-file-plus"
                        onClick={() => {
                            addShiftHandler()
                        }}
                    >
                        <Dropdown.Item disabled={isActionButtonsDisabled} onClick={() => addShiftHandler()}>
                            Add Single Shift
                        </Dropdown.Item>
                        <Dropdown.Item
                            disabled={isActionButtonsDisabled}
                            onClick={() => {
                                setShowDialog('AddMultipleShiftsDialog')
                            }}
                        >
                            Add Multiple Shifts
                        </Dropdown.Item>
                    </SplitIconButton>

                    <ToolbarDropdownButton
                        title={
                            <InfoIcon
                                icon="bi-pencil"
                                style={{
                                    fontSize: '19px',
                                    color: shiftsGridSelectedIds.length === 0 ? '#bbb' : '#444',
                                    width: '10px',
                                }}
                                tooltip="Edit Shift Name(s)"
                            />
                        }
                        disabled={shiftsGridSelectedIds.length === 0}
                    >
                        {props.shiftSegments.map((shiftSeg) => (
                            <Dropdown.Item
                                key={shiftSeg.name}
                                onClick={() => {
                                    setSelectedGridShiftsToMatchShiftSegmentDefinition(shiftSeg)
                                }}
                            >
                                {shiftSeg.name}
                            </Dropdown.Item>
                        ))}
                    </ToolbarDropdownButton>

                    <IconButton
                        tooltip="Delete Shift(s)"
                        onClick={deleteShiftsHandler}
                        disabled={shiftsGridSelectedIds.length === 0}
                        toolbarLeftMargin
                        icon="bi-trash"
                    />
                </div>
            </div>
            {showDialog === 'AddMultipleShiftsDialog' && (
                <AddMultipleShiftsOptionsDialog
                    closeCallback={(result: DialogResultEnum, value?: AddMultipleShiftsValue) => {
                        if (result === DialogResultEnum.Completed && value) {
                            addMultipleShiftsHandler(value)
                        }
                        setShowDialog('None')
                    }}
                />
            )}
            <ShiftsGrid
                gridRef={shiftsGridRef}
                height={360}
                mode="Patterns"
                emptyGridText=" Click 'Add' to create a new Shift in the selected Pattern"
                shifts={selectedPatternShifts}
                shiftSegments={props.shiftSegments}
                setShifts={updateShifts}
                setSelectedShiftIds={setShiftsGridSelectedIds}
                setShiftsCustomizedFlag={() => {}}
            />
        </>
    )
}

export default PatternsTab
