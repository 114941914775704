import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns'
import { GridCellProps } from '@progress/kendo-react-grid'
import { FormEvent, memo, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import globals from 'services/global/globals'
import { handleApiError } from 'services/utilities/toastrUtils'
import { Share, SharePermissionDefinition, ShareType, ShareUsersConfiguration } from 'types/ShareUsersConfiguration'
import ButtonCustom from '../Buttons/ButtonCustom'
import DialogResultEnum from '../GenericDialogs/dialogResult'
import ModalWrapper from '../GenericDialogs/ModalWrapper'
import KendoGridCustom, { getSelectedIds, SelectionState } from '../Kendo/KendoGridCustom'
import { CellRenderMode, GridDataType, PermissionCell } from './PermissionCell'

export type SharingDialogProps = {
    /**
     * Do not save to the api on OK.  Default behavior is to save to the api on OK click.
     */
    noSaveOnOkay?: boolean
    /**
     * Ids of the items that would be shared.
     */
    itemIds: number[]
    /**
     * Type of item (for itemIds)
     */
    itemType: ShareType
    /**
     * Close dialog (OK or cancel)
     */
    closeCallback: (result: DialogResultEnum, shares: Share[]) => void
}

const GridHeight = 400
interface UserCellMode {
    userId: number
    permissionCellMode: CellRenderMode
}

/**
 * Type that adds userCellModes to ShareUsersConfiguration.  This is to have additional ui state.
 */
interface ShareUsersConfigurationForUI extends ShareUsersConfiguration {
    /**
     * Array of userId/ui cell state maps; controls if the kendo grid permission cell is text or a dropdown.
     */
    userCellModes: UserCellMode[]
}

/**
 * Dialog main content
 */
const SharingDialogContent = (props: SharingDialogProps) => {
    const api = globals.getApi()
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const [data, setData] = useState<ShareUsersConfigurationForUI | null>(null)

    useEffect(() => {
        const loadData = async () => {
            const shareData = await api.getUserSharing(props.itemIds, props.itemType)
            const shareDataForUi: ShareUsersConfigurationForUI = {
                ...shareData,
                userCellModes: [],
            }
            setData(shareDataForUi)
        }
        loadData()
    }, [api, props])

    /**
     * OK click
     * @param event
     */
    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        try {
            // prevent usual form submission
            event.preventDefault()
            event.stopPropagation()
            const updatedShares = { ...data! }
            // only include the changed shares
            updatedShares.shares = updatedShares.shares.filter((x) => x.itemId === 0)
            if (props.noSaveOnOkay !== true) {
                await api.updateUserSharing(updatedShares)
            }
            props.closeCallback(DialogResultEnum.Completed, updatedShares.shares)
        } catch (err: any) {
            handleApiError(err)
        }
    }

    /**
     * Handle when the user changes a permission through the grid
     * @param userId
     * @param updatedPermissionId
     */
    const updatePermissionHandler = (userIds: number[], updatedPermissionId: number) => {
        setData((previous) => {
            const prev = previous!
            const updated = { ...prev }

            // filter out old shares for this user
            userIds.forEach((userId) => {
                updated.shares = updated.shares.filter((x) => x.targetUserId !== userId)

                const permissionDef = updated.sharePermissionDefinitions!.find((x) => x.key === updatedPermissionId)!
                // add in the new share
                updated.shares.push({
                    itemId: 0, // don't need for sending to server, all selected items get the same permission after changing in the ui
                    permissionDescription: permissionDef.value,
                    permissionId: permissionDef.key,
                    targetUserId: userId,
                })
            })

            // this sets all cells back to "view" mode
            updated.userCellModes = []

            return updated
        })
    }

    /**
     * Update the cell render mode for a particular permission cell (switch between view and dropdown)
     * @param mode
     */
    const updatePermissionCellRenderModeHandler = (userId: number, mode: CellRenderMode) => {
        setData((previous) => {
            const prev = previous!
            const updated = { ...prev }

            // reset all to "view"
            updated.userCellModes = []

            // set this one user to "edit"
            updated.userCellModes.push({
                userId,
                permissionCellMode: mode,
            })

            return updated
        })
    }

    let gridData: GridDataType[] = []
    if (data) {
        gridData = data.users.map((u) => {
            let share: Share
            const userShares = data.shares.filter((x) => x.targetUserId === u.id)

            const itemSpecificUserShares = userShares.filter((x) => x.itemId > 0)
            const userHasSomePermissions =
                itemSpecificUserShares.length > 0 && data.itemIds.length !== itemSpecificUserShares.length

            const mixedPermissions =
                userHasSomePermissions ||
                (itemSpecificUserShares.length === data.itemIds.length &&
                    itemSpecificUserShares.length > 1 &&
                    itemSpecificUserShares.filter((y) => y.permissionId !== itemSpecificUserShares[0].permissionId)
                        .length > 0)

            if (mixedPermissions) {
                // mixed permissions (user has selected multiple items that this user has mixed permission on)
                // So make an ad-hoc permission object labelled mixed
                share = {
                    permissionDescription: 'Mixed',
                    targetUserId: u.id,
                    itemId: 0,
                    permissionId: -1,
                }
            } else {
                // no mixed permissions, so use the first one found
                share = userShares[0]
            }

            return {
                id: u.id,
                name: u.name,
                permissionId: share?.permissionId || 0,
                permissionDescription: share?.permissionDescription || 'None',
                cellRenderMode: data.userCellModes.find((x) => x.userId === u.id)?.permissionCellMode || 'view',
            }
        })
    }

    const selectedUserIds = getSelectedIds(selectedRowsState)
    return (
        <Form noValidate onSubmit={submitHandler}>
            <Modal.Header closeButton>
                <Modal.Title>Choose Users to Share With</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!data && (
                    <div style={{ height: `${GridHeight}px` }}>
                        <p>Loading...</p>
                    </div>
                )}

                {data && (
                    <>
                        <div style={{ display: 'flex', marginBottom: '8px', justifyContent: 'space-between' }}>
                            <small style={{ width: '70%', marginRight: '10px' }}>
                                Assign a permission to multiple users at once by checking their checkboxes then using
                                the Assign Permission dropdown list. Or change permissions individually in the grid
                                below.
                            </small>
                            <DropDownList
                                style={{ fontSize: '0.8em', width: '200px', height: '34px' }}
                                textField="value"
                                data={data.sharePermissionDefinitions}
                                disabled={selectedUserIds.length === 0}
                                value={{ key: -1, value: 'Assign Permission' }}
                                onChange={(e: DropDownListChangeEvent) => {
                                    const selectedPermission = e.target.value as SharePermissionDefinition
                                    if (selectedPermission.key < 0) {
                                        // the "assign permission" item
                                        return
                                    }
                                    updatePermissionHandler(selectedUserIds, selectedPermission.key)
                                }}
                            />
                        </div>
                        <KendoGridCustom
                            data={gridData}
                            columns={[
                                {
                                    field: 'name',
                                    title: 'Name',
                                },
                                {
                                    field: 'permissionDescription',
                                    title: 'Permission',
                                    cell: (permissionCellProps: GridCellProps) =>
                                        PermissionCell(
                                            permissionCellProps,
                                            data.sharePermissionDefinitions,
                                            updatePermissionHandler,
                                            updatePermissionCellRenderModeHandler,
                                        ),
                                },
                            ]}
                            filterable
                            height={`${GridHeight}px`}
                            pageable
                            pageSize={10}
                            selectedRowsState={selectedRowsState}
                            onSetSelectedRowsState={(newState: SelectionState) => {
                                setData((previous) => {
                                    const updated = { ...previous! }
                                    updated.userCellModes = []
                                    return updated
                                })
                                setSelectedRowsState(newState)
                            }}
                            setColumnVisibility={() => {}}
                        />
                    </>
                )}
            </Modal.Body>

            <Modal.Footer>
                <ButtonCustom isLarge type="submit" variant="primary">
                    OK
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    variant="secondary"
                    onClick={() => props.closeCallback(DialogResultEnum.Cancelled, [])}
                >
                    Cancel
                </ButtonCustom>
            </Modal.Footer>
        </Form>
    )
}

/**
 * Dialog content wrapper
 * @param props
 * @returns
 */
const SharingDialog = (props: SharingDialogProps) => {
    return (
        <ModalWrapper
            closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled, [])}
            className="DialogMediumWidth"
        >
            <SharingDialogContent {...props} />
        </ModalWrapper>
    )
}

export default memo(SharingDialog)
