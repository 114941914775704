import { isDesktopMode } from 'services/axios/axios-sfc'
import { ScenarioSelection } from './ScenarioSelection'

const getDescriptionFromDashboardConfig = ({
    includeAllMyScenarios,
    includeAllScenariosSharedwMe,
    selectedScenarioIds,
    selectedScenarioName,
}: ScenarioSelection): string => {
    let description = ''

    const selectedScenarioIdsCount = selectedScenarioIds.split(',').length

    if (includeAllMyScenarios && includeAllScenariosSharedwMe) description = 'All My Scenarios and Shared Scenarios'
    else if (includeAllMyScenarios && includeAllScenariosSharedwMe === false) {
        description = isDesktopMode() ? 'All Scenarios' : 'All My Scenarios'
    } else if (includeAllMyScenarios === false && includeAllScenariosSharedwMe) description = 'All Shared Scenarios'
    else if (includeAllMyScenarios === false && includeAllScenariosSharedwMe === false) {
        if (selectedScenarioIdsCount > 1) description = selectedScenarioIdsCount + ' Scenarios Selected'
        else if (selectedScenarioIdsCount === 1) {
            description = selectedScenarioName || 'No Scenarios Selected'
        } else {
            description = 'No Scenarios Selected'
        }
    }

    return description
}

export default getDescriptionFromDashboardConfig
