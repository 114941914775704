import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InsightsState = {
    hazardClassRequiresRefresh: number
}

const initialInsightsState: InsightsState = { hazardClassRequiresRefresh: 0 }

const insightsReducer = createSlice({
    name: 'insights',
    initialState: initialInsightsState,
    reducers: {
        setHazardClassViewRequiresRefresh(state, action: PayloadAction<number>) {
            state.hazardClassRequiresRefresh = action.payload
        },
    },
})

export default insightsReducer.reducer
export const insightsActions = insightsReducer.actions
