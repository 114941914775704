import { Redirect } from 'react-router'

const ScenarioRedirectPage = () => {
    const lastViewedScenarioId = window.localStorage.getItem('lastViewedScenarioId')
    if (lastViewedScenarioId) {
        return <Redirect to={`/scenario/${lastViewedScenarioId}`} />
    }
    return <Redirect to="/scenarios/" />
}

export default ScenarioRedirectPage
