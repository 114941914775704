import { GridCellProps, GridHeaderCellProps } from '@progress/kendo-react-grid'
import dateFormatting from 'services/formatting/dateTimeFormatting'
import { ColumnMenuWithFilter, KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { FormattedScenarioMetricCell, FormattedScheduleMetricCell } from 'views/Common/Kendo/FormattedMetricCell'
import { createFormattedMetricHeaderCell, FormattedScheduleNameCell } from 'views/Common/Kendo/FormattedNameCell'
import { CustomHeaderClass, getHeaderCellClass } from 'views/Common/Kendo/KendoGridCustom'

const getColumns = (
    scheduleErrorClicked: () => void,
    scheduleShareIconClicked: (scheduleId: number) => void,
    tagNames: string[],
    enableFiltering: boolean,
    filteredFields: string[],
) => {
    const columns: KendoGridColumn[] = [
        {
            field: 'name',
            title: 'Name',
            width: '150px',
            filter: 'text',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('name', filteredFields),
            cell: (props: GridCellProps) =>
                FormattedScheduleNameCell(props, scheduleErrorClicked, scheduleShareIconClicked),
        },
        {
            field: 'scheduleOwnerName',
            title: 'Owner',
            width: '80px',
            filter: 'text',
            headerClassName: getHeaderCellClass('scheduleOwnerName', filteredFields),
            columnMenu: ColumnMenuWithFilter,
        },
        {
            field: 'modified',
            title: 'Modified',
            filter: 'date',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('modified', filteredFields),
            format: dateFormatting.getKendoGridDateFormat(),
        },
        {
            field: 'viewed',
            title: 'Viewed',
            filter: 'date',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('viewed', filteredFields),
            format: dateFormatting.getKendoGridDateFormat(),
        },
        {
            field: 'viewedByUserName',
            title: 'Viewed By',
            filter: 'text',
            headerClassName: getHeaderCellClass('viewedByUserName', filteredFields),
            columnMenu: ColumnMenuWithFilter,
        },
        {
            field: 'startStation',
            title: 'Start Station',
            filter: 'text',
            headerClassName: getHeaderCellClass('startStation', filteredFields),
            columnMenu: ColumnMenuWithFilter,
        },
        {
            field: 'baseLocation',
            title: 'Base Station',
            filter: 'text',
            headerClassName: getHeaderCellClass('baseLocation', filteredFields),
            columnMenu: ColumnMenuWithFilter,
        },
        {
            field: 'metrics.effectivenessCrewingAvg',
            title: 'Effectiveness Average (Crewing)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Effectiveness Average', 'Crewing', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass('metrics.effectivenessCrewingAvg', filteredFields, CustomHeaderClass),
            cell: FormattedScenarioMetricCell,
            hide: true,
        },
        {
            field: 'metrics.effectivenessCrewingMin',
            title: 'Effectiveness Minimum (Crewing)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Effectiveness Minimum', 'Crewing', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass('metrics.effectivenessCrewingMin', filteredFields, CustomHeaderClass),
            cell: FormattedScenarioMetricCell,
        },
        {
            field: 'metrics.effectivenessCriticalMin',
            title: 'Effectiveness Minimum (Critical)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Effectiveness Minimum', 'Critical', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass('metrics.effectivenessCriticalMin', filteredFields, CustomHeaderClass),
            cell: FormattedScenarioMetricCell,
        },
        {
            title: 'KSS Maximum (Critical)',
            field: 'metrics.kssCriticalMax',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'KSS Maximum', 'Critical', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass('metrics.kssCriticalMax', filteredFields, CustomHeaderClass),
            cell: FormattedScheduleMetricCell,
        },
        {
            field: 'metrics.samnPerelliCriticalMax',
            title: 'Samn-Perelli Maximum (Critical)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Samn-Perelli Maximum', 'Critical', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass('metrics.samnPerelliCriticalMax', filteredFields, CustomHeaderClass),
            cell: FormattedScheduleMetricCell,
        },
        {
            field: 'metrics.reservoirCrewingMin',
            title: 'Reservoir Minimum (Crewing)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Reservoir Minimum', 'Crewing', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass('metrics.reservoirCrewingMin', filteredFields, CustomHeaderClass),
            cell: FormattedScheduleMetricCell,
        },
        {
            field: 'metrics.reservoirCriticalMin',
            title: 'Reservoir Minimum (Critical)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Reservoir Minimum', 'Critical', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass('metrics.reservoirCriticalMin', filteredFields, CustomHeaderClass),
            cell: FormattedScheduleMetricCell,
        },
        {
            field: 'metrics.percentBelowCriterionCrewing',
            title: '% Below Criterion (Crewing)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, '% Below Criterion', 'Crewing', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass(
                'metrics.percentBelowCriterionCrewing',
                filteredFields,
                CustomHeaderClass,
            ),
            cell: FormattedScheduleMetricCell,
        },
        {
            field: 'metrics.percentBelowCriterionCritical',
            title: '% Below Criterion (Critical)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, '% Below Criterion', 'Critical', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass(
                'metrics.percentBelowCriterionCritical',
                filteredFields,
                CustomHeaderClass,
            ),
            cell: FormattedScheduleMetricCell,
        },
        {
            field: 'metrics.numberofExcessivePBCEventsCrewing',
            title: 'Number of Excessive % Below Criterion Events (Crewing)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(
                    props,
                    'Number of Excessive % Below Criterion Events',
                    'Crewing',
                    !enableFiltering,
                ),
            filter: 'numeric',
            headerClassName: getHeaderCellClass(
                'metrics.numberofExcessivePBCEventsCrewing',
                filteredFields,
                CustomHeaderClass,
            ),
            cell: FormattedScheduleMetricCell,
            hide: true,
        },
        {
            title: 'Total FHA (Crewing)',
            field: 'metrics.totalFatigueHazardAreaCrewing',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Total FHA', 'Crewing', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass(
                'metrics.totalFatigueHazardAreaCrewing',
                filteredFields,
                CustomHeaderClass,
            ),
            cell: FormattedScheduleMetricCell,
            hide: true,
        },
        {
            title: 'Total FHA (Critical)',
            field: 'metrics.totalFatigueHazardAreaCritical',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Total FHA', 'Critical', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass(
                'metrics.totalFatigueHazardAreaCritical',
                filteredFields,
                CustomHeaderClass,
            ),
            cell: FormattedScheduleMetricCell,
            hide: true,
        },
        {
            title: '% FHA (Crewing)',
            field: 'metrics.percentFatigueHazardAreaCrewing',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, '% FHA', 'Crewing', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass(
                'metrics.percentFatigueHazardAreaCrewing',
                filteredFields,
                CustomHeaderClass,
            ),
            cell: FormattedScheduleMetricCell,
            columnMenu: ColumnMenuWithFilter,
            hide: true,
        },
        {
            title: '% FHA (Critical)',
            field: 'metrics.percentFatigueHazardAreaCritical',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, '% FHA', 'Critical', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass(
                'metrics.percentFatigueHazardAreaCritical',
                filteredFields,
                CustomHeaderClass,
            ),
            cell: FormattedScheduleMetricCell,
            columnMenu: ColumnMenuWithFilter,
            hide: true,
        },
        {
            field: 'metrics.workloadMax',
            title: 'Workload Maximum',
            filter: 'numeric',
            cell: FormattedScheduleMetricCell,
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('metrics.workloadMax', filteredFields),
            hide: true,
        },
        {
            field: 'metrics.workloadMed',
            title: 'Workload Median',
            filter: 'numeric',
            cell: FormattedScheduleMetricCell,
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('metrics.workloadMed', filteredFields),
            hide: true,
        },
    ]

    tagNames.forEach((tagName) => {
        columns.push({
            field: `tagValues.${tagName}`,
            title: `${tagName}*`,
            filter: 'text',
            headerClassName: getHeaderCellClass(`tagValues.${tagName}`, filteredFields),
            columnMenu: ColumnMenuWithFilter,
            hide: true,
        })
    })

    return columns
}

export default getColumns
