import { FormEvent, memo, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { calculateNewUtcTimeFromDisplayTimeChange } from 'services/utilities/timeUtils'
import { TimeModeEnum } from 'types/interfaces'
import ScheduleEvent from 'types/ScheduleEvent'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import OverlappingEventAdjuster from 'views/OverlappingEvents/Components/OverlappingEventAdjuster'
import OverlappingEventDescription from '../Components/OverlappingEventDescription'

export type UpdatedDates = {
    event1Uuid: string
    event2Uuid: string
    event1StartMinutesDiff: number
    event1DurationMinutes: number
    event2StartMinutesDiff: number
    event2DurationMinutes: number
}

export type OverlappingEventsDialogProps = {
    timeMode: TimeModeEnum
    event1: ScheduleEvent
    event2: ScheduleEvent
    closeCallback: (status: DialogResultEnum, updatedDates?: UpdatedDates) => void
}

const getOverlapErrorMessage = (overlapAmountInMinutes: number) => {
    if (overlapAmountInMinutes <= 0) {
        return ''
    }
    if (overlapAmountInMinutes < 60) {
        return 'Overlap amount: ' + overlapAmountInMinutes + ' minutes'
    }
    const hours = Math.floor(overlapAmountInMinutes / 60)
    const minutes = overlapAmountInMinutes % 60
    return `Overlap amount: ${hours}  hours, ${minutes} minutes`
}

const calculateOverlapInMinutes = (time1: Date, time2: Date) => {
    return Math.abs((time1.getTime() - time2.getTime()) / 60000)
}

// these helper functions that check for overlaps mutate the statusObject; simpler that way.

const OverlappingEventsDialogContent = (props: OverlappingEventsDialogProps) => {
    const event1 = props.event1
    const event2 = props.event2

    const event1Uuid = event1.uuid!
    const event2Uuid = event2.uuid!

    // here we use state to track utc times, but we also calculate the times "for display",
    // which may be local, base, etc.  This is needed for the date/time widgets

    const [event1Start, setEvent1Start] = useState(new Date(event1.getStartMs()))
    const [event1End, setEvent1End] = useState(new Date(event1.getEndMs()))
    const event1StartForDisplay = new Date(event1.getStartMsForDisplay(props.timeMode, event1Start.getTime()))
    const event1EndForDisplay = new Date(event1.getEndMsForDisplay(props.timeMode, event1End.getTime()))

    const [event2Start, setEvent2Start] = useState(new Date(event2.getStartMs()))
    const [event2End, setEvent2End] = useState(new Date(event2.getEndMs()))
    const event2StartForDisplay = new Date(event2.getStartMsForDisplay(props.timeMode, event2Start.getTime()))
    const event2EndForDisplay = new Date(event2.getEndMsForDisplay(props.timeMode, event2End.getTime()))

    let highlightEvent1Start = false
    let highlightEvent1End = false
    let highlightEvent2Start = false
    let highlightEvent2End = false
    let errorMessage = ''

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const event1StartMinutesDiff = (event1Start.getTime() - props.event1.getStartMs()) / 60000
        const event2StartMinutesDiff = (event2Start.getTime() - props.event2.getStartMs()) / 60000

        const event1DurationMinutes = (event1End.getTime() - event1Start.getTime()) / 60000
        const event2DurationMinutes = (event2End.getTime() - event2Start.getTime()) / 60000

        props.closeCallback(DialogResultEnum.Completed, {
            event1Uuid,
            event2Uuid,
            event1StartMinutesDiff,
            event1DurationMinutes,
            event2StartMinutesDiff,
            event2DurationMinutes,
        })
    }

    const startTimeOverlaps = (startTime: Date, otherEventStart: Date, otherEventEnd: Date) => {
        const doesOverlap = startTime > otherEventStart && startTime < otherEventEnd
        if (doesOverlap) {
            errorMessage = getOverlapErrorMessage(calculateOverlapInMinutes(startTime, otherEventEnd))
        }
        return doesOverlap
    }

    const endTimeOverlaps = (endTime: Date, otherEventStart: Date, otherEventEnd: Date) => {
        const doesOverlap = endTime > otherEventStart && endTime < otherEventEnd
        if (doesOverlap) {
            errorMessage = getOverlapErrorMessage(calculateOverlapInMinutes(endTime, otherEventStart))
        }
        return doesOverlap
    }

    const eventInsideOther = (start1: Date, end1: Date, start2: Date, end2: Date) => {
        const doesOverlap = start1 >= start2 && end1 <= end2
        if (doesOverlap) {
            errorMessage = 'Events overlap completely'
        }
        return doesOverlap
    }

    // test for overlapping event times
    if (startTimeOverlaps.call(this, event1Start, event2Start, event2End)) {
        highlightEvent1Start = true
    }

    if (endTimeOverlaps.call(this, event1End, event2Start, event2End)) {
        highlightEvent1End = true
    }

    if (startTimeOverlaps.call(this, event2Start, event1Start, event1End)) {
        highlightEvent2Start = true
    }

    if (endTimeOverlaps.call(this, event2End, event1Start, event1End)) {
        highlightEvent2End = true
    }

    // test if either event is fully encapsulated in the other
    if (
        eventInsideOther.call(this, event1Start, event1End, event2Start, event2End) ||
        eventInsideOther.call(this, event2Start, event2End, event1Start, event1End)
    ) {
        highlightEvent1Start = true
        highlightEvent1End = true
        highlightEvent2Start = true
        highlightEvent2End = true
    }

    // test for negative event durations
    if (event1End <= event1Start) {
        highlightEvent1Start = true
        highlightEvent1End = true
        errorMessage = 'Events must have a duration greater than 0'
    }
    if (event2End <= event2Start) {
        highlightEvent2Start = true
        highlightEvent2End = true
        errorMessage = 'Events must have a duration greater than 0'
    }

    const isInvalid = highlightEvent1Start || highlightEvent1End || highlightEvent2Start || highlightEvent2End

    return (
        <>
            <Form id="scheduleSaveAsForm" noValidate onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Fix Overlapping Events</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Fix these overlapping events by modifying the time of one or both. Times shown are in{' '}
                        <strong>{TimeModeEnum[props.timeMode]}</strong>.
                    </p>
                    <OverlappingEventAdjuster
                        startOverlap={highlightEvent1Start}
                        endOverlap={highlightEvent1End}
                        eventNumber="1"
                        label={event1.label}
                        start={event1StartForDisplay}
                        end={event1EndForDisplay}
                        setStart={(newDisplayStart) => {
                            setEvent1Start((previousStart) =>
                                calculateNewUtcTimeFromDisplayTimeChange(
                                    newDisplayStart,
                                    event1StartForDisplay,
                                    previousStart,
                                ),
                            )
                        }}
                        setEnd={(newDisplayEnd) => {
                            setEvent1End((previousEnd) =>
                                calculateNewUtcTimeFromDisplayTimeChange(
                                    newDisplayEnd,
                                    event1EndForDisplay,
                                    previousEnd,
                                ),
                            )
                        }}
                        showEndSyncButton
                        endSyncButtonClicked={() => {
                            setEvent1End(event2Start)
                        }}
                    />
                    <OverlappingEventAdjuster
                        startOverlap={highlightEvent2Start}
                        endOverlap={highlightEvent2End}
                        eventNumber="2"
                        label={event2.label}
                        start={event2StartForDisplay}
                        end={event2EndForDisplay}
                        setStart={(newDisplayStart) => {
                            setEvent2Start((previousStart) =>
                                calculateNewUtcTimeFromDisplayTimeChange(
                                    newDisplayStart,
                                    event2StartForDisplay,
                                    previousStart,
                                ),
                            )
                        }}
                        setEnd={(newDisplayEnd) => {
                            setEvent2End((previousEnd) =>
                                calculateNewUtcTimeFromDisplayTimeChange(
                                    newDisplayEnd,
                                    event2EndForDisplay,
                                    previousEnd,
                                ),
                            )
                        }}
                        showStartSyncButton
                        startSyncButtonClicked={() => {
                            setEvent2Start(event1End)
                        }}
                    />
                    <OverlappingEventDescription>{errorMessage}</OverlappingEventDescription>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom isLarge variant="primary" type="submit" disabled={isInvalid}>
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => {
                            props.closeCallback(DialogResultEnum.Cancelled)
                        }}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const OverlappingEventsDialog = (props: OverlappingEventsDialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <OverlappingEventsDialogContent {...props} />
        </ModalWrapper>
    )
}

export default memo(OverlappingEventsDialog)
