import Scenario, { parseScenario } from './Scenario'

export const parseInsightsConfigMetadata = (data: any) => {
    const parsed = data as InsightsMetadata
    parsed.scenarios = parsed.scenarios.map(parseScenario)
    return parsed
}

interface InsightsMetadata {
    scenarios: Scenario[]
}

export default InsightsMetadata
