import { GridCellProps } from '@progress/kendo-react-grid'
import dateFormatting from 'services/formatting/dateTimeFormatting'
import { User } from 'types/interfaces'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import { ColumnMenuWithFilter, KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { getHeaderCellClass } from 'views/Common/Kendo/KendoGridCustom'

const getColumns = (customFields: string | null, userClicked: (user: User) => void, filteredFields: string[]) => {
    const columns: KendoGridColumn[] = [
        {
            field: 'name',
            title: 'Full Name',
            filter: 'text',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('name', filteredFields),
            cell: (props: GridCellProps) => {
                const user = props.dataItem as User
                return (
                    <td>
                        <TransparentButton
                            onClick={() => {
                                userClicked(user)
                            }}
                        >
                            {user.name}
                        </TransparentButton>
                    </td>
                )
            },
        },
        {
            field: 'loginId',
            title: 'Email',
            filter: 'text',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('loginId', filteredFields),
        },
        {
            field: 'rolesDescription',
            title: 'Roles',
            filter: 'text',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('rolesDescription', filteredFields),
        },
        {
            field: 'lastLogin',
            title: 'Latest Login',
            filter: 'date',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('lastLogin', filteredFields),
            format: dateFormatting.getKendoGridDateFormat(),
        },
        {
            field: 'state',
            title: 'Status',
            filter: 'text',
            headerClassName: getHeaderCellClass('state', filteredFields),
            columnMenu: ColumnMenuWithFilter,
        },
    ]

    if (customFields) {
        customFields.split(',').forEach((field, index) => {
            const fieldName = field.split(':')[0]
            const fieldLabel = field.split(':')[1]
            columns.splice(3 + index, 0, {
                field: fieldName,
                title: fieldLabel,
                filter: 'text',
                headerClassName: getHeaderCellClass(fieldLabel, filteredFields),
                columnMenu: ColumnMenuWithFilter,
            })
        })
    }

    return columns
}

export default getColumns
