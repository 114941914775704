import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { CSSProperties } from 'react'
import { Card } from 'react-bootstrap'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import DateTimePickerCustom from 'views/Common/Kendo/DateTimePickerCustom'
import classes from './OverlappingEventAdjuster.module.css'

const OverlappingEventAdjuster = (props: {
    eventNumber: '1' | '2'
    label: string
    start: Date
    setStart: (start: Date) => void
    showStartSyncButton?: boolean
    startSyncButtonClicked?: () => void
    end: Date
    setEnd: (start: Date) => void
    showEndSyncButton?: boolean
    endSyncButtonClicked?: () => void
    startOverlap: boolean
    endOverlap: boolean
}) => {
    const invalidStyle: CSSProperties = {
        border: '2px solid red',
    }
    const startTimeStyle = props.startOverlap ? invalidStyle : {}
    const endTimeStyle = props.endOverlap ? invalidStyle : {}
    const syncButtonIcon = <i className={`bi-arrow-left-right ${classes.leftRightArrowIcon}`} />
    return (
        <div className={classes.container}>
            <p className={classes.eventName}>
                Event {props.eventNumber}: <strong>{props.label}</strong>
            </p>
            <Card>
                <Card.Body>
                    <div className={classes.eventsWrapper}>
                        <div>
                            <div className={classes.timeLabel}>
                                <p>Start</p>
                                {props.showStartSyncButton && (
                                    <TransparentButton
                                        name={`syncStartTime${props.eventNumber}`}
                                        onClick={props.startSyncButtonClicked}
                                    >
                                        {syncButtonIcon}
                                    </TransparentButton>
                                )}
                            </div>

                            <div style={startTimeStyle}>
                                <DateTimePickerCustom
                                    name={`startTime${props.eventNumber}`}
                                    value={props.start}
                                    format={dateTimeFormatting.getKendoDateTimeFormat()}
                                    onChange={(e: DateTimePickerChangeEvent) => {
                                        props.setStart(e.value!)
                                    }}
                                    width="100%"
                                />
                            </div>
                        </div>
                        <div>
                            <div className={classes.timeLabel}>
                                <p>End</p>
                                {props.showEndSyncButton && (
                                    <TransparentButton
                                        name={`syncEndTime${props.eventNumber}`}
                                        onClick={props.endSyncButtonClicked}
                                    >
                                        {syncButtonIcon}
                                    </TransparentButton>
                                )}
                            </div>
                            <div style={endTimeStyle}>
                                <DateTimePickerCustom
                                    name={`endTime${props.eventNumber}`}
                                    value={props.end}
                                    format={dateTimeFormatting.getKendoDateTimeFormat()}
                                    onChange={(e: DateTimePickerChangeEvent) => {
                                        props.setEnd(e.value!)
                                    }}
                                    width="100%"
                                />
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default OverlappingEventAdjuster
