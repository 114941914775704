import { GridCellProps } from '@progress/kendo-react-grid'
import { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'
import classes from './EditInPlaceDropdown.module.css'

type ListOption = {
    label: string
    value: string
}

const EditInPlaceDropdown = (
    props: GridCellProps,
    listOptions: ListOption[],
    isInvalid: (dataItem: any) => boolean,
) => {
    const { dataItem } = props
    const field = props.field || ''
    const dataValue = dataItem[field] || ''

    const handleDropdownValueChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e,
                value: e.target.value,
            })
        }
    }

    const validationErrorBorder = isInvalid(dataItem) ? '1px solid red' : ''
    const customRendering =
        dataItem.inEdit === props.field ? (
            <td>
                <Form.Control
                    className={classes.editorSmall}
                    as="select"
                    custom
                    onChange={handleDropdownValueChange}
                    value={dataValue}
                >
                    {listOptions.map((listOption) => (
                        <option key={listOption.value} value={listOption.value}>
                            {listOption.label}
                        </option>
                    ))}
                </Form.Control>
            </td>
        ) : (
            <td style={{ border: validationErrorBorder }}>{dataValue}</td>
        )

    // since there is cell and row-level render overriding, we need to make use of that in this manner.
    return props.render ? props.render(customRendering, props) : customRendering
}

export default EditInPlaceDropdown
