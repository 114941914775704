import classes from './ProgressBar.module.css'

const ProgressBar = (props: { textDescription: string; progressValue: number }) => {
    return (
        <div id="progressSection" className={classes.progressBarContainer}>
            <progress value={props.progressValue} className={classes.progressBar} />
            <small className="form-text text-muted">{props.textDescription}</small>
        </div>
    )
}

export default ProgressBar
