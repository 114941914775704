import useSessionStatus from 'hooks/useSessionStatus'
import useUser from 'hooks/useUser'
import { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import { RootState } from 'store/store'
import MetaData from 'types/Metadata'
import EventsFilterBuilder from 'views/Common/FilterBuilder/EventsFilterBuilder'
import NotFound from 'views/Common/NotFound/NotFound'
import FileProcessingPage from 'views/FileProcessing/Page/FileProcessingPage'
import HazardClassConfiguration from 'views/Insights/Page/HazardClassConfiguration'
import HazardClassView from 'views/Insights/Page/HazardClassView'
import InsightsDashboard from 'views/Insights/Page/InsightsDashboard'
import Logout from 'views/Logout/Logout'
import ReportConfiguration from 'views/Reports/Page/ReportConfiguration'
import ReportsDashboard from 'views/Reports/Page/ReportsDashboard'
import ReportView from 'views/Reports/Page/ReportView'
import ResetPassword from 'views/ResetPassword/ResetPassword'
import DesktopAddEventsScheduleDialog from 'views/Scenarios/Dialogs/DesktopAddEventsScheduleDialog'
import DesktopAddShiftsScheduleDialog from 'views/Scenarios/Dialogs/DesktopAddShiftsScheduleDialog'
import ScenarioRedirectPage from 'views/Scenarios/Page/ScenarioRedirectPage'
import ScenariosPage from 'views/Scenarios/Page/ScenariosPage'
import ScheduleDetailsPage from 'views/Schedules/ScheduleDetails/Page/ScheduleDetailsPage'
import SchedulesPage from 'views/Schedules/SchedulesPage/SchedulesPage'
import Users from 'views/Users/Page/UsersPage'

const AuthenticatedRoutes = (props: { logoutHandler: () => void }) => {
    const userState = useSessionStatus()
    const user = useUser()
    const usersRoute = user?.isAdministrator ? <Users /> : <Redirect to="/scenarios" />

    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Switch>
                <Route path="/scenarios">
                    <ScenariosPage />
                </Route>
                <Route path="/scenario/:id">
                    <SchedulesPage />
                </Route>
                <Route path="/schedule/:id">
                    <ScheduleDetailsPage />
                </Route>
                <Route path="/schedules/">
                    <ScenarioRedirectPage />
                </Route>
                <Route path="/reports/:id/configuration">
                    <ReportConfiguration />
                </Route>
                <Route path="/reports/:id/configurationFromDashboard">
                    <ReportConfiguration fromDashboard />
                </Route>
                <Route path="/reports-print/:id">
                    <ReportView isPrintPreview />
                </Route>
                <Route path="/reports-print">
                    <ReportsDashboard isPrintPreview />
                </Route>
                <Route path="/reports/newreport">
                    <ReportConfiguration />
                </Route>
                <Route path="/reports/:id">
                    <ReportView />
                </Route>
                {!metadata.disableFeatureReports && (
                    <Route path="/reports/">
                        <ReportsDashboard />
                    </Route>
                )}
                <Route path="/insights/:id/configuration">
                    <HazardClassConfiguration />
                </Route>
                <Route path="/insights/:id/configurationFromDashboard">
                    <HazardClassConfiguration fromDashboard />
                </Route>
                <Route path="/insights/:id/copyFromDashboard">
                    <HazardClassConfiguration isCopy fromDashboard />
                </Route>
                <Route path="/insights/:id/copy">
                    <HazardClassConfiguration isCopy />
                </Route>
                <Route path="/insights/NewHazardClass">
                    <HazardClassConfiguration />
                </Route>
                <Route path="/insights/:id">
                    <HazardClassView />
                </Route>
                {!metadata.disableFeatureInsights && (
                    <Route path="/insights/">
                        <InsightsDashboard />
                    </Route>
                )}
                <Route path="/users">{usersRoute}</Route>
                <Route path="/fileprocessing">
                    <FileProcessingPage />
                </Route>
                <Route exact path="/">
                    <Redirect to="/scenarios" />
                </Route>
                <Route path="/logout">
                    {userState === 'LoggedIn' ? <Logout logoutHandler={props.logoutHandler} /> : <Redirect to="/" />}
                </Route>
                <Route path="/createEventsScheduleDialog">
                    <DesktopAddEventsScheduleDialog />
                </Route>
                <Route path="/createShiftsScheduleDialog">
                    <DesktopAddShiftsScheduleDialog />
                </Route>
                <Route path="/eventsfilterbuilder">
                    <EventsFilterBuilder />
                </Route>
                <Route exact path="/setpasswordrequest">
                    <ResetPassword mode="RequestReset" logout={props.logoutHandler} />
                </Route>
                <Route path="/setpassword/:token">
                    <ResetPassword mode="SetNewPassword" logout={props.logoutHandler} />
                </Route>
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </Suspense>
    )
}

export default AuthenticatedRoutes
