import { Dispatch } from 'redux'
import globals from 'services/global/globals'
import { insightsRecalculationActions, reportRecalculationActions } from 'store/recalculationStore'

/**
 * Fetch from the server any outstanding requests to recalculate reports or insights (asynchronously only for Web).  Ids of items being refreshed are stored in the redux store (app state).  When on Reports or Insights pages, the useRecalculateItemsStatus hook fetches the latest status for any of these items being processed, until complete.  Then it clears them from the store.
 * @param dispatch
 * @returns
 */
const checkExistingRecalculationRequests = async (dispatch: Dispatch<any>) => {
    const api = globals.getApi().getProcessingApi()

    const recalculationRequests = await api.getExistingRecalculationRequests()
    const insightsRequests = recalculationRequests.filter((x) => x.itemType === 'Insights')
    const reportsRequests = recalculationRequests.filter((x) => x.itemType === 'Report')
    if (insightsRequests.length) {
        dispatch(insightsRecalculationActions.setRecalculationRequestIds(insightsRequests.map((x) => x.id)))
    }
    if (reportsRequests.length) {
        dispatch(reportRecalculationActions.setRecalculationRequestIds(reportsRequests.map((x) => x.id)))
    }
}

export default checkExistingRecalculationRequests
