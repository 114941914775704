import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts'
import HazardClassCase from 'types/HazardClassCase'
import {
    PieChartColorGreen,
    PieChartColorOrange,
    PieChartColorRed,
    PieChartColorYellow,
} from 'views/Insights/PatternPanel/PatternsPieChart'

const DutiesStackedBarChart = (props: {
    height: number
    isPrintPreview: boolean
    patterns: HazardClassCase[]
    patternSeriesClicked?: (hcCase: HazardClassCase) => void
    className?: string
}) => {
    const lt70s = props.patterns.filter((x) => x.truePositive.lt70)
    const btw7075 = props.patterns.filter((x) => x.match.btw7075)
    const btw7580 = props.patterns.filter((x) => x.match.btw7580)
    const gt80 = props.patterns.filter((x) => x.truePositive.gt80)
    const categories = props.patterns.map((pattern) => pattern.index!)

    const renderTooltip = (context: any) => {
        const { series, value } = context.point || context

        let fieldLabel = '< 70'
        if (series.field === 'match.btw7075') {
            fieldLabel = '70-75'
        }
        if (series.field === 'match.btw7580') {
            fieldLabel = '75-80'
        }
        if (series.field === 'match.truePositive.gt80') {
            fieldLabel = '> 80'
        }

        return (
            <div>
                {value} Duties w Score {fieldLabel}
            </div>
        )
    }

    return (
        <Chart
            style={{ height: props.height, width: 1000 }}
            transitions={false}
            onSeriesClick={(e) => !props.isPrintPreview && props.patternSeriesClicked?.(e.dataItem)}
            className={props.className}
        >
            <ChartTitle text="Matching Duties Count" font="15px sans-serif" padding={0} margin={0} />
            <ChartTooltip render={renderTooltip} />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={categories} majorGridLines={{ visible: false }}>
                    <ChartCategoryAxisTitle text="Pattern Number" font="12px sans-serif" />
                </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem />
            </ChartValueAxis>
            <ChartSeries>
                <ChartSeriesItem
                    type="column"
                    gap={0.5}
                    stack
                    data={lt70s}
                    field="truePositive.lt70"
                    categoryField="index"
                    color={PieChartColorRed}
                />
                <ChartSeriesItem
                    type="column"
                    gap={0.5}
                    stack
                    data={btw7075}
                    field="match.btw7075"
                    categoryField="index"
                    color={PieChartColorOrange}
                />
                <ChartSeriesItem
                    type="column"
                    gap={0.5}
                    stack
                    data={btw7580}
                    field="match.btw7580"
                    categoryField="index"
                    color={PieChartColorYellow}
                />
                <ChartSeriesItem
                    type="column"
                    gap={0.5}
                    stack
                    data={gt80}
                    field="truePositive.gt80"
                    categoryField="index"
                    color={PieChartColorGreen}
                />
            </ChartSeries>
        </Chart>
    )
}

export default DutiesStackedBarChart
