import { CSSProperties } from 'react'
import ButtonCustom, { ButtonCustomProps } from 'views/Common/Buttons/ButtonCustom'

export const ToolbarButtonHeightPx: number = 30

const ToolbarButton = (props: ButtonCustomProps) => {
    const styleOverride: CSSProperties = {
        height: `${ToolbarButtonHeightPx}px`,
        paddingTop: '0',
        paddingBottom: '0',
    }
    if (props?.width) {
        styleOverride.minWidth = props.width + 'px'
    }

    const content = props.children ?? <img src={props.image} alt="img" style={{ width: '16px' }} />
    return (
        <ButtonCustom {...props} style={styleOverride} size="sm">
            {content}
        </ButtonCustom>
    )
}

export default ToolbarButton
