import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import SimpleDialog from 'views/Common/GenericDialogs/SimpleDialog'

const ConfirmationDialog = (props: {
    /**
     * Text in the header of the dialog
     */
    headerText: string
    /**
     * Body of the dialog
     */
    children?: React.ReactNode
    /**
     * Optional override of default "Close" text on the secondary button
     */
    secondaryButtonTextOverride?: string
    /**
     * Callback when the dialog is closed
     */
    closeCallback: () => void
    /**
     * Callback when the dialog is confirmed
     */
    confirmedCallback: () => void
}) => {
    /**
     * Controls the footer buttons to show
     * @returns
     */
    const getFooterButtons = (): React.ReactNode => {
        return (
            <>
                <ButtonCustom
                    autoFocus
                    isLarge
                    variant="primary"
                    onClick={() => {
                        props.closeCallback()
                        props.confirmedCallback()
                    }}
                >
                    OK
                </ButtonCustom>
                <ButtonCustom isLarge variant="secondary" onClick={props.closeCallback}>
                    {props.secondaryButtonTextOverride ?? 'Cancel'}
                </ButtonCustom>
            </>
        )
    }

    return (
        <SimpleDialog
            headerText={props.headerText}
            closeCallback={props.closeCallback}
            getFooterButtons={getFooterButtons}
        >
            {props.children}
        </SimpleDialog>
    )
}

export default ConfirmationDialog
