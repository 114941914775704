import Schedule from 'types/Schedule'

const getTipContent = (schedule: Schedule) => (
    <div style={{ textAlign: 'left', maxWidth: 300 }}>
        Warning(s):
        <br />
        <br />
        <ul>
            {schedule.warning.map((x, i) => (
                <li key={i.toString()}>{x}</li>
            ))}
        </ul>
    </div>
)

export default getTipContent
