import { AddHazardClassFromLibraryRequest, AddReportFromLibraryRequest } from 'types/AddFromLibraryRequest'
import { HazardClass, HazardClassDialogConfig, parseHazardClass } from 'types/HazardClass'
import { InsightsDashboardConfig } from 'types/InsightsDashboardConfig'
import InsightsMetadata, { parseInsightsConfigMetadata } from 'types/InsightsMetadata'
import { ReportDrilldownRequest } from 'types/ReportDrilldownRequest'
import ReportingMetadata, { parseReportConfigMetadata } from 'types/ReportingMetadata'
import { parseReport, Report, ReportDialogConfig } from 'types/Reports'
import { ScenarioSelection } from 'types/ScenarioSelection'
import Axios from '../axios/axios-sfc'

class SFReportingApi {
    constructor(private axios: Axios) {}

    private sortItems = (items: { dashboardOrder: number }[]) => {
        items.sort((item1, item2) => {
            if (item1.dashboardOrder < item2.dashboardOrder) {
                return -1
            }
            if (item1.dashboardOrder > item2.dashboardOrder) {
                return 1
            }

            return 0
        })
    }

    saveReport = async (report: ReportDialogConfig): Promise<Report> => {
        const reportId = report.id > 0 ? report.id.toString() : ''
        const updatedReport = await (await this.axios.put(`/Api/Reports/${reportId}`, report)).data
        return parseReport(updatedReport)
    }

    deleteReport = async (reportId: number): Promise<void> => {
        await this.axios.delete(`/Api/Reports/${reportId}`)
    }

    /**
     * Get the reports dashboard configuration
     * @returns
     */
    getReportsDashboard = async (): Promise<ScenarioSelection> => {
        return (await this.axios.get('/Api/Reports/DashboardConfig')).data as ScenarioSelection
    }

    /**
     * Get the insights dashboard configuration
     * @returns
     */
    getInsightsDashboard = async (): Promise<InsightsDashboardConfig> => {
        return (await this.axios.get('/Api/Insights/DashboardConfig')).data as InsightsDashboardConfig
    }

    /**
     * Update reports dashboard config (selected scenarios)
     * @param dashboard
     */
    saveInsightsDashboard = async (dashboard: InsightsDashboardConfig): Promise<void> => {
        await this.axios.put('/Api/Insights/DashboardConfig', dashboard)
    }

    /**
     * Update reports dashboard config (selected scenarios)
     * @param dashboard
     */
    saveReportsDashboard = async (dashboard: ScenarioSelection): Promise<void> => {
        await this.axios.put('/Api/Reports/DashboardConfig', dashboard)
    }

    /**
     * Update the dashboard order to match the given report ids order.
     */
    saveReportsDashboardThumbnailOrder = async (reportIdsOrdered: number[]): Promise<void> => {
        await this.axios.put('/Api/Reports/UpdateDashboardOrder', { idsOrdered: reportIdsOrdered })
    }

    /**
     * Reset the reports dashboard to library defaults.
     */
    resetReportsDashboard = async (): Promise<Report[]> => {
        const reports = (await this.axios.get('/Api/Reports/ResetDashboard')).data
        return reports.map(parseReport)
    }

    /**
     * Reset the insights dashboard to defaults.
     */
    resetInsightsDashboard = async (): Promise<HazardClass[]> => {
        const hazardClasses = (await this.axios.get('/Api/Insights/ResetDashboard')).data
        return hazardClasses.map(parseHazardClass)
    }

    /**
     * Get reports for the dashboard
     * @returns
     */
    getReports = async (): Promise<Report[]> => {
        const data = (await this.axios.get('/Api/Reports')).data as Array<any>
        const reports = data.map<Report>(parseReport)
        this.sortItems(reports)
        return reports
    }

    /**
     * Get a single report.
     */
    getReport = async (reportId: number): Promise<Report> => {
        const data = (await this.axios.get(`/Api/Reports/${reportId}`)).data
        return parseReport(data)
    }

    /**
     * Get drilldown data for this report
     */
    getReportDrilldown = async (request: ReportDrilldownRequest): Promise<any> => {
        return (
            await this.axios.get(
                `/Api/Reports/Drilldown?reportId=${request.reportId}&xAxisStartIndex=${request.xAxisStartIndex}&xAxisEndIndex=${request.xAxisEndIndex}&visibleSeriesIds=${request.visibleSeriesIds}`,
            )
        ).data
    }

    /**
     * Get the reports library.
     */
    getReportsLibrary = async (): Promise<ReportDialogConfig[]> => {
        const data = (await this.axios.get('/Api/Reports/Library')).data as Array<any>
        return data.map<Report>(parseReport)
    }

    /**
     * Get the reports library.
     */
    getReportsLibraryDefaults = async (): Promise<ReportDialogConfig[]> => {
        const data = (await this.axios.get('/Api/Reports/LibraryDefaultReports')).data as Array<any>
        return data.map<Report>(parseReport)
    }

    /**
     * Update the library with the given library reports configuration.
     */
    saveReportsLibrary = async (libraryReports: ReportDialogConfig[]): Promise<void> => {
        await this.axios.post('/Api/Reports/Library', libraryReports)
    }

    /**
     * Add library reports to the user's dashboard, start analysis or analyze synchronously
     */
    addLibraryReportsToDashboard = async (reports: AddReportFromLibraryRequest): Promise<Report[]> => {
        const rawReports = (await this.axios.post('/Api/Reports/AddLibraryItemsToDashboard', reports)).data as any[]
        return rawReports.map(parseReport)
    }

    /**
     * Add library reports to the user's dashboard, start analysis or analyze synchronously
     */
    addLibraryHazardClassesToDashboard = async (reports: AddHazardClassFromLibraryRequest): Promise<HazardClass[]> => {
        const rawHazardClasses = (await this.axios.post('/Api/Insights/AddLibraryItemsToDashboard', reports))
            .data as any[]
        return rawHazardClasses.map(parseHazardClass)
    }

    /**
     * Get metadata required for reports
     * @returns
     */
    getReportsConfigMetadata = async (usedColorsString?: string): Promise<ReportingMetadata> => {
        const usedColors = encodeURIComponent(usedColorsString || '')
        const data = (
            await this.axios.get(`/Api/Reports/ReportsConfigMetadata?usedColors=${usedColors}&numberToGet=100`)
        ).data
        return parseReportConfigMetadata(data)
    }

    /**
     * Get metadata required for insights
     * @returns
     */
    getInsightsConfigMetadata = async (): Promise<InsightsMetadata> => {
        const data = (await this.axios.get('/Api/Insights/InsightsConfigMetadata')).data
        return parseInsightsConfigMetadata(data)
    }

    /**
     * Create or update hazard class.
     * @param hazardClass
     * @returns
     */
    saveHazardClass = async (
        hazardClass: HazardClassDialogConfig,
        requiresRunInsights: boolean,
        requiresReorderPatterns: boolean,
    ): Promise<HazardClass> => {
        const updatedHazardClass = await (
            await this.axios.put(`/Api/Insights/${hazardClass.id}`, {
                requiresRunInsights,
                requiresReorderPatterns,
                hazardClass,
            })
        ).data
        return parseHazardClass(updatedHazardClass)
    }

    /**
     * Delete the hazard class with the given id.
     */
    deleteHazardClass = async (hazardClassId: number): Promise<void> => {
        await await this.axios.delete(`/Api/Insights/${hazardClassId}`)
    }

    /**
     * Get all the hazard classes for Insights Dashbaord
     * @returns
     */
    getHazardClasses = async (): Promise<HazardClass[]> => {
        const data = (await this.axios.get('/Api/Insights')).data as Array<any>
        const hazardClasses = data.map<HazardClass>(parseHazardClass)
        this.sortItems(hazardClasses)
        return hazardClasses
    }

    /**
     * Get all the hazard classes for Insights Dashbaord
     * @returns
     */
    getHazardClass = async (hazardClassId: number): Promise<HazardClass> => {
        const data = (await this.axios.get(`/Api/Insights/${hazardClassId}`)).data
        const hazardClass = parseHazardClass(data)
        return hazardClass
    }

    /**
     * Update the dashboard order to match the given hazard class ids order.
     */
    saveInsightsDashboardThumbnailOrder = async (hazardClassIdsOrdereed: number[]): Promise<void> => {
        await this.axios.put('/Api/Insights/UpdateDashboardOrder', { idsOrdered: hazardClassIdsOrdereed })
    }

    updatePatternProperties = async (hazardClassId: number, patternId: number, name: string, hidden: boolean) => {
        await this.axios.put('/Api/Insights/UpdatePatternProperties', { hazardClassId, patternId, name, hidden })
    }
}

export default SFReportingApi
