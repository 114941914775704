import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns'
import { ChangeEvent, CSSProperties } from 'react'
import { Card, Col, Form } from 'react-bootstrap'
import { TagKeyValue } from 'types/ScheduleEvent'
import IconButtonDelete from 'views/Common/Buttons/IconButtonDelete'
import classes from './TagsTable.module.css'

const TagsTable = (props: {
    readonly?: boolean
    tags: TagKeyValue[]
    allTagNames: string[]
    showValidationErrors?: boolean
    tagChanged: (id: number, name: string, value: string) => void
    tagRemoved: (id: number) => void
}) => {
    const tagChangeHandler = (tagId: number, tagName: string, tagValue: string) => {
        props.tagChanged(tagId, tagName, tagValue)
    }

    const invalidCellStyle: CSSProperties = { border: '1px solid red' }

    const otherTagHasSameName = (id: number, name: string) => {
        return props.tags.filter((x) => x.id !== id && x.name === name).length > 0
    }

    const tagRows = props.tags.map((tag) => {
        const isInvalid = props.showValidationErrors && (tag.name === '' || otherTagHasSameName(tag.id, tag.name))

        const nameCellStyling = isInvalid ? invalidCellStyle : {}
        return (
            <Form.Row key={tag.id} className="mb-2">
                <Col xs={6} style={nameCellStyling}>
                    <ComboBox
                        disabled={props.readonly}
                        style={{ width: '100%' }}
                        value={tag.name}
                        data={props.allTagNames}
                        allowCustom
                        placeholder="Provide a unique tag name"
                        onChange={(e: ComboBoxChangeEvent) => {
                            // remove multiple consecutive spaces, and trim
                            const tagNameCleaned = e.value?.replace(/\s\s+/g, ' ').trim()
                            tagChangeHandler(tag.id, tagNameCleaned || '', tag.value)
                        }}
                    />
                    {isInvalid && <small style={{ color: 'red' }}>Please provide a unique Tag Name</small>}
                </Col>
                <Col xs={5}>
                    <Form.Control
                        disabled={props.readonly}
                        id={`txtTag_${tag.id.toString()}_value`}
                        name={`tag_${tag.id.toString()}_value`}
                        type="text"
                        placeholder="Provide a tag value"
                        value={tag.value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            tagChangeHandler(tag.id, tag.name, e.target.value)
                        }}
                    />
                </Col>
                <Col xs={1} className="pt-1">
                    {!props.readonly && (
                        <IconButtonDelete
                            onClick={() => {
                                props.tagRemoved(tag.id)
                            }}
                        />
                    )}
                </Col>
            </Form.Row>
        )
    })

    if (tagRows.length === 0) {
        return null
    }
    return (
        <Card>
            <Card.Body className={classes.cardBody}>
                <Form.Row>
                    <Col xs={6}>
                        <small>Tag Name</small>
                    </Col>

                    <Col xs={5}>
                        <small>Tag Value</small>
                    </Col>
                </Form.Row>
                {tagRows}
            </Card.Body>
        </Card>
    )
}

export default TagsTable
