import { Builder, BuilderProps, Config, ImmutableTree, Query } from 'react-awesome-query-builder'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'

const renderBuilder = (builderProps: BuilderProps) => (
    <div className="query-builder-container">
        <div className="query-builder qb-lite">
            <Builder {...builderProps} />
        </div>
    </div>
)

export interface FilterDialogProps {
    query: { tree: ImmutableTree; config: Config }
    setQuery: (query: { tree: ImmutableTree; config: Config }) => void
}

const FilterBuilder = (props: FilterDialogProps) => {
    const configCopy = { ...props.query.config }

    // set appropriate date/time format for datetime fields
    Object.keys(configCopy.fields).forEach((key) => {
        const field = configCopy.fields[key]
        if (field.type === 'datetime') {
            field.fieldSettings = {
                dateFormat: dateTimeFormatting.getAppDateFormat(),
                timeFormat: dateTimeFormatting.getAppTimeFormat(),
            }
        }
    })

    return (
        <Query
            {...configCopy}
            value={props.query.tree}
            onChange={(immutableTree: ImmutableTree) => {
                props.setQuery({ tree: immutableTree, config: configCopy })
            }}
            renderBuilder={renderBuilder}
        />
    )
}

export default FilterBuilder
