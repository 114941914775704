import ScheduleEvent from 'types/ScheduleEvent'
import classes from './EventTypeColorIcon.module.css'

const EventTypeColorIcon = (props: { scheduleEvent: ScheduleEvent }) => {
    if (props.scheduleEvent.isDutyRollup()) {
        return <></>
    }
    const typeColorClass = `typeColor_${props.scheduleEvent.getDataType()}`
    const colorClass = `${classes.typeColor} ${classes[typeColorClass]}`

    return (
        <>
            <div className={colorClass} title={props.scheduleEvent.getDataTypeDescription()} />
        </>
    )
}

export default EventTypeColorIcon
