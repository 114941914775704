import { useEffect, useState } from 'react'
import { Config, ImmutableTree, Utils as QbUtils } from 'react-awesome-query-builder'
import globals from 'services/global/globals'
import {
    convertQueryBuilderToRAConfiguration,
    convertQueryBuilderToSql,
    convertRAToQueryBuilder,
} from 'services/queryBuilder/queryBuilderService'
import { handleApiError } from 'services/utilities/toastrUtils'
import { ReportItemDefinition } from 'types/ReportingMetadata'
import FilterBuilder from './FilterBuilder'

const EventsFilterBuilder = () => {
    const api = globals.getApi()
    const [filterConfig, setFilterConfig] = useState<{ tree: ImmutableTree; config: Config } | null>(null)
    const [dropdownConfigItems, setDropdownConfigItems] = useState<ReportItemDefinition[]>([])
    useEffect(() => {
        const loadData = async () => {
            try {
                const loadedConfig = await api.getEventsFilterConfig()

                const { convertedQuery, config } = convertQueryBuilderToRAConfiguration(
                    loadedConfig.configItems,
                    loadedConfig.rules,
                )

                const tree = QbUtils.checkTree(QbUtils.loadTree(convertedQuery), config)

                setFilterConfig({ config, tree })
                setDropdownConfigItems(loadedConfig.configItems)
            } catch (err: any) {
                handleApiError(err)
            }
        }
        loadData()
    }, [api])

    // hook up functions called from SFC into the web.
    useEffect(() => {
        const w = window as any
        w.getJsonRulesFromQueryBuilder = () => {
            const queryBuilderFormat = convertRAToQueryBuilder(
                filterConfig!.tree,
                filterConfig!.config,
                dropdownConfigItems,
            )

            return JSON.stringify(queryBuilderFormat)
        }

        w.getSQLStringFromQueryBuilder = () => {
            const queryBuilderFormat = convertRAToQueryBuilder(
                filterConfig!.tree,
                filterConfig!.config,
                dropdownConfigItems,
            )
            const sql = convertQueryBuilderToSql(queryBuilderFormat, dropdownConfigItems)
            return sql
        }
    }, [filterConfig, dropdownConfigItems])

    if (!filterConfig) {
        return <p>Loading...</p>
    }

    return <FilterBuilder query={filterConfig} setQuery={setFilterConfig} />
}

export default EventsFilterBuilder
