import Schedule from 'types/Schedule'
import PrintableTagsList from './PrintableTagsList'

const LabelledValue = (props: { label: string; value: string }) => (
    <div>
        <strong>{props.label}:</strong> {props.value}
    </div>
)

const PrintableScheduleDetailsHeader = (props: { schedule: Schedule }) => {
    return (
        <div className="mb-4">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <LabelledValue label="Schedule Name" value={props.schedule.name} />
                <LabelledValue label="Scenario Name" value={props.schedule.scenarioName} />
            </div>
            <LabelledValue label="Base" value={props.schedule.baseLocation} />
            <PrintableTagsList tags={props.schedule.tagCollection} />
        </div>
    )
}

export default PrintableScheduleDetailsHeader
