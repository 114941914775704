import React from 'react'
import TransparentButton from '../Buttons/TransparentButton'
import classes from './PageHeading.module.css'

const PageHeading = (props: {
    headingProps: React.HTMLAttributes<HTMLElement>
    headingSubContent?: React.ReactNode
}) => {
    const className = `${classes.pageHeading} ${props.headingProps.className || ''}`
    const content = props.headingProps.onClick ? (
        <TransparentButton color="black">{props.headingProps.children}</TransparentButton>
    ) : (
        props.headingProps.children
    )
    return (
        <>
            <h3 className={className} {...props.headingProps}>
                {content}
            </h3>
            {props.headingSubContent}
        </>
    )
}

export default PageHeading
