import useWindowSize from 'hooks/useWindowSize'
import { HTMLAttributes, ReactNode, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import PageHeading from 'views/Common/Layout/PageHeading'

const PageLayout = (props: {
    /**
     * The main content of the page goes here
     */
    children: React.ReactNode
    /**
     * Don't use the default of placing content into a <Col>.  Assumes client will put into (1 or more) <Col> itself
     */
    doNotPutContentInCol?: boolean
    contentRowClass?: string
    /**
     * Optional navigation breadcrums
     */
    breadcrumbs?: React.ReactNode
    /**
     * Heading for the view
     */
    headingContent: ReactNode
    /**
     * Row underneath the heading
     */
    headingSubContent?: ReactNode
    /**
     * Optional handler for clicking the heading
     */
    headingProps?: HTMLAttributes<HTMLElement>
    /**
     * Buttons
     */
    buttons?: React.ReactNode
    /**
     * Fires with content height ('###px' string format) when window size changes
     */
    onMainContentHeightChange?: (newHeight: number) => void
    onMainContentWidthChange?: (newHeight: number) => void
    windowResizeDelayMs?: number
}) => {
    // use custom windowHeight hook to detect when the browser size changes
    // then send that back up to where the grid is configured
    const [windowWidth, windowHeight] = useWindowSize(props.windowResizeDelayMs)
    const [contentHeight, setContentHeight] = useState(0)
    const isPrintPreview = useSelector<RootState, boolean>((x) => x.app.isPrintPreview)

    useEffect(() => {
        if (props.onMainContentHeightChange) {
            const pageHeadingSection = document.getElementsByClassName('aboveMainSection')[0] as HTMLElement
            const breadcrumbsSection = document.getElementsByClassName('breadcrumbsSection')[0] as HTMLElement
            const navbar = document.getElementById('mainNavigation')
            let topMostHeight = 0

            if (breadcrumbsSection) {
                topMostHeight += breadcrumbsSection.offsetHeight
            }

            if (pageHeadingSection) {
                topMostHeight += pageHeadingSection.offsetHeight
            }

            if (navbar) {
                topMostHeight += navbar.offsetHeight
            }

            // Extra padding to make sure there's no scrollbar
            const magicExtra = 20

            // tell the calling component which generates the main content what the new content height should be
            const newContentHeight = windowHeight - topMostHeight - magicExtra

            if (newContentHeight !== contentHeight) {
                setContentHeight(newContentHeight)
                props.onMainContentHeightChange(newContentHeight)
            }
        }

        // width resize callback
        if (props.onMainContentWidthChange) {
            props.onMainContentWidthChange(windowWidth)
        }
    }, [props, windowHeight, contentHeight, windowWidth])

    // extra top margin if no breadcrumbs
    const headingMarginTop = props.breadcrumbs ? '' : 'mt-3'
    const headingProps: HTMLAttributes<HTMLElement> = { ...props.headingProps, children: props.headingContent }

    return (
        <>
            <Container fluid>
                {!isPrintPreview && <div className="breadcrumbsSection">{props.breadcrumbs}</div>}
                <Row className="aboveMainSection">
                    <Col sm={12} className={headingMarginTop}>
                        <Row className="justify-content-between">
                            <Col className="col-auto">
                                <PageHeading
                                    headingProps={headingProps || {}}
                                    headingSubContent={props.headingSubContent}
                                />
                            </Col>
                            {props.buttons && <Col className="auto text-right">{props.buttons}</Col>}
                        </Row>
                    </Col>
                </Row>
                <Row className={`${props.contentRowClass || ''} mt-2`}>
                    {props.doNotPutContentInCol ? <>{props.children}</> : <Col sm="12">{props.children}</Col>}
                </Row>
            </Container>
        </>
    )
}

export default PageLayout
