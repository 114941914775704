import useUser from 'hooks/useUser'
import { ChangeEvent, FormEvent, memo, useState } from 'react'
import { Alert, Form, Modal } from 'react-bootstrap'
import globals from 'services/global/globals'
import { ExportItemTypeEnum, ExportSchedulesRequest } from 'types/ExportSchedulesRequest'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import simpleDialogClasses from 'views/Common/GenericDialogs/SimpleDialog.module.css'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'

type ExportDialogProps = {
    selectedIds: number[]
    columnConfigJson: string
    exportItemType: ExportItemTypeEnum
    closeCallback: (status: DialogResultEnum) => void
}

const ExportScenarioDialogContent = (props: ExportDialogProps) => {
    const [includeSummaryCsv, setIncludeSummaryCsv] = useState(true)
    const [includeScheduleResultsCsv, setIncludeSchedulesResultsCsv] = useState(true)
    const [includeScheduleDetailsCsv, setIncludeScheduleDetailsCsv] = useState(true)
    const [formDescription, setDescription] = useState('')
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const api = globals.getApi()
    const processingApi = api.getProcessingApi()
    const user = useUser()!

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const exportConfig: ExportSchedulesRequest = {
            description: formDescription,
            summaryCsv: includeSummaryCsv,
            scheduleResultsCsv: includeScheduleResultsCsv,
            detailCsv: includeScheduleDetailsCsv && user.accountEnabledDetailCsvExport,
            itemType: props.exportItemType,
            selectedIds: props.selectedIds,
            ScheduleUserColumnConfigJson: props.columnConfigJson,
        }

        try {
            await processingApi.exportSchedules(exportConfig)
            props.closeCallback(DialogResultEnum.Completed)
        } catch (err: any) {
            setErrorMessage(err.message)
        }
    }

    const title = props.exportItemType === ExportItemTypeEnum.Scenario ? 'Export Scenario' : 'Export Schedule(s)'

    return (
        <>
            <Form noValidate onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <FormLabelCustom>Choose the type of files to export:</FormLabelCustom>
                        <div className={simpleDialogClasses.checkBoxContainer}>
                            <Form.Check
                                id="chkSummaryCsv"
                                label="Summary CSV"
                                type="checkbox"
                                checked={includeSummaryCsv}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setIncludeSummaryCsv(e.target.checked)}
                            />
                            <Form.Check
                                id="chkScheduleResultsCsv"
                                label="Schedules Table CSV"
                                type="checkbox"
                                checked={includeScheduleResultsCsv}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setIncludeSchedulesResultsCsv(e.target.checked)
                                }
                            />
                            {user.accountEnabledDetailCsvExport && (
                                <Form.Check
                                    id="chkScheduleDetailCsv"
                                    label="Detail CSV"
                                    type="checkbox"
                                    checked={includeScheduleDetailsCsv}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setIncludeScheduleDetailsCsv(e.target.checked)
                                    }
                                />
                            )}
                        </div>
                    </Form.Group>

                    <Form.Group>
                        <FormLabelCustom htmlFor="txtDescription">
                            Description for finding on the File Processing page
                        </FormLabelCustom>
                        <Form.Control
                            id="txtDescription"
                            name="description"
                            autoFocus
                            type="text"
                            placeholder="Optional Description"
                            value={formDescription}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                            required
                        />
                    </Form.Group>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom isLarge variant="primary" type="submit">
                        Export
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const ExportScenarioDialog = (props: ExportDialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <ExportScenarioDialogContent {...props} />
        </ModalWrapper>
    )
}

export default memo(ExportScenarioDialog)
