import { maxBy, meanBy, minBy } from 'lodash'
import formatDecimal from 'services/formatting/numberFormatter'
import { HazardClass } from 'types/HazardClass'
import { Duty } from 'types/HazardClassCase'
import { PatternTrendItem } from 'types/PatternTrendItem'
import classes from './PatternTrends.module.css'

const PatternTrends = (props: { hazardClass: HazardClass; hazardDuties: Duty[] }) => {
    const patternTrends: PatternTrendItem[] = []

    if (
        props.hazardClass.metricEffectivenessWeight > 0 ||
        props.hazardClass.metricReservoirWeight > 0 ||
        props.hazardClass.metricFHAWeight > 0
    ) {
        patternTrends.push({
            itemName: 'WOCL Duration',
            minValue: minBy(props.hazardDuties, 'woclDuration')!.woclDuration,
            maxValue: maxBy(props.hazardDuties, 'woclDuration')!.woclDuration,
            avgValue: meanBy(props.hazardDuties, 'woclDuration'),
        })

        patternTrends.push({
            itemName: 'Prior Sleep 24h',
            minValue: minBy(props.hazardDuties, 'priorSleep24')!.priorSleep24!,
            maxValue: maxBy(props.hazardDuties, 'priorSleep24')!.priorSleep24!,
            avgValue: meanBy(props.hazardDuties, 'priorSleep24'),
        })

        patternTrends.push({
            itemName: 'Prior Day Sleep 24h',
            minValue: minBy(props.hazardDuties, 'priorDaySleep24')!.priorDaySleep24!,
            maxValue: maxBy(props.hazardDuties, 'priorDaySleep24')!.priorDaySleep24!,
            avgValue: meanBy(props.hazardDuties, 'priorDaySleep24'),
        })
    }

    if (props.hazardClass.metricWorkloadWeight > 0) {
        patternTrends.push({
            itemName: 'Workload factors triggered',
            minValue: minBy(props.hazardDuties, 'insightsScoreWorkload')!.insightsScoreWorkload,
            maxValue: maxBy(props.hazardDuties, 'insightsScoreWorkload')!.insightsScoreWorkload,
            avgValue: meanBy(props.hazardDuties, 'insightsScoreWorkload')!,
        })
    }

    return (
        <div className={classes.container}>
            <strong>Contributing Factors</strong>
            <ul>
                {patternTrends.map((item) => {
                    let description = null
                    if (item.maxValue !== undefined && item.maxValue > 0) {
                        description = (
                            <li key={item.itemName}>
                                <TrendDescription item={item} />
                            </li>
                        )
                    }
                    return description
                })}
            </ul>
        </div>
    )
}

const TrendDescription = (props: { item: PatternTrendItem }) => {
    const formatNumber = (num: number) => formatDecimal(num, 1, true)
    return (
        <p key={props.item.itemName}>
            {props.item.itemName} average of {formatNumber(props.item.avgValue!)} between{' '}
            {formatNumber(props.item.minValue!)} and {formatNumber(props.item.maxValue!)}
        </p>
    )
}

export default PatternTrends
