export enum ExportItemTypeEnum {
    Scenario,
    Schedule,
}
export interface ExportSchedulesRequest {
    ScheduleUserColumnConfigJson: string
    description: string
    summaryCsv: boolean
    detailCsv: boolean
    scheduleResultsCsv: boolean
    itemType: ExportItemTypeEnum
    selectedIds: number[]
}
