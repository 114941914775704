/**
    Custom override of axios where we can take override requests that are intended for SFC (desktop) and route to the window.safteFastConsole object.
 */

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { User } from 'types/interfaces'
import { SFC } from 'types/SFC'
import SessionControl from './SessionControl'

const isDesktopMode = (includeMock?: boolean): boolean => {
    const sfcObject = (window as any).safteFastConsole
    return sfcObject !== undefined && (!sfcObject.isMock || includeMock === true)
}

const setDesktopHasLoaded = () => {
    ;(window as any).desktopHasLoaded = true
}

const getDesktopHasLoaded = () => {
    return (window as any).desktopHasLoaded === true
}

type AxiosMethodType = 'get' | 'post' | 'put' | 'delete'

type RequestFetchOptions = {
    genericUrl: string
    url: string
    dataObject?: any
}
type RequestMapping = {
    genericUrl: string
    method: AxiosMethodType
    fetch: (request: RequestFetchOptions) => Promise<string>
}

class Axios {
    private instance = axios.create()
    private requestMappings: RequestMapping[] = []
    private publicApiCalls = ['/Api/Users/PasswordReset']

    constructor(private sessionControl: SessionControl) {
        // If we are running in SFC (desktop) mode or just running the react development
        // server, there will be a safteFastConsole object on the window.
        // In that case, we setup routes for all the api calls which direct requests
        // to the safteFastConsole object.
        if (isDesktopMode(true)) {
            this.instance.get = (url: string) => {
                return this.fetchDataAtUrl(url, 'get', null)
            }
            this.instance.post = (url: string, data?: any) => {
                return this.fetchDataAtUrl(url, 'post', JSON.stringify(data))
            }
            this.instance.delete = (url: string) => {
                return this.fetchDataAtUrl(url, 'delete', null)
            }
            this.instance.put = (url: string, data?: any) => {
                return this.fetchDataAtUrl(url, 'put', JSON.stringify(data))
            }

            const sfc = (window as any).safteFastConsole as SFC
            this.addEndpoint('get', '/Api/Metadata', () => sfc.getMetadata())
            this.addEndpoint('get', '/Api/Metadata/AppVersion', () => new Promise((resolve) => resolve('0')))

            this.addEndpoint('get', '/Api/Users/AuthenticateCredentials', () => sfc.authenticateCredentials())
            this.addEndpoint('get', '/Api/Users/AuthenticateToken', () => sfc.authenticateToken())
            this.addEndpoint('get', '/Api/Users', () => sfc.getUsers())
            this.addEndpoint('get', '/Api/Users/LoggedOut', () => sfc.signOut())
            this.addEndpoint(
                'get',
                '/Api/Users/ActiveUsersAndLicenseLimit',
                () => new Promise((resolve) => resolve('{"activeUsersCount":0, "activeUsersLimit": 1}')),
            )

            this.addEndpoint('get', '/Api/Scenarios', () => sfc.getScenariosList())
            this.addEndpoint('get', '/Api/Scenarios/:id/Schedules', () => sfc.getScenarioSchedules(1))
            this.addEndpoint('get', '/Api/Scenarios/ScenariosWithMetrics', () => sfc.getScenariosWithMetrics())
            this.addEndpoint('get', '/Api/Scenarios/:id', (options) =>
                sfc.getScenarioById(this.getIdFromOptions(options)),
            )
            this.addEndpoint('delete', '/Api/Insights/:id', (options) =>
                sfc.deleteHazardClass(this.getIdFromOptions(options)),
            )

            this.addEndpoint('get', '/Api/Shifts/GetPatternsShifts', () => sfc.getPatternsShifts())
            this.addEndpoint('post', '/Api/Shifts/CreateOrUpdateShiftsSchedule', (options) =>
                sfc.createOrUpdateShiftsSchedule(options.dataObject),
            )
            this.addEndpoint('post', '/Api/Shifts/UpdatePatternsShifts', (options) =>
                sfc.updatePatternsShifts(options.dataObject),
            )
            this.addEndpoint('post', '/Api/Schedules/EditEvent', (options) => sfc.editEvent(options.dataObject))
            this.addEndpoint('post', '/Api/Schedules/CreateEvent', (options) => sfc.createEvent(options.dataObject))
            this.addEndpoint('post', '/Api/Schedules/DeleteEvent', (options) => sfc.deleteEvent(options.dataObject))
            this.addEndpoint('post', '/Api/Schedules/EditDuty', (options) => sfc.editDuty(options.dataObject))
            this.addEndpoint('post', '/Api/Schedules/EditDutyDates', (options) => sfc.editDutyDates(options.dataObject))

            this.addEndpoint('get', '/Api/Schedules/GetNewScheduleDefaultsForScenario/:id', () =>
                sfc.getNewScheduleDefaultsForScenario(),
            )
            this.addEndpoint('post', '/Api/Schedules/CreateNewEventsSchedule', (options) =>
                sfc.postNewEventsSchedule(options.dataObject),
            )
            this.addEndpoint('get', '/Api/Schedules/:id', () => sfc.getScheduleWithDetails())
            this.addEndpoint('post', '/Api/Schedules/UpdateAndReanalyzeSchedule', (options) =>
                sfc.updateScheduleAndReanalyze(options.dataObject),
            )
            this.addEndpoint('put', '/Api/Schedules/UpdateScheduleViewSettings', (options) =>
                sfc.updateScheduleSettings(options.dataObject),
            )
            this.addEndpoint('put', '/Api/Schedules/UpdateLastViewed', (options) =>
                sfc.updateLastViewed(options.dataObject),
            )
            this.addEndpoint('put', '/Api/Schedules/UpdateScheduleSettingsForAnalysis', (options) =>
                sfc.updateScheduleSettingsForAnalysis(options.dataObject),
            )
            this.addEndpoint('put', '/Api/Schedules/:id/Parameters', (options) =>
                sfc.updateScheduleParameters(options.dataObject),
            )
            this.addEndpoint('get', '/Api/Schedules/:id/Parameters', () => sfc.getScheduleParameters())
            this.addEndpoint('post', '/Api/Schedules/:id/ResetExplicitSleep', () => sfc.resetExplicitSleep())

            this.addEndpoint('get', '/Api/Reports/DashboardConfig', () => sfc.getReportsDashboardConfig())
            this.addEndpoint('get', '/Api/Reports/ReportsConfigMetadata', (options) => {
                const query = options.url.split('?')[1]
                const args = query.split('&')
                const usedColors = this.getArgumentValueFromArgsPairs('usedColors', args)!
                const numberColorsToGet = parseInt(this.getArgumentValueFromArgsPairs('numberToGet', args)!)
                return sfc.getReportsMetadata(usedColors, numberColorsToGet)
            })
            this.addEndpoint('get', '/Api/Reports', () => sfc.getReports())
            this.addEndpoint('get', '/Api/Reports/Drilldown', (options) => {
                const query = options.url.split('?')[1]
                const args = query.split('&')
                const reportId = parseInt(this.getArgumentValueFromArgsPairs('reportId', args)!)
                const xAxisStartIndex = parseInt(this.getArgumentValueFromArgsPairs('xAxisStartIndex', args)!)
                const xAxisEndIndex = parseInt(this.getArgumentValueFromArgsPairs('xAxisEndIndex', args)!)
                const visibleSeriesIds = this.getArgumentValueFromArgsPairs('visibleSeriesIds', args)
                return sfc.getReportDrilldownData(
                    JSON.stringify({
                        reportId,
                        xAxisStartIndex,
                        xAxisEndIndex,
                        visibleSeriesIds,
                    }),
                )
            })

            this.addEndpoint('get', '/Api/Reports/ResetDashboard', () => sfc.resetReportsDashboard())

            this.addEndpoint('put', '/Api/Reports', (options) => sfc.createReport(options.dataObject))
            this.addEndpoint('get', '/Api/Reports/Library', () => sfc.getReportsLibrary())
            this.addEndpoint('get', '/Api/Reports/LibraryDefaultReports', () => sfc.getReportsLibraryDefaults())
            this.addEndpoint('post', '/Api/Reports/Library', (options) => sfc.updateReportsLibrary(options.dataObject))
            this.addEndpoint('post', '/Api/Reports/AddLibraryItemsToDashboard', (options) =>
                sfc.addLibraryReportsToDashboard(options.dataObject),
            )
            this.addEndpoint('put', '/Api/Reports/UpdateDashboardOrder', (options) =>
                sfc.updateReportsDashboardOrder(options.dataObject),
            )
            this.addEndpoint('put', '/Api/Reports/:id', (options) => sfc.updateReport(options.dataObject))
            this.addEndpoint('delete', '/Api/Reports/:id', (options) =>
                sfc.deleteReport(this.getIdFromOptions(options)),
            )
            this.addEndpoint('get', '/Api/Reports/:id', (options) => sfc.getReportData(this.getIdFromOptions(options)))

            this.addEndpoint('put', '/Api/Insights/:id', (options) => sfc.createOrUpdateHazardClass(options.dataObject))
            this.addEndpoint('get', '/Api/Insights', () => sfc.getInsights())
            this.addEndpoint('get', '/Api/Insights/:id', (options) =>
                sfc.getHazardClass(this.getIdFromOptions(options)),
            )
            this.addEndpoint('get', '/Api/Insights/DashboardConfig', () => sfc.getInsightsDashboardConfig())
            this.addEndpoint('put', '/Api/Insights/DashboardConfig', (options) =>
                sfc.updateInsightsDashboardConfig(options.dataObject),
            )
            this.addEndpoint('get', '/Api/Insights/Library', () => sfc.getInsightsLibrary())
            this.addEndpoint('post', '/Api/Insights/AddLibraryItemsToDashboard', (options) =>
                sfc.addLibraryHazardClassesToDashboard(options.dataObject),
            )
            this.addEndpoint('get', '/Api/Insights/LibraryDefaultReports', () => sfc.getInsightsLibraryDefaults())
            this.addEndpoint('post', '/Api/Insights/Library', (options) =>
                sfc.updateInsightsLibrary(options.dataObject),
            )
            this.addEndpoint('put', '/Api/Insights/UpdateDashboardOrder', (options) =>
                sfc.updateInsightsDashboardOrder(options.dataObject),
            )
            this.addEndpoint('put', '/Api/Insights/UpdatePatternProperties', (options) =>
                sfc.updatePatternProperties(options.dataObject),
            )

            this.addEndpoint('delete', '/Api/Insights/:id', (options) =>
                sfc.deleteHazardClass(this.getIdFromOptions(options)),
            )
            this.addEndpoint('get', '/Api/Insights/ResetDashboard', () => sfc.resetInsightsDashboard())
            this.addEndpoint('get', '/Api/Insights/InsightsConfigMetadata', () => sfc.getInsightsMetadata())

            this.addEndpoint('get', '/Api/FileProcessing', () => sfc.getFileProcessingRecords())
            this.addEndpoint('get', '/Api/FileProcessing/ImportScenario', () => sfc.postImportScenario())
            this.addEndpoint('get', '/Api/FileProcessing/AnyRecalculationRecordForUser', () =>
                sfc.getAnyRecalculationRecordsForUser(),
            )
            this.addEndpoint('get', '/Api/FileProcessing/RecalculationStatus', (options) => {
                const query = options.url.split('?')[1]
                const requestIds = query.split('=')[1]
                return sfc.getRecalculationStatus(requestIds)
            })
            this.addEndpoint('post', '/Api/FileProcessing/Recalculate', (options) => {
                return sfc.recalculate(options.dataObject)
            })
            this.addEndpoint('get', '/Api/Stations/GetStationsByPartialTextMatch', (options) => {
                const query = options.url.split('?')[1]
                const searchTerm = query.split('=')[1]
                return sfc.getStationsByPartialTextMatch(searchTerm)
            })
            this.addEndpoint('get', '/Api/Stations/GetUtcOffsetMinutes', (options) => {
                const query = options.url.split('?')[1]
                // stationCode=yyz&time=12344534238

                const [stationCodeArg, timeArg] = query.split('&')
                // ["stationCode=yyz", "time=12344534238"]

                const stationCode = stationCodeArg.split('=')[1]
                const time = timeArg.split('=')[1]
                return sfc.getUtcOffsetMinutes(stationCode, parseInt(time))
            })

            this.addEndpoint('get', '/Api/Parameters/EventsFilterConfig', () => sfc.getEventFilterBuilderConfig())
        }
    }

    /**
     * Get the argument value for a particular query string argument.  Input format like ['color=blue', 'number=555']
     * @param args
     */
    private getArgumentValueFromArgsPairs = (argumentName: string, args: string[]): string | undefined => {
        return args.find((x) => x.indexOf(argumentName) >= 0)?.split('=')[1]
    }

    /**
     * Make a url generic by removing numbers and query arguments
     */
    private makeGenericUrl = (url: string) =>
        url
            .replace(/\/(\d)+(\/|)/g, '/:id/') // replace numbers with :id
            .replace(/\/$/, '') // replace trailing slash with blank
            .replace(/\?.*$/, '') // replace query string with blank

    private getIdFromOptions = (options: RequestFetchOptions) =>
        parseInt(options.url.replace(options.genericUrl.replace(':id', ''), ''))

    private addEndpoint = (
        method: AxiosMethodType,
        genericUrl: string,
        fn: (options: RequestFetchOptions) => Promise<string>,
    ): void => {
        this.requestMappings.push({
            method,
            genericUrl,
            fetch: fn,
        })
    }

    /**
     * Fetch data from safteFastConsole (proxy object) for a given URL.
     * @param url
     * @returns
     */
    private fetchDataAtUrl = async (url: string, method: AxiosMethodType, dataObject: any): Promise<any> => {
        const endpoint = this.requestMappings.find(
            (x) => x.method === method && x.genericUrl === this.makeGenericUrl(url),
        )
        if (!endpoint) {
            throw Error(`Unmapped SFC endpoint: [${method.toUpperCase()}] => ${url}<br>${dataObject}`)
        }

        let data: any
        try {
            data = JSON.parse(await endpoint.fetch({ url, genericUrl: this.makeGenericUrl(url), dataObject }))
        } catch (error: any) {
            const sfc = (window as any).safteFastConsole as SFC
            const fetchError = (await sfc.getLastErrorMessage()) || error.toString()
            return new Promise<any>((unusedResolve, reject) => {
                reject(fetchError)
            })
        }

        return new Promise((resolve) => {
            resolve({ data })
        })
    }

    getAxiosErrorMessage = (error: AxiosError): string => {
        let msg = ''
        if (error.response) {
            if (error.response.data) {
                if (typeof error.response.data === 'string') {
                    msg = error.response.data
                } else {
                    msg = JSON.stringify(error.response.data)
                }
            } else {
                msg = error.response.statusText
            }
        } else if (error.message) {
            msg = error.message
        } else {
            msg = error.toString()
        }
        return msg
    }

    handleCustomErrors = (error: AxiosError) => {
        // these custom status codes are coded in the API
        if (error.response?.status === 480) {
            this.sessionControl.setSessionExpired(error.response.data.error)
        }

        if (error.response?.status === 482) {
            this.sessionControl.setPasswordExpired()
        }
    }

    axiosRequest = async (
        method: AxiosMethodType,
        url: string,
        requestConfig?: AxiosRequestConfig,
        requestData?: any,
    ): Promise<AxiosResponse<any>> => {
        try {
            switch (method) {
                case 'get':
                    return await this.instance.get(url, requestConfig)
                case 'post':
                    return await this.instance.post(url, requestData, requestConfig)
                case 'put':
                    return await this.instance.put(url, requestData, requestConfig)
                case 'delete':
                    return await this.instance.delete(url, requestConfig)
                default:
                    throw new Error(`HTTP method ${method} is not implemented for SFC`)
            }
        } catch (e: any) {
            const error: AxiosError = e
            const isPublicApiCall = this.publicApiCalls.find((publicUrl) => url.includes(publicUrl))
            if (!isPublicApiCall) {
                this.handleCustomErrors(error)
            }
            throw Error(this.getAxiosErrorMessage(error))
        }
    }

    get = async (url: string, requestConfig?: AxiosRequestConfig): Promise<AxiosResponse<any>> =>
        this.axiosRequest('get', url, requestConfig)

    put = async (url: string, requestData?: any, requestConfig?: AxiosRequestConfig): Promise<AxiosResponse<any>> =>
        this.axiosRequest('put', url, requestConfig, requestData)

    post = async (url: string, requestData?: any, requestConfig?: AxiosRequestConfig): Promise<AxiosResponse<any>> =>
        this.axiosRequest('post', url, requestConfig, requestData)

    delete = async (
        url: string,
        requestData?: any,
        requestConfig?: AxiosRequestConfig,
    ): Promise<AxiosResponse<any>> => {
        let appliedRequestConfig = requestConfig
        if (requestData) {
            if (!appliedRequestConfig) {
                appliedRequestConfig = {}
            }
            appliedRequestConfig.data = requestData
        }
        return this.axiosRequest('delete', url, appliedRequestConfig)
    }
}

/**
 * Fake user object for desktop or local development mode
 */
const DesktopUser = {
    id: 0,
    accountName: 'DesktopMode',
    isAdministrator: true,
} as User

export default Axios
export { isDesktopMode, setDesktopHasLoaded, getDesktopHasLoaded, DesktopUser }
