import { TimePicker, TimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { ChangeEvent, useEffect, useState } from 'react'
import { Alert, Col, Form, Modal } from 'react-bootstrap'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import globals from 'services/global/globals'
import { showResponseMessage } from 'services/utilities/toastrUtils'
import { ScenarioParameters } from 'types/Scenario'
import Schedule from 'types/Schedule'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import simpleDialogClasses from 'views/Common/GenericDialogs/SimpleDialog.module.css'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'

type DialogProps = {
    scheduleId: number
    closeCallback: (status: DialogResultEnum, updatedSchedule?: Schedule) => void
}

const rightColumnMargin = 'ml-3'
const rowMarginBottom = 'mb-2'

const hhmmToDate = (hhmm: string): Date => {
    const hrs = hhmm.substring(0, 2)
    const mins = hhmm.substring(2, 4)
    const d = new Date(2000, 0, 1)
    d.setHours(parseInt(hrs))
    d.setMinutes(parseInt(mins))
    return d
}

const dateToHhmm = (d: Date): string => dateTimeFormatting.formatDateAsTimeString(d).replace(':', '')

const SleepSettingsDialogContent = (props: DialogProps) => {
    const api = globals.getApi()
    const [parameters, setParameters] = useState<ScenarioParameters | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [validatedForm, setValidatedForm] = useState(false)

    useEffect(() => {
        const loadData = async () => {
            const scheduleParameters = await api.getScheduleParameters(props.scheduleId)
            setParameters(scheduleParameters)
        }
        loadData()
    }, [api, props.scheduleId])

    const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement

        let invalid = false
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }

        if (invalid) {
            // keep the form open, let the user fix the issues
            return
        }

        if (form.checkValidity() === false) {
            setValidatedForm(true)
            // keep the form open, let the user fix the issues
            return
        }

        try {
            const [schedule, message] = await api.updateScheduleParameters(props.scheduleId, parameters!)
            showResponseMessage(message)
            props.closeCallback(DialogResultEnum.Completed, schedule)
        } catch (err: any) {
            setErrorMessage(err.message)
        }
    }

    if (!parameters) {
        return <p>Loading...</p>
    }

    return (
        <>
            <Form noValidate validated={validatedForm} onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Sleep Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <FormLabelCustom>Sleep Mode:</FormLabelCustom>
                        <div className={simpleDialogClasses.checkBoxContainer}>
                            <Form.Check
                                type="radio"
                                id="rdoAutoSleep"
                                name="sleepModeRadios"
                                label="Auto-Sleep"
                                checked={parameters.editSleep !== true}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setParameters((previous) => {
                                        const updated = { ...previous! }
                                        updated.editSleep = !e.target.checked
                                        return updated
                                    })
                                }}
                                value="autosleep"
                            />
                            <Form.Check
                                type="radio"
                                id="rdoEditSleep"
                                name="sleepModeRadios"
                                label="Manual Sleep Editing"
                                checked={parameters.editSleep === true}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setParameters((previous) => {
                                        const updated = { ...previous! }
                                        updated.editSleep = e.target.checked
                                        return updated
                                    })
                                }}
                                value="manualsleep"
                            />
                        </div>
                    </Form.Group>
                    <Form.Row className={rowMarginBottom}>
                        <Col>
                            <Form.Group>
                                <FormLabelCustom>Max Rest Day Sleep (0 - 720)</FormLabelCustom>
                                <Form.Control
                                    type="number"
                                    placeholder="Number of Minutes"
                                    value={parameters.maxRestDaySleep}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setParameters((previous) => ({
                                            ...previous!,
                                            maxRestDaySleep: parseInt(e.target.value),
                                        }))
                                    }
                                    disabled={parameters.editSleep}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col className={rightColumnMargin}>
                            <Form.Group>
                                <FormLabelCustom>Bedtime</FormLabelCustom>
                                <TimePicker
                                    value={hhmmToDate(parameters.bedtime)}
                                    onChange={(e: TimePickerChangeEvent) =>
                                        setParameters((previous) => ({
                                            ...previous!,
                                            bedtime: dateToHhmm(e.value!),
                                        }))
                                    }
                                    format="HH:mm"
                                    width="100%"
                                    disabled={parameters.editSleep}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className={rowMarginBottom}>
                        <Col>
                            <Form.Group>
                                <FormLabelCustom>Max Work Day Sleep (0 - 720)</FormLabelCustom>
                                <Form.Control
                                    type="number"
                                    placeholder="Number of Minutes"
                                    value={parameters.maxWorkDaySleep}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setParameters((previous) => ({
                                            ...previous!,
                                            maxWorkDaySleep: parseInt(e.target.value),
                                        }))
                                    }
                                    disabled={parameters.editSleep}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col className={rightColumnMargin}>
                            <Form.Group>
                                <FormLabelCustom>Minimum Sleep (0 - 480)</FormLabelCustom>
                                <Form.Control
                                    type="number"
                                    placeholder="Number of Minutes"
                                    value={parameters.minimumSleep}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setParameters((previous) => ({
                                            ...previous!,
                                            minimumSleep: parseInt(e.target.value),
                                        }))
                                    }
                                    disabled={parameters.editSleep}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className={rowMarginBottom}>
                        <Col>
                            <Form.Group>
                                <FormLabelCustom>Awake Zone Start</FormLabelCustom>
                                <TimePicker
                                    value={hhmmToDate(parameters.awakeZoneStart)}
                                    onChange={(e: TimePickerChangeEvent) =>
                                        setParameters((previous) => ({
                                            ...previous!,
                                            awakeZoneStart: dateToHhmm(e.value!),
                                        }))
                                    }
                                    format="HH:mm"
                                    width="100%"
                                    disabled={parameters.editSleep}
                                />
                            </Form.Group>
                        </Col>
                        <Col className={rightColumnMargin}>
                            <Form.Group>
                                <FormLabelCustom>Commute Time (0 - 480)</FormLabelCustom>
                                <Form.Control
                                    type="number"
                                    placeholder="Number of Minutes"
                                    value={parameters.commute}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setParameters((previous) => ({
                                            ...previous!,
                                            commute: parseInt(e.target.value),
                                        }))
                                    }
                                    disabled={parameters.editSleep || parameters.commuteType === 'VariableEvents'}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <FormLabelCustom>Awake Zone End</FormLabelCustom>
                                <TimePicker
                                    value={hhmmToDate(parameters.awakeZoneEnd)}
                                    onChange={(e: TimePickerChangeEvent) =>
                                        setParameters((previous) => ({
                                            ...previous!,
                                            awakeZoneEnd: dateToHhmm(e.value!),
                                        }))
                                    }
                                    format="HH:mm"
                                    width="100%"
                                    disabled={parameters.editSleep}
                                />
                            </Form.Group>
                        </Col>
                        <Col className={rightColumnMargin}>
                            <Form.Group>
                                <FormLabelCustom>Autonap</FormLabelCustom>
                                <Form.Control
                                    as="select"
                                    name="autoNap"
                                    value={parameters.autoNap.toString()}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setParameters((previous) => ({
                                            ...previous!,
                                            autoNap: e.target.value === 'true',
                                        }))
                                    }
                                    disabled={parameters.editSleep}
                                    custom
                                >
                                    <option value="true">ON</option>
                                    <option value="false">OFF</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom isLarge variant="primary" type="submit">
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => {
                            props.closeCallback(DialogResultEnum.Cancelled)
                        }}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const SleepSettingsDialog = (props: DialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <SleepSettingsDialogContent {...props} />
        </ModalWrapper>
    )
}
export default SleepSettingsDialog
