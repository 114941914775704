import React from 'react'
import { getPreviousWorkEvent, getProposedStartTimeForNewDutyEvent } from 'services/utilities/timeUtils'
import ScheduleEvent from 'types/ScheduleEvent'

export const DragActionDuty = 'AddDuty'
export const DragActionWork = 'AddWork'
export const DragActionMarker = 'AddMarker'
export const DragActionSleep = 'AddSleep'

const DropTargetTopLineClass = 'dropTargetTopBorderBlue'
const DropTargetBottomLineClass = 'dropTargetBottomBorderBlue'
const DropTargetDutyRow = 'dropTargetDutyRow'

export const DropStateInDutyLinesShown = 'InDutyLinesVisible'
export const DropStateAfterDutyLinesShown = 'AfterDutyLinesVisible'
export const DropStateBeforeEvents = 'BeforeEventsLinesVisible'

const closestNodeByName = (el: HTMLElement, nodeName: string) => {
    if (Element.prototype.closest !== undefined) {
        return el.closest(nodeName)
    }

    /* IE11 polyfill for element.closest (not exact implementation) */
    let localEl: Node | null = el
    do {
        if (localEl.nodeName.toLowerCase() === nodeName.toLowerCase()) {
            return localEl
        }
        localEl = localEl.parentElement || localEl.parentNode
    } while (localEl !== null && localEl.nodeType === 1)
    return null
}

const hideDropLines = (dragLinesEl: React.RefObject<HTMLInputElement>) => {
    const dutyStartEndRowsTop = document.querySelectorAll(`.${DropTargetTopLineClass}`)
    const dutyStartEndRows = document.querySelectorAll(`.${DropTargetBottomLineClass}`)
    const highlightedDutyRows = document.querySelectorAll(`.${DropTargetDutyRow}`)

    // hide all drop lines
    dutyStartEndRows.forEach((tr) => {
        tr.classList.remove(DropTargetBottomLineClass)
    })
    dutyStartEndRowsTop.forEach((tr) => {
        tr.classList.remove(DropTargetTopLineClass)
    })
    highlightedDutyRows.forEach((tr) => {
        tr.classList.remove(DropTargetDutyRow)
    })

    // update known state of drop lines
    dragLinesEl.current!.value = ''
}

const isDraggingAboveFirstEvent = (
    evt: React.DragEvent,
    unused_collapsedDutyIds: string[],
    unused_scheduleEvent: ScheduleEvent,
    allScheduleEvents: ScheduleEvent[],
) => {
    // find the mouse position relative to the top of this table row.
    const targetElement = evt.target as HTMLElement
    const rect = targetElement.getBoundingClientRect()
    const y = evt.clientY - rect.top
    const mousePositionRelativeToTopOfRow = y / rect.height

    let isFirstRow = false
    if (targetElement.parentNode !== undefined) {
        const row = closestNodeByName(evt.target as HTMLElement, 'tr') as HTMLTableRowElement
        isFirstRow = row.id === allScheduleEvents[0].id.toString() && mousePositionRelativeToTopOfRow < 0.5
    }
    return isFirstRow
}

const isDraggingAfterDuty = (
    evt: React.DragEvent,
    collapsedDutyIds: string[],
    scheduleEvent: ScheduleEvent,
    allScheduleEvents: ScheduleEvent[],
) => {
    if (scheduleEvent.isPartOfDuty(allScheduleEvents) === false) {
        return true
    }
    // find the mouse position relative to the top of this table row.
    const targetElement = evt.target as HTMLElement
    const rect = targetElement.getBoundingClientRect()
    const y = evt.clientY - rect.top
    const mousePositionRelativeToTopOfRow = y / rect.height

    const isDutyCollapsed = collapsedDutyIds.includes(scheduleEvent.dutyUuid ?? '')

    const isLastHalfOfLastDutyEvent =
        (scheduleEvent.isLastEventInDuty(allScheduleEvents) === true ||
            (scheduleEvent.isFirstEventInDuty(allScheduleEvents) === true && isDutyCollapsed)) &&
        mousePositionRelativeToTopOfRow > 0.5

    return isLastHalfOfLastDutyEvent
}

const getDutyStartRowCssClassName = (scheduleEvent: ScheduleEvent) => `dutyStartRow_${scheduleEvent.dutyUuid}`
const getDutyEndRowCssClassName = (scheduleEvent: ScheduleEvent) => `dutyEndRow_${scheduleEvent.dutyUuid}`

const showStartOfEventDropLine = (unusedevt: React.DragEvent, dragLinesEl: React.RefObject<HTMLInputElement>) => {
    // Get all table rows.
    // The first row is the table header row
    // so we know that we want to get this first row
    const allTrs = document.querySelectorAll('tr')
    // Add the DropTargetTopLineClass to list of classes
    // to show the blue drop line in the table above the
    // first row
    allTrs[1].classList.add(DropTargetTopLineClass)
    // Note another interaction with DropTargetTopLineClass
    // can be found in hideDropLines method
    // This line below will show the modal to complete the drop
    dragLinesEl.current!.value = DropStateBeforeEvents
}

const showEndOfDutyDropLine = (evt: React.DragEvent, dragLinesEl: React.RefObject<HTMLInputElement>) => {
    if (dragLinesEl.current!.value === DropStateAfterDutyLinesShown) {
        return
    }
    const endOfDutyRow = closestNodeByName(evt.target as HTMLElement, 'tr') as HTMLTableRowElement
    endOfDutyRow.classList.add(DropTargetBottomLineClass)

    dragLinesEl.current!.value = DropStateAfterDutyLinesShown
}

/**
 * Highlight the entire duty
 * @param scheduleEvent
 */
const showInDutyDropLines = (scheduleEvent: ScheduleEvent, dragLinesEl: React.RefObject<HTMLInputElement>) => {
    if (dragLinesEl.current!.value === DropStateInDutyLinesShown) {
        return
    }

    const startDutyRow = document.querySelector(
        `.${getDutyStartRowCssClassName(scheduleEvent)}`,
    )! as HTMLTableRowElement
    const endDutyRow = document.querySelector(`.${getDutyEndRowCssClassName(scheduleEvent)}`)! as HTMLTableRowElement

    const allRows = Array.from(startDutyRow?.parentElement!.querySelectorAll('tr')!)
    const startDutyRowIndex = allRows.indexOf(startDutyRow)
    let endDutyRowIndex = allRows.indexOf(endDutyRow)
    if (endDutyRowIndex < 0) endDutyRowIndex = startDutyRowIndex

    for (let i = startDutyRowIndex; i <= endDutyRowIndex; i++) {
        allRows[i].classList.add(DropTargetDutyRow)
    }

    startDutyRow?.classList.add(DropTargetTopLineClass)
    endDutyRow?.classList.add(DropTargetBottomLineClass)
    if (!endDutyRow) {
        startDutyRow?.classList.add(DropTargetBottomLineClass)
    }

    dragLinesEl.current!.value = DropStateInDutyLinesShown
}

const onDragLeaveScheduleEventHandler = (
    dragDropTimerEl: React.RefObject<HTMLInputElement>,
    dragLinesEl: React.RefObject<HTMLInputElement>,
) => {
    // delay before hiding drag-lines.  This avoids flickering while dragging an event over a duty.
    // If drag-events continue, this timer is cancelled.  Otherwise (the user is no longer dragging)
    // so the timer executes and hides the drag-lines.  The amount of delay needed should be small
    // as it is the time between the onDragLeave event and the onDragOver event.
    const dragLeaveDelayMs = 100

    const dragDropTimeoutId = parseInt(dragDropTimerEl.current!.value)
    clearTimeout(dragDropTimeoutId)
    const newDragLeaveTimerId = window.setTimeout(() => hideDropLines(dragLinesEl), dragLeaveDelayMs)
    dragDropTimerEl.current!.value = newDragLeaveTimerId.toString()
}

const onDragOverScheduleEventHandler = (
    evt: React.DragEvent,
    dragActionEl: React.RefObject<HTMLInputElement>,
    dragLinesEl: React.RefObject<HTMLInputElement>,
    collapsedDutyIds: string[],
    dragDropTimeoutId: number,
    scheduleEvent: ScheduleEvent,
    allScheduleEvents: ScheduleEvent[],
) => {
    evt.preventDefault()
    const dragAction = dragActionEl.current!.value

    // clear the dragLeaveTimer which is only needed for if the user no longer continues.
    // to drag.  If they continue dragging (as in this code), then we can control
    // the hiding/showing of the drag lines all right here.  This is what prevents
    // flickering while dragging over a duty.
    window.clearTimeout(dragDropTimeoutId)

    hideDropLines(dragLinesEl)

    // then decide if we are showing the drop "after-duty" or "in-duty"
    if (isDraggingAboveFirstEvent(evt, collapsedDutyIds, scheduleEvent, allScheduleEvents)) {
        showStartOfEventDropLine(evt, dragLinesEl)
    } else if (isDraggingAfterDuty(evt, collapsedDutyIds, scheduleEvent, allScheduleEvents)) {
        showEndOfDutyDropLine(evt, dragLinesEl)
    } else if (dragAction !== DragActionDuty) {
        showInDutyDropLines(scheduleEvent, dragLinesEl)
    }
}

export type AddScheduleEventArgs = {
    dutyUuid: string | undefined
    dutyLabel: string | null
    from: string
    to: string
    time: Date
    tzFromMinutes: number
    tzToMinutes: number
    tzBaseMinutes: number
}

const onDropOverScheduleEvent = (
    dragAction: string,
    dragLinesEl: React.RefObject<HTMLInputElement>,
    scheduleEvent: ScheduleEvent,
    allEvents: ScheduleEvent[],
    addScheduleEvent: (args: AddScheduleEventArgs) => void,
) => {
    const dragLinesState = dragLinesEl.current!.value
    if (
        dragLinesState !== DropStateAfterDutyLinesShown &&
        dragLinesState !== DropStateInDutyLinesShown &&
        dragLinesState !== DropStateBeforeEvents
    ) {
        // no drag-lines, so this isn't a valid drop target
        return
    }

    hideDropLines(dragLinesEl)

    let referenceEvent: ScheduleEvent = scheduleEvent

    // if dragging single event into a duty
    if (dragAction !== DragActionDuty && dragLinesState === DropStateInDutyLinesShown) {
        referenceEvent = scheduleEvent.getLastWorkEventInDuty(allEvents)
    }

    let dutyUuid: string | undefined
    let dutyLabel: string | null = null

    const dragInDuty = dragLinesState === DropStateInDutyLinesShown
    if (dragInDuty) {
        dutyUuid = referenceEvent.dutyUuid
        dutyLabel = referenceEvent.getDutyEvents(allEvents).find((x) => x.isDutyRollup())?.label || null
    }

    const dragBeforeEvents = dragLinesState === 'BeforeEventsLinesVisible'

    const suggestedStartTime =
        dragAction === DragActionDuty || (DragActionWork && !dragInDuty)
            ? getProposedStartTimeForNewDutyEvent(dragBeforeEvents, referenceEvent, allEvents)
            : new Date(referenceEvent.getEndMs())

    const previousWorkEvent = getPreviousWorkEvent(referenceEvent, allEvents) || referenceEvent

    addScheduleEvent({
        dutyUuid,
        dutyLabel,
        from: previousWorkEvent.to,
        to: previousWorkEvent.to,
        time: suggestedStartTime,
        tzFromMinutes: previousWorkEvent.tzToMinutes,
        tzToMinutes: previousWorkEvent.tzToMinutes,
        tzBaseMinutes: previousWorkEvent.tzBaseMinutes,
    })
}

export {
    onDropOverScheduleEvent,
    getDutyStartRowCssClassName,
    getDutyEndRowCssClassName,
    onDragLeaveScheduleEventHandler,
    onDragOverScheduleEventHandler,
}
