import { DateTimePicker, DateTimePickerChangeEvent, DateTimePickerProps } from '@progress/kendo-react-dateinputs'
import { useEffect, useState } from 'react'

const DateTimePickerCustom = (props: DateTimePickerProps) => {
    const [value, setValue] = useState(props.value)
    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return (
        <DateTimePicker
            {...props}
            value={value}
            onChange={(e: DateTimePickerChangeEvent) => {
                setValue(e.value)
                if (
                    e.nativeEvent.inputType === 'deleteContentBackward' ||
                    e.nativeEvent.input === 'deleteContentForward'
                ) {
                    return
                }
                if (e.nativeEvent.inputType === 'insertText') {
                    if (e.value === null || e.value.getFullYear() < 1000) {
                        return
                    }
                }
                if (e.value !== null) {
                    props.onChange?.(e)
                }
            }}
        />
    )
}

export default DateTimePickerCustom
