import { CSSProperties, ReactElement } from 'react'
import { ButtonGroup, Dropdown } from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import { ButtonCustomProps, WhiteButtonBorder } from './ButtonCustom'
import IconButton from './IconButton'
import { ToolbarButtonHeightPx } from './ToolbarButton'

export interface SplitButtonProps extends ButtonCustomProps {
    icon: string
    children: ReactElement<DropdownItem>[] | ReactElement<DropdownItem>
    tooltipSplit?: string
}
const SplitIconButton = (props: SplitButtonProps) => {
    /* eslint-disable */
    const { icon, children, ...requiredProps } = props
    /* eslint-enable */

    const variant = props.variant || 'white'
    const toggleStyle: CSSProperties = {
        height: `${ToolbarButtonHeightPx}px`,
        width: '20px',
        paddingLeft: '3px',
        paddingBottom: 0,
        paddingTop: 0,
        border: WhiteButtonBorder,
    }

    return (
        <Dropdown as={ButtonGroup} size={props.size}>
            <IconButton {...requiredProps} toolbarLeftMargin icon={icon} />
            <Dropdown.Toggle
                split
                style={toggleStyle}
                variant={variant}
                id="dropdown-split-basic"
                disabled={props.disabled}
                title={props.tooltipSplit}
            />
            <Dropdown.Menu align="right">{props.children}</Dropdown.Menu>
        </Dropdown>
    )
}

export default SplitIconButton
