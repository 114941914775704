import { isDesktopMode } from 'services/axios/axios-sfc'
import HazardClassCase, { parseHazardClassCases } from './HazardClassCase'
import { Recalculatable } from './Recalculatable'

export const getNewHazardClass = (): HazardClass => {
    const newHazardClass: HazardClass = {
        id: 0,
        name: '',
        description: '',
        createdById: 0,
        accountId: 0,

        includeAsDefault: false,
        isLibraryItem: false,

        hidePatternDuties: false,
        dashboardOrder: 0,
        patternsLimit: 5,
        patternsSort: 'MatchingDutiesCount',

        includeAllMyScenarios: false,
        includeAllScenariosSharedwMe: false,
        overrideDashboardScenarioSelection: false,
        selectedScenarioIds: '',
        selectedScenarioNames: '',
        overlay: false,

        filterTPC80: 3,
        filterTPR80: 90,

        patternStartEnable: true,
        patternDurationEnable: true,
        patternPriorRestEnable: true,
        patternRouteEnable: false,

        refineTo: 'LT80',

        maxDuties: 3,
        maxDutyLookback: 4320,

        workContext: 'Crewing',

        metricEffectivenessWeight: 1,
        metricReservoirWeight: 0,
        metricFHAWeight: 0,
        metricWorkloadWeight: 0,

        startTimeStep: '02:00',
        startTimeStagger: '02:00',
        startTimeCombine: false,
        durationStep: 120,
        durationStagger: 120,
        durationCombine: false,
        priorRestStep: 480,
        priorRestStagger: 120,
        priorRestCombine: false,

        dutiesCountAll: 0,
        dutiesCountLT70: 0,
        dutiesCountBTW7075: 0,
        dutiesCountBTW7580: 0,
        dutiesCountGT80: 0,

        casesJson: '',
        longestDutyPatternLength: 0,
        requiresRecalculation: false,
    }
    return newHazardClass
}

export const getNewHazardClassDialogConfig = (): HazardClassDialogConfig => {
    const newHazardClass: HazardClassDialogConfig = {
        id: 0,
        name: '',
        description: '',
        createdById: 0,
        patternsLimit: 5,
        patternsSort: 'MatchingDutiesCount',
        isLibraryItem: false,
        durationStep: 120,
        filterTPC80: 3,
        filterTPR80: 90,
        patternStartEnable: true,
        patternDurationEnable: true,
        patternPriorRestEnable: true,
        patternRouteEnable: false,
        refineTo: 'LT80',
        includeAllMyScenarios: false,
        includeAllScenariosSharedwMe: false,
        maxDuties: 3,
        maxDutyLookback: 4320,
        metricEffectivenessWeight: 1,
        workContext: 'Crewing',
        metricReservoirWeight: 0,
        metricFHAWeight: 0,
        metricWorkloadWeight: 0,
        overrideDashboardScenarioSelection: false,
        selectedScenarioIds: '',
        priorRestStep: 480,
        startTimeStep: '02:00',
        startTimeCombine: false,
    }
    return newHazardClass
}

export const parseHazardClass = (data: any): HazardClass => {
    const hazardClass = { ...(data as HazardClass) }

    // For convenience, UI uses uniqe id in each case for cross reference
    // between the cases table and the source data.
    if (hazardClass.casesJson) {
        hazardClass.casesParsed = parseHazardClassCases(hazardClass.casesJson)
        hazardClass.dutyCount = 0
        hazardClass.hazardDutyCount =
            hazardClass.dutiesCountLT70 + hazardClass.dutiesCountBTW7075 + hazardClass.dutiesCountBTW7580
        hazardClass.totalPatternsCount = 0

        hazardClass.casesParsed.forEach((thisCase: HazardClassCase) => {
            const caseDuties = thisCase.duties
            if (isDesktopMode()) {
                // SFC doesn't use schedule (db) id, it has another field
                // that it sets uniquely, so copy that into the scheduleId field.
                caseDuties.forEach((duty) => {
                    duty.scheduleId = duty.scheduleUniqueId
                })
            }
            hazardClass.dutyCount! += thisCase.duties.length
            hazardClass.totalPatternsCount! += 1
        })

        // calculate the longest duty pattern so we can generate the right number of columns in the table
        hazardClass.longestDutyPatternLength = hazardClass.casesParsed.reduce((accumulator, value) => {
            return Math.max(accumulator, Object.keys(value.dutyDescriptions).length)
        }, 0)
    }

    return hazardClass
}

/**
 * Do the changes in the updated hazard class require the patterns to be reordered?
 * @param original
 * @param updated
 * @returns
 */
export const changeRequiresReorderPatterns = (
    original: HazardClassDialogConfig,
    updated: HazardClassDialogConfig,
): boolean => {
    return original.patternsSort !== updated.patternsSort
}

/**
 * Do the changes in the updated hazard class require insights to be re-run?
 * @param original
 * @param updated
 * @returns
 */
export const changeRequiresRunInsights = (
    original: HazardClassDialogConfig,
    updated: HazardClassDialogConfig,
): boolean => {
    return (
        original.durationStep !== updated.durationStep ||
        original.patternStartEnable !== updated.patternStartEnable ||
        original.patternDurationEnable !== updated.patternDurationEnable ||
        original.patternPriorRestEnable !== updated.patternPriorRestEnable ||
        original.patternRouteEnable !== updated.patternRouteEnable ||
        original.filterTPC80 !== updated.filterTPC80 ||
        original.filterTPR80 !== updated.filterTPR80 ||
        original.refineTo !== updated.refineTo ||
        original.includeAllMyScenarios !== updated.includeAllMyScenarios ||
        original.includeAllScenariosSharedwMe !== updated.includeAllScenariosSharedwMe ||
        original.workContext !== updated.workContext ||
        original.metricEffectivenessWeight !== updated.metricEffectivenessWeight ||
        original.metricFHAWeight !== updated.metricFHAWeight ||
        original.metricReservoirWeight !== updated.metricReservoirWeight ||
        original.metricWorkloadWeight !== updated.metricWorkloadWeight ||
        original.overrideDashboardScenarioSelection !== updated.overrideDashboardScenarioSelection ||
        original.priorRestStep !== updated.priorRestStep ||
        original.selectedScenarioIds !== updated.selectedScenarioIds ||
        original.startTimeCombine !== updated.startTimeCombine ||
        original.startTimeStep !== updated.startTimeStep
    )
}

export type HazardClassDialogConfig = Pick<
    HazardClass,
    | 'id'
    | 'name'
    | 'description'
    | 'patternsLimit'
    | 'patternsSort'
    | 'isLibraryItem'
    | 'metricEffectivenessWeight'
    | 'workContext'
    | 'metricReservoirWeight'
    | 'metricFHAWeight'
    | 'metricWorkloadWeight'
    | 'overrideDashboardScenarioSelection'
    | 'patternStartEnable'
    | 'patternDurationEnable'
    | 'patternPriorRestEnable'
    | 'patternRouteEnable'
    | 'includeAllMyScenarios'
    | 'includeAllScenariosSharedwMe'
    | 'selectedScenarioIds'
    | 'priorRestStep'
    | 'durationStep'
    | 'startTimeStep'
    | 'maxDutyLookback'
    | 'maxDuties'
    | 'startTimeCombine'
    | 'filterTPC80'
    | 'filterTPR80'
    | 'refineTo'
    | 'createdById'
>

export type HazardClassPatternsSort = 'MatchingDutiesCount' | 'MatchingDutiesPercent'

export interface HazardClass extends Recalculatable {
    id: number
    name: string
    description: string
    patternsLimit: number
    patternsSort: HazardClassPatternsSort
    hidePatternDuties: boolean
    dashboardOrder: number
    accountId: number
    createdByName?: any
    createdBy?: any
    createdById: number
    includeAsDefault: boolean
    isLibraryItem: boolean
    patternStartEnable: boolean
    patternDurationEnable: boolean
    patternPriorRestEnable: boolean
    patternRouteEnable: boolean
    startTimeStep: string
    startTimeStagger: string
    startTimeCombine: boolean
    durationStep: number
    durationStagger: number
    durationCombine: boolean
    priorRestStep: number
    priorRestStagger: number
    priorRestCombine: boolean
    metricEffectivenessWeight: number
    workContext: 'Crewing' | 'Critical' | 'Overall'
    metricReservoirWeight: number
    metricFHAWeight: number
    metricWorkloadWeight: number
    maxDuties: number
    maxDutyLookback: number
    refineTo: 'LT70' | 'LT75' | 'LT80' | 'GT80'
    filterTPC80: number
    filterTPR80: number
    overrideDashboardScenarioSelection: boolean
    overlay: boolean
    selectedScenarioNames: string
    // casesJsonRecord?: any
    casesParsed?: HazardClassCase[]
    includeAllMyScenarios: boolean
    includeAllScenariosSharedwMe: boolean
    selectedScenarioIds: string
    dutiesCountAll: number
    dutiesCountLT70: number
    dutiesCountBTW7075: number
    dutiesCountBTW7580: number
    dutiesCountGT80: number

    /**
     * Set on client-side
     */
    casesJson: string
    scheduleCount?: number
    dutyCount?: number
    hazardDutyCount?: number
    totalPatternsCount?: number
    longestDutyPatternLength: number
}
