import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { globalActions } from 'store/globalStore'

/**
 * Set the global state of the app being in a print preview mode or not
 */
const usePrintPreviewGlobalState = (isPrintPreview?: boolean) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(globalActions.setPrintPreview(isPrintPreview === true))
    }, [dispatch, isPrintPreview])
}

export default usePrintPreviewGlobalState
