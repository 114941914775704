import { useSelector } from 'react-redux'
import { isDesktopMode } from 'services/axios/axios-sfc'
import { UserState } from 'store/globalStore'
import { RootState } from 'store/store'

const useSessionStatus = (): UserState => {
    const isDesktop = isDesktopMode(true)
    const userState = useSelector<RootState, UserState>((x) => x.app.userState)
    if (isDesktop) {
        return 'LoggedIn'
    }
    return userState
}

export default useSessionStatus
