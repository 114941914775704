import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import getOverriddenColumns from 'services/utilities/kendoGridUtils'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import getSchedulesGridColumns from 'views/Schedules/SchedulesPage/SchedulesPageColumns'
import useLocalStorage from './useLocalStorage'

/**
 * Get kendo grid columns for the schedules grid for use with the "export" functionality
 * @returns
 */
const useSchedulesGridColumns = (tagNames: string[]): any[] => {
    //
    const defaultColumns = getSchedulesGridColumns(
        () => {},
        () => {},
        tagNames,
        false,
        [],
    )

    const [columnsFromLocalStorage, unusedSetColumnsFromLocalStorage] = useLocalStorage<KendoGridColumn[]>(
        'schedulesGrid',
        [],
    )

    const mergedColumns = getOverriddenColumns(columnsFromLocalStorage, defaultColumns)

    if (!mergedColumns.find((col) => col.field === 'name')) {
        // the user hid the name on the ui but it should be exported, so add it back in
        mergedColumns.unshift({ field: 'name', title: 'Name' })
    }

    return mergedColumns.map((col, index) => {
        let format = null
        let displayText = col.title
        if (col.filter === 'date') {
            // server will always export in UTC, so we need to put that into the
            // header of the CSV to make it clear that it isn't the same as what is
            // show in the UI, which is local
            displayText += ' (UTC)'
            format = dateTimeFormatting.getKendoDateTimeFormat()
        }

        return { ...col, index, displayText, format }
    })
}

export default useSchedulesGridColumns
