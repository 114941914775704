import { IndexedDutyDescriptions } from 'types/HazardClassCase'

const convertTimeStringToMinutes = (timeString: string): number => {
    const timeSplit = timeString.split(':')
    const hourMinutes = parseInt(timeSplit[0]) * 60
    const minutes = parseInt(timeSplit[1])
    return hourMinutes + minutes
}

/**
 * Add additional data to the duty needed for generating rectangle positions.
 */
const getDutyPatternForVisualization = (dutyDescriptions: IndexedDutyDescriptions) => {
    const dutyKeysOrdered = Object.keys(dutyDescriptions).reverse()

    const orderedDutyDescription = dutyKeysOrdered.map((dutyKey) => {
        return dutyDescriptions[dutyKey]
    })

    orderedDutyDescription.forEach((dutyDescription, i) => {
        // initially we calculate the duty time (in minutes) from the string "hh:mm" without an extra day
        // added for each duty.  This would put them all stacked in the same day.  Code below pushes
        // them out the appropriate number of days based on priorRestMin
        dutyDescription.startMinuteMin = convertTimeStringToMinutes(dutyDescription.startTimeMin)
        dutyDescription.startMinuteMax = convertTimeStringToMinutes(dutyDescription.startTimeMax)

        if (dutyDescription.priorRestMin && i > 0) {
            // if diff between d1 endmax and d2 startMin < priorRestMin;
            // this means we haven't visualized enough rest, so bump to another day
            const priorDuty = orderedDutyDescription[i - 1]
            const priorDutyEndMax = priorDuty.startMinuteMax + priorDuty.durationMax
            let oneDayMinRestGap = dutyDescription.startMinuteMin - priorDutyEndMax

            while (oneDayMinRestGap < dutyDescription.priorRestMin) {
                // priorRestMin is not satisfied, so push out additional
                // days until satisfied
                dutyDescription.startMinuteMin += 1440
                dutyDescription.startMinuteMax += 1440
                oneDayMinRestGap = dutyDescription.startMinuteMin - priorDutyEndMax
            }

            // PriorRestMin and PriorRestMax are at odds with each other sometimes because it can be a large range.
            // If we exceed PriorRestMax, we want to actually bump *back* by 1 day.  This means PriorRestMax
            // takes precedence over PriorRestMin.
            const priorDutyEndMin = priorDuty.startMinuteMin + priorDuty.durationMin
            const maxRestForVisualization = dutyDescription.startMinuteMax - priorDutyEndMin
            const isEnoughSpaceToMoveBack = priorDutyEndMax <= dutyDescription.startMinuteMin - 1440
            if (maxRestForVisualization > dutyDescription.priorRestMax && isEnoughSpaceToMoveBack) {
                // bump back by 1 day
                dutyDescription.startMinuteMin -= 1440
                dutyDescription.startMinuteMax -= 1440
            }
        }

        if (dutyDescription.startMinuteMax < dutyDescription.startMinuteMin) {
            // this means that the max is sometime after midnight,
            // while the min is before midnight; so bump the max by +1 day
            dutyDescription.startMinuteMax += 1440
        }

        const dutyIndex = orderedDutyDescription.length - i - 1
        dutyDescription.index = dutyIndex
        dutyDescription.profileName =
            dutyDescription.index === 0 ? 'Hazard Duty' : 'Prior Duty ' + dutyDescription.index
    })

    return orderedDutyDescription
}

export default getDutyPatternForVisualization
