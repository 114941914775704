import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartTooltip,
} from '@progress/kendo-react-charts'
import HazardClassCase from 'types/HazardClassCase'
import { PieChartColorOrange, PieChartColorRed, PieChartColorYellow } from '../PatternPanel/PatternsPieChart'

const DutiesPercentOfTotalBarChart = (props: {
    height: number
    isPrintPreview: boolean
    patterns: HazardClassCase[]
    patternSeriesClicked?: (hcCase: HazardClassCase) => void
    className?: string
}) => {
    const lt70s = props.patterns.filter((x) => x.positivePredictedValue.lt70)
    const btw7075 = props.patterns.filter((x) => x.positivePredictedValue.lt75)
    const btw7580 = props.patterns.filter((x) => x.positivePredictedValue.lt80)
    // const gt80 = props.patterns.map((x) => x.positivePredictedValue.gt80)
    const categories = props.patterns.map((pattern) => pattern.index!)

    const renderTooltip = (context: any) => {
        const { series, value } = context.point || context

        let fieldLabel = '< 70'
        if (series.field === 'positivePredictedValue.lt75') {
            fieldLabel = '< 75'
        }
        if (series.field === 'positivePredictedValue.lt80') {
            fieldLabel = '< 80'
        }

        return (
            <div>
                Accounts for {Math.round(value)}% of All Duties w Score {fieldLabel}
            </div>
        )
    }

    return (
        <Chart
            style={{ height: props.height, width: 1000 }}
            transitions={false}
            onSeriesClick={(e) => !props.isPrintPreview && props.patternSeriesClicked?.(e.dataItem)}
            className={props.className}
        >
            <ChartTitle text="Matching Duties % of All Duties" font="15px sans-serif" padding={0} margin={0} />
            <ChartTooltip render={renderTooltip} />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={categories} majorGridLines={{ visible: false }}>
                    <ChartCategoryAxisTitle text="Pattern Number" font="12px sans-serif" />
                </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartSeries>
                <ChartSeriesItem
                    type="column"
                    gap={0.5}
                    spacing={0}
                    data={lt70s}
                    field="positivePredictedValue.lt70"
                    categoryField="index"
                    color={PieChartColorRed}
                />
                <ChartSeriesItem
                    type="column"
                    gap={0.5}
                    spacing={0}
                    data={btw7075}
                    field="positivePredictedValue.lt75"
                    categoryField="index"
                    color={PieChartColorOrange}
                />
                <ChartSeriesItem
                    type="column"
                    gap={0.5}
                    spacing={0}
                    data={btw7580}
                    field="positivePredictedValue.lt80"
                    categoryField="index"
                    color={PieChartColorYellow}
                />
            </ChartSeries>
            {/* <ChartValueAxis>
                <ChartValueAxisItem majorUnit={1} />
            </ChartValueAxis> */}
        </Chart>
    )
}

export default DutiesPercentOfTotalBarChart
