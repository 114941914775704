// organize-imports-ignore
import 'antd/dist/antd.css'
import { FormEvent, memo, useState } from 'react'
import { Utils as QbUtils } from 'react-awesome-query-builder'
import 'react-awesome-query-builder/lib/css/styles.css'
import 'views/Common/FilterBuilder/FilterBuilderStylesOverride.css'
import { Form, Modal } from 'react-bootstrap'
import {
    convertQueryBuilderToRAConfiguration,
    convertRAToQueryBuilder,
} from 'services/queryBuilder/queryBuilderService'
import { ReportItemDefinition } from 'types/ReportingMetadata'
import { QueryBuilder } from 'types/Reports'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import FilterBuilder from 'views/Common/FilterBuilder/FilterBuilder'
// import 'react-awesome-query-builder/lib/css/compact_styles.css'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'

export interface ReportFilterDialogProps {
    type: 'Events' | 'Schedules'
    dataItems: ReportItemDefinition[]
    rules?: QueryBuilder
    closeCallback: (status: DialogResultEnum, updatedQuery?: QueryBuilder) => void
}

/**
 * Main component function
 */
const FilterDialogContent = (props: ReportFilterDialogProps) => {
    const { convertedQuery, config } = convertQueryBuilderToRAConfiguration(props.dataItems, props.rules)

    const [query, setQuery] = useState({
        tree: QbUtils.checkTree(QbUtils.loadTree(convertedQuery), config),
        config,
    })

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const queryBuilderFormat = convertRAToQueryBuilder(query.tree, query.config, props.dataItems)
        props.closeCallback(DialogResultEnum.Completed, queryBuilderFormat)
    }

    return (
        <Form onSubmit={submitHandler}>
            <Modal.Header closeButton>
                <Modal.Title>{props.type} Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Configure rules to filter the data in this report.</p>
                <FilterBuilder query={query} setQuery={setQuery} />
            </Modal.Body>

            <Modal.Footer>
                <ButtonCustom isLarge type="submit" variant="primary">
                    OK
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    variant="secondary"
                    onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                >
                    Cancel
                </ButtonCustom>
            </Modal.Footer>
        </Form>
    )
}

const ReportConfigFilterDialog = (props: ReportFilterDialogProps) => {
    return (
        <ModalWrapper
            className="DialogMediumWidth"
            closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}
        >
            <FilterDialogContent {...props} />
        </ModalWrapper>
    )
}

export default memo(ReportConfigFilterDialog)
