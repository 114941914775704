import { TimePicker, TimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { GridCellProps } from '@progress/kendo-react-grid'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import classes from './EditInPlaceTimePicker.module.css'

const EditInPlaceTimepicker = (props: GridCellProps) => {
    const { dataItem } = props
    const field = props.field || ''
    const dataValue = dataItem[field] || ''
    const theDate = dateTimeFormatting.formatTimeStringAsDate(dataValue)

    const customRendering = (
        <td>
            {dataItem.inEdit === field ? (
                <TimePicker
                    className={classes.editorSmall}
                    format="HH:mm"
                    width="100%"
                    value={theDate}
                    onChange={(e: TimePickerChangeEvent) => {
                        if (props.onChange) {
                            const newDate = e.target.value
                            if (newDate) {
                                props.onChange({
                                    dataIndex: 0,
                                    dataItem: props.dataItem,
                                    field: props.field,
                                    syntheticEvent: e.syntheticEvent,
                                    value: dateTimeFormatting.formatDateAsTimeString(newDate),
                                })
                            }
                        }
                    }}
                />
            ) : (
                dataValue.toString()
            )}
        </td>
    )

    // since there is cell and row-level render overriding, we need to make use of that in this manner.
    return props.render ? props.render(customRendering, props) : customRendering
}

export default EditInPlaceTimepicker
