import { configureStore } from '@reduxjs/toolkit'
import appReducer from './globalStore'
import insightsReducer from './insightsStore'
import { insightsRecalculationReducer, reportsRecalculationReducer } from './recalculationStore'
import reportsReducer from './reportingStore'

const store = configureStore({
    reducer: {
        reports: reportsReducer,
        insights: insightsReducer,
        reportsRecalculation: reportsRecalculationReducer,
        insightsRecalculation: insightsRecalculationReducer,
        app: appReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export default store
