const Prefix = 'DATE_VALUE:'

/**
 * Change object date properties into string format that can be recognized and deserialized later
 * @param obj
 * @param stack
 */
export const serializeObjectDates = (obj: any, stack: string = '') => {
    if (!obj) {
        return
    }
    Object.keys(obj).forEach((property) => {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
            if (obj[property] instanceof Date) {
                // serialize to a recognizable string
                obj[property] = `${Prefix}${obj[property].toISOString()}`
            } else if (typeof obj[property] === 'object') {
                serializeObjectDates(obj[property], stack + '.' + property)
            }
        }
    })
}

/**
 * Find any serialized dates strings and deserialize back to date objects
 * @param obj
 * @param stack
 */
export const deserializeObjectDates = (obj: any, stack: string = '') => {
    if (!obj) {
        return
    }
    Object.keys(obj).forEach((property) => {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
            if (typeof obj[property] === 'object') {
                deserializeObjectDates(obj[property], stack + '.' + property)
            } else if (typeof obj[property] === 'string' && obj[property].indexOf(Prefix) === 0) {
                // found the date string, turn it back into a date object
                const justDateString = obj[property].substring(Prefix.length)
                obj[property] = new Date(Date.parse(justDateString))
            }
        }
    })
}
