import ScheduleEvent from 'types/ScheduleEvent'

/**
 * Returns a date that has the given UTC hour, but is later or earlier than the given date by some minimum threshold.
 * @param currentUtcTime the given date
 * @param targetHour the given UTC hour
 * @param minimumHoursGap minimum threshold in hours
 * @param goBackward if true we find the next *earlier* date, otherwise the next later date
 */
export const getNextUtcTimeAtTargetHour = (
    referenceUtcTime: Date,
    targetHour: number,
    targetMinute: number,
    minimumHoursGap: number,
    goBackward: boolean,
) => {
    const targetDate = new Date(referenceUtcTime.getTime())
    targetDate.setUTCMinutes(targetMinute)
    targetDate.setUTCMilliseconds(0)

    // the user either wants to find the next later or earlier in time
    const goBackOrForwardBy1 = goBackward === true ? (a: number) => a - 1 : (a: number) => a + 1

    let hourSteps = 0
    while (targetDate.getUTCHours() !== targetHour || hourSteps < minimumHoursGap) {
        targetDate.setUTCHours(goBackOrForwardBy1(targetDate.getUTCHours()))
        hourSteps++
    }

    return targetDate
}

const getPreviousEvent = (scheduleEvent: ScheduleEvent, events: ScheduleEvent[]): ScheduleEvent | undefined =>
    events[events.findIndex((ev) => ev.id === scheduleEvent.id) - 1]

export const getPreviousWorkEvent = (
    referenceEvent: ScheduleEvent | undefined,
    allEvents: ScheduleEvent[],
): ScheduleEvent | undefined => {
    let previousWorkEvent: ScheduleEvent | undefined = referenceEvent
    // go back until we find a reference work event
    while (previousWorkEvent !== undefined && previousWorkEvent.isWorkEvent() === false) {
        previousWorkEvent = getPreviousEvent(previousWorkEvent, allEvents)
    }

    return previousWorkEvent
}

/**
 * Get a proposed start time for a new duty event model.  That is, the single default work event
 * of a new duty.
 * @param {boolean} isAfter is the new duty going after (vs before) the referenceEvent?
 * @param {eventModel} referenceEvent nearest work event
 */
export const getProposedStartTimeForNewDutyEvent = (
    beforeFirstEvent: boolean,
    referenceEvent: ScheduleEvent,
    visibleEvents: ScheduleEvent[],
) => {
    // find an appropriate day/time to insert the new event
    let proposedStartTime
    const previousWorkEvent = getPreviousWorkEvent(referenceEvent, visibleEvents)

    if (referenceEvent.type === 'Duty') {
        // after collapsed duty
        proposedStartTime = findNext8AmEarlierOrLater(referenceEvent, referenceEvent.tzToMinutes, 'end', false)
    } else if (!previousWorkEvent) {
        // before all other duties
        proposedStartTime = findNext8AmEarlierOrLater(
            referenceEvent,
            referenceEvent.tzFromMinutes,
            'start',
            beforeFirstEvent,
        )
    } else {
        // first, assume that we can just put it the next day at 8am local.
        proposedStartTime = findNext8AmEarlierOrLater(referenceEvent, previousWorkEvent.tzToMinutes, 'end', false)

        const workEvents = visibleEvents.filter((x) => x.isWorkEvent())
        const referenceWorkFromList = workEvents.find((x) => x.uuid === referenceEvent!.uuid)!
        const referenceWorkIndex = workEvents.indexOf(referenceWorkFromList)
        if (referenceWorkIndex >= 0 && referenceWorkIndex < workEvents.length - 1) {
            const nextEvent = workEvents[referenceWorkIndex + 1]
            if (nextEvent.getStartMs() < proposedStartTime.getTime() && referenceEvent.isWorkEvent()) {
                /**
                 * Need to bump back the new start because it is later than the next duty.
                 * Without using the parameter settings to figure out what auto-duty events will be created,
                 * we don't really know how much time to leave.  So we'll guess that an X (see below) hour gap after the previous
                 * event is enough.
                 */
                const hoursGap = 3
                proposedStartTime = new Date(referenceEvent.getEndMs() + hoursGap * 60 * 60000)
            }
        }
    }

    return proposedStartTime
}

export const findNext8AmEarlierOrLater = (
    event: ScheduleEvent,
    tzOffsetMinutes: number,
    attr: 'start' | 'end',
    goBackward: boolean,
) => {
    // 8am local to UTC
    let utcHour8 = 8 - tzOffsetMinutes / 60
    utcHour8 = (utcHour8 + 24) % 24
    let utcHour8Minutes = 0
    const utcHour8String = utcHour8.toString()
    if (utcHour8String.indexOf('.') >= 0) {
        // get the minutes for places with fractional UTC offset
        let minutesString = utcHour8String.split('.')[1]
        minutesString = '0.' + minutesString // now back to the form '0.xyz'
        utcHour8Minutes = parseFloat(minutesString) * 60
    }
    // just the hour
    const utcHour8Hrs = Math.floor(utcHour8)
    const referenceTime = attr === 'start' ? event.start : new Date(event.getEndMs())
    const minimumHoursGap = event.isAnySleep() ? 0 : 12
    return getNextUtcTimeAtTargetHour(referenceTime, utcHour8Hrs, utcHour8Minutes, minimumHoursGap, goBackward)
}

/**
 * Calculate a new utc time based on the amount of change in a display time value.
 * This is a common pattern with our time pickers in UTC and Local
 * @param newDisplayTime F
 * @param previousDisplayTime
 * @param previousUtcTime
 * @returns
 */
export const calculateNewUtcTimeFromDisplayTimeChange = (
    newDisplayTime: Date,
    previousDisplayTime: Date,
    previousUtcTime: Date,
) => {
    const timeDiffMs = newDisplayTime.getTime() - previousDisplayTime.getTime()
    return new Date(previousUtcTime.getTime() + timeDiffMs)
}
