import { ChangeEvent, useEffect, useState } from 'react'
import { Card, FormCheck } from 'react-bootstrap'
import globals from 'services/global/globals'
import Scenario from 'types/Scenario'

/**
 * 1. Fetch scenarios list from /Api/Scenarios
 * 2. use SelectedScenarioIds in the model
 * 3. Enable/Disable based on checkboxes
 * @returns 1
 */

const ScenarioSelectorDesktop = (props: {
    selectedScenarioIds: string
    includeAll: boolean
    combine: boolean
    setSelectedScenarioIds: (val: string) => void
    setIncludeAll: (val: boolean) => void
    setCombine: (val: boolean) => void
}) => {
    const api = globals.getApi()
    const [scenarios, setScenarios] = useState<Scenario[]>([])
    const selectedIds = props.selectedScenarioIds
        .split(',')
        .map((x) => parseInt(x))
        .filter((x) => x)

    useEffect(() => {
        const loadData = async () => setScenarios(await api.getScenariosList())
        loadData()
    }, [api])
    return (
        <>
            <div style={{ display: 'flex' }}>
                <FormCheck
                    style={{ marginBottom: 5 }}
                    id="chkAllScenarios"
                    label="Include All Scenarios"
                    type="checkbox"
                    checked={props.includeAll}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.setIncludeAll(e.target.checked)}
                />

                <FormCheck
                    style={{ marginBottom: 5, marginLeft: 20 }}
                    id="chkCombine"
                    label="Combine Scenarios into one data set"
                    type="checkbox"
                    checked={props.combine}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.setCombine(e.target.checked)}
                />
            </div>
            <ScenariosCheckList
                includeAll={props.includeAll}
                scenarios={scenarios}
                selectedScenarioIds={selectedIds}
                setSelectedScenarioIds={(ids: number[]) => props.setSelectedScenarioIds(ids.join(','))}
            />
        </>
    )
}

const ScenariosCheckList = (props: {
    includeAll: boolean
    scenarios: Scenario[]
    selectedScenarioIds: number[]
    setSelectedScenarioIds: (ids: number[]) => void
}) => {
    return (
        <Card>
            <Card.Body style={{ backgroundColor: '#fafafa' }}>
                {props.scenarios.map((scenario) => (
                    <FormCheck
                        key={scenario.name}
                        type="checkbox"
                        id={`chkScenario-${scenario.id}`}
                        label={scenario.name}
                        disabled={props.includeAll}
                        checked={
                            props.includeAll || props.selectedScenarioIds.find((id) => id === scenario.id) !== undefined
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.checked) {
                                // add to list
                                const newList = [...props.selectedScenarioIds, scenario.id]
                                props.setSelectedScenarioIds(newList)
                            } else {
                                // remove from list
                                const newList = props.selectedScenarioIds.filter((x) => x !== scenario.id)
                                props.setSelectedScenarioIds(newList)
                            }
                        }}
                    />
                ))}
            </Card.Body>
        </Card>
    )
}

export default ScenarioSelectorDesktop
