import { ReactNode } from 'react'
import classes from './DashboardControlCircle.module.css'

const DashboardControlCircle = (props: { tooltip?: string; children: ReactNode }) => {
    return (
        <div title={props.tooltip} className={classes.controlCircle}>
            {props.children}
        </div>
    )
}

export default DashboardControlCircle
