import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import globals from 'services/global/globals'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import AddScheduleDialog from './AddScheduleDialog'

const DesktopAddEventsScheduleDialog = () => {
    const [redirectToScheduleDetails, setRedirectToScheduleDetails] = useState(false)
    const api = globals.getApi()

    if (redirectToScheduleDetails) {
        return <Redirect to="/schedule/1" />
    }
    return (
        <AddScheduleDialog
            closeCallback={(status) => {
                if (status === DialogResultEnum.Cancelled) {
                    api.notifyDesktopCancel()
                    return
                }

                // adding a schedule, so transfer to schedule details page
                setRedirectToScheduleDetails(true)
            }}
        />
    )
}

export default DesktopAddEventsScheduleDialog
