import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragMoveEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import usePrintPreviewGlobalState from 'hooks/usePrintPreviewGlobalState'
import useRecalculateItemsStatus from 'hooks/useRecalculateItemsStatus'
import { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import checkExistingRecalculationRequests from 'services/api/checkExistingRecalculationRequests'
import { isDesktopMode } from 'services/axios/axios-sfc'
import globals from 'services/global/globals'
import { handleApiError, handleRecalculationError } from 'services/utilities/toastrUtils'
import { RecalculationState, reportRecalculationActions } from 'store/recalculationStore'
import { EditLibraryReport, reportsActions } from 'store/reportingStore'
import { RootState } from 'store/store'
import ReportingMetadata from 'types/ReportingMetadata'
import { Report, ReportDialogConfig } from 'types/Reports'
import Scenario from 'types/Scenario'
import { ScenarioSelection } from 'types/ScenarioSelection'
import EllipsisDropdown, { EllipsisDropdownItem, ItemWithIcon } from 'views/Common/Buttons/EllipsisDropdown'
import IconButton from 'views/Common/Buttons/IconButton'
import ReportRefreshButton from 'views/Common/Buttons/ReportRefreshButton'
import ScenarioSelectorDropdown from 'views/Common/Buttons/ScenarioSelectorDropdown'
import SeperatorVertical from 'views/Common/Buttons/SeparatorVertical'
import SplitIconButton from 'views/Common/Buttons/SplitIconButton'
import ToolbarButton from 'views/Common/Buttons/ToolbarButton'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import ConfirmResetDashboardDialog from 'views/Common/GenericDialogs/ConfirmResetDashboardDialog'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import LoadingSpinner from 'views/Common/GenericDialogs/LoadingSpinner'
import PageLayout from 'views/Common/Layout/PageLayout'
import ScenarioSelectorDialog from 'views/Scenarios/Dialogs/ScenarioSelectionDialog'
import DraggableReportDashboardThumbnail from '../Components/DraggableReportDashboardThumbnail'
import ReportsLibraryDialog from '../Dialogs/ReportsLibraryDialog'
import ReportConfiguration from './ReportConfiguration'

type DialogMode =
    | 'DashboardSelectionSelection'
    | 'ConfirmDeleteReport'
    | 'ReportsLibrary'
    | 'AddReportFromLibrary'
    | 'ResetDashboardToLibraryDefaults'
    | 'None'

const ReportsDashboard = (props: { isPrintPreview?: boolean }) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const api = globals.getApi()
    const processingApi = api.getProcessingApi()
    const reportingApi = api.getReportingApi()

    const [isDraggingReportId, setIsDraggingReportId] = useState(0)
    const [reportToDelete, setReportToDelete] = useState<Report | null>(null)
    const [dialogMode, setDialogMode] = useState<DialogMode>('None')

    const [reports, setReports] = useState<Report[] | undefined>()
    const [libraryReports, setLibraryReports] = useState<ReportDialogConfig[] | undefined>()
    const [defaultLibraryReports, setDefaultLibraryReports] = useState<ReportDialogConfig[] | undefined>()
    const [reportingMetadata, setReportConfigMetadata] = useState<ReportingMetadata | null>(null)
    const [dashboardConfig, setDashboardConfig] = useState<ScenarioSelection | null>(null)
    const [recentScenarios, setRecentScenarios] = useState<Scenario[]>([])
    const [isSynchronousRefreshing, setIsSynchronousRefreshing] = useState(false)
    const [synchronousRefreshTime, setSynchronousRefreshTime] = useState<Date | null>(null)
    const editLibraryReport = useSelector<RootState, EditLibraryReport | null>((x) => x.reports.editLibraryReport)
    const synchronousOnly = useSelector<RootState, boolean | undefined>(
        (x) => x.app.user?.refreshReportsSynchronously || isDesktopMode(),
    )
    const recalculationState = useSelector<RootState, RecalculationState>((x) => x.reportsRecalculation)
    const isRefreshing = recalculationState.itemsBeingRecalculated.length > 0 || isSynchronousRefreshing

    const [recalculationCompletedTime, reportsRecalculationState] = useRecalculateItemsStatus('Report', 2000)
    const loadReportsLibrary = async () => {
        const libraryReportsLoaded = await reportingApi.getReportsLibrary()
        setLibraryReports(libraryReportsLoaded)
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                if (!isDesktopMode()) {
                    const loadedDashboardConfig = await reportingApi.getReportsDashboard()
                    setDashboardConfig(loadedDashboardConfig)
                }
                const loadedReports = await reportingApi.getReports()
                const libraryReportsLoaded = await reportingApi.getReportsLibrary()
                const reportsLibraryDefaultsLoaded = await reportingApi.getReportsLibraryDefaults()
                setLibraryReports(libraryReportsLoaded)
                setDefaultLibraryReports(reportsLibraryDefaultsLoaded)
                const metadata = await reportingApi.getReportsConfigMetadata()
                setReports(loadedReports)
                const mostRecentScenarios = getScenariosSortedMostRecentN(metadata.scenarios, 5)
                setRecentScenarios(mostRecentScenarios)
                setReportConfigMetadata(metadata)
                dispatch(reportRecalculationActions.setIsCompletingRecalculation(false))
                setIsSynchronousRefreshing(false)
                if (recalculationCompletedTime) {
                    toast.success('Report data has been refreshed')
                }
                document.title = 'Reports Dashboard'
            } catch (err: any) {
                handleApiError(err)
            }
        }
        loadData()
    }, [reportingApi, recalculationCompletedTime, dispatch, synchronousRefreshTime, props.isPrintPreview])

    useEffect(() => {
        if (!editLibraryReport || !editLibraryReport.isUpdated) {
            return
        }
        const updatedReport = editLibraryReport.report
        setLibraryReports((previous) => {
            return previous!.map((report) => {
                if (report.id === updatedReport.id) {
                    return { ...updatedReport }
                }
                return report
            })
        })
        dispatch(reportsActions.setEditLibraryReport(null))
    }, [dispatch, editLibraryReport])

    const updateScenarioSelection = async (updatedSelection: ScenarioSelection) => {
        const updatedDashboardConfig = { ...dashboardConfig!, ...updatedSelection }
        setDashboardConfig(updatedDashboardConfig)
        await reportingApi.saveReportsDashboard(updatedSelection)
        refreshReports()
    }

    const refreshReports = () => {
        processingApi.beginItemRefresh(
            reports!.map((x) => x.id),
            'Report',
            dispatch,
        )
    }

    usePrintPreviewGlobalState(props.isPrintPreview)

    const anyReportRequiresRefresh = reports !== undefined && reports.filter((x) => x.requiresRecalculation).length > 0

    let toolbarButtons = <></>
    if (props.isPrintPreview) {
        toolbarButtons = (
            <div className="noPrint">
                <IconButton
                    tooltip="Back to Reports Dashboard"
                    toolbarLeftMargin
                    onClick={() => history.goBack()}
                    icon="bi-arrow-left"
                />
                <IconButton tooltip="Print" toolbarLeftMargin onClick={() => window.print()} icon="bi-printer" />
            </div>
        )
    } else {
        toolbarButtons = (
            <>
                <ReportRefreshButton
                    isRefreshing={isRefreshing}
                    refreshJustFinished={reportsRecalculationState.isCompletingRecalculation}
                    requiresRefresh={anyReportRequiresRefresh}
                    disabled={isRefreshing || (!isDesktopMode() && !anyReportRequiresRefresh)}
                    onClick={() => {
                        if (reports) {
                            if (synchronousOnly) {
                                setIsSynchronousRefreshing(true)
                                setSynchronousRefreshTime(new Date())
                            } else {
                                refreshReports()
                            }
                        }
                    }}
                />
                {!isDesktopMode() && dashboardConfig && (
                    <>
                        <ScenarioSelectorDropdown
                            dashboardConfig={dashboardConfig}
                            recentScenarios={recentScenarios}
                            showMoreClicked={() => setDialogMode('DashboardSelectionSelection')}
                            onSelectionUpdated={async (selection) => {
                                await updateScenarioSelection(selection)
                            }}
                            disabled={isRefreshing}
                        />
                        <SeperatorVertical />
                    </>
                )}

                <SplitIconButton
                    tooltip="Add Report"
                    tooltipSplit="More Add Report Options..."
                    icon="bi-file-plus"
                    onClick={() => history.push('/reports/newreport')}
                    disabled={isRefreshing}
                >
                    <Dropdown.Item onClick={() => history.push('/reports/newreport')} disabled={isRefreshing}>
                        Add Report
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setDialogMode('AddReportFromLibrary')} disabled={isRefreshing}>
                        Add Report From Library
                    </Dropdown.Item>
                </SplitIconButton>

                <SplitIconButton
                    tooltip="Open Reports Library"
                    tooltipSplit="More Report Library Options..."
                    icon="bi-collection"
                    onClick={() => setDialogMode('ReportsLibrary')}
                    disabled={isRefreshing}
                >
                    <Dropdown.Item onClick={() => setDialogMode('ReportsLibrary')} disabled={isRefreshing}>
                        Open Reports Library
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => setDialogMode('ResetDashboardToLibraryDefaults')}
                        disabled={isRefreshing}
                    >
                        Reset Dashboard to Library Defaults
                    </Dropdown.Item>
                </SplitIconButton>

                <SeperatorVertical />

                <IconButton
                    tooltip="Print Preview"
                    toolbarLeftMargin
                    disabled={isRefreshing}
                    onClick={() => {
                        history.push('/reports-print')
                    }}
                    icon="bi-printer"
                />

                {isDesktopMode() && (
                    <ToolbarButton
                        toolbarLeftMargin
                        tooltip="Open the SAFTE-FAST Console User Manual"
                        width={30}
                        disabled={isRefreshing}
                        onClick={api.launchHelpForm}
                    >
                        ?
                    </ToolbarButton>
                )}

                <EllipsisDropdown>
                    <EllipsisDropdownItem onClick={() => history.push('/reports/newreport')}>
                        <ItemWithIcon bootstrapIconClass="bi-file-plus">Add Report</ItemWithIcon>
                    </EllipsisDropdownItem>
                    <EllipsisDropdownItem onClick={() => setDialogMode('AddReportFromLibrary')}>
                        <ItemWithIcon bootstrapIconClass="bi-file-plus">Add Report From Library</ItemWithIcon>
                    </EllipsisDropdownItem>
                    <Dropdown.Divider />
                    <EllipsisDropdownItem onClick={() => setDialogMode('ResetDashboardToLibraryDefaults')}>
                        <ItemWithIcon bootstrapIconClass="bi-arrow-counterclockwise">
                            Reset Dashboard to Library Defaults
                        </ItemWithIcon>
                    </EllipsisDropdownItem>
                    <EllipsisDropdownItem onClick={() => setDialogMode('ReportsLibrary')}>
                        <ItemWithIcon bootstrapIconClass="bi-collection">Open Reports Library</ItemWithIcon>
                    </EllipsisDropdownItem>
                    <Dropdown.Divider />
                    <EllipsisDropdownItem onClick={() => history.push('/reports-print')}>
                        <ItemWithIcon bootstrapIconClass="bi-printer">Print Preview</ItemWithIcon>
                    </EllipsisDropdownItem>
                </EllipsisDropdown>
            </>
        )
    }

    // drag & drop configuration for dnd-kit
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: props.isPrintPreview ? 9999999 : 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )

    const reportsThumbs =
        !reports || !reportingMetadata ? (
            <>Loading...</>
        ) : (
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragMove={(e: DragMoveEvent) => {
                    if (isDraggingReportId === 0) {
                        setIsDraggingReportId(parseInt(e.active.id))
                    }
                }}
                onDragEnd={(e: DragEndEvent) => {
                    const { active, over } = e
                    setIsDraggingReportId(0)
                    if (over && active.id && over.id && active.id !== over.id) {
                        const updated = [...reports!]
                        const draggedReport = updated.find((x) => x.id.toString() === active.id)!
                        const replacedReport = updated.find((x) => x.id.toString() === over.id)!
                        const oldIndex = updated.indexOf(draggedReport)
                        const newIndex = updated.indexOf(replacedReport)

                        // use dnd-kit built in function for reordering the array
                        const reorderedReports = arrayMove(updated, oldIndex, newIndex)

                        // save to db then re-render
                        reportingApi.saveReportsDashboardThumbnailOrder(reorderedReports.map((x) => x.id))
                        setReports(reorderedReports)
                    }
                }}
            >
                <SortableContext items={reports.map((x) => x.id.toString())}>
                    {reports.map((report) => (
                        <DraggableReportDashboardThumbnail
                            key={report.id.toString()}
                            report={report}
                            reportingMetadata={reportingMetadata}
                            isRefreshing={isRefreshing}
                            isDraggingReportId={isDraggingReportId}
                            onEditClick={() => history.push(`/reports/${report.id}/configurationFromDashboard`)}
                            onDeleteClick={() => {
                                setReportToDelete(report)
                                setDialogMode('ConfirmDeleteReport')
                            }}
                        />
                    ))}
                </SortableContext>
            </DndContext>
        )

    const pageLayoutDesktopCssClass = isDesktopMode() ? 'reportsInsightsDashboardContainingRowForDesktop' : ''
    const layoutCssClass = props.isPrintPreview
        ? 'reportsInsightsDashboardContainingRowPrintPreview'
        : 'reportsInsightsDashboardContainingRow'

    handleRecalculationError(reportsRecalculationState.recalculationErrorMessage, dispatch, 'report')
    const isEditingLibraryReport = !!editLibraryReport && !editLibraryReport.isUpdated

    if (isDesktopMode() && !reports) {
        return <LoadingSpinner />
    }

    return (
        <>
            {dialogMode === 'ConfirmDeleteReport' && reportToDelete && (
                <ConfirmationDialog
                    headerText="Confirm Delete"
                    confirmedCallback={async () => {
                        const reportId = reportToDelete.id
                        try {
                            await reportingApi.deleteReport(reportId)
                            setReportToDelete(null)
                            // update the reports list, removing the one that was deleted
                            setReports((previous) => {
                                return [...previous!].filter((x) => x.id !== reportId)
                            })
                        } catch (err: any) {
                            handleApiError(err)
                        }
                    }}
                    closeCallback={() => {
                        setDialogMode('None')
                        setReportToDelete(null)
                    }}
                >
                    {`Are you sure you want to delete the report "${reportToDelete.name}"?`}
                </ConfirmationDialog>
            )}

            {dialogMode === 'DashboardSelectionSelection' && reportingMetadata && dashboardConfig && (
                <ScenarioSelectorDialog
                    scenarios={reportingMetadata.scenarios}
                    initialSelection={dashboardConfig}
                    closeCallback={async (state: DialogResultEnum, updatedSelection?: ScenarioSelection) => {
                        setDialogMode('None')
                        if (state === DialogResultEnum.Completed && updatedSelection) {
                            await reportingApi.saveReportsDashboard(updatedSelection)
                            processingApi.beginItemRefresh(
                                reports!.map((x) => x.id),
                                'Report',
                                dispatch,
                            )
                        }
                    }}
                />
            )}

            {!isEditingLibraryReport &&
                dialogMode === 'ReportsLibrary' &&
                libraryReports &&
                defaultLibraryReports &&
                reportingMetadata && (
                    <ReportsLibraryDialog
                        dialogMode="Library"
                        libraryReports={libraryReports}
                        defaultLibraryReports={defaultLibraryReports}
                        reportingMetadata={reportingMetadata}
                        setLibraryReports={setLibraryReports}
                        closeCallback={(status) => {
                            if (status === DialogResultEnum.Completed) {
                                toast.success('Reports Library saved')
                            }
                            loadReportsLibrary()
                            setDialogMode('None')
                        }}
                    />
                )}

            {dialogMode === 'AddReportFromLibrary' && libraryReports && defaultLibraryReports && reportingMetadata && (
                <ReportsLibraryDialog
                    scenarios={reportingMetadata.scenarios}
                    dialogMode="ReportSelection"
                    libraryReports={libraryReports}
                    defaultLibraryReports={defaultLibraryReports}
                    reportingMetadata={reportingMetadata}
                    setLibraryReports={setLibraryReports}
                    closeCallback={async (status, newReports) => {
                        if (status === DialogResultEnum.Completed && newReports) {
                            // update dashboard order
                            const allReports = [...newReports, ...reports!]
                            allReports.forEach((rpt, i) => {
                                rpt.dashboardOrder = i + 1
                            })
                            reportingApi.saveReportsDashboardThumbnailOrder(allReports.map((x) => x.id))

                            // re-render
                            setReports(allReports)

                            // kick off polling to check for completed recalcuation
                            await checkExistingRecalculationRequests(dispatch)
                        }
                        loadReportsLibrary()
                        setDialogMode('None')
                    }}
                />
            )}

            {dialogMode === 'ResetDashboardToLibraryDefaults' && (
                <ConfirmResetDashboardDialog
                    closeCallback={() => setDialogMode('None')}
                    confirmedCallback={async () => {
                        // call the server to reset the dashboard and provide the new reports
                        const defaultReports = await reportingApi.resetReportsDashboard()

                        // re-render
                        setReports(defaultReports)

                        // kick off polling to check for completed recalcuation
                        await checkExistingRecalculationRequests(dispatch)
                    }}
                    dashboardName="Reports"
                />
            )}

            {isEditingLibraryReport && <ReportConfiguration libraryReport={editLibraryReport.report} />}

            {!isEditingLibraryReport && (
                <PageLayout
                    doNotPutContentInCol
                    contentRowClass={`${layoutCssClass} ${pageLayoutDesktopCssClass} align-content-start`}
                    headingContent="Reports Dashboard"
                    buttons={toolbarButtons}
                >
                    {reportsThumbs}
                </PageLayout>
            )}
        </>
    )
}

const getScenariosSortedMostRecentN = (scenarios: Scenario[], nMostRecent: number) => {
    const scenariosCopy = [...scenarios]
    scenariosCopy.sort((a, b) => {
        const aTime = a.modifiedDate.getTime()
        const bTime = b.modifiedDate.getTime()
        if (aTime < bTime) return 1
        if (aTime > bTime) return -1
        return 0
    })
    return scenariosCopy.slice(0, nMostRecent)
}

export default ReportsDashboard
