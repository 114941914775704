import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal'
import { RecalculationRequestStatus } from 'types/RecalculationStatus'

export const RecalculationStatusOngoing = 'Recalculating'
export const RecalculationStatusComplete = 'RecalculationComplete'
export const RecalculationStatusError = 'RecalculationError'

export type RecalculationState = {
    recalculationRequestIds: number[]
    itemsBeingRecalculated: number[]
    isCompletingRecalculation: boolean
    recalculationErrorMessage: null | string
}

const initialReportsState: RecalculationState = {
    recalculationRequestIds: [],
    itemsBeingRecalculated: [],
    isCompletingRecalculation: false,
    recalculationErrorMessage: null,
}

const setItemRecalculationStatus = (
    state: WritableDraft<RecalculationState>,
    action: PayloadAction<RecalculationRequestStatus[]>,
) => {
    const records = action.payload
    records.forEach((record) => {
        if (record.status === RecalculationStatusOngoing) {
            // add ids to list of being calculated
            record.itemIds.forEach((id) => {
                if (!state.itemsBeingRecalculated.includes(id)) {
                    state.itemsBeingRecalculated.push(id)
                }
            })
        } else if (record.status === RecalculationStatusComplete || record.status === RecalculationStatusError) {
            // remove ids from list of "being calculated"
            record.itemIds.forEach((id) => {
                if (state.itemsBeingRecalculated.includes(id)) {
                    state.itemsBeingRecalculated = state.itemsBeingRecalculated.filter((x) => x !== id)
                }
            })

            // the overall request is done
            state.recalculationRequestIds = state.recalculationRequestIds.filter((x) => x !== record.requestId)
            state.isCompletingRecalculation = true
        }

        if (record.status === RecalculationStatusError) {
            state.recalculationErrorMessage = record.error || 'Unknown error'
        }
    })
}

const setRecalculationRequestIds = (state: WritableDraft<RecalculationState>, action: PayloadAction<number[]>) => {
    state.recalculationRequestIds = state.recalculationRequestIds.concat(action.payload)
}

const setIsCompletingRecalculation = (state: WritableDraft<RecalculationState>, action: PayloadAction<boolean>) => {
    state.isCompletingRecalculation = action.payload
}

const clearRecalculationError = (state: WritableDraft<RecalculationState>) => {
    state.recalculationErrorMessage = null
}

const reportsRecalculationSlice = createSlice({
    name: 'reportsRecalculation',
    initialState: initialReportsState,
    reducers: {
        setItemRecalculationStatus,
        setIsCompletingRecalculation,
        setRecalculationRequestIds,
        clearRecalculationError,
    },
})

const insightsRecalculationSlice = createSlice({
    name: 'insightsRecalculation',
    initialState: { ...initialReportsState },
    reducers: {
        setItemRecalculationStatus,
        setIsCompletingRecalculation,
        setRecalculationRequestIds,
        clearRecalculationError,
    },
})

export const reportsRecalculationReducer = reportsRecalculationSlice.reducer
export const insightsRecalculationReducer = insightsRecalculationSlice.reducer
export const reportRecalculationActions = reportsRecalculationSlice.actions
export const insightsRecalculationActions = insightsRecalculationSlice.actions
