import useGridDataStateFromLocalStorage from 'hooks/useGridDataStateFromLocalStorage'
import useSchedulesGridColumns from 'hooks/useSchedulesGridColumns'
import useUser from 'hooks/useUser'
import { flatten, uniq } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { getMergedColumns, handleFilterButtonClick } from 'services/utilities/kendoGridUtils'
import { handleApiError } from 'services/utilities/toastrUtils'
import { RootState } from 'store/store'
import { ExportItemTypeEnum } from 'types/ExportSchedulesRequest'
import MetaData from 'types/Metadata'
import Scenario from 'types/Scenario'
import Schedule from 'types/Schedule'
import EllipsisDropdown, { EllipsisDropdownItem, ItemWithIcon } from 'views/Common/Buttons/EllipsisDropdown'
import IconButton from 'views/Common/Buttons/IconButton'
import IconButtonColumns from 'views/Common/Buttons/IconButtonColumns'
import IconButtonFilter from 'views/Common/Buttons/IconButtonFilter'
import SeperatorVertical from 'views/Common/Buttons/SeparatorVertical'
import SplitIconButton from 'views/Common/Buttons/SplitIconButton'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import GridPageLayout from 'views/Common/Layout/PageLayout'
import RowBreadcrumbs from 'views/Common/Layout/RowBreadcrumbs'
import SharingDialog from 'views/Common/SharingDialog/SharingDialog'
import AddScheduleDialog from 'views/Scenarios/Dialogs/AddScheduleDialog'
import ExportScenarioDialog from 'views/Scenarios/Dialogs/ExportScenarioDialog'
import getColumns from 'views/Schedules/SchedulesPage/SchedulesPageColumns'
import RenameScenarioDialog from '../../Scenarios/Dialogs/RenameScenarioDialog'
import CopyScheduleDialog from '../CopyScheduleDialog/CopyScheduleDialog'
import DeleteScheduleConfirmationDialog from '../DeleteScheduleConfirmationDialog/DeleteScheduleConfirmationDialog'
import ScheduleErrorsDialog from '../ScheduleErrorsDialog/ScheduleErrorsDialog'
import ShiftScheduleDialog from '../ShiftsDialog/ShiftScheduleDialog'

type DialogMode =
    | 'AddEventsSchedule'
    | 'AddShiftsSchedule'
    | 'Share'
    | 'CopySchedule'
    | 'DeleteSchedules'
    | 'Export'
    | 'RenameScenario'
    | 'None'

const LocalStorageKeyForDataState = 'SchedulesGridDataState'
const SchedulesPage = () => {
    const user = useUser()
    const [enableFiltering, filterFields, gridDataState, setGridDataState, setFilteringEnabled] =
        useGridDataStateFromLocalStorage(LocalStorageKeyForDataState, 'SchedulesGridFilteringEnabled')

    const [schedulesAllTagFields, setSchedulesAllTagFields] = useState<string[]>([])
    const [isDisabledCopyDeleteAction, setIsDisabledCopyDeleteAction] = useState<boolean>(false)
    const [isDisabledShareAction, setIsDisabledShareAction] = useState<boolean>(false)
    const [isDisabledExportAction, setIsDisabledExportAction] = useState<boolean>(false)
    const getColumnsHelper = useCallback(
        () =>
            getColumns(
                () => {
                    setShowScenarioErrors(true)
                },
                (scheduleId: number) => {
                    setSelectedIdsForSharingDialog([scheduleId])
                    setDialogMode('Share')
                },
                schedulesAllTagFields,
                enableFiltering,
                filterFields,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [enableFiltering, JSON.stringify(filterFields), schedulesAllTagFields],
    )
    const [dialogMode, setDialogMode] = useState<DialogMode>('None')
    const [dataNeedsReloading, setDataNeedsReloading] = useState<Date | null>(null)
    const [selectedIdsForSharingDialog, setSelectedIdsForSharingDialog] = useState<number[]>([])
    const [showScenarioErrors, setShowScenarioErrors] = useState(false)
    const [showGridColumnPicker, setShowGridColumnPicker] = useState(false)
    // boolean to determine whether data is loading in Grid. Passed to kendo grid custom.
    const [loadingData, setLoadingData] = useState<boolean>(true)
    const [scenario, setScenario] = useState<Scenario | null>(null)
    const [schedulesData, setSchedulesData] = useState<Array<Schedule>>([])
    const [gridHeight, setGridHeight] = useState(0)
    const [stateColumns, setStateColumns] = useState<Array<KendoGridColumn>>(() => getColumnsHelper())
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const [redirectToScheduleDetails, setRedirectToScheduleDetails] = useState(0)
    const [redirectToFileProcessing, setRedirectToFileProcessing] = useState(false)

    const metadata = useSelector<RootState, MetaData | undefined>((x) => x.app.metadata)!
    const api = globals.getApi()

    const params = useParams() as any

    useEffect(() => {
        // load scenario data
        async function loadSchedules() {
            try {
                const scenarioId = Number(params.id)
                const singleScenario = await api.getScenarioById(scenarioId)
                const data = await api.getScenarioSchedules(scenarioId)
                setScenario(singleScenario!)
                setSchedulesData(data)
                // get all the distinct tag names for grid columns
                setSchedulesAllTagFields(
                    uniq(
                        flatten(data.map((x) => x.tagCollection)).reduce<string[]>(
                            (prev, cur) => [...prev, cur.name],
                            [],
                        ),
                    ),
                )
                window.localStorage.setItem('lastViewedScenarioId', scenarioId.toString())
                const firstSchedule = data[0]
                document.title = firstSchedule ? `Scenario View - "${firstSchedule.scenarioName}"` : 'Scenarios'
            } catch (err: any) {
                handleApiError(err)
            } finally {
                setLoadingData(false)
            }
        }

        loadSchedules()
    }, [params, api, dataNeedsReloading])

    const isAdministrator = user != null ? user!.isAdministrator : false

    /*
    Can user add action/schedule
    User needs:
    - have Scenario shared with me permission that is Edit
    - am I the owner of the shared item
    - administrator
    - I created the item
    */
    const canAddAction =
        scenario != null
            ? scenario.permission.sharedWithMePermission === 'Edit' ||
              scenario.permission.amOwnerOfSharedItem ||
              scenario.createdById === user!.id ||
              isAdministrator
            : false

    // update columns when things change
    // update action buttons when there are changes
    useEffect(() => {
        const getSelectedScheduleIds = (state: SelectionState) =>
            Object.keys(state).filter(
                (key) => state[key] === true && schedulesData.some((ev) => ev.id.toString() === key),
            )

        setStateColumns((previousColumns) => getMergedColumns(getColumnsHelper(), previousColumns))

        const getSchedulesFromIds = (ids: string[]) => schedulesData.filter((evt) => ids.includes(evt.id.toString()))
        const currentlySelectedIds = getSelectedScheduleIds(selectedRowsState)
        let isDisabledCopyDeleteActionButton = false
        let isDisabledShareButton = false
        let isDisabledExportButton = false
        if (currentlySelectedIds.length > 0) {
            const currentlySelectedSchedules = getSchedulesFromIds(currentlySelectedIds) as Schedule[]
            /* Can user add/delete schedule
            User needs:
            - be administrator
            - Owner, Edit Scenario share
            */
            const canCopyDeleteAction =
                scenario != null
                    ? scenario.permission.sharedWithMePermission === 'Edit' ||
                      scenario.permission.amOwnerOfSharedItem ||
                      scenario.createdById === user!.id ||
                      isAdministrator
                    : false
            if (!canCopyDeleteAction) {
                isDisabledCopyDeleteActionButton = true
            }
            /* Can user share schedule
            User needs:
            - be administrator
            - be owner of the scenario/schedule
            */
            const canShareScenario = scenario != null ? scenario.createdById === user!.id || isAdministrator : false
            if (!canShareScenario) {
                isDisabledShareButton = true
            }
            /* Can user edit scenario:
            User needs:
            - have Scenario shared with me permission that is Edit
            - am I the owner of the shared item
            - administrator
            - I created the item
            */
            const canEditScenario =
                scenario != null
                    ? scenario.permission.sharedWithMePermission === 'Edit' ||
                      scenario.permission.amOwnerOfSharedItem ||
                      scenario.createdById === user!.id ||
                      isAdministrator
                    : false
            const canEditSchedule = currentlySelectedSchedules[0].permission.sharedWithMePermission === 'Edit'
            const canViewSchedule = currentlySelectedSchedules[0].permission.sharedWithMePermission === 'View'
            if (!canViewSchedule && !canEditScenario && !canEditSchedule) {
                isDisabledExportButton = true
            }
            setIsDisabledCopyDeleteAction(isDisabledCopyDeleteActionButton)
            setIsDisabledShareAction(isDisabledShareButton)
            setIsDisabledExportAction(isDisabledExportButton)
        }
    }, [enableFiltering, getColumnsHelper, selectedRowsState, isAdministrator, scenario, schedulesData, user])

    // determine if user has permission to rename the scenario
    const canRenameScenario = scenario != null ? scenario.createdById === user!.id : false

    const schedulesGridColumns = useSchedulesGridColumns(schedulesAllTagFields)

    const schedulesGrid = (
        <KendoGridCustom
            centeredContent
            localStorageKeyForColumnState="schedulesGrid"
            localStorageKeyForGridDataState={LocalStorageKeyForDataState}
            showColumnPicker={showGridColumnPicker}
            onColumnPickerHide={() => setShowGridColumnPicker(false)}
            defaultSort={{ field: 'viewed', dir: 'desc' }}
            columnMenuFiltering={enableFiltering}
            height={`${gridHeight}px`}
            defaultEmptyGridText="Click 'Add' to create a new Schedule"
            data={schedulesData}
            isLoading={loadingData}
            columns={stateColumns}
            selectedRowsState={selectedRowsState}
            onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
            setColumnVisibility={(newColumnState: KendoGridColumn[]) => setStateColumns(newColumnState)}
        />
    )

    const selectedIds = getSelectedIds(selectedRowsState)
    const toolbarButtons = (
        <>
            <SplitIconButton
                disabled={!canAddAction}
                tooltip="Add Schedule"
                tooltipSplit="More Add Schedule Options..."
                icon="bi-file-plus"
                onClick={() => {
                    if (metadata.scheduleBuilderType.toLocaleLowerCase() === 'events') {
                        setDialogMode('AddEventsSchedule')
                    } else {
                        // shift
                        setDialogMode('AddShiftsSchedule')
                    }
                }}
            >
                <Dropdown.Item disabled={!canAddAction} onClick={() => setDialogMode('AddEventsSchedule')}>
                    Add Schedule by Events
                </Dropdown.Item>
                <Dropdown.Item disabled={!canAddAction} onClick={() => setDialogMode('AddShiftsSchedule')}>
                    Add Schedule by Shifts
                </Dropdown.Item>
            </SplitIconButton>
            <IconButton
                tooltip="Copy Schedule"
                onClick={() => setDialogMode('CopySchedule')}
                disabled={selectedIds.length !== 1 || isDisabledCopyDeleteAction}
                toolbarLeftMargin
                icon="bi-files"
            />
            <IconButton
                tooltip="Delete Schedule(s)"
                onClick={() => setDialogMode('DeleteSchedules')}
                disabled={selectedIds.length === 0 || isDisabledCopyDeleteAction}
                toolbarLeftMargin
                icon="bi-trash"
            />
            <SeperatorVertical />

            <IconButtonColumns onClick={() => setShowGridColumnPicker(true)} />
            <IconButtonFilter
                filterEnabled={enableFiltering}
                filterIsActive={!!filterFields.length}
                onClick={() =>
                    handleFilterButtonClick(enableFiltering, gridDataState, setFilteringEnabled, setGridDataState)
                }
            />

            <EllipsisDropdown>
                <EllipsisDropdownItem disabled={!canAddAction} onClick={() => setDialogMode('AddEventsSchedule')}>
                    <ItemWithIcon bootstrapIconClass="bi-file-plus">Add Schedule by Events</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem disabled={!canAddAction} onClick={() => setDialogMode('AddShiftsSchedule')}>
                    <ItemWithIcon bootstrapIconClass="bi-file-plus">Add Schedule by Shifts</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem
                    onClick={() => setDialogMode('CopySchedule')}
                    disabled={selectedIds.length !== 1 || isDisabledCopyDeleteAction}
                >
                    <ItemWithIcon bootstrapIconClass="bi-files">Copy Schedule</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem
                    onClick={() => setDialogMode('DeleteSchedules')}
                    disabled={selectedIds.length === 0 || isDisabledCopyDeleteAction}
                >
                    <ItemWithIcon bootstrapIconClass="bi-trash">Delete Schedule(s)</ItemWithIcon>
                </EllipsisDropdownItem>
                <Dropdown.Divider />
                <EllipsisDropdownItem
                    onClick={() => setDialogMode('Export')}
                    disabled={selectedIds.length === 0 || isDisabledExportAction}
                >
                    <ItemWithIcon bootstrapIconClass="bi-file-earmark-arrow-down">Export Schedule(s)</ItemWithIcon>
                </EllipsisDropdownItem>
                <Dropdown.Divider />
                <EllipsisDropdownItem
                    onClick={() => {
                        setSelectedIdsForSharingDialog(selectedIds)
                        setDialogMode('Share')
                    }}
                    disabled={selectedIds.length === 0 || isDisabledShareAction}
                >
                    <ItemWithIcon bootstrapIconClass="bi-people">Share Schedule(s)</ItemWithIcon>
                </EllipsisDropdownItem>
                <Dropdown.Divider />
                <EllipsisDropdownItem onClick={() => setShowGridColumnPicker(true)}>
                    <ItemWithIcon bootstrapIconClass="bi-layout-three-columns">Show/Hide Columns</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem
                    onClick={() =>
                        handleFilterButtonClick(enableFiltering, gridDataState, setFilteringEnabled, setGridDataState)
                    }
                >
                    <ItemWithIcon bootstrapIconClass="bi-funnel">
                        {enableFiltering ? 'Disable Column Filters' : 'Enable Column Filters'}
                    </ItemWithIcon>
                </EllipsisDropdownItem>
            </EllipsisDropdown>
        </>
    )

    const breadCrumbs = (
        <RowBreadcrumbs entries={[{ label: 'Scenarios', url: '/scenarios' }, { label: 'Scenario View' }]} />
    )

    const scenarioName = scenario?.name
    const scenarioId = scenario?.id

    const addScheduleCallback = useCallback((status: DialogResultEnum, newSchedule?: Schedule) => {
        setDialogMode('None')
        if (status === DialogResultEnum.Completed && newSchedule) {
            setRedirectToScheduleDetails(newSchedule.id)
        }
    }, [])

    const copyScheduleDialogCallback = useCallback((status: DialogResultEnum) => {
        if (status === DialogResultEnum.Completed) {
            setDataNeedsReloading(new Date())
        }
        setDialogMode('None')
    }, [])

    const sharingDialogCallback = useCallback((status: DialogResultEnum) => {
        if (status === DialogResultEnum.Completed) {
            toast.success('Sharing attributes have been updated')
            setDataNeedsReloading(new Date())
        }
        setDialogMode('None')
    }, [])

    const exportDialogCallback = useCallback((status: DialogResultEnum) => {
        if (status === DialogResultEnum.Completed) {
            setRedirectToFileProcessing(true)
        }
        setDialogMode('None')
    }, [])

    const deleteSchedulesCallback = useCallback(
        (status: DialogResultEnum) => {
            if (status === DialogResultEnum.Completed) {
                const successMessage = `${selectedIds.length} schedule${selectedIds.length === 1 ? '' : 's'} deleted`
                toast.success(successMessage, {
                    position: toast.POSITION.TOP_LEFT,
                })

                // clear the grid selection
                setSelectedRowsState((previous) => {
                    const updated = { ...previous }
                    selectedIds.forEach((id) => {
                        updated[id] = false
                    })
                    return updated
                })

                setDataNeedsReloading(new Date())
            }
            setDialogMode('None')
        },
        [selectedIds],
    )

    const renameScenarioCallback = useCallback((status: DialogResultEnum) => {
        if (status === DialogResultEnum.Completed) {
            setDataNeedsReloading(new Date())
        }
        setDialogMode('None')
    }, [])

    if (redirectToFileProcessing) {
        return <Redirect to="/fileprocessing" />
    }

    if (redirectToScheduleDetails > 0) {
        return <Redirect to={`/schedule/${redirectToScheduleDetails}`} />
    }

    return (
        <>
            {dialogMode === 'AddEventsSchedule' && (
                <AddScheduleDialog
                    scenarioId={scenarioId}
                    scenarioName={scenarioName}
                    closeCallback={addScheduleCallback}
                />
            )}

            {dialogMode === 'AddShiftsSchedule' && scenarioId && (
                <ShiftScheduleDialog
                    scenarioId={scenarioId}
                    scenarioName={scenarioName}
                    closeCallback={addScheduleCallback}
                />
            )}

            {dialogMode === 'Share' && (
                <SharingDialog
                    itemType="schedule"
                    itemIds={selectedIdsForSharingDialog}
                    closeCallback={sharingDialogCallback}
                />
            )}

            {dialogMode === 'CopySchedule' && (
                <CopyScheduleDialog
                    schedule={schedulesData!.find((x) => x.id === selectedIds[0])!}
                    closeCallback={copyScheduleDialogCallback}
                />
            )}

            {dialogMode === 'Export' && (
                <ExportScenarioDialog
                    columnConfigJson={JSON.stringify(schedulesGridColumns)}
                    exportItemType={ExportItemTypeEnum.Schedule}
                    selectedIds={selectedIds}
                    closeCallback={exportDialogCallback}
                />
            )}

            {dialogMode === 'DeleteSchedules' && scenarioId && (
                <DeleteScheduleConfirmationDialog
                    scenarioId={scenarioId}
                    scheduleIds={selectedIds}
                    onCloseDialog={deleteSchedulesCallback}
                />
            )}

            {dialogMode === 'RenameScenario' && scenarioId && (
                <RenameScenarioDialog
                    scenarioName={scenarioName}
                    scenarioId={scenarioId}
                    readonly={!canRenameScenario}
                    closeCallback={renameScenarioCallback}
                />
            )}

            {showScenarioErrors && scenarioId && (
                <ScheduleErrorsDialog
                    scenarioId={scenarioId}
                    closeCallback={() => {
                        setShowScenarioErrors(false)
                    }}
                />
            )}

            <GridPageLayout
                breadcrumbs={breadCrumbs}
                headingContent={scenarioName}
                headingProps={{
                    title: 'Click to rename Scenario',
                    onClick: () => setDialogMode('RenameScenario'),
                }}
                buttons={toolbarButtons}
                onMainContentHeightChange={setGridHeight}
            >
                {schedulesGrid}
            </GridPageLayout>
        </>
    )
}

export default SchedulesPage
