import ConfirmationDialog from './ConfirmationDialog'

const ConfirmResetDashboardDialog = (props: {
    closeCallback: () => void
    confirmedCallback: () => void
    dashboardName: string
    dashboardItems?: string
}) => {
    return (
        <ConfirmationDialog
            closeCallback={props.closeCallback}
            confirmedCallback={props.confirmedCallback}
            headerText="Confirm Reset Dashboard"
        >
            <>
                <p>Are you sure you want to reset your {props.dashboardName} dashboard to your library defaults?</p>
                <p>
                    You will lose any custom {props.dashboardItems || props.dashboardName} in your dashboard that are
                    not in your library.
                </p>
            </>
        </ConfirmationDialog>
    )
}

export default ConfirmResetDashboardDialog
