import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { TableOptionsFilterSelections } from 'types/interfaces'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import simpleDialogClasses from 'views/Common/GenericDialogs/SimpleDialog.module.css'
import classes from './GraphOptionsMenu.module.css'

const TableOptionsMenu = (props: {
    initialSelections: TableOptionsFilterSelections
    tableOptionsChanged: (selections: TableOptionsFilterSelections) => void
}) => {
    const [selections, setSelections] = useState<TableOptionsFilterSelections>(props.initialSelections)

    const optionsChangedCallback = useCallback(
        (s: TableOptionsFilterSelections) => props.tableOptionsChanged(s),
        [props],
    )

    useEffect(() => {
        if (JSON.stringify(selections) === JSON.stringify(props.initialSelections)) {
            return
        }
        optionsChangedCallback(selections)
    }, [props.initialSelections, optionsChangedCallback, selections])

    return (
        <Form className="p-3" style={{ width: '300px', fontSize: '0.85em' }}>
            <p className={classes.strongLabel}>Show these event types in the table:</p>
            <div className={simpleDialogClasses.checkBoxContainer}>
                <Form.Group>
                    <Form.Check
                        id="crewing"
                        label="Crewing"
                        type="checkbox"
                        checked={props.initialSelections.includeCrewing}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setSelections((oldVal) => ({
                                ...oldVal,
                                includeCrewing: e.target.checked,
                            }))
                        }
                    />
                    <Form.Check
                        id="noncrewing"
                        label="Non-Crewing"
                        type="checkbox"
                        checked={props.initialSelections.includeNonCrewing}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setSelections((oldVal) => ({
                                ...oldVal,
                                includeNonCrewing: e.target.checked,
                            }))
                        }
                    />
                    <Form.Check
                        id="marker"
                        label="Marker"
                        type="checkbox"
                        checked={props.initialSelections.includeMarker}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setSelections((oldVal) => ({
                                ...oldVal,
                                includeMarker: e.target.checked,
                            }))
                        }
                    />
                    <Form.Check
                        id="explicitsleep"
                        label="Explicit Sleep"
                        type="checkbox"
                        checked={props.initialSelections.includeExplicitSleep}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setSelections((oldVal) => ({
                                ...oldVal,
                                includeExplicitSleep: e.target.checked,
                            }))
                        }
                    />
                    <Form.Check
                        id="autosleep"
                        label="Auto-Sleep"
                        type="checkbox"
                        checked={props.initialSelections.includeAutoSleep}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setSelections((oldVal) => ({
                                ...oldVal,
                                includeAutoSleep: e.target.checked,
                            }))
                        }
                    />
                </Form.Group>
            </div>
        </Form>
    )
}

export const TableSettingsDialog = (props: {
    initialSelections: TableOptionsFilterSelections
    closeCallback: (status: DialogResultEnum) => void
    tableOptionsChanged: (selections: TableOptionsFilterSelections) => void
}) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Table Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TableOptionsMenu
                        initialSelections={props.initialSelections}
                        tableOptionsChanged={props.tableOptionsChanged}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCustom
                        isLarge
                        variant="primary"
                        type="submit"
                        onClick={() => {
                            props.closeCallback(DialogResultEnum.Cancelled)
                        }}
                    >
                        Close
                    </ButtonCustom>
                </Modal.Footer>
            </>
        </ModalWrapper>
    )
}

export default TableOptionsMenu
