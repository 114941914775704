import { useEffect, useRef } from 'react'
import HazardClassCase from 'types/HazardClassCase'
import CaseDutyPatternVisualizerClass from './CaseDutyPatternVisualizerClass'

const CaseDutyPatternVisualizer = (props: { case: HazardClassCase; width: number; isPrintPreview: boolean }) => {
    const container: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const svgComponent = new CaseDutyPatternVisualizerClass(container.current!, props.case)
        svgComponent.render()
    }, [props.case, props.width, props.isPrintPreview])
    return (
        <>
            <div
                style={{ paddingLeft: '20px', height: '95px' }}
                id={`case${props.case.id!.toString()}`}
                ref={container}
            />
        </>
    )
}

export default CaseDutyPatternVisualizer
