import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridDataStateChangeEvent } from '@progress/kendo-react-grid'
import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import globals from 'services/global/globals'
import { insightsActions } from 'store/insightsStore'
import { HazardClass } from 'types/HazardClass'
import HazardClassCase from 'types/HazardClassCase'
import EllipsisDropdown, { EllipsisDropdownItem, ItemWithIcon } from 'views/Common/Buttons/EllipsisDropdown'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import CaseDutyPatternVisualizer from '../Components/CaseDutyPatternVisualizer'
import HazardClassCaseActualDutiesTable from '../Components/HazardClassCaseActualDutiesTable'
import PatternCharts from './PatternCharts'
import PatternDescription from './PatternDescription'
import PatternTrends from './PatternFactors'
import classes from './PatternPanel.module.css'

interface PatternsPanelProps {
    setRef: (el: HTMLElement) => void
    hazardClass: HazardClass
    isPrintPreview: boolean
    case: HazardClassCase
    renameClicked: (isHidden: boolean) => void
    getCaseById: (parentId: number) => number
    parentClicked: (parentId: number) => void
    panelWidth: number
    dataStateChanged: (e: GridDataStateChangeEvent) => void
}

const PatternPanel = (props: PatternsPanelProps) => {
    const [isHidden, setIsHidden] = useState(props.case.hidden === true)
    const exportRef = useRef<ExcelExport | null>(null)
    const dispatch = useDispatch()
    const api = globals.getApi().getReportingApi()

    const toggleHiddenState = async () => {
        const newHiddenState = !isHidden
        setIsHidden(newHiddenState)
        await api.updatePatternProperties(props.hazardClass.id, props.case.id!, props.case.name, newHiddenState)
        dispatch(insightsActions.setHazardClassViewRequiresRefresh(new Date().getTime()))
    }

    const exportDuties = () => {
        exportRef.current!.save()
    }

    if (props.isPrintPreview && isHidden) {
        return null
    }

    let conatinerClasses = classes.container
    if (props.isPrintPreview) {
        conatinerClasses += ` ${classes.containerPrint}`
    }

    return (
        <div className={conatinerClasses}>
            <div className={classes.toolbarSection}>
                <div style={{ display: 'flex' }}>
                    {!props.isPrintPreview && (
                        <VisbilityButton isHidden={isHidden} toggleHiddenState={toggleHiddenState} />
                    )}
                    <strong ref={(el) => props.setRef(el!)}>{props.case.name}</strong>
                    {!props.isPrintPreview && <EditPencilIcon renameClicked={() => props.renameClicked(isHidden)} />}
                </div>
                {!props.isPrintPreview && (
                    <EllipsisButton
                        isHidden={isHidden}
                        toggleHiddenState={toggleHiddenState}
                        renameClicked={() => props.renameClicked(isHidden)}
                        exportClicked={exportDuties}
                    />
                )}
            </div>
            {!isHidden && (
                <>
                    {props.case.parentId !== 0 && (
                        <div>
                            Subset of{' '}
                            <text
                                type="button"
                                className="linkStyle"
                                onClick={() => props.parentClicked(props.case.parentId)}
                            >
                                Pattern {props.getCaseById(props.case.parentId)}
                            </text>
                        </div>
                    )}

                    <div className={classes.dutyVizPieSection}>
                        <div className={classes.dutyVizSection}>
                            <CaseDutyPatternVisualizer
                                case={props.case}
                                width={props.panelWidth}
                                isPrintPreview={props.isPrintPreview}
                            />
                            <PatternDescription pattern={props.case} />
                            <PatternTrends hazardClass={props.hazardClass} hazardDuties={props.case.duties} />
                        </div>
                        <PatternCharts case={props.case} />
                    </div>
                    {!props.hazardClass.hidePatternDuties && (
                        <div className={classes.bottomSection}>
                            <HazardClassCaseActualDutiesTable
                                exportRef={exportRef}
                                duties={props.case.duties}
                                patternExportFilename={`${props.hazardClass.name}_HazardClass_MatchingDuties_${props.case.name}`}
                                sortDescriptor={props.case.dutiesGridSort!}
                                dataStateChanged={props.dataStateChanged}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

const VisbilityButton = (props: { isHidden: boolean; toggleHiddenState: () => void }) => {
    let visibilityToggleClassName = 'bi-dash'
    if (props.isHidden) {
        visibilityToggleClassName = 'bi-plus'
    }

    return (
        <TransparentButton
            onClick={props.toggleHiddenState}
            tooltip={props.isHidden ? 'Include Pattern' : 'Hide Pattern'}
        >
            <div
                style={{
                    cursor: 'pointer',
                    color: '#444',
                    fontSize: '1.5em',
                    marginTop: -6,
                    marginRight: 4,
                }}
                className={visibilityToggleClassName}
            />
        </TransparentButton>
    )
}

const EditPencilIcon = (props: { renameClicked: () => void }) => {
    return (
        <TransparentButton onClick={props.renameClicked} tooltip="Rename Pattern ">
            <div
                className="bi-pencil"
                style={{ cursor: 'pointer', color: '#555', fontSize: '1.0em', marginTop: -6, marginLeft: 6 }}
            />
        </TransparentButton>
    )
}

const EllipsisButton = (props: {
    isHidden: boolean
    toggleHiddenState: () => void
    renameClicked: () => void
    exportClicked: () => void
}) => {
    return (
        <EllipsisDropdown>
            <EllipsisDropdownItem onClick={props.toggleHiddenState}>
                <ItemWithIcon bootstrapIconClass={props.isHidden ? 'bi-plus' : 'bi-dash'}>
                    {props.isHidden ? 'Include Pattern' : 'Hide Pattern'}
                </ItemWithIcon>
            </EllipsisDropdownItem>
            <EllipsisDropdownItem onClick={props.renameClicked}>
                <ItemWithIcon bootstrapIconClass="bi-pencil">Rename Pattern</ItemWithIcon>
            </EllipsisDropdownItem>
            <EllipsisDropdownItem onClick={props.exportClicked}>
                <ItemWithIcon bootstrapIconClass="bi-file-earmark-arrow-down">Export Duties to Excel</ItemWithIcon>
            </EllipsisDropdownItem>
        </EllipsisDropdown>
    )
}

export default PatternPanel
