import * as d3 from 'd3'
import { D3DragEvent, D3Selection } from 'types/d3TypeHelpers'
import { DetailData } from 'types/DetailItem'
import svgUtils from './EffectivenessGraphSvgUtils'
import * as graphTimeUtils from './EffectivenessGraphTimeUtils'
import { Scales, XScale, YScale, ZoombarOptions, ZoomDetails } from './EffectivenessGraphTypes'
import * as graphUtils from './EffectivenessGraphUtils'

type SliderId = 'dragLeft' | 'dragRight'
class EffectivenessGraphZoombar {
    /**
     * The thickness of the drag rectangles that adjust the zoom
     */
    static readonly barWidth = 6

    /**
     * How far back from the left edge of the zoombar does the label sit.
     * This was determined by inspection to line up the label edge with the
     * labels on the graph key.
     * This should be coordinated with another variable "labelIndent" in graphView.
     */
    static readonly zoomLabelXOffset = 54

    /**
     * Controls the granularity of the zoom lines.  Lower
     * number means more detail.
     */
    static readonly graphMinutes = 60

    /**
     * Used to keep the mouse cursor aligned with the center of the drag slider.
     * Without this, when you drag a slider, the left edge of the slider will be
     * aligned with the mouse pointer.  Nicer to have it aligned in the middle.
     *  */
    static readonly dragPointerCenteringOffset = 2

    /**
     * How close together can the sliders be placed (ie, how far can we zoom in)
     */
    static readonly minDistanceBetweenSliders = 10

    /**
     * Delay after zooming before updating the graph
     */
    static readonly delayBeforeUpdateInMs = 300

    private group: D3Selection
    private yScale: YScale
    private xScale: XScale
    private data: DetailData[]
    private dragStartXLocation: number | undefined
    private isUpdating: boolean = false
    private adjustGraphTimeout: number = 0

    /**
     * New zoombar, not yet rendered
     * @param options
     */
    constructor(private options: ZoombarOptions) {
        this.data = this.prepareData(this.options.detailData)
        this.yScale = this.establishYScale()
        this.xScale = this.establishXScale()
        this.group = this.createZoombarGroup(this.options)
    }

    /**
     * Render the zoombar in the svg
     */
    public render = () => {
        const groupNonClipped: D3Selection = this.group.append('g').attr('clip-path', 'url(#zoombarNonClipPath)')
        const groupClippable: D3Selection = this.group.append('g').attr('clip-path', 'url(#zoombarClipPath)')

        const backgroundRectangleGeneratorOptions = graphUtils.createGraphBackgroundColorConfigurations(
            this.yScale,
            this.options.effectivenessPalette,
        )

        // Area where we are not zoomed into (dark area that is sort of behind the zoom bar).
        // Put this one first so that the others will overlap it unless they are clipped by the
        // zooming mechanism.
        this.createPalettedBackgroundRectangle(groupNonClipped, 0, '#ffffff', this.options.zoomBarHeight)
        this.createEndBorders(groupNonClipped)

        // put in the background colors, on the clip-path
        backgroundRectangleGeneratorOptions.forEach((graphThresholdConfig, i) => {
            this.createPalettedBackgroundRectangle(
                groupClippable,
                graphThresholdConfig.yFunction(),
                this.options.effectivenessPalette[i]?.color,
                graphThresholdConfig.heightFunction(),
            )
        })

        this.generateEffectivenessLine(groupClippable, groupNonClipped)
        this.createAllTickMarks(groupClippable, groupNonClipped)
        this.createZoomLabel()
        this.createInvisibleDragResizeOverlay(groupClippable)
        this.syncSliderTimesWithGraph()
        const leftXScaled = this.getLeftSliderPositionScaled()
        const rightXScaled = this.getRightSliderPositionScaled()
        this.createZoomedSectionClipPath(leftXScaled, rightXScaled)
        this.createSliders(leftXScaled, rightXScaled)
    }

    /**
     * Create a colored rectangle that stretches across (ie, the palette of the zoombar)
     * @param {*} group
     * @param {*} y
     * @param {*} fillColor
     * @param {*} height
     * @param {*} options
     */
    private createPalettedBackgroundRectangle = (group: D3Selection, y: number, fillColor: string, height: number) => {
        const dragFn = d3
            .drag<SVGRectElement, any>()
            .subject(Object)
            .on('start', (e: any) => this.onZoombarDragStart(e))
            .on('drag', (e: any) => this.onZoombarDrag(e))
            .on('end', () => this.onZoombarDragEnd())

        // not sure the history of this constant, but it seems
        // to be keeping the edge of the rectangle from overflowing to the left
        const x = 1.2
        group
            .append<SVGRectElement>('rect')
            .attr('class', 'zoombar-background')
            .attr('x', x)
            .attr('y', y)
            .attr('fill', fillColor)
            .attr('width', this.options.zoombarRelativeWidth)
            .attr('height', height)
            .on('wheel', (e: any) => this.onScrollWheelEvent(e))
            .on('click', (e: any) => this.onZoombarMouseClick(e))
            .call(dragFn)
    }

    /**
     * Generate the effectiveness line with all its sections, colored by type of activity.
     */
    private generateEffectivenessLine = (groupClippable: D3Selection, groupNonClipped: D3Selection) => {
        const scales: Scales = {
            yScale: this.yScale,
            xScale: this.xScale,
        }

        const clippedLineSections = svgUtils.createGraphLineWithAllSections(groupClippable, this.data, scales)
        const nonClippedLineSections = svgUtils.createGraphLineWithAllSections(groupNonClipped, this.data, scales)

        // in case the mouse is directly over the eff. line during wheel event:
        clippedLineSections.concat(nonClippedLineSections).forEach((section) => {
            section.on('wheel', (e) => {
                this.onScrollWheelEvent(e)
            })
        })
    }

    private createSliders = (leftXScaled: number, rightXScaled: number) => {
        this.createDragEndRectangle('dragLeft', leftXScaled)
        this.createDragEndRectangle('dragRight', rightXScaled)
    }

    private prepareData = (detailData: DetailData[]) => {
        /**
         * Loop through the minute-level (detail) data and pull out only the changes
         * that are what needs to be shown in the zoom bar.  So, if the activity or
         * crewing/non-crewing changes, we need that.
         */
        let lastTime = 0
        const dataArray: DetailData[] = []
        const data = detailData
        data.forEach((d: DetailData, i: number) => {
            if (d.isNewActivityBoundary === true && i > 0) {
                if (data[i - 1].utcTime.getTime() !== lastTime) {
                    dataArray.push(data[i - 1]) // add the last item in the activity group
                }
                lastTime = 0 // force adding the current activity item
            }

            if (d.utcTime.getTime() >= lastTime + 1000 * 60 * EffectivenessGraphZoombar.graphMinutes) {
                dataArray.push(d)
                lastTime = d.utcTime.getTime()
            }
        }, this)
        return dataArray
    }

    private createZoombarGroup = (options: ZoombarOptions) => {
        return options.svgEl
            .append('g')
            .attr('class', 'zoombar-group')
            .attr(
                'transform',
                'translate(' +
                    options.graphX +
                    ', ' +
                    (options.graphHeight - options.zoomBarHeight + options.zoombarTopMargin) +
                    ')',
            )
    }

    /**
     * Create a draggable rectangle for zoombar adjustment
     * @param {*} group
     * @param {*} height
     * @param {*} xPosition
     */
    private createDragEndRectangle = (id: SliderId, xPosition: number) => {
        const allowSliderOverlap = false
        const isDragging = true
        const dragFn = d3
            .drag<SVGRectElement, any>()
            .subject(Object)
            .on('drag', (e: D3DragEvent) => {
                const xAbsolute = e.sourceEvent.clientX
                this.adjustSlider(xAbsolute, id, allowSliderOverlap, isDragging)
            })
            .on('end', () => {
                this.dragZoomCompletedHandler()
            })

        let xPositionCalculated = xPosition
        if (id === 'dragRight') {
            const rightEdge = this.getSvgScaledValueFromAbsoluteXValue(this.getRightEdgeOfZoombarAbsolute())
            if (xPosition >= rightEdge) {
                // the left side of the handle is really the indicator of the time, but it looks odd
                // if the right slider handle's left edge is aligned with the far side of the zoombar,
                // meaning it hangs over too far to the right.  So back it off by the width of the handle
                xPositionCalculated -= EffectivenessGraphZoombar.barWidth
            }
        }

        this.group
            .append('rect')
            .attr('id', id)
            .attr('class', 'zoombar-slider')
            .attr('x', xPositionCalculated)
            .attr('y', 0)
            .attr('height', this.options.zoomBarHeight)
            .attr('width', EffectivenessGraphZoombar.barWidth)
            .call((s) => dragFn(s))
    }
    /**
     * Create a clip path that will be used to hide area outside of the path.
     * Ie, outside of the zoomed area.
     */
    private createZoomedSectionClipPath = (leftXScaled: number, rightXScaled: number) => {
        this.group
            .append('clipPath')
            .attr('id', 'zoombarClipPath')
            .append('rect')
            .attr('x', leftXScaled)
            .attr('y', 0)
            .attr('width', rightXScaled - leftXScaled)
            .attr('height', this.options.zoomBarHeight)

        // this "non-clip" path is just to trim stuff around
        // the edge of the zoombar that shouldn't be showing
        this.group
            .append('clipPath')
            .attr('id', 'zoombarNonClipPath')
            .append('rect')
            .attr('x', 1)
            .attr('y', 0)
            .attr('width', this.options.zoombarRelativeWidth - 2)
            .attr('height', this.options.zoomBarHeight)
    }

    private establishXScale = (): XScale => {
        const mapDataToX = this.data.map((d) => {
            return d.utcTime.getTime()
        })
        const min = d3.min(mapDataToX) || 100
        const max = d3.max(mapDataToX) || 100
        return d3.scaleUtc().range([0, this.options.zoombarRelativeWidth]).domain([min, max])
    }

    private establishYScale = (): YScale => {
        const mapDataToY = this.data.map((d) => {
            return d.effectiveness
        })
        const yMin = Math.min(75, d3.min(mapDataToY) || 100)
        const yMax = d3.max(mapDataToY) || 100
        return d3.scaleLinear().range([this.options.zoomBarHeight, 0]).domain([yMin, yMax])
    }
    private syncSliderTimesWithGraph = () => {
        // Update the zoombar time to match the zoom position understood by the graph.
        // This happens when the schedule gets longer or shorter when zoomed in (like if an event
        // is added at the end of deleted from the end)
        const currentSettings = this.getCurrentZoomDetails()
        if (this.options.zoomLeftRatio && this.options.zoomLeftRatio !== currentSettings.leftRatio) {
            const leftPositionScaled = this.options.zoomLeftRatio * this.options.zoombarRelativeWidth
            const leftPositionTime = this.xScale.invert(leftPositionScaled)
            this.setLeftSliderTime(leftPositionTime)
        }
        if (this.options.zoomRightRatio && this.options.zoomRightRatio !== currentSettings.rightRatio) {
            const rightPositionScaled = this.options.zoomRightRatio * this.options.zoombarRelativeWidth
            const rightPositionTime = this.xScale.invert(rightPositionScaled)
            this.setRightSliderTime(rightPositionTime)
        }
    }

    private setRightSliderTime = (time: Date) => {
        this.options.rightSliderPositionTime = time
    }

    private getCurrentZoomDetails = (): ZoomDetails => {
        const leftRatio = this.getLeftSliderPositionScaled() / this.options.zoombarRelativeWidth
        const rightRatio = this.getRightSliderPositionScaled() / this.options.zoombarRelativeWidth
        const startTime = this.xScale.invert(this.getLeftSliderPositionScaled())

        return {
            leftRatio,
            rightRatio,
            startTime,
        }
    }

    private getLeftSliderPositionAbsolute = () => {
        return this.getXAbsoluteValueFromScaledValue(this.getLeftSliderPositionScaled())
    }

    private getRightSliderPositionAbsolute = () => {
        return this.getXAbsoluteValueFromScaledValue(this.getRightSliderPositionScaled())
    }

    private getZoomedWidth = () => {
        return this.getRightSliderPositionScaled() - this.getLeftSliderPositionScaled()
    }

    private getRightSliderPositionScaled = () => {
        if (this.options.rightSliderPositionTime) {
            const rightXScaledFromTime = this.xScale(this.options.rightSliderPositionTime)
            if (rightXScaledFromTime) {
                return rightXScaledFromTime
            }
        }
        return this.getSvgScaledValueFromAbsoluteXValue(this.getRightEdgeOfZoombarAbsolute())
    }

    private getLeftSliderPositionScaled = () => {
        if (this.options.leftSliderPositionTime) {
            const leftXScaledFromTime = this.xScale(this.options.leftSliderPositionTime)
            if (leftXScaledFromTime) {
                return leftXScaledFromTime
            }
        }
        return this.getSvgScaledValueFromAbsoluteXValue(this.getLeftEdgeOfZoombarAbsolute())
    }

    /**
     * Convert from absolute screen width to svg relative width.
     * @param {*} xAbsolute
     */
    private getSvgScaledValueFromAbsoluteXValue = (xAbsolute: number) => {
        const mouseXRelativeToLeftSideOfZoombar = xAbsolute - this.getLeftEdgeOfZoombarAbsolute()
        return mouseXRelativeToLeftSideOfZoombar * this.getSvgRatio()
    }

    /**
     * Get the actual width of the full svg
     */
    private getSvgWidthAbsolute = (): number => {
        return this.options.fullSvgAbsoluteWidth
    }

    /**
     * Get the ratio of scaled svg width to absolute width
     */
    private getSvgRatio = () => {
        return this.options.fullSvgRelativeWidth / this.getSvgWidthAbsolute()
    }

    private getXAbsoluteValueFromScaledValue = (xScaledValue: number) => {
        return xScaledValue / this.getSvgRatio() + this.getLeftEdgeOfZoombarAbsolute()
    }

    /**
     * Get the x-position on the screen of the left edge of the zoombar
     */
    private getLeftEdgeOfZoombarAbsolute = () => {
        const zoombarGroup = document.querySelector('g.zoombar-group')!
        return (
            zoombarGroup.getBoundingClientRect()!.left +
            EffectivenessGraphZoombar.zoomLabelXOffset / this.getSvgRatio() +
            EffectivenessGraphZoombar.dragPointerCenteringOffset
        )
    }

    /**
     * Get the x-position on the screen of the right edge of the zoombar
     */
    private getRightEdgeOfZoombarAbsolute = () => {
        const zoombarWidth = this.options.zoombarRelativeWidth / this.getSvgRatio()
        return zoombarWidth + this.getLeftEdgeOfZoombarAbsolute()
    }

    private setLeftSliderTime = (time: Date) => {
        this.options.leftSliderPositionTime = time
    }

    private createZoomLabel = () => {
        const labelHeightApproximation = 3
        this.group
            .append('text')
            .attr('class', 'key')
            .attr('x', -EffectivenessGraphZoombar.zoomLabelXOffset)
            .attr('y', this.options.zoomBarHeight / 2.0 + labelHeightApproximation)
            .text('Zoom')
    }

    /**
     * Create a overlay that will be used to highlight where the user is
     * dragging the zoombar.  Appears during zoombar resize, but disappears after done.
     * @param {*} parentGroup
     */
    private createInvisibleDragResizeOverlay = (parentGroup: D3Selection) => {
        parentGroup
            .append('rect')
            .attr('class', 'resizing-indicator-rectangle')
            .attr('x', 0)
            .attr('y', 1)
            .attr('fill', '#6a9cea')
            .attr('fill-opacity', '0.0') // initially 0 opacity (invisible)
            .attr('width', this.options.zoombarRelativeWidth)
            .attr('height', this.options.zoomBarHeight)
    }

    private onZoombarDragStart = (e: any) => {
        this.setIsStartingDragZooming(e)
    }

    private onZoombarDrag = (e: any) => {
        this.updateZoombarWhileDragging(e)
    }

    private onZoombarDragEnd = () => {
        this.dragZoomCompletedHandler()
    }

    private setIsStartingDragZooming = (e: any) => {
        if (!e.x || !e.sourceEvent) {
            throw Error('Expected x value on event:' + e)
        }
        this.dragStartXLocation = e.sourceEvent.x
    }

    /**
     * Zoom via mouse drag has completed, so update.
     */
    private dragZoomCompletedHandler = () => {
        this.setZoombarDisplayToIndicateNotResizing()
        this.updateGraphToMatchZoombar()
    }

    /**
     * Set the default appearance of the zoombar (for use after resizing)
     */
    private setZoombarDisplayToIndicateNotResizing = () => {
        document.querySelector('.resizing-indicator-rectangle')?.setAttribute('fill-opacity', '0.0')
    }

    /**
     * Set the appearance of the zoombar to indicate that it is being resized.
     */
    private setZoombarDisplayToIndicateResizing = () => {
        document.querySelector('.resizing-indicator-rectangle')?.setAttribute('fill-opacity', '0.4')
    }

    private onZoombarMouseClick = (e: any) => {
        this.centerZoombarAtClickPoint(e)
    }

    /**
     * Center the zoombar at the click point
     */
    private centerZoombarAtClickPoint = (e: any) => {
        const clickX = e.x as number

        let newLeftX
        let newRightX
        if (
            this.getRightSliderPositionScaled() === this.options.zoombarRelativeWidth &&
            this.getLeftSliderPositionScaled() === 0
        ) {
            // if they haven't zoomed in at all, zoom in automatically for them.  Why else would they click here?
            const defaultZoomWidth = 200
            newLeftX = clickX - defaultZoomWidth / 2.0
            newRightX = clickX + defaultZoomWidth / 2.0
        } else {
            const currentCenterOfZoom =
                (this.getRightSliderPositionAbsolute() - this.getLeftSliderPositionAbsolute()) / 2.0 +
                this.getLeftSliderPositionAbsolute()
            const delta = clickX - currentCenterOfZoom
            newLeftX = this.getLeftSliderPositionAbsolute() + delta
            newRightX = this.getRightSliderPositionAbsolute() + delta
        }
        // since we are moving one then the other, allow a temporary overlap
        const showDragResizeOverlay = false
        this.zoomIntoSection(newLeftX, newRightX, showDragResizeOverlay)
    }

    /**
     * Adjust the zoombar / clipping while dragging.
     */
    private updateZoombarWhileDragging = (e: any) => {
        if (!e.x || !e.sourceEvent) {
            throw Error('Expected x value on event:' + e)
        }
        const dragCurrentXLocation = e.sourceEvent.x
        const leftSideXLocation = Math.min(this.dragStartXLocation || 0, dragCurrentXLocation)
        const rightSideXLocation = Math.max(this.dragStartXLocation || 0, dragCurrentXLocation)
        if (rightSideXLocation - leftSideXLocation < 10) {
            return
        }
        const isDragging = true
        this.zoomIntoSection(leftSideXLocation, rightSideXLocation, isDragging)
    }

    /**
     * Zoom into a section on the zoombar.
     * @param {*} leftSideXLocation
     * @param {*} rightSideXLocation
     */
    private zoomIntoSection = (leftSideXLocation: number, rightSideXLocation: number, isDragging: boolean) => {
        const allowSliderOverlap = true
        this.adjustSlider(leftSideXLocation, 'dragLeft', allowSliderOverlap, isDragging)
        this.adjustSlider(rightSideXLocation, 'dragRight', allowSliderOverlap, isDragging)
    }

    private onScrollWheelEvent = (e: any) => {
        this.zoomBothSlidersOnScroll(e)
    }

    /**
     * Respond to mouse scroll wheel to adjust edge sliders
     * @param {*} d
     * @param {*} zoombarRelativeWidth
     * @param {*} fullSvgRelativeWidth
     */
    private zoomBothSlidersOnScroll = (e: any) => {
        // scroll zooming is a little fast, so scaling it down here
        const wheelDelta = e.wheelDelta / 5.0
        this.adjustSlider(this.getLeftSliderPositionAbsolute() + wheelDelta, 'dragLeft', false, false)
        this.adjustSlider(this.getRightSliderPositionAbsolute() - wheelDelta, 'dragRight', false, false)
        // prevent scroll on the window if the scrollbar happens to be present
        e.preventDefault()
    }

    /**
     * Limit the edge sliders so they don't go past the edge of the zoombar
     * @param {} xPositionAbsolute
     */
    private limitZoombarSliderXPosition = (xPositionAbsolute: number) => {
        const leftEdgeOfZoombar = this.getLeftEdgeOfZoombarAbsolute()
        const rightEdgeOfZoombar = this.getRightEdgeOfZoombarAbsolute() - EffectivenessGraphZoombar.barWidth

        if (xPositionAbsolute <= leftEdgeOfZoombar) {
            return leftEdgeOfZoombar
        }
        if (xPositionAbsolute >= rightEdgeOfZoombar) {
            return rightEdgeOfZoombar
        }
        return xPositionAbsolute
    }

    /**
     * Adjust the slider by the amount of mouse movement
     * @param {*} xAbsolute
     * @param {*} sliderId
     * @param {*} allowSliderOverlap - don't worry about how close the sliders are, because another call
     * to this function will be made to move the other slider after
     */
    private adjustSlider = (
        xAbsolute: number,
        sliderId: SliderId,
        allowSliderOverlap: boolean,
        isDragging: boolean,
    ) => {
        if (this.isUpdating) {
            return
        }

        const xAbsoluteConstrained = this.limitZoombarSliderXPosition(xAbsolute)
        const xScaled = this.getSvgScaledValueFromAbsoluteXValue(xAbsoluteConstrained)
        const time = this.xScale.invert(xScaled)

        // keep the position so we can use with scroll wheel movements
        // which are "deltas", so we need to calculate the final absolute x value.
        // Also keeping the scaled values for the clipPath
        if (sliderId === 'dragLeft') {
            if (
                allowSliderOverlap !== true &&
                xScaled > this.getRightSliderPositionScaled() - EffectivenessGraphZoombar.minDistanceBetweenSliders
            ) {
                return
            }
            this.setLeftSliderTime(time)
        } else {
            if (
                allowSliderOverlap !== true &&
                xScaled < this.getLeftSliderPositionScaled() + EffectivenessGraphZoombar.minDistanceBetweenSliders
            ) {
                return
            }
            this.setRightSliderTime(time)
        }

        this.updateSliderSVGAndTriggerUpdate(sliderId, xScaled, isDragging)
    }

    /**
     * Update the given slider to the new position
     * @param {*} sliderId
     * @param {*} xScaled
     */
    private updateSliderSVGAndTriggerUpdate = (sliderId: SliderId, xScaled: number, isDragging: boolean) => {
        // simplest thing is to remove and re-create the slider
        document.querySelector(`rect#${sliderId}`)?.remove()
        this.createDragEndRectangle(sliderId, xScaled)
        // update the zoombar clip rectangle
        const clipRectangle = document.querySelector('#zoombarClipPath rect')!
        if (sliderId === 'dragLeft') {
            clipRectangle.setAttribute('x', this.getLeftSliderPositionScaled().toString())
        }
        clipRectangle.setAttribute('width', this.getZoomedWidth().toString())
        if (isDragging) {
            this.setZoombarDisplayToIndicateResizing()
        } else {
            // send an update message after a brief delay
            this.updateGraphToMatchZoombar()
        }
    }

    /**
     * Trigger the graph to update to match the state of the zoombar
     */
    private updateGraphToMatchZoombar = () => {
        window.clearTimeout(this.adjustGraphTimeout)
        this.adjustGraphTimeout = window.setTimeout(() => {
            // finished zooming, tell the graph to update
            this.isUpdating = true
            this.options.updateGraphCallback(this.getCurrentZoomDetails())
            this.isUpdating = false
        }, EffectivenessGraphZoombar.delayBeforeUpdateInMs)
    }

    private getDayAxis = (xScale: XScale) => {
        const startDate = xScale.domain()[0]
        const endDate = xScale.domain()[1]
        const midnightDatesWithOffsets = graphTimeUtils.getGraphMidnightDates(
            this.options.detailData,
            startDate,
            endDate,
            this.options.timeMode,
        )
        return d3
            .axisTop<Date>(xScale)
            .tickFormat(d3.timeFormat(''))
            .tickSize(7)
            .tickValues(midnightDatesWithOffsets.map((x) => x.time))
    }

    private createTickMarks = (group: D3Selection, cssClass: string) => {
        // tick marks
        group
            .append('g')
            .attr('class', cssClass)
            .attr('transform', 'translate( 0, ' + this.options.zoomBarHeight + ')')
            .call(this.getDayAxis(this.xScale))
    }

    private createAllTickMarks = (groupClippable: D3Selection, groupNonClipped: D3Selection) => {
        this.createTickMarks(groupClippable, 'xAxisZoomMajor')
        this.createTickMarks(groupNonClipped, 'xAxisZoomMinor')
    }

    /**
     * Create borders around the zoombar
     * @param {} group
     */
    private createEndBorders = (group: D3Selection) => {
        // tweak to keep the border slightly inside the zoombar so it isn't clipped
        const rightBorderPadding = 1.0
        const leftBorderPadding = 1.5
        group
            .append('g')
            .attr('class', 'yAxis')
            .attr('transform', 'translate( 0,0)')
            .append('path')
            .attr(
                'd',
                'M' +
                    leftBorderPadding +
                    ',0V' +
                    this.options.zoomBarHeight +
                    'M' +
                    (this.options.zoombarRelativeWidth - rightBorderPadding) +
                    ',0V' +
                    this.options.zoomBarHeight +
                    'Z',
            )
    }
}

export default EffectivenessGraphZoombar
