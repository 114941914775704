import { ChangeEvent, useEffect, useState } from 'react'
import { FormCheck } from 'react-bootstrap'
import { updateColumnOrdering } from 'services/utilities/kendoGridUtils'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import SimpleDialog from 'views/Common/GenericDialogs/SimpleDialog'
import { KendoGridColumn } from '../Kendo/CustomColumnMenu'

const ColumnPickerDialog = (props: {
    /**
     * Callback when the dialog is closed
     */
    closeCallback: () => void

    /**
     * Columns have been updated
     */
    updateColumns: (columns: KendoGridColumn[]) => void

    /**
     * The columns
     */
    columns: KendoGridColumn[]

    /**
     * List of columns with filter applied
     */
    filteredColumns: string[]

    /**
     * List of columns that cannot be disabled (SFC options)
     */
    alwaysShownColumns: string[]
}) => {
    const [columnState, setColumnState] = useState(props.columns)
    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        props.updateColumns(columnState)
        const scrollableBody = document.querySelector('#scrollableColumnPickerBody')!
        scrollableBody.scrollTo(0, scrollPosition)
    }, [scrollPosition, props, columnState])

    /**
     * Controls the footer buttons to show
     * @returns
     */
    const getFooterButtons = (): React.ReactNode => {
        return (
            <>
                <ButtonCustom isLarge variant="secondary" onClick={props.closeCallback}>
                    Close
                </ButtonCustom>
            </>
        )
    }

    // consolidate font sizes
    return (
        <SimpleDialog
            headerText="Show / Hide Columns"
            closeCallback={props.closeCallback}
            getFooterButtons={getFooterButtons}
        >
            <>
                <p>Select the columns you want to appear in the grid:</p>
                <div
                    id="scrollableColumnPickerBody"
                    className="scrollableModalBody"
                    style={{ marginLeft: '15px', fontSize: '0.85em', color: '#444' }}
                >
                    {columnState.map((col) => {
                        const isFiltered = props.filteredColumns.includes(col.field as string)
                        const isAlwaysShown = props.alwaysShownColumns.includes(col.field as string)
                        let tooltip = ''
                        if (isFiltered) {
                            tooltip = 'Cannot hide this column because it has a filter applied'
                        } else if (isAlwaysShown) {
                            tooltip = 'This column is configured to always be shown (SFC Options)'
                        }
                        return (
                            <span title={tooltip}>
                                <FormCheck
                                    title={tooltip}
                                    key={col.title}
                                    id={col.title}
                                    label={col.title}
                                    name={col.title}
                                    checked={col.hide !== true || isAlwaysShown}
                                    disabled={isFiltered || isAlwaysShown}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        // keep the scroll position so it isn't lost on the next render
                                        const scrollableBody = document.querySelector('#scrollableColumnPickerBody')!
                                        setScrollPosition(scrollableBody.scrollTop)

                                        setColumnState((previous) => {
                                            const updatedColumns = [
                                                ...previous.map((previousColumn) => {
                                                    if (col.title === previousColumn.title) {
                                                        return { ...previousColumn, hide: !e.target.checked }
                                                    }
                                                    return previousColumn
                                                }),
                                            ]

                                            return updateColumnOrdering(updatedColumns)
                                        })
                                    }}
                                />
                            </span>
                        )
                    })}
                </div>
            </>
        </SimpleDialog>
    )
}

export default ColumnPickerDialog
