import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns'
import { GridCellProps } from '@progress/kendo-react-grid'
import { Option } from 'views/Common/Widget/ReactSelectTypes'

const EditInPlaceComboBox = (
    props: GridCellProps,
    fieldName: string,
    options: Option[],
    isInvalid: (dataItem: any) => boolean,
) => {
    const onChange = (event: ComboBoxChangeEvent) => {
        if (props.onChange) {
            const newValue = event.value
            if (newValue) {
                const newEvent = {
                    dataIndex: 0,
                    dataItem: props.dataItem,
                    field: fieldName,
                    value: newValue.value || newValue.label,
                    syntheticEvent: event.syntheticEvent,
                }
                props.onChange(newEvent)
            }
        }
    }

    const { dataItem } = props
    const field = props.field || ''
    const dataValue = dataItem[field] || ''
    const validationErrorBorder = isInvalid(dataItem) ? '1px solid red' : ''
    const customRendering =
        dataItem.inEdit === props.field ? (
            <td>
                <ComboBox
                    textField="label"
                    dataItemKey="value"
                    data={options}
                    value={options.find((x) => x.value === dataValue)}
                    onChange={onChange}
                    allowCustom
                />
            </td>
        ) : (
            <td style={{ border: validationErrorBorder }}>{dataValue.toString()}</td>
        )

    // since there is cell and row-level render overriding, we need to make use of that in this manner.
    return props.render ? props.render(customRendering, props) : customRendering
}

export default EditInPlaceComboBox
