import { useSelector } from 'react-redux'
import { DesktopUser, isDesktopMode } from 'services/axios/axios-sfc'
import { RootState } from 'store/store'
import { User } from 'types/interfaces'

const useUser = (): User | undefined => {
    let user = useSelector<RootState, User | undefined>((x) => x.app.user)
    if (isDesktopMode(true)) {
        user = DesktopUser
    }
    return user
}

export default useUser
