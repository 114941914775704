import React from 'react'
import { Form } from 'react-bootstrap'
import simpleDialogClasses from 'views/Common/GenericDialogs/SimpleDialog.module.css'

const FormLabelCustom = (props: React.LabelHTMLAttributes<HTMLElement>) => {
    const propsSansChildren = { ...props }
    propsSansChildren.children = undefined
    return (
        <Form.Label {...propsSansChildren} className={simpleDialogClasses.formLabel}>
            {props.children}
        </Form.Label>
    )
}

export default FormLabelCustom
