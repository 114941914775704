import { GridCellProps, GridFilterCellProps, GridHeaderCellProps } from '@progress/kendo-react-grid'
import { CSSProperties } from 'react'
import { isDesktopMode } from 'services/axios/axios-sfc'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import Schedule from 'types/Schedule'
import ScheduleEvent from 'types/ScheduleEvent'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import { ColumnMenuWithFilter, KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import DropdownFilterCell from 'views/Common/Kendo/DropdownFilterCell'
import EventLabelCell from 'views/Common/Kendo/EventLabelCell'
import { CrewingCell } from 'views/Common/Kendo/FormattedCell'
import { FormattedEventMetricCell } from 'views/Common/Kendo/FormattedMetricCell'
import { createFormattedMetricHeaderCell } from 'views/Common/Kendo/FormattedNameCell'
import { getHeaderCellClass } from 'views/Common/Kendo/KendoGridCustom'

export type ClickEditTimeParams = {
    startTime: Date
    startTimeLocalized: Date
    endTimeLocalized: Date
    endTime: Date
    timePosition: 'start' | 'end'
    dutyUuid: string
}

export const CrewingFilterTrue = 'Crewing'
export const CrewingFilterFalse = 'Non-Crewing'
export const CriticalFilterTrue = 'Critical'
export const CriticalFilterFalse = 'Non-Critical'

const invalidCellStyling: CSSProperties = {
    border: '2px solid red',
}

const eventHasOverlap = (schedule: Schedule, scheduleEvent: ScheduleEvent, timePosition: 'start' | 'end') => {
    if (timePosition === 'start') {
        return schedule.errors.filter((x) => x.overlapEvent2Uuid === scheduleEvent.uuid!).length > 0
    }
    return schedule.errors.filter((x) => x.overlapEvent1Uuid === scheduleEvent.uuid!).length > 0
}

const getClassCrewing = (scheduleEvent: ScheduleEvent): any => {
    return `${scheduleEvent.crewing ? 'labelCrewing' : ''}`
}

const getDateCellContent = (
    scheduleEvent: ScheduleEvent,
    timeProperty: string,
    readOnly: boolean,
    clickEditTime: () => void,
) => {
    const scheduleEventAny = scheduleEvent as any
    const formattedTime = dateTimeFormatting.formatDateTimeShort(scheduleEventAny[timeProperty].getTime())
    if (scheduleEvent.isDutyRollup() && !readOnly) {
        return <TransparentButton onClick={clickEditTime}>{formattedTime}</TransparentButton>
    }
    return <>{formattedTime}</>
}

const getColumns = (
    schedule: Schedule,
    allScheduleEvents: ScheduleEvent[],
    collapsedDutyIds: string[],
    eventButtonClicked: (scheduleEvent: ScheduleEvent) => void,
    clickDutyToggle: (operation: 'expand' | 'collapse', dutyId: string) => void,
    clickEditTime: (args: ClickEditTimeParams) => void,
    tagNames: string[],
    enableFilter: boolean,
    filteredFields: string[],
    readOnly: boolean,
): KendoGridColumn[] => {
    const getFormattedMetricCell = (props: GridCellProps): JSX.Element => {
        return FormattedEventMetricCell(schedule, props)
    }

    const clickEditTimeHandler = (scheduleEvent: ScheduleEvent, timePosition: 'start' | 'end') => {
        const scheduleEventAny = scheduleEvent as any
        clickEditTime({
            startTime: scheduleEvent.start,
            startTimeLocalized: scheduleEventAny.start_formatted,
            endTime: new Date(scheduleEvent.getEndMs()),
            endTimeLocalized: scheduleEventAny.end_formatted,
            timePosition,
            dutyUuid: scheduleEvent.dutyUuid!,
        })
    }

    // these column field names names must match the TableColumn properties OptionsOverridesDto.cs
    let columns: KendoGridColumn[] = [
        {
            field: 'label',
            title: 'Label',
            filter: 'text',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('label', filteredFields, 'left-aligned'),
            cellClassName: 'no-ellipsis',
            cell: (props: GridCellProps) => {
                const scheduleEvent = props.dataItem as ScheduleEvent
                const isCollapsed = scheduleEvent.isDutyRollup() && collapsedDutyIds.includes(scheduleEvent.dutyUuid!)
                return EventLabelCell(props, isCollapsed, allScheduleEvents, eventButtonClicked, clickDutyToggle)
            },
            width: 300,
            minResizableWidth: 200,
        },
        {
            field: 'type',
            title: 'Event Type',
            filter: 'text',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('type', filteredFields),
            cell: CrewingCell,
            width: 120,
            hide: true,
        },
        {
            field: 'workType',
            title: 'Work Type',
            filter: 'text',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('workType', filteredFields),
            cell: CrewingCell,
            width: 120,
            hide: true,
        },
        {
            field: 'crewing_description',
            title: 'Crewing',
            filter: 'text',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('crewing_description', filteredFields),
            cell: CrewingCell,
            filterCell: (props: GridFilterCellProps) => {
                return (
                    <DropdownFilterCell {...props} data={[CrewingFilterTrue, CrewingFilterFalse]} defaultItem="Any" />
                )
            },
            width: 120,
            hide: true,
        },
        {
            field: 'critical_description',
            title: 'Critical',
            filter: 'text',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('critical_description', filteredFields),
            cell: CrewingCell,
            filterCell: (props: GridFilterCellProps) => (
                <DropdownFilterCell {...props} data={[CriticalFilterTrue, CriticalFilterFalse]} defaultItem="Any" />
            ),
            width: 120,
            hide: true,
        },
        {
            field: 'from',
            title: 'From',
            filter: 'text',
            headerClassName: getHeaderCellClass('from', filteredFields),
            columnMenu: ColumnMenuWithFilter,
            cell: CrewingCell,
            width: 60,
        },
        {
            field: 'to',
            title: 'To',
            filter: 'text',
            headerClassName: getHeaderCellClass('to', filteredFields),
            columnMenu: ColumnMenuWithFilter,
            cell: CrewingCell,
            width: 60,
        },
        {
            field: 'start_formatted',
            title: 'Start',
            filter: 'date',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('start_formatted', filteredFields),
            cell: (props: GridCellProps): JSX.Element => {
                const scheduleEvent = props.dataItem as ScheduleEvent
                const cellStyling = eventHasOverlap(schedule, scheduleEvent, 'start') ? invalidCellStyling : {}
                return (
                    <td style={cellStyling} className={getClassCrewing(scheduleEvent)}>
                        {getDateCellContent(scheduleEvent, 'start_formatted', readOnly, () =>
                            clickEditTimeHandler(scheduleEvent, 'start'),
                        )}
                    </td>
                )
            },
            width: 135,
        },
        {
            field: 'end_formatted',
            title: 'End',
            filter: 'date',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('end_formatted', filteredFields),
            cell: (props: GridCellProps): JSX.Element => {
                // there's no end time property, only start and duration.  So we add one in an ad-hoc manner,
                // so that the grid has something to bind to.  This allows filtering to work.
                const scheduleEvent = props.dataItem as ScheduleEvent
                const cellStyling = eventHasOverlap(schedule, scheduleEvent, 'end') ? invalidCellStyling : {}
                return (
                    <td className={getClassCrewing(scheduleEvent)} style={cellStyling}>
                        {getDateCellContent(scheduleEvent, 'end_formatted', readOnly, () =>
                            clickEditTimeHandler(scheduleEvent, 'end'),
                        )}
                    </td>
                )
            },
            width: 135,
        },
        {
            field: 'duration',
            title: 'Duration',
            filter: 'numeric',
            headerClassName: getHeaderCellClass('duration', filteredFields),
            columnMenu: ColumnMenuWithFilter,
            cell: CrewingCell,
            width: 80,
        },
        {
            field: 'quality_description',
            title: 'Sleep Quality',
            headerClassName: getHeaderCellClass('quality_description', filteredFields),
            columnMenu: ColumnMenuWithFilter,
            cell: CrewingCell,
            width: 100,
            hide: true,
        },
        {
            field: 'plannedWorkSleepRuleId',
            title: 'Planned Work Sleep Rule',
            headerClassName: getHeaderCellClass('plannedWorkSleepRuleId', filteredFields),
            columnMenu: ColumnMenuWithFilter,
            cell: CrewingCell,
            width: 100,
            hide: true,
        },
        {
            field: 'plannedWorkSleepQuality_description',
            title: 'Planned Work Sleep Quality',
            headerClassName: getHeaderCellClass('plannedWorkSleepQuality_description', filteredFields),
            columnMenu: ColumnMenuWithFilter,
            cell: CrewingCell,
            width: 100,
            hide: true,
        },
        {
            field: 'effectivenessMin',
            title: 'Effectiveness Minimum',
            filter: 'numeric',
            headerClassName: getHeaderCellClass('effectivenessMin', filteredFields),
            columnMenu: ColumnMenuWithFilter,
            cell: getFormattedMetricCell,
            width: 100,
        },
        {
            field: 'effectivenessAvg',
            title: 'Effectiveness Average',
            filter: 'numeric',
            headerClassName: getHeaderCellClass('effectivenessAvg', filteredFields),
            columnMenu: ColumnMenuWithFilter,
            cell: getFormattedMetricCell,
            width: 100,
            hide: true,
        },
        {
            field: 'effectivenessCriticalMin',
            title: 'Effectiveness Minimum (Critical)',
            headerClassName: getHeaderCellClass('effectivenessCriticalMin', filteredFields),
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Effectiveness Minimum', 'Critical', !enableFilter),
            filter: 'numeric',
            cell: getFormattedMetricCell,
            width: 100,
        },
        {
            field: 'reservoirCriticalMin',
            title: 'Reservoir Minimum (Critical)',
            headerClassName: getHeaderCellClass('reservoirCriticalMin', filteredFields),
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Reservoir Minimum', 'Critical', !enableFilter),
            filter: 'numeric',
            cell: getFormattedMetricCell,
            width: 100,
        },
        {
            field: 'percentBelowCriterionCritical',
            title: '% Below Criterion (Critical)',
            headerClassName: getHeaderCellClass('percentBelowCriterionCritical', filteredFields),
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, '% Below Criterion', 'Critical', !enableFilter),
            filter: 'numeric',
            cell: getFormattedMetricCell,
            width: 100,
        },
        {
            field: 'percentFHACritical',
            title: '% FHA (Critical)',
            headerClassName: getHeaderCellClass('percentFHACritical', filteredFields),
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, '% FHA', 'Critical', !enableFilter),
            filter: 'numeric',
            cell: getFormattedMetricCell,
            width: 100,
            hide: true,
        },
        {
            field: 'workloadMax',
            title: 'Workload Maximum',
            headerClassName: getHeaderCellClass('workloadMax', filteredFields),
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Workload Maximum', undefined, !enableFilter),
            filter: 'numeric',
            cell: getFormattedMetricCell,
            width: 100,
            hide: true,
        },
    ]

    tagNames.forEach((tagName) => {
        columns.push({
            field: `tagValues.${tagName}`,
            title: `${tagName}*`,
            filter: 'text',
            headerClassName: getHeaderCellClass(`tagValues.${tagName}`, filteredFields),
            columnMenu: ColumnMenuWithFilter,
            cell: CrewingCell,
            width: 100,
            hide: true,
        })
    })

    if (isDesktopMode(true)) {
        const columnOverrides = schedule.viewSettings.columnOverrides
        // SFC has this hard coded to always show but doesn't pass that data over for some reason
        columnOverrides.push({ field: 'label', isChecked: true, isShown: false })
        // filter out columns that should never be shown for sfc
        columns = columns.filter((x) => !columnOverrides.find((y) => y.field === x.field && !y.isShown && !y.isChecked))
        // default to hidden if the override says so
        columns.forEach((col) => {
            const override = columnOverrides.find((x) => x.field === col.field)
            if (!override) return
            col.hide = !override.isChecked
        })
    }

    return columns
}

export { getColumns }
