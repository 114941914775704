import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartValueAxis,
    ChartValueAxisItem,
    LegendItemClickEvent,
    SelectEndEvent,
    SeriesClickEvent,
} from '@progress/kendo-react-charts'
import { memo, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { ChartFontSize, ChartFontSizeSm } from 'types/ChartFormatting'
import ReportingMetadata from 'types/ReportingMetadata'
import { Report } from 'types/Reports'
import { getDataItemDisplayNameForSeries, getKendoChartXAxisTitle, getKendoChartYAxisSeries } from './reportConversion'

const ReportChart = ({
    report,
    metadata,
    chartHeight,
    noTitle,
    setDrilldownSelection,
    drilldownSelection,
}: {
    report: Report
    metadata: ReportingMetadata
    chartHeight: number
    noTitle?: boolean
    setDrilldownSelection?: (arg: { from: number; to: number }) => void
    drilldownSelection?: { from: number; to: number }
}) => {
    const [reportSeries, setReportSeries] = useState(report.yAxisSeries)
    useEffect(() => {
        setReportSeries(report.yAxisSeries)
    }, [report.yAxisSeries])
    if (report.xAxisValues === null || report.yAxisSeries === null) {
        return <Alert variant="warning">{`Data is not yet calculated for report "${report.name}"`}</Alert>
    }

    const yAxisSeriesConfigs = getKendoChartYAxisSeries(report, metadata).reverse()
    const xAxisTitle = getKendoChartXAxisTitle(report, metadata)
    const hardCodedColors = report.seriesColors?.split(',')
    const titleFont = 'bold 14px sans-serif'
    let truncatedCategories = report.xAxisValues
    if (Array.isArray(truncatedCategories)) {
        truncatedCategories = truncatedCategories.map((x) => (x.length > 20 ? `${x.substring(0, 20)}...` : x))
    }
    const handleLegendItemClick = (e: LegendItemClickEvent) => {
        e.preventDefault()
        if (reportSeries) {
            const items = reportSeries?.map((item) => {
                const dataItemLabel = getDataItemDisplayNameForSeries(report, metadata, item)
                const seriesConfig = report.configOptions?.series.find((x) => x.seriesId === item.seriesId)
                if (dataItemLabel === e.text && seriesConfig) {
                    seriesConfig.visible = !seriesConfig.visible
                    return item
                }
                return item
            })
            setReportSeries(items)
            if (setDrilldownSelection && drilldownSelection) {
                setDrilldownSelection({ from: drilldownSelection.from, to: drilldownSelection.to })
            }
        }
    }
    return (
        <Chart
            transitions={false}
            style={{ height: chartHeight }}
            onSelectEnd={(e: SelectEndEvent) => {
                if (setDrilldownSelection) {
                    setDrilldownSelection({ from: e.from, to: e.to })
                }
            }}
            onSeriesClick={(e: SeriesClickEvent) => {
                if (!setDrilldownSelection) {
                    return
                }
                const dataGroupIndex = (e.point as any).categoryIx
                if (Number.isNaN(dataGroupIndex)) {
                    throw Error('Could not get the data group index')
                }
                setDrilldownSelection({ from: dataGroupIndex, to: dataGroupIndex + 1 })
            }}
            onLegendItemClick={handleLegendItemClick}
        >
            {noTitle !== true && <ChartTitle font={titleFont} text={report.name} />}
            <ChartLegend position="bottom" orientation="horizontal" labels={{ font: ChartFontSize }} />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem
                    categories={truncatedCategories}
                    startAngle={45}
                    select={drilldownSelection}
                    labels={{ font: ChartFontSizeSm, rotation: 'auto' }}
                    majorGridLines={{ visible: false }}
                    title={{ font: ChartFontSize, text: xAxisTitle }}
                />
            </ChartCategoryAxis>
            <ChartValueAxis>
                {yAxisSeriesConfigs.map((item) => {
                    return (
                        <ChartValueAxisItem
                            key={item.key}
                            name={item.key}
                            labels={{ font: ChartFontSizeSm }}
                            title={{ font: ChartFontSize, text: item.title }}
                        />
                    )
                })}
            </ChartValueAxis>
            <ChartSeries>
                {reportSeries &&
                    reportSeries.map((series, i) => {
                        const seriesConfig = report.configOptions?.series.find((x) => x.seriesId === series.seriesId)
                        let plotType = report.chartType
                        let seriesColor: string | undefined
                        if (seriesConfig) {
                            plotType = seriesConfig.plotType
                            seriesColor = seriesConfig.color
                        } else if (hardCodedColors) {
                            // WT eff report
                            seriesColor = hardCodedColors[i]
                        }
                        const dataItemLabel = getDataItemDisplayNameForSeries(report, metadata, series)
                        const yAxisSeriesConfig = yAxisSeriesConfigs.find((x) => x.seriesIds.includes(series.seriesId))!

                        if (!yAxisSeriesConfig) {
                            // edge case where we just removed one of the series but the data is not yet recalculated
                            return null
                        }

                        return (
                            <ChartSeriesItem
                                key={series.seriesId.toString()}
                                axis={yAxisSeriesConfig.key}
                                overlay={{ gradient: 'none' }}
                                labels={{ visible: report.showValueLabels, format: '{0:n1}' }}
                                name={dataItemLabel}
                                type={plotType as 'column' | 'line'}
                                tooltip={{ visible: true, format: `${dataItemLabel}: {0:n1}` }}
                                color={seriesColor}
                                data={series.data}
                                visible={seriesConfig?.visible}
                            />
                        )
                    })}
            </ChartSeries>
        </Chart>
    )
}

export default memo(ReportChart)
