import { GridExpandChangeEvent } from '@progress/kendo-react-grid'
import '@progress/kendo-theme-bootstrap/dist/all.css'
import useGridDataStateFromLocalStorage from 'hooks/useGridDataStateFromLocalStorage'
import useSchedulesGridColumns from 'hooks/useSchedulesGridColumns'
import useUser from 'hooks/useUser'
import { useCallback, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { handleFilterButtonClick } from 'services/utilities/kendoGridUtils'
import { handleApiError } from 'services/utilities/toastrUtils'
import { RootState } from 'store/store'
import { ExportItemTypeEnum } from 'types/ExportSchedulesRequest'
import MetaData from 'types/Metadata'
import Scenario from 'types/Scenario'
import Schedule from 'types/Schedule'
import EllipsisDropdown, { EllipsisDropdownItem, ItemWithIcon } from 'views/Common/Buttons/EllipsisDropdown'
import IconButton from 'views/Common/Buttons/IconButton'
import IconButtonColumns from 'views/Common/Buttons/IconButtonColumns'
import IconButtonFilter from 'views/Common/Buttons/IconButtonFilter'
import SeperatorVertical from 'views/Common/Buttons/SeparatorVertical'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import PageLayout from 'views/Common/Layout/PageLayout'
import SharingDialog from 'views/Common/SharingDialog/SharingDialog'
import ScenariosGridPieCharts from 'views/Scenarios/Components/ScenariosGridPieCharts'
import AddScheduleDialog from 'views/Scenarios/Dialogs/AddScheduleDialog'
import DeleteScenarioConfirmation from 'views/Scenarios/Dialogs/DeleteScenarioConfirmation'
import ExportScenarioDialog from 'views/Scenarios/Dialogs/ExportScenarioDialog'
import ImportScenarioDialog from 'views/Scenarios/Dialogs/ImportScenarioDialog'
import getColumns from 'views/Scenarios/Page/ScenariosPageGridColumns'
import ShiftScheduleDialog from 'views/Schedules/ShiftsDialog/ShiftScheduleDialog'
import CopyScenarioDialog from '../CopyScenarioDialog/CopyScenarioDialog'

type ShownDialogType = 'None' | 'Import' | 'Add' | 'Share' | 'Export' | 'Copy' | 'Delete'
const LocalStorageKeyForDataState = 'ScenariosGridDataState'

const ScenariosPage = () => {
    const user = useUser()
    const [dataNeedsReloading, setDataNeedsReloading] = useState<Date | null>(null)
    const [canShareDeleteScenario, setShareDeleteScenario] = useState<boolean>(false)
    const [selectedIdsForSharingDialog, setSelectedIdsForSharingDialog] = useState<number[]>([])
    const [showDialog, setShowDialog] = useState<ShownDialogType>('None')
    const [redirectToFileProcessing, setRedirectToFileProcessing] = useState(false)
    const [redirectToScheduleDetails, setRedirectToScheduleDetails] = useState(0)
    const [showGridColumnPicker, setShowGridColumnPicker] = useState(false)
    // boolean to determine whether data is loading in Grid. Passed to kendo grid custom.
    const [loadingData, setLoadingData] = useState<boolean>(true)

    const [enableFiltering, filterFields, gridDataState, setGridDataState, setFilteringEnabled] =
        useGridDataStateFromLocalStorage(LocalStorageKeyForDataState, 'scenariosGridFilteringEnabled')

    const getColumnsHelper = useCallback(() => {
        return getColumns(
            (scenarioId: number) => {
                setSelectedIdsForSharingDialog([scenarioId])
                setShowDialog('Share')
            },
            enableFiltering,
            filterFields,
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableFiltering, JSON.stringify(filterFields)])

    const [scenarioDataResult, setScenariosData] = useState<Array<Scenario>>([])
    const [gridHeight, setGridHeight] = useState(0)
    const [stateColumns, setStateColumns] = useState<Array<KendoGridColumn>>(getColumnsHelper())
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const api = globals.getApi()

    useEffect(() => {
        const loadScenarios = async () => {
            try {
                const data = await api.getScenariosWithMetrics()
                setScenariosData(data)
            } catch (err: any) {
                handleApiError(err)
            } finally {
                setLoadingData(false)
            }
        }

        loadScenarios()
        document.title = 'Scenarios'
    }, [dataNeedsReloading, api])

    useEffect(() => {
        const getSelectedScenarioIds = (state: SelectionState) =>
            Object.keys(state).filter(
                (key) => state[key] === true && scenarioDataResult.some((ev) => ev.id.toString() === key),
            )

        const getScenariosFromIds = (ids: string[]) =>
            scenarioDataResult.filter((evt) => ids.includes(evt.id.toString()))

        const updatedColumns = getColumnsHelper()
        setStateColumns((previousColumns) =>
            [...previousColumns].map((col) => {
                const updatedColumn = updatedColumns.find((x) => x.field === col.field)!
                return { ...col, headerCell: updatedColumn.headerCell, headerClassName: updatedColumn.headerClassName }
            }),
        )
        // Sharing Enable/Disabled features on selectedRowsState
        let isAdministrator = false
        if (user != null) {
            isAdministrator = user!.isAdministrator
        }
        const currentlySelectedIds = getSelectedScenarioIds(selectedRowsState)
        if (currentlySelectedIds.length > 0) {
            const newlySelectedScenarios = getScenariosFromIds(currentlySelectedIds) as Scenario[]
            const isDisabled =
                newlySelectedScenarios[0].permission.amOwnerOfSharedItem === false &&
                newlySelectedScenarios[0].createdById !== user?.id &&
                isAdministrator === false
            setShareDeleteScenario(isDisabled)
        }
    }, [enableFiltering, getColumnsHelper, selectedRowsState, scenarioDataResult, user])

    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)

    const selectedIds = getSelectedIds(selectedRowsState)
    const handleToggleRowExpand = (event: GridExpandChangeEvent) => {
        const isExpanded = event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded
        const scenario: Scenario = event.dataItem
        const newExpandedState = !isExpanded
        setScenariosData((previousState) => {
            return previousState.map((thisScenario) => {
                const newScenarioState: Scenario = {
                    ...thisScenario,
                }
                if (newScenarioState.id === scenario.id) {
                    ;(newScenarioState as any).expanded = newExpandedState
                }
                return newScenarioState
            })
        })
    }

    const scenariosGrid = (
        <KendoGridCustom
            centeredContent
            localStorageKeyForColumnState="scenariosGrid"
            localStorageKeyForGridDataState={LocalStorageKeyForDataState}
            showColumnPicker={showGridColumnPicker}
            onColumnPickerHide={() => setShowGridColumnPicker(false)}
            columnMenuFiltering={enableFiltering}
            height={`${gridHeight}px`}
            isLoading={loadingData}
            data={scenarioDataResult}
            defaultEmptyGridText="Click 'Add' or 'Import' to create a new Scenario"
            defaultSort={{ field: 'modifiedDate', dir: 'desc' }}
            detailComponent={ScenariosGridPieCharts}
            expandField="expanded"
            onExpandChange={handleToggleRowExpand}
            columns={stateColumns}
            selectedRowsState={selectedRowsState}
            onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
            setColumnVisibility={(newColumnState: KendoGridColumn[]) => setStateColumns(newColumnState)}
        />
    )

    const toolbarButtons = (
        <>
            <IconButton
                tooltip="Add Scenario"
                onClick={() => setShowDialog('Add')}
                toolbarLeftMargin
                icon="bi-file-plus"
            />
            <IconButton
                tooltip="Copy Scenario"
                onClick={() => setShowDialog('Copy')}
                disabled={selectedIds.length !== 1}
                toolbarLeftMargin
                icon="bi-files"
            />
            <IconButton
                tooltip="Delete Scenario(s)"
                onClick={() => setShowDialog('Delete')}
                disabled={selectedIds.length === 0 || canShareDeleteScenario}
                toolbarLeftMargin
                icon="bi-trash"
            />
            {(!metadata.disableFeatureImports || !metadata.disableFeatureExports) && <SeperatorVertical />}
            {!metadata.disableFeatureImports && (
                <IconButton
                    tooltip="Import Scenario"
                    onClick={() => setShowDialog('Import')}
                    toolbarLeftMargin
                    icon="bi-file-earmark-arrow-up"
                />
            )}
            {!metadata.disableFeatureExports && (
                <IconButton
                    tooltip="Export Scenario"
                    onClick={() => setShowDialog('Export')}
                    disabled={selectedIds.length !== 1}
                    toolbarLeftMargin
                    icon="bi-file-earmark-arrow-down"
                />
            )}
            <SeperatorVertical />
            <IconButtonColumns onClick={() => setShowGridColumnPicker(true)} />
            <IconButtonFilter
                filterEnabled={enableFiltering}
                filterIsActive={!!filterFields.length}
                onClick={() =>
                    handleFilterButtonClick(enableFiltering, gridDataState, setFilteringEnabled, setGridDataState)
                }
            />
            <EllipsisDropdown>
                <EllipsisDropdownItem onClick={() => setShowDialog('Add')}>
                    <ItemWithIcon bootstrapIconClass="bi-file-plus">Add Scenario</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => setShowDialog('Copy')} disabled={selectedIds.length !== 1}>
                    <ItemWithIcon bootstrapIconClass="bi-files">Copy Scenario</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem
                    onClick={() => setShowDialog('Delete')}
                    disabled={selectedIds.length === 0 || canShareDeleteScenario}
                >
                    <ItemWithIcon bootstrapIconClass="bi-trash">Delete Scenario(s)</ItemWithIcon>
                </EllipsisDropdownItem>
                <>
                    {(!metadata.disableFeatureImports || !metadata.disableFeatureExports) && <Dropdown.Divider />}
                    {!metadata.disableFeatureImports && (
                        <EllipsisDropdownItem onClick={() => setShowDialog('Import')}>
                            <ItemWithIcon bootstrapIconClass="bi-file-earmark-arrow-up">Import Scenario</ItemWithIcon>
                        </EllipsisDropdownItem>
                    )}
                    {!metadata.disableFeatureExports && (
                        <EllipsisDropdownItem
                            onClick={() => setShowDialog('Export')}
                            disabled={selectedIds.length !== 1}
                        >
                            <ItemWithIcon bootstrapIconClass="bi-file-earmark-arrow-down">Export Scenario</ItemWithIcon>
                        </EllipsisDropdownItem>
                    )}
                </>
                <Dropdown.Divider />
                <EllipsisDropdownItem
                    onClick={() => {
                        setSelectedIdsForSharingDialog(selectedIds)
                        setShowDialog('Share')
                    }}
                    disabled={selectedIds.length === 0 || canShareDeleteScenario}
                >
                    <ItemWithIcon bootstrapIconClass="bi-people">Share Scenario(s)</ItemWithIcon>
                </EllipsisDropdownItem>
                <Dropdown.Divider />
                <EllipsisDropdownItem onClick={() => setShowGridColumnPicker(true)}>
                    <ItemWithIcon bootstrapIconClass="bi-layout-three-columns">Show/Hide Columns</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem
                    onClick={() =>
                        handleFilterButtonClick(enableFiltering, gridDataState, setFilteringEnabled, setGridDataState)
                    }
                >
                    <ItemWithIcon bootstrapIconClass="bi-funnel">
                        {enableFiltering ? 'Disable Column Filters' : 'Enable Column Filters'}
                    </ItemWithIcon>
                </EllipsisDropdownItem>
            </EllipsisDropdown>
        </>
    )

    const importScenarioDialogCallback = useCallback((status: DialogResultEnum) => {
        setShowDialog('None')
        if (status === DialogResultEnum.Completed) {
            setRedirectToFileProcessing(true)
        }
    }, [])

    const addScheduleDialogCallback = useCallback((status: DialogResultEnum, newSchedule?: Schedule) => {
        setShowDialog('None')
        if (status === DialogResultEnum.Completed && newSchedule) {
            setRedirectToScheduleDetails(newSchedule.id)
        }
    }, [])

    const exportScenarioDialogCallback = useCallback((status: DialogResultEnum) => {
        setShowDialog('None')
        if (status === DialogResultEnum.Completed) {
            setRedirectToFileProcessing(true)
        }
    }, [])

    const copyScenarioDialogCallback = useCallback((status: DialogResultEnum) => {
        if (status === DialogResultEnum.Completed) {
            setDataNeedsReloading(new Date())
        }
        setShowDialog('None')
    }, [])

    const shareDialogCallback = useCallback((status: DialogResultEnum) => {
        if (status === DialogResultEnum.Completed) {
            toast.success('Sharing attributes have been updated')
            setDataNeedsReloading(new Date())
        }
        setShowDialog('None')
    }, [])

    const importDialog = showDialog === 'Import' && (
        <ImportScenarioDialog closeCallback={importScenarioDialogCallback} />
    )

    const addScenarioDialogType =
        metadata.scheduleBuilderType.toLocaleLowerCase() === 'events' ? (
            <AddScheduleDialog closeCallback={addScheduleDialogCallback} />
        ) : (
            <ShiftScheduleDialog closeCallback={addScheduleDialogCallback} />
        )

    const addScenarioDialog = showDialog === 'Add' && addScenarioDialogType

    const schedulesGridColumns = useSchedulesGridColumns([])

    const exportDialog = showDialog === 'Export' && (
        <ExportScenarioDialog
            columnConfigJson={JSON.stringify(schedulesGridColumns)}
            exportItemType={ExportItemTypeEnum.Scenario}
            selectedIds={selectedIds}
            closeCallback={exportScenarioDialogCallback}
        />
    )

    const copyDialog = showDialog === 'Copy' && (
        <CopyScenarioDialog
            scenario={scenarioDataResult!.find((x) => x.id === selectedIds[0])!}
            closeCallback={copyScenarioDialogCallback}
        />
    )

    const shareDialog = showDialog === 'Share' && (
        <SharingDialog itemType="scenario" itemIds={selectedIdsForSharingDialog} closeCallback={shareDialogCallback} />
    )

    if (redirectToFileProcessing) {
        return <Redirect to="/fileprocessing" />
    }
    if (redirectToScheduleDetails > 0) {
        return <Redirect to={`/schedule/${redirectToScheduleDetails}`} />
    }

    return (
        <PageLayout headingContent="Scenarios" buttons={toolbarButtons} onMainContentHeightChange={setGridHeight}>
            {importDialog}
            {showDialog === 'Delete' && selectedIds.length > 0 && (
                <DeleteScenarioConfirmation
                    scenarioIds={selectedIds}
                    onCloseDialog={(result: DialogResultEnum) => {
                        if (result === DialogResultEnum.Completed) {
                            const successMessage = `${selectedIds.length} scenario${
                                selectedIds.length === 1 ? '' : 's'
                            } deleted`
                            toast.success(successMessage, {
                                position: toast.POSITION.TOP_LEFT,
                            })

                            // clear the grid selection
                            setSelectedRowsState((previous) => {
                                const updated = { ...previous }
                                selectedIds.forEach((id) => {
                                    updated[id] = false
                                })
                                return updated
                            })

                            setDataNeedsReloading(new Date())
                        }
                        setShowDialog('None')
                    }}
                />
            )}
            {exportDialog}
            {copyDialog}
            {addScenarioDialog}
            {scenariosGrid}
            {shareDialog}
        </PageLayout>
    )
}

export default ScenariosPage
