import HazardClassCase, { DutyDescription } from 'types/HazardClassCase'

const generateDutyDescription = (index: number, dutyDescription: DutyDescription): string[] => {
    const dutyName = index === 0 ? 'Hazard Duty' : 'Prior Duty ' + index
    const priorRestName = 'Prior Rest ' + (index + 1)

    let priorRestDescription = `${priorRestName} - Unknown, as ${dutyName} is at the beginning of the schedule`
    if (dutyDescription.priorRestMin && dutyDescription.priorRestMax) {
        priorRestDescription = `${priorRestName} - As short as ${dutyDescription.priorRestMin} and as long as ${dutyDescription.priorRestMax} minutes`
    }

    return [
        `${dutyName} - Start Time at ${dutyDescription.startTimeMin} at the earliest and ${dutyDescription.startTimeMax} at the latest, with Duration as short as ${dutyDescription.durationMin} and as long as ${dutyDescription.durationMax}.`,
        priorRestDescription,
    ]
}

const PatternDescription = ({ pattern }: { pattern: HazardClassCase }) => {
    const descriptionEntries: string[] = []
    Object.keys(pattern.dutyDescriptions).forEach((index) => {
        const dutyDescription = pattern.dutyDescriptions[index]
        const [textDescriptionMain, textDescriptionPriorRest] = generateDutyDescription(
            parseInt(index),
            dutyDescription,
        )
        descriptionEntries.push(textDescriptionMain, textDescriptionPriorRest)
    })
    const paragraphElements = descriptionEntries.map((x) => (
        <p key={x} style={{ fontSize: '0.9em' }}>
            {x}
        </p>
    ))
    return <>{paragraphElements}</>
}

export default PatternDescription
