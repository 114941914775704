import TransparentButton from 'views/Common/Buttons/TransparentButton'
import DashboardControlCircle from './DashboardControlCircle'
import classes from './GraphDashboardControlButtons.module.css'

const GraphDashboardControlButtons = (props: {
    isPinned?: boolean
    closeClick: () => void
    pinClick: (isPinning: boolean) => void
}) => {
    const imageName = props.isPinned ? 'bi-pin-fill' : 'bi-pin-angle-fill'
    return (
        <div className={classes.container}>
            <DashboardControlCircle tooltip={props.isPinned ? 'Unpin' : 'Pin to the right'}>
                <TransparentButton
                    onClick={() => {
                        props.pinClick(true)
                    }}
                >
                    <i className={`${classes.button} ${imageName}`} />
                </TransparentButton>
            </DashboardControlCircle>
            <DashboardControlCircle tooltip="Close">
                <TransparentButton onClick={props.closeClick}>
                    <i className={`${classes.button} bi-x-lg`} />
                </TransparentButton>
            </DashboardControlCircle>
        </div>
    )
}

export default GraphDashboardControlButtons
