import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReportDialogConfig } from 'types/Reports'

export type EditLibraryReport = {
    report: ReportDialogConfig
    isUpdated: boolean
}

type ReportsState = {
    fullColorPalette: string[]
    unusedReportSeriesColors: string[]
    editLibraryReport: EditLibraryReport | null
}

const initialReportsState: ReportsState = {
    fullColorPalette: [],
    unusedReportSeriesColors: [],
    editLibraryReport: null,
}

const reportsReducer = createSlice({
    name: 'reports',
    initialState: initialReportsState,
    reducers: {
        setFullColorPalette(state, action: PayloadAction<string[]>) {
            state.fullColorPalette = action.payload
        },

        setUnusedReportSeriesColors(state, action: PayloadAction<string[]>) {
            state.unusedReportSeriesColors = action.payload
        },

        setEditLibraryReport(state, action: PayloadAction<EditLibraryReport | null>) {
            state.editLibraryReport = action.payload
        },
    },
})

export default reportsReducer.reducer
export const reportsActions = reportsReducer.actions
