import * as d3 from 'd3'
import HazardClassCase, { DutyDescription } from 'types/HazardClassCase'
import getDutyPatternForVisualization from './DutyPatternFormatter'

interface DutyVizConfig {
    graphicsYOffset: number
    rectangleBorderWidth: string
    timeScale: d3.ScaleLinear<number, number, never>
    dutyRectangles: DutyRectangles
    dutyProfileContainerRectangle: DutyProfileContainerRectangle
    restRectangle: RestRectangle
    timeline: Timeline
}

interface Timeline {
    color: string
    midnightLabelFontSize: string
    lineThickness: string
}

interface RestRectangle {
    color: string
    opacity: number
}

interface DutyProfileContainerRectangle {
    color: string
    opacity: number
    borderColor: string
}

interface DutyRectangles {
    height: number
    verticalGap: number
    mainSectionColor: string
    extendedSectionColor: string
    mainLabelY: number
    mainLabelFontSize: string
    rectangleLabelFontSize: string
}

type RectangleConfig = {
    x: number
    y: number
    width: number
    height: number
    fillColor: string
    strokeWidth: string
    strokeColor: string
    opacity: number
}

interface DutyRectangleBasic {
    startMinute: number
    duration: number
    color: string
    type: string
}
interface DutyRectangle extends DutyRectangleBasic {
    startTimeStringMin: string
    startTimeStringMax: string
    startTimeString: string
    durationExtended: number
}

type SVGSelection = d3.Selection<SVGGElement, any, d3.BaseType, unknown>

class CaseDutyPatternVisualizerClass {
    constructor(private container: HTMLElement, private thisCase: HazardClassCase) {}
    render = () => {
        this.container.innerHTML = '<svg style="width:100%" />'
        const svg: SVGSelection = d3.select(`#${this.container.id} svg`)
        const orderedDuties = getDutyPatternForVisualization(this.thisCase.dutyDescriptions)
        // Most of the graph 'variables' are right here.
        const config: DutyVizConfig = {
            graphicsYOffset: 45, // leave space at the top for labels
            rectangleBorderWidth: '1px',
            timeScale: this.createTimeScale(orderedDuties),
            dutyRectangles: {
                height: 20,
                verticalGap: 2,
                mainSectionColor: '#676767',
                extendedSectionColor: '#aaa',
                mainLabelY: 15,
                mainLabelFontSize: '12.5px',
                rectangleLabelFontSize: '11.5px',
            },
            dutyProfileContainerRectangle: {
                color: '#ddd',
                opacity: 1,
                borderColor: '#555',
            },
            restRectangle: {
                color: '#6184C4',
                opacity: 0.1,
            },
            timeline: {
                color: '#888',
                midnightLabelFontSize: '11px',
                lineThickness: '0.5px',
            },
        }
        this.drawTimeline(svg, config)
        orderedDuties.forEach((duty, dutyIndex) => {
            this.drawDutyProfile(svg, duty, config)
            this.drawRestSection(svg, orderedDuties, dutyIndex, config)
        })

        this.drawFinalRestSection(svg, orderedDuties, config)
    }

    /**
     * Draw the early/late duty boxes inside a containing rectangle
     */
    private drawDutyProfile = (svg: SVGSelection, duty: DutyDescription, config: DutyVizConfig) => {
        const dutyProfileGroup = this.createDutyProfileGroup(svg, duty)
        this.drawDutyProfileSurroundingRectangle(dutyProfileGroup, duty, config)
        this.drawDutyProfileRectangles(dutyProfileGroup, duty, config)
    }

    private drawFinalRestSection = (svg: SVGSelection, duties: DutyDescription[], config: DutyVizConfig) => {
        const finalDuty = duties[duties.length - 1]
        const restStart = finalDuty.startMinuteMax + finalDuty.durationMax
        const duration = config.timeScale.domain()[1] - restStart
        this.drawRestSectionRectangle(svg, restStart, duration, config)
    }

    private drawRestSection = (
        svg: SVGSelection,
        duties: DutyDescription[],
        dutyIndex: number,
        config: DutyVizConfig,
    ) => {
        const duty = duties[dutyIndex]
        let restDuration = duty.priorRestMin
        let restStart = 0
        if (restDuration === null) {
            // "unknown" rest
            restStart = config.timeScale.domain()[0]
            restDuration = duty.startMinuteMin - restStart
        } else if (dutyIndex > 0) {
            // If this isn't the first duty, just calculate where the rest section
            // goes so we have it exact
            const priorDuty = duties[dutyIndex - 1]
            restStart = priorDuty.startMinuteMax + priorDuty.durationMax
            restDuration = duty.startMinuteMin - restStart
        } else {
            restStart = duty.startMinuteMin - restDuration
        }

        this.drawRestSectionRectangle(svg, restStart, restDuration, config)

        if (dutyIndex === 0 && duty.priorRestMin) {
            // first duty in chronological order, and we have a known rest period before it,
            // so draw part of a duty before this
            this.drawDutyFragment(svg, restStart, config)
        }
    }

    private drawDutyFragment = (svg: SVGSelection, endMinute: number, config: DutyVizConfig) => {
        const duration = 120
        const startMinute = endMinute - duration
        this.drawRect(svg, {
            x: config.timeScale(startMinute),
            y: this.getOuterRectangleYPosition(config),
            width: this.getTimeScaledWidth(startMinute, duration, config),
            height: this.getOuterRectangleHeight(config),
            fillColor: config.dutyProfileContainerRectangle.color,
            strokeWidth: config.rectangleBorderWidth,
            strokeColor: config.dutyProfileContainerRectangle.borderColor,
            opacity: 1,
        })
    }

    private drawRestSectionRectangle = (
        svg: SVGSelection,
        restStart: number,
        restDuration: number,
        config: DutyVizConfig,
    ) => {
        this.drawRect(svg, {
            x: config.timeScale(restStart),
            y: this.getOuterRectangleYPosition(config),
            width: this.getTimeScaledWidth(restStart, restDuration, config),
            height: this.getOuterRectangleHeight(config),
            fillColor: config.restRectangle.color,
            strokeWidth: config.rectangleBorderWidth,
            strokeColor: config.restRectangle.color,
            opacity: config.restRectangle.opacity,
        })
    }

    /**
     * Create a group to contain a duty profile.
     * @returns
     */
    private createDutyProfileGroup = (svg: SVGSelection, duty: DutyDescription): SVGSelection => {
        const group = svg
            .selectAll('g.duty-' + duty.index)
            .data([duty])
            .enter()
            .append('g')
            .attr('class', 'duty-' + duty.index)
        return group
    }

    private getOuterRectangleHeight = (config: DutyVizConfig) => {
        return config.dutyRectangles.height * 2 + config.dutyRectangles.verticalGap
    }

    private getOuterRectangleYPosition = (config: DutyVizConfig) => {
        return config.graphicsYOffset - config.dutyRectangles.height - config.dutyRectangles.verticalGap / 2.0
    }

    /**
     * Draw the lighter rectangle that surrounds the duty rectangles.
     */
    private drawDutyProfileSurroundingRectangle = (
        dutiesRectanglesGroup: SVGSelection,
        duty: DutyDescription,
        config: DutyVizConfig,
    ) => {
        const dutyProfileDuration = this.getDutyProfileDuration(duty)
        const scaledWidth = this.getTimeScaledWidth(duty.startMinuteMin, dutyProfileDuration, config)

        this.drawRect(dutiesRectanglesGroup, {
            x: config.timeScale(duty.startMinuteMin),
            y: this.getOuterRectangleYPosition(config),
            width: scaledWidth,
            height: this.getOuterRectangleHeight(config),
            fillColor: config.dutyProfileContainerRectangle.color,
            strokeWidth: config.rectangleBorderWidth,
            strokeColor: config.dutyProfileContainerRectangle.borderColor,
            opacity: config.dutyProfileContainerRectangle.opacity || 1.0,
        })

        dutiesRectanglesGroup
            .append('text')
            .text(duty.profileName)
            .attr('x', config.timeScale(duty.startMinuteMin))
            .attr('y', config.dutyRectangles.mainLabelY)
            .attr('style', 'font-size: ' + config.dutyRectangles.mainLabelFontSize + '; font-weight: 600;fill: #444')
    }

    private drawRect = (container: SVGSelection, options: RectangleConfig) => {
        container
            .append('rect')
            .attr('x', options.x)
            .attr('y', options.y)
            .attr('width', options.width)
            .attr('height', options.height)
            .attr(
                'style',
                'fill: ' +
                    options.fillColor +
                    ';' +
                    'stroke-width: ' +
                    options.strokeWidth +
                    ';' +
                    'stroke: ' +
                    options.strokeColor +
                    '; ' +
                    'opacity: ' +
                    options.opacity +
                    ';',
            )
    }

    private getDutyProfileDuration = (duty: DutyDescription) => {
        const dutyStartMinMaxDiff = duty.startMinuteMax - duty.startMinuteMin
        return dutyStartMinMaxDiff + duty.durationMax
    }

    private getTimeScaledWidth = (startMinute: number, duration: number, config: DutyVizConfig) => {
        const scaledEndTime = config.timeScale(startMinute + duration)
        const scaledWidth = scaledEndTime - config.timeScale(startMinute)
        return scaledWidth
    }

    /**
     * Horizontal line, with time markers
     */
    private drawTimeline = (svg: SVGSelection, config: DutyVizConfig) => {
        const midnightLineHeight = config.dutyRectangles.height * 2 + config.dutyRectangles.verticalGap
        const midnightLineY1 = -(midnightLineHeight / 2.0) + config.graphicsYOffset
        let midnightLineY2 = midnightLineHeight / 2.0 + config.graphicsYOffset

        // bring the midnight marker line down just below the timeline content
        midnightLineY2 += 10

        const xStart = config.timeScale.range()[0]
        const xEnd = config.timeScale.range()[1]
        svg.append('line')
            .attr('x1', xStart)
            .attr('x2', xEnd)
            .attr('y1', config.graphicsYOffset)
            .attr('y2', config.graphicsYOffset)
            .attr('stroke', config.timeline.color)
            .attr('stroke-width', config.timeline.lineThickness)

        // calculate the necessary midnight markers.
        const midnights = []
        let midnightMinute = 0
        while (midnightMinute < config.timeScale.domain()[1]) {
            midnights.push(midnightMinute)
            midnightMinute += 1440
        }
        midnightMinute = -1440
        while (midnightMinute > config.timeScale.domain()[0]) {
            midnights.push(midnightMinute)
            midnightMinute -= 1440
        }

        // midnight marker lines
        const midnightLinesGroup = svg.append('g')
        midnightLinesGroup
            .selectAll('line')
            .data(midnights)
            .enter()
            .append('line')
            .attr('class', 'midnight-line')
            .attr('x1', config.timeScale)
            .attr('x2', config.timeScale)
            .attr('y1', midnightLineY1)
            .attr('y2', midnightLineY2)
            .attr('stroke', config.timeline.color)
            .attr('stroke-width', config.timeline.lineThickness)

        // midnight marker labels
        const approximateFontHeight = 13
        const approximateLabelWidth = 10
        midnightLinesGroup
            .selectAll('text')
            .data(midnights)
            .enter()
            .append('text')
            .attr('x', (d) => {
                return config.timeScale(d) - approximateLabelWidth / 2.0
            })
            .attr('y', midnightLineY2 + approximateFontHeight)
            .attr('style', 'font-size: ' + config.timeline.midnightLabelFontSize + '; fill: ' + config.timeline.color)
            .text('00')
    }

    private createTimeScale = (orderedDuties: DutyDescription[]) => {
        // make full width, minus a little margin spacing
        const earlyStart = orderedDuties[0]
        const lateStart = orderedDuties[orderedDuties.length - 1]
        const startOfDutyPaddingMinutes = 60
        let scaleStartTime = earlyStart.startMinuteMin - (earlyStart.priorRestMin || 0) - startOfDutyPaddingMinutes
        const goBackExtraDay = !earlyStart.priorRestMin || earlyStart.startMinuteMin - earlyStart.priorRestMin <= 0
        if (goBackExtraDay) {
            // bring the scale start earlier to show another midnight line
            if (scaleStartTime === 0) {
                scaleStartTime = -1440
            } else if (scaleStartTime > 0) {
                scaleStartTime = -startOfDutyPaddingMinutes
            }
        }
        const endOfDutyPaddingMinutes = 30
        let scaleEndTime = lateStart.startMinuteMax + lateStart.durationMax + endOfDutyPaddingMinutes
        // push out the scale end so that there is an extra midnight at the end
        scaleEndTime += 1440 - (scaleEndTime % 1440) + endOfDutyPaddingMinutes
        // a 24h scale, that scales from minutes to screen position.
        return d3.scaleLinear().domain([scaleStartTime, scaleEndTime]).range([0, this.container.offsetWidth])
    }

    private getDutyRectangle = (
        duty: DutyDescription,
        startProperty: 'startTimeMin' | 'startTimeMax',
        config: DutyVizConfig,
    ): DutyRectangle => {
        const startMinuteProperty = startProperty === 'startTimeMin' ? 'startMinuteMin' : 'startMinuteMax'
        return {
            startTimeStringMin: duty.startTimeMin,
            startTimeStringMax: duty.startTimeMax,
            startTimeString: duty[startProperty],
            startMinute: duty[startMinuteProperty],
            duration: duty.durationMin,
            durationExtended: duty.durationMax,
            color: config.dutyRectangles.mainSectionColor,
            type: startProperty,
        }
    }

    private getExtendedDutySectionRectangle = (
        dutyRectangle: DutyRectangle,
        config: DutyVizConfig,
    ): DutyRectangleBasic => {
        return {
            startMinute: dutyRectangle.startMinute + dutyRectangle.duration,
            duration: dutyRectangle.durationExtended - dutyRectangle.duration,
            color: config.dutyRectangles.extendedSectionColor,
            type: dutyRectangle.type,
        }
    }

    /**
     * Rectangles that represent duties.
     */
    private drawDutyProfileRectangles = (
        dutyContainerGroup: SVGSelection,
        duty: DutyDescription,
        config: DutyVizConfig,
    ) => {
        const rect1 = this.getDutyRectangle(duty, 'startTimeMin', config)
        const rect2 = this.getDutyRectangle(duty, 'startTimeMax', config)

        const rect1Extension = this.getExtendedDutySectionRectangle(rect1, config)
        const rect2Extension = this.getExtendedDutySectionRectangle(rect2, config)

        const dutyRectanglesGroup = dutyContainerGroup.append('g')

        const getDutyRectangleXPosition = (dutyRect: DutyRectangleBasic) => {
            return config.timeScale(dutyRect.startMinute)
        }

        const getDutyRectangleYPosition = (dutyRect: DutyRectangleBasic) => {
            const rectY =
                dutyRect.type === 'startTimeMin'
                    ? config.graphicsYOffset - config.dutyRectangles.height - config.dutyRectangles.verticalGap / 2.0
                    : config.graphicsYOffset + config.dutyRectangles.verticalGap / 2.0
            return rectY
        }

        // create the duty rectangle svg elements
        dutyRectanglesGroup
            .selectAll('rect')
            .data([rect1, rect1Extension, rect2, rect2Extension])
            .enter()
            .append('rect')
            .attr('style', (d) => {
                return 'fill: ' + d.color + '; stroke: #555; stroke-width: ' + config.rectangleBorderWidth
            })
            .attr('width', (d) => {
                const endMinuteScaled = config.timeScale(d.startMinute + d.duration)
                const startMinuteScaled = config.timeScale(d.startMinute)
                return endMinuteScaled - startMinuteScaled
            })
            .attr('height', config.dutyRectangles.height)
            .attr('x', getDutyRectangleXPosition)
            .attr('y', getDutyRectangleYPosition)

        // create the start-time labels inside the duty rectangles
        const approximateFontHeight = 14
        dutyRectanglesGroup
            .selectAll('text')
            .data([rect1, rect2])
            .enter()
            .append('text')
            .attr('x', (d) => {
                const leftMargin = 3
                return leftMargin + getDutyRectangleXPosition(d)
            })
            .attr('y', (d) => {
                return approximateFontHeight + getDutyRectangleYPosition(d)
            })
            .attr(
                'style',
                'font-size: ' + config.dutyRectangles.rectangleLabelFontSize + '; font-weight: 500;fill: #eee',
            )
            .text((d) => {
                return d.startTimeString
            })
    }
}

export default CaseDutyPatternVisualizerClass
