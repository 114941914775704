import { Button, ButtonProps } from 'react-bootstrap'
import classes from './BigButton.module.css'

export interface BigButtonProps extends ButtonProps {
    activated?: boolean
    tooltip?: string
}

const BigButton = (props: BigButtonProps) => {
    const variant = props.activated === true ? 'secondary' : 'outline-secondary'

    return (
        <span title={props.tooltip}>
            <Button
                className={classes.bigButton}
                onClick={props.onClick}
                variant={variant}
                disabled={props.disabled}
                active={false}
            >
                {props.children}
            </Button>
        </span>
    )
}

export default BigButton
