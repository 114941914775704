import { ButtonHTMLAttributes } from 'react'

interface TransparentButtonProps extends ButtonHTMLAttributes<HTMLElement> {
    color?: string
    tooltip?: string
}

const TransparentButton = (props: TransparentButtonProps) => {
    const className = `transparentButton linkStyle ${props.className || ''}`

    return (
        <span title={props.tooltip}>
            <button {...props} type="button" className={className}>
                <span style={{ color: props.color }}>{props.children}</span>
            </button>
        </span>
    )
}

export default TransparentButton
