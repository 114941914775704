import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragMoveEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import usePrintPreviewGlobalState from 'hooks/usePrintPreviewGlobalState'
import useRecalculateItemsStatus from 'hooks/useRecalculateItemsStatus'
import { useCallback, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { getDesktopHasLoaded, isDesktopMode, setDesktopHasLoaded } from 'services/axios/axios-sfc'
import globals from 'services/global/globals'
import { sortItems, sortItemsByStringField } from 'services/utilities/arrayUtils'
import { handleApiError, handleRecalculationError } from 'services/utilities/toastrUtils'
import { insightsRecalculationActions, RecalculationState } from 'store/recalculationStore'
import { RootState } from 'store/store'
import { HazardClass, HazardClassDialogConfig } from 'types/HazardClass'
import { HazardClassSort, InsightsDashboardConfig } from 'types/InsightsDashboardConfig'
import InsightsMetadata from 'types/InsightsMetadata'
import Scenario from 'types/Scenario'
import { ScenarioSelection } from 'types/ScenarioSelection'
import EllipsisDropdown, {
    EllipsisDropdownHeader,
    EllipsisDropdownItem,
    IndentedDropdownItem,
    ItemWithIcon,
} from 'views/Common/Buttons/EllipsisDropdown'
import IconButton from 'views/Common/Buttons/IconButton'
import InsightsRefreshButton from 'views/Common/Buttons/InsightsRefreshButton'
import ScenarioSelectorDropdown from 'views/Common/Buttons/ScenarioSelectorDropdown'
import SeperatorVertical from 'views/Common/Buttons/SeparatorVertical'
import ToolbarButton from 'views/Common/Buttons/ToolbarButton'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import LoadingSpinner from 'views/Common/GenericDialogs/LoadingSpinner'
import PageLayout from 'views/Common/Layout/PageLayout'
import ScenarioSelectorDialog from 'views/Scenarios/Dialogs/ScenarioSelectionDialog'
import DraggableInsightsDashboardThumbnail from '../Components/DraggableInsightsDashboardThumbnail'

type DialogMode = 'DashboardSelectionSelection' | 'ConfirmDeleteHazardClass' | 'ConfirmResetDashboard' | 'None'

const InsightsDashboard = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [hazardClassToDelete, setHazardClassToDelete] = useState<HazardClassDialogConfig | null>(null)

    const [isDraggingHazardClassId, setIsDraggingHazardClassId] = useState(0)
    const [hazardClasses, setHazardClasses] = useState<HazardClass[] | undefined>(undefined)
    const [dialogMode, setDialogMode] = useState<DialogMode>('None')
    const [dashboardConfig, setDashboardConfig] = useState<InsightsDashboardConfig | null>(null)
    const [insightsMetadata, setMetadata] = useState<InsightsMetadata | null>(null)
    const [recentScenarios, setRecentScenarios] = useState<Scenario[]>([])
    const api = globals.getApi()
    const reportingApi = api.getReportingApi()
    const processingApi = api.getProcessingApi()

    const [isSynchronousRefreshing, setIsSynchronousRefreshing] = useState(false)
    const [synchronousRefreshTime, setSynchronousRefreshTime] = useState<Date | null>(null)
    const synchronousOnly = useSelector<RootState, boolean | undefined>((x) => x.app.user?.refreshInsightsSynchronously)

    const recalculationState = useSelector<RootState, RecalculationState>((x) => x.insightsRecalculation)
    const isRefreshing = recalculationState.itemsBeingRecalculated.length > 0 || isSynchronousRefreshing

    const [recalculationCompletedTime, , insightsRecalculationState] = useRecalculateItemsStatus('Insights', 2000)

    const sortHazardClasses = (sort: HazardClassSort, loadedHazardClasses: HazardClass[]) => {
        if (sort === 'Custom') {
            sortItems(loadedHazardClasses, 'dashboardOrder')
        } else if (sort === 'Name') {
            sortItemsByStringField(loadedHazardClasses, 'name')
        } else if (sort === 'HazardDutiesCount') {
            sortItems(loadedHazardClasses, 'hazardDutyCount', true)
        } else if (sort === 'TotalPatternsCount') {
            sortItems(loadedHazardClasses, 'totalPatternsCount', true)
        }
    }

    const beginAsyncRefreshHazardClasses = useCallback(
        async (items: HazardClass[]) => {
            if (synchronousOnly) {
                setIsSynchronousRefreshing(true)
                setSynchronousRefreshTime(new Date())
            } else {
                await processingApi.beginItemRefresh(
                    items.map((x) => x.id),
                    'Insights',
                    dispatch,
                )
            }
        },
        [dispatch, processingApi, synchronousOnly],
    )

    useEffect(() => {
        const loadData = async () => {
            try {
                const loadedDashboardConfig = await reportingApi.getInsightsDashboard()
                setDashboardConfig(loadedDashboardConfig)
                const metadata = await reportingApi.getInsightsConfigMetadata()
                setMetadata(metadata)
                const mostRecentScenarios = getScenariosSortedMostRecentN(metadata.scenarios, isDesktopMode() ? 999 : 5)
                setRecentScenarios(mostRecentScenarios)
                const loadedHazardClasses = await reportingApi.getHazardClasses()
                sortHazardClasses(loadedDashboardConfig.hazardClassesSort, loadedHazardClasses)
                setHazardClasses(loadedHazardClasses)
                dispatch(insightsRecalculationActions.setIsCompletingRecalculation(false))
                setIsSynchronousRefreshing(false)
                if (
                    isDesktopMode() &&
                    !getDesktopHasLoaded() &&
                    loadedHazardClasses.find((x) => x.requiresRecalculation)
                ) {
                    setDesktopHasLoaded()
                    beginAsyncRefreshHazardClasses(loadedHazardClasses)
                }
                if (recalculationCompletedTime) {
                    toast.success('Insights data has been refreshed')
                }
            } catch (err: any) {
                handleApiError(err)
            }
        }
        loadData()
        document.title = 'Insights Dashboard'
    }, [reportingApi, recalculationCompletedTime, dispatch, synchronousRefreshTime, beginAsyncRefreshHazardClasses])

    const updateDashboardSort = async (sort: HazardClassSort) => {
        const updatedDashboardConfig: InsightsDashboardConfig = {
            ...dashboardConfig!,
            hazardClassesSort: sort,
        }
        await reportingApi.saveInsightsDashboard(updatedDashboardConfig)
        setDashboardConfig(updatedDashboardConfig)

        // apply the sort to the loaded hazard classes
        setHazardClasses((prev) => {
            const sorted = [...prev!]
            sortHazardClasses(sort, sorted)
            return sorted
        })
    }

    const updateScenarioSelection = async (updatedSelection: ScenarioSelection) => {
        const updatedDashboardConfig = { ...dashboardConfig!, ...updatedSelection }
        setDashboardConfig(updatedDashboardConfig)
        await reportingApi.saveInsightsDashboard(updatedDashboardConfig)
        await beginAsyncRefreshHazardClasses(hazardClasses!)
    }

    const anyHazardClassRequiresRefresh =
        !!hazardClasses && hazardClasses.filter((x) => x.requiresRecalculation).length > 0

    const toolbarButtons = dashboardConfig && (
        <>
            <InsightsRefreshButton
                isRefreshing={isRefreshing}
                refreshJustFinished={insightsRecalculationState.isCompletingRecalculation}
                requiresRefresh={anyHazardClassRequiresRefresh}
                disabled={isRefreshing}
                onClick={async () => {
                    if (hazardClasses) {
                        await beginAsyncRefreshHazardClasses(hazardClasses)
                    }
                }}
            />

            {dashboardConfig && (
                <>
                    <ScenarioSelectorDropdown
                        dashboardConfig={dashboardConfig}
                        recentScenarios={recentScenarios}
                        showMoreClicked={() => setDialogMode('DashboardSelectionSelection')}
                        onSelectionUpdated={async (selection) => {
                            await updateScenarioSelection(selection)
                        }}
                        disabled={isRefreshing}
                        recentScenariosLabelOverride={isDesktopMode() ? 'Scenarios List' : undefined}
                    />
                    <SeperatorVertical />
                </>
            )}

            <IconButton
                tooltip="Add Hazard Class"
                toolbarLeftMargin
                disabled={isRefreshing}
                onClick={() => history.push('/Insights/NewHazardClass')}
                icon="bi-file-plus"
            />
            <SeperatorVertical />

            {isDesktopMode() && (
                <ToolbarButton
                    toolbarLeftMargin
                    tooltip="Open the SAFTE-FAST Console User Manual"
                    width={30}
                    disabled={isRefreshing}
                    onClick={api.launchHelpForm}
                >
                    ?
                </ToolbarButton>
            )}

            <EllipsisDropdown>
                <EllipsisDropdownItem onClick={() => history.push('/Insights/NewHazardClass')}>
                    <ItemWithIcon bootstrapIconClass="bi-file-plus">Add Hazard Class</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => setDialogMode('ConfirmResetDashboard')}>
                    <ItemWithIcon bootstrapIconClass="bi-arrow-counterclockwise">Reset to Defaults</ItemWithIcon>
                </EllipsisDropdownItem>
                <Dropdown.Divider />
                <EllipsisDropdownHeader bootstrapIconClass="bi-sort-down-alt">Sort by</EllipsisDropdownHeader>
                <EllipsisDropdownItem onClick={() => updateDashboardSort('Name')}>
                    <IndentedDropdownItem checked={dashboardConfig.hazardClassesSort === 'Name'}>
                        Name
                    </IndentedDropdownItem>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => updateDashboardSort('HazardDutiesCount')}>
                    <IndentedDropdownItem checked={dashboardConfig.hazardClassesSort === 'HazardDutiesCount'}>
                        Hazard Duties Count
                    </IndentedDropdownItem>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => updateDashboardSort('TotalPatternsCount')}>
                    <IndentedDropdownItem checked={dashboardConfig.hazardClassesSort === 'TotalPatternsCount'}>
                        Total Patterns
                    </IndentedDropdownItem>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem onClick={() => updateDashboardSort('Custom')}>
                    <IndentedDropdownItem checked={dashboardConfig.hazardClassesSort === 'Custom'}>
                        Custom
                    </IndentedDropdownItem>
                </EllipsisDropdownItem>
            </EllipsisDropdown>
        </>
    )

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )

    // in case the user is on the Hazard Class Print view, then hits the browser
    // back button, we need to show the navbar again.  User should click Go Back
    // which sets that, but they might not click that button.  Maybe a better way to do this.
    usePrintPreviewGlobalState(false)

    const pageLayoutDesktopCssClass = isDesktopMode() ? 'reportsInsightsDashboardContainingRowForDesktop' : ''

    handleRecalculationError(insightsRecalculationState.recalculationErrorMessage, dispatch, 'insights')

    if (isDesktopMode() && !hazardClasses) {
        return <LoadingSpinner />
    }

    return (
        <>
            {dialogMode === 'ConfirmDeleteHazardClass' && hazardClassToDelete && (
                <ConfirmationDialog
                    headerText="Confirm Delete"
                    confirmedCallback={async () => {
                        try {
                            const hazardClassId = hazardClassToDelete.id
                            await reportingApi.deleteHazardClass(hazardClassId)
                            setHazardClassToDelete(null)
                            // update the reports list, removing the one that was deleted
                            setHazardClasses((previous) => {
                                return [...previous!].filter((x) => x.id !== hazardClassId)
                            })
                        } catch (err: any) {
                            handleApiError(err)
                        }
                    }}
                    closeCallback={() => {
                        setDialogMode('None')
                        setHazardClassToDelete(null)
                    }}
                >
                    {`Are you sure you want to delete the hazard class "${hazardClassToDelete.name}"?`}
                </ConfirmationDialog>
            )}

            {dialogMode === 'ConfirmResetDashboard' && (
                <ConfirmationDialog
                    headerText="Confirm Reset Dashboard"
                    confirmedCallback={async () => {
                        try {
                            const defaultHazardClasses = await reportingApi.resetInsightsDashboard()
                            setHazardClasses(defaultHazardClasses)
                            toast.success('Hazard Classes have been reset to defaults')
                            await beginAsyncRefreshHazardClasses(defaultHazardClasses)
                        } catch (err: any) {
                            handleApiError(err)
                        }
                    }}
                    closeCallback={() => setDialogMode('None')}
                >
                    <>
                        Resetting your dashboard will delete all your Hazard Classes and replace them with the original
                        defaults.
                        <br />
                        <br />
                        Are you sure you want to proceed?
                    </>
                </ConfirmationDialog>
            )}

            {dialogMode === 'DashboardSelectionSelection' && insightsMetadata && dashboardConfig && (
                <ScenarioSelectorDialog
                    scenarios={insightsMetadata.scenarios}
                    initialSelection={dashboardConfig}
                    closeCallback={async (state: DialogResultEnum, updatedSelection?: ScenarioSelection) => {
                        setDialogMode('None')
                        if (state === DialogResultEnum.Completed && updatedSelection) {
                            await updateScenarioSelection(updatedSelection)
                        }
                    }}
                />
            )}

            <PageLayout
                doNotPutContentInCol
                contentRowClass={`reportsInsightsDashboardContainingRow ${pageLayoutDesktopCssClass} align-content-start`}
                headingContent="Insights Dashboard"
                buttons={toolbarButtons}
            >
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragMove={(e: DragMoveEvent) => {
                        if (isDraggingHazardClassId === 0) {
                            setIsDraggingHazardClassId(parseInt(e.active.id))
                        }
                    }}
                    onDragEnd={(e: DragEndEvent) => {
                        const { active, over } = e
                        setIsDraggingHazardClassId(0)
                        if (over && active.id && over.id && active.id !== over.id) {
                            const updated = [...hazardClasses!]
                            const draggedHazardClass = updated.find((x) => x.id.toString() === active.id)!
                            const replacedHazardClass = updated.find((x) => x.id.toString() === over.id)!
                            const oldIndex = updated.indexOf(draggedHazardClass)
                            const newIndex = updated.indexOf(replacedHazardClass)

                            // use dnd-kit built in function for reordering the array
                            const reorderedHazardClasses = arrayMove(updated, oldIndex, newIndex)

                            // save to db then re-render
                            reportingApi.saveInsightsDashboardThumbnailOrder(reorderedHazardClasses.map((x) => x.id))
                            setHazardClasses(reorderedHazardClasses)
                            setDashboardConfig((prev) => ({ ...prev!, hazardClassesSort: 'Custom' }))
                        }
                    }}
                >
                    {!hazardClasses && <>Loading...</>}
                    {hazardClasses && (
                        <SortableContext items={hazardClasses.map((x) => x.id.toString())}>
                            {hazardClasses!.map((hazardClass) => (
                                <DraggableInsightsDashboardThumbnail
                                    key={hazardClass.id.toString()}
                                    hazardClass={hazardClass}
                                    isRefreshing={isRefreshing}
                                    isDraggingHazardClassId={isDraggingHazardClassId}
                                    onCopyClick={() => history.push(`/Insights/${hazardClass.id}/CopyFromDashboard`)}
                                    onEditClick={() =>
                                        history.push(`/Insights/${hazardClass.id}/configurationFromDashboard`)
                                    }
                                    onDeleteClick={() => {
                                        setHazardClassToDelete(hazardClass)
                                        setDialogMode('ConfirmDeleteHazardClass')
                                    }}
                                />
                            ))}
                        </SortableContext>
                    )}
                </DndContext>
            </PageLayout>
        </>
    )
}

const getScenariosSortedMostRecentN = (scenarios: Scenario[], nMostRecent: number) => {
    const scenariosCopy = [...scenarios]
    scenariosCopy.sort((a, b) => {
        const aTime = a.modifiedDate.getTime()
        const bTime = b.modifiedDate.getTime()
        if (aTime < bTime) return 1
        if (aTime > bTime) return -1
        return 0
    })
    return scenariosCopy.slice(0, nMostRecent)
}

export default InsightsDashboard
