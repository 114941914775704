import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CSSProperties } from 'react'
import { Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import globals from 'services/global/globals'
import { HazardClass } from 'types/HazardClass'
import InsightsDashboardThumbnail from './InsightsDashboardThumbnail'

const DraggableInsightsDashboardThumbnail = (props: {
    hazardClass: HazardClass
    isDraggingHazardClassId: number
    onDeleteClick: () => void
    onEditClick: () => void
    onCopyClick: () => void
    isRefreshing: boolean
}) => {
    const dispatch = useDispatch()
    const processingApi = globals.getApi().getProcessingApi()

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.hazardClass.id.toString(),
    })

    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <Col
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            key={props.hazardClass.id.toString()}
            xs="12"
            className="pl-0 pr-3"
        >
            <InsightsDashboardThumbnail
                isRefreshing={props.isRefreshing}
                isDraggingHazardClassId={props.isDraggingHazardClassId}
                onRefreshClick={() => processingApi.beginItemRefresh([props.hazardClass.id], 'Insights', dispatch)}
                onDeleteClick={props.onDeleteClick}
                onEditClick={props.onEditClick}
                onCopyClick={props.onCopyClick}
                hazardClass={props.hazardClass}
            />
        </Col>
    )
}

export default DraggableInsightsDashboardThumbnail
