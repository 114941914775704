import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns'
import { GridCellProps } from '@progress/kendo-react-grid'
import { SharePermissionDefinition } from 'types/ShareUsersConfiguration'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import { FormattedNameCell } from 'views/Common/Kendo/FormattedNameCell'

export type CellRenderMode = 'edit' | 'view'
export type GridDataType = {
    id: number
    name: string
    permissionId: number
    permissionDescription: string
    cellRenderMode: CellRenderMode
}

/**
 * Custom cell for the permission column; text content changes to dropdown list when clicked
 */
export const PermissionCell = (
    props: GridCellProps,
    permissionDefinitions: SharePermissionDefinition[],
    updatePermissionValue: (userIds: number[], permissionValue: number) => void,
    updateCellRenderMode: (userId: number, mode: CellRenderMode) => void,
) => {
    const dataItem = props.dataItem as GridDataType
    const permission = dataItem.permissionDescription
    const markup =
        permission.toLowerCase() === 'none' ? (
            <span className="grid-button">{permission}</span>
        ) : (
            <strong className="grid-button">{permission}</strong>
        )

    let renderedContent: JSX.Element = <></>

    if (dataItem.cellRenderMode === 'view') {
        // show the content as text (user then clicks to switch to edit mode)
        renderedContent = (
            <TransparentButton
                onClick={() => {
                    updateCellRenderMode(dataItem.id, 'edit')
                }}
            >
                {markup}
            </TransparentButton>
        )
    }

    if (dataItem.cellRenderMode === 'edit') {
        // edit mode showst a dropdown to choose the permission
        const boundPermissionDefinitions = [...permissionDefinitions]
        renderedContent = (
            <DropDownList
                data={boundPermissionDefinitions}
                textField="value"
                dataItemKey="key"
                opened
                onChange={(e: DropDownListChangeEvent) => {
                    const selectedPermission = e.target.value as SharePermissionDefinition
                    updatePermissionValue([dataItem.id], selectedPermission.key)
                }}
            />
        )
    }

    return FormattedNameCell(props, renderedContent)
}
