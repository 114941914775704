import * as d3 from 'd3'
import { D3Selection, SVGPathEl } from 'types/d3TypeHelpers'
import {
    DetailData,
    isAutoMarker,
    isAwake,
    isCrewing,
    isCriticalAutoMarker,
    isCriticalMarker,
    isMarker,
    isNotCrewing,
    isSleep,
    isWorkMarker,
    isWorkSleep,
} from 'types/DetailItem'
import { Scales } from './EffectivenessGraphTypes'
import { FilteredLineSection } from './EffectivenessGraphUtils'

/**
 * Create a line for the graph or zoombar
 */
const createSvgDataLine = (
    group: D3Selection,
    scales: Scales,
    dataArray: DetailData[],
    lineType: FilteredLineSection,
    filterFn: (d: DetailData, i: number, data: DetailData[]) => boolean,
): SVGPathEl => {
    const line = d3
        .line<DetailData>()
        .curve(d3.curveLinear)
        .x((d) => scales.xScale(d.utcTime.getTime()))
        .y((d) => scales.yScale(d.effectiveness))
        .defined(filterFn)

    return group
        .append('path')
        .data([dataArray])
        .attr('class', `${lineType.lineType} ${lineType.lineThicknessOptional ?? ''} js-graph-line`)
        .attr('d', line)
}

/**
 * Create the graph line with multiple sections
 * @param group
 * @param scales
 * @param detailData
 * @param lineTypes
 */
const createGraphLineSections = (
    group: D3Selection,
    scales: Scales,
    detailData: DetailData[],
    lineTypes: FilteredLineSection[],
): SVGPathEl[] => {
    // filter on the current data item and next data item
    const matchItemAndNext = (filterFn: (d: DetailData) => boolean, index: number, data: DetailData[]) => {
        const d = data[index]
        const d1 = data[index + 1]
        return filterFn(d) || (d1 && filterFn(d1))
    }

    // generate the section of line for each line type
    return lineTypes.map((line) => {
        return createSvgDataLine(group, scales, detailData, line, (d, i) => {
            return matchItemAndNext(line.filter, i, detailData)
        })
    })
}

/**
 * Generate the graph effectiveness line, which is composed of sections for the type of activity at a given time.
 */
const createGraphLineWithAllSections = (group: D3Selection, data: DetailData[], scales: Scales): SVGPathEl[] => {
    return createGraphLineSections(group, scales, data, [
        {
            lineType: 'awakeLine',
            filter: (d) => isAwake(d),
        },
        {
            lineType: 'notCrewingLine',
            filter: (d) => isNotCrewing(d),
        },
        {
            lineType: 'crewingLine',
            filter: (d) => isCrewing(d),
        },
        {
            lineType: 'markerLine',
            filter: (d) => isMarker(d) || isWorkMarker(d),
        },
        {
            lineType: 'criticalMarkerLine',
            filter: (d) => isCriticalMarker(d),
        },
        {
            lineType: 'autoMarkerLine',
            filter: (d) => isAutoMarker(d),
        },
        {
            lineType: 'criticalAutoMarkerLine',
            filter: (d) => isCriticalAutoMarker(d),
        },
        {
            lineType: 'sleepLine',
            filter: (d) => isSleep(d),
        },
        {
            lineType: 'workSleepLine',
            filter: (d) => isWorkSleep(d),
        },
    ])
}

export default { createGraphLineWithAllSections }
