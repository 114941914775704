import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import FormLabelCustom from '../Widget/FormLabelCustom'
import ConfirmationDialog from './ConfirmationDialog'

const ConfirmationDialogContent = (props: {
    itemName: string
    itemTypeName: string
    setNameInContainer: (name: string) => void
}) => {
    const [name, setName] = useState(props.itemName)

    useEffect(() => {
        // propagate the name change up to the container
        props.setNameInContainer(name)
    }, [props, name])

    return (
        <>
            <p>The given {props.itemTypeName} name already exists in your library. You can:</p>
            <p>
                A) Leave the name as it is to overwrite the library item
                <br />
                B) Change the name below to one that is not already used to create a separate library item
            </p>
            <FormLabelCustom>Name:</FormLabelCustom>
            <Form.Control
                type="text"
                placeholder={`Enter a name for the ${props.itemTypeName} in the library`}
                value={name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
        </>
    )
}

const ConfirmSaveLibraryItem = (props: {
    closeCallback: () => void
    confirmedCallback: (itemName: string) => void
    itemTypeName: string
    itemName: string
}) => {
    const hiddenInputRef = useRef<HTMLInputElement>(null)
    return (
        <ConfirmationDialog
            closeCallback={props.closeCallback}
            confirmedCallback={() => {
                props.confirmedCallback(hiddenInputRef.current!.value)
            }}
            headerText="Save to Library"
        >
            <>
                <ConfirmationDialogContent
                    {...props}
                    setNameInContainer={(newName) => {
                        hiddenInputRef.current!.value = newName
                    }}
                />
                <input type="hidden" ref={hiddenInputRef} value={props.itemName} />
            </>
        </ConfirmationDialog>
    )
}

export default ConfirmSaveLibraryItem
