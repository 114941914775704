import { StringOrNull } from './interfaces'

export type WorkTypes = 'Preparation' | 'CommuteToWork' | 'Brief' | 'Debrief' | 'CommuteFromWork' | 'Unwind'

export interface DutyAutoWorkType {
    workType: WorkTypes
    label: string
}

interface MetaData {
    scenarioNameCaption: string | null
    scheduleNameCaption: string | null
    refreshReportsSynchronously: boolean
    refreshInsightsSynchronously: boolean
    disableFeatureReports: boolean
    disableFeatureInsights: boolean
    disableFeatureImports: boolean
    disableFeatureExports: boolean
    disableHelpSupportPortal: boolean
    disableHelpTrainingAcademy: boolean
    trainingAcademyUrl: string | null
    loginAnnouncement: string | null
    graphDashboardHorizontalOrientation: boolean
    userCustomFields: string | null
    scheduleBuilderType: string
    industry: string
    dutyAutoWorkTypes: DutyAutoWorkType[]
    plannedWorkSleepQualities: string[]
    sleepQualities: string[]
    importInputTypes: string[]
}
/**
 * Convert from any to MetaData type
 * @param data
 * @returns
 */
export const parseMetadata = (data: any): MetaData => {
    if (!data) {
        throw new Error('Unable to parse null or undefined metadata object from api')
    }
    return {
        disableFeatureExports: Boolean(data.disableFeatureExports),
        disableFeatureImports: Boolean(data.disableFeatureImports),
        disableFeatureReports: Boolean(data.disableFeatureReports),
        disableFeatureInsights: Boolean(data.disableFeatureInsights),
        disableHelpSupportPortal: Boolean(data.disableHelpSupportPortal),
        disableHelpTrainingAcademy: Boolean(data.disableHelpTrainingAcademy),
        refreshReportsSynchronously: Boolean(data.refreshReportsSynchronously),
        refreshInsightsSynchronously: Boolean(data.refreshInsightsSynchronously),
        graphDashboardHorizontalOrientation: Boolean(data.graphDashboardHorizontalOrientation),

        scenarioNameCaption: StringOrNull(data.scenarioNameCaption),
        scheduleNameCaption: StringOrNull(data.scheduleNameCaption),
        trainingAcademyUrl: StringOrNull(data.trainingAcademyUrl),
        loginAnnouncement: String(data.loginAnnouncement),
        scheduleBuilderType: String(data.scheduleBuilderType),
        industry: data.industry,

        userCustomFields: data.userCustomFields,
        plannedWorkSleepQualities: data.plannedWorkSleepQualities,
        dutyAutoWorkTypes: data.dutyAutoWorkTypes,
        sleepQualities: data.sleepQualities,

        importInputTypes: data.inputTypes,
    }
}

export default MetaData
