import InfoIcon from 'views/Common/Widget/InfoIcon'
import classes from './GraphDashboardMetricsBlock.module.css'

export interface MetricTableRow {
    label: string
    labelTitle?: string
    value: string
    color?: string
    flag?: boolean
    flagText?: string
}

const GraphDashboardMetricsBlock = (props: { marginTop?: string; title: string; rows: MetricTableRow[] }) => {
    return (
        <div className={classes.container} style={{ marginTop: props.marginTop }}>
            <div className={classes.vTextContainer}>
                <p className={classes.vText}>{props.title}</p>
            </div>
            <table className={classes.metricsTable}>
                <tbody>
                    {props.rows.map((row) => {
                        const flagContent = row.flag ? (
                            <InfoIcon
                                icon="bi-flag-fill"
                                style={{ color: '#c32424', cursor: row.flagText ? 'help' : 'move' }}
                                tooltip={row.flagText}
                            />
                        ) : (
                            <div style={{ width: '12px' }} />
                        )
                        return (
                            <tr key={row.label}>
                                <td className={classes.labelCell} title={row.labelTitle}>
                                    {row.label}
                                </td>
                                <td className={classes.valueCell} style={{ backgroundColor: row.color }}>
                                    {row.value}
                                </td>
                                <td style={{ border: 'none' }}>{flagContent}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default GraphDashboardMetricsBlock
