import SessionControl from 'services/axios/SessionControl'
import SFCMock from '../api-mock/sfcMock'
import SFApi from '../api/sfApi'
import { isDesktopMode } from '../axios/axios-sfc'

const setApi = (api: SFApi) => {
    const w = window as any
    w.sfapi = api
}

const getApi = (): SFApi => {
    return (window as any).sfapi as SFApi
}

const createMockApi = () => {
    const api = new SFApi(new SessionControl())
    setApi(api)
}

if (!isDesktopMode() && (window.location.port === '3000' || process.env.JEST_WORKER_ID !== undefined)) {
    // is react dev server or Jest tests, so mock the SFC object
    ;(window as any).safteFastConsole = new SFCMock()
    createMockApi()
}

export default { setApi, getApi }
