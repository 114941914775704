import ScheduleEvent from 'types/ScheduleEvent'
import InfoIcon from 'views/Common/Widget/InfoIcon'
import classes from './AutoEventIcon.module.css'

const AutoEventIcon = ({ scheduleEvent }: { scheduleEvent: ScheduleEvent }) => {
    const requiresAutoIcon =
        scheduleEvent.isAutoMarker() || scheduleEvent.isAutoSleep() || scheduleEvent.eventOwner === 'System'

    if (!requiresAutoIcon) {
        return <div className={classes.emptySpace} />
    }

    return <InfoIcon tooltip="Auto-Event may be automatically adjusted" className={classes.autoEventIcon} />
}

export default AutoEventIcon
