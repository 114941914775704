import { CSSProperties } from 'react'
import ScheduleEvent from 'types/ScheduleEvent'
import IconButton from '../Buttons/IconButton'

const style: CSSProperties = {
    //     border: 'none',
    backgroundColor: '#ddd',
    fontSize: '0.9em',
    marginRight: '4px',
}

/**
 * Duty expand/collapse button for the Schedule Events table on Schedule Details.
 * @param param0
 * @returns
 */
const DutyRollupIconButton = ({
    scheduleEvent,
    isCollapsed,
    clickDutyToggle,
}: {
    scheduleEvent: ScheduleEvent
    isCollapsed: boolean
    clickDutyToggle: (operation: 'expand' | 'collapse', dutyId: string) => void
}) => {
    if (!scheduleEvent.isDutyRollup()) {
        return <></>
    }
    const icon = isCollapsed ? 'bi-chevron-right' : 'bi-chevron-down'
    return (
        <IconButton
            className="grid-button"
            style={style}
            onClick={() => clickDutyToggle(isCollapsed ? 'expand' : 'collapse', scheduleEvent.dutyUuid!)}
            icon={`${icon} grid-button`}
        />
    )
}

export default DutyRollupIconButton
