import React, { CSSProperties } from 'react'
import IconButton from './IconButton'
import { ToolbarButtonHeightPx } from './ToolbarButton'

const IconButtonColumns = (props: React.HtmlHTMLAttributes<HTMLButtonElement>) => {
    let defaultStyle: CSSProperties = {
        height: ToolbarButtonHeightPx,
        color: '#666',
    }

    if (props.style) {
        defaultStyle = { ...defaultStyle, ...props.style }
    }
    return (
        <IconButton
            tooltip="Show/Hide Columns"
            toolbarLeftMargin
            {...props}
            style={defaultStyle}
            icon="bi-layout-three-columns"
        />
    )
}

export default IconButtonColumns
