import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { GraphRightAxisSelections } from 'types/interfaces'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import simpleDialogClasses from 'views/Common/GenericDialogs/SimpleDialog.module.css'
import classes from './GraphOptionsMenu.module.css'

const GraphOptionsMenu = (props: {
    initialSelections: GraphRightAxisSelections
    graphOptionsChanged: (selections: GraphRightAxisSelections) => void
}) => {
    const [selections, setSelections] = useState<GraphRightAxisSelections>(props.initialSelections)

    const optionsChangedCallback = useCallback((s: GraphRightAxisSelections) => props.graphOptionsChanged(s), [props])

    useEffect(() => {
        if (selections === props.initialSelections) {
            return
        }
        optionsChangedCallback(selections)
    }, [props.initialSelections, optionsChangedCallback, selections])

    return (
        <>
            <Form className="p-3" style={{ width: '250px', fontSize: '0.85em' }}>
                <p className={classes.strongLabel}>Right Axis:</p>
                <div className={simpleDialogClasses.checkBoxContainer}>
                    <Form.Group>
                        <Form.Check
                            id="SleepReservoir"
                            label="Sleep Reservoir"
                            type="checkbox"
                            checked={selections.rightAxis === 'SleepReservoir'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setSelections((oldVal) => {
                                    return {
                                        rightAxis: e.target.checked ? 'SleepReservoir' : null,
                                        standardDeviation: oldVal.standardDeviation,
                                    }
                                })
                            }}
                        />
                        <Form.Check
                            id="circadianPhase"
                            label="Circadian Phase"
                            type="checkbox"
                            checked={selections.rightAxis === 'CircadianPhase'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setSelections((oldVal) => {
                                    return {
                                        rightAxis: e.target.checked ? 'CircadianPhase' : null,
                                        standardDeviation: oldVal.standardDeviation,
                                    }
                                })
                            }}
                        />
                        <Form.Check
                            id="goalPhase"
                            label="Goal Phase"
                            type="checkbox"
                            checked={selections.rightAxis === 'GoalPhase'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setSelections((oldVal) => {
                                    return {
                                        rightAxis: e.target.checked ? 'GoalPhase' : null,
                                        standardDeviation: oldVal.standardDeviation,
                                    }
                                })
                            }}
                        />
                        <Form.Check
                            id="outOfPhase"
                            label="Out of Phase"
                            type="checkbox"
                            checked={selections.rightAxis === 'OutOfPhase'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setSelections((oldVal) => {
                                    return {
                                        rightAxis: e.target.checked ? 'OutOfPhase' : null,
                                        standardDeviation: oldVal.standardDeviation,
                                    }
                                })
                            }}
                        />
                        <Form.Check
                            id="sleepIntensity"
                            label="Sleep Intensity"
                            type="checkbox"
                            checked={selections.rightAxis === 'SleepIntensity'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setSelections((oldVal) => {
                                    return {
                                        rightAxis: e.target.checked ? 'SleepIntensity' : null,
                                        standardDeviation: oldVal.standardDeviation,
                                    }
                                })
                            }}
                        />
                        <Form.Check
                            id="lapseIndex"
                            label="Lapse Index"
                            type="checkbox"
                            checked={selections.rightAxis === 'LapseIndex'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setSelections((oldVal) => {
                                    return {
                                        rightAxis: e.target.checked ? 'LapseIndex' : null,
                                        standardDeviation: oldVal.standardDeviation,
                                    }
                                })
                            }}
                        />
                        <Form.Check
                            id="workload"
                            label="Workload"
                            type="checkbox"
                            checked={selections.rightAxis === 'Workload'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setSelections((oldVal) => {
                                    return {
                                        rightAxis: e.target.checked ? 'Workload' : null,
                                        standardDeviation: oldVal.standardDeviation,
                                    }
                                })
                            }}
                        />
                        <Form.Check
                            id="bac"
                            label="Blood Alcohol Concentration"
                            type="checkbox"
                            checked={selections.rightAxis === 'BAC'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setSelections((oldVal) => {
                                    return {
                                        rightAxis: e.target.checked ? 'BAC' : null,
                                        standardDeviation: oldVal.standardDeviation,
                                    }
                                })
                            }}
                        />
                    </Form.Group>
                </div>
                <p className={classes.strongLabel}>Line Overlay:</p>
                <div className={simpleDialogClasses.checkBoxContainer}>
                    <Form.Group style={{ marginBottom: '0' }}>
                        <Form.Check
                            id="StandardDeviation"
                            label="Standard Deviation"
                            type="checkbox"
                            checked={selections.standardDeviation}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setSelections((oldVal) => {
                                    return {
                                        rightAxis: oldVal.rightAxis,
                                        standardDeviation: e.target.checked,
                                    }
                                })
                            }}
                        />
                    </Form.Group>
                </div>
            </Form>
        </>
    )
}

export const GraphSettingsDialog = (props: {
    initialSelections: GraphRightAxisSelections
    closeCallback: (status: DialogResultEnum) => void
    graphOptionsChanged: (selections: GraphRightAxisSelections) => void
}) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Graph Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GraphOptionsMenu
                        initialSelections={props.initialSelections}
                        graphOptionsChanged={props.graphOptionsChanged}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCustom
                        isLarge
                        variant="primary"
                        type="submit"
                        onClick={() => {
                            props.closeCallback(DialogResultEnum.Cancelled)
                        }}
                    >
                        Close
                    </ButtonCustom>
                </Modal.Footer>
            </>
        </ModalWrapper>
    )
}

export default GraphOptionsMenu
