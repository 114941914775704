/* eslint-disable @typescript-eslint/no-unused-vars */
import { HazardClass } from 'types/HazardClass'
import { Report } from 'types/Reports'
import { ScenarioParameters } from 'types/Scenario'
import { SFC } from 'types/SFC'

// conditionally load mock data if development environment.  This avoids including it in the production build.
const mockdata = process.env.NODE_ENV === 'development' ? require('./mockdata') : {}

class SFCMock implements SFC {
    getRecalculationStatus(recalculateRequestIds: string): Promise<string> {
        return new Promise((resolve) => resolve('[]'))
    }
    recalculate(request: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    cancel?(): void {
        throw new Error('Method not implemented.')
    }
    notify?(notificationType: string, notificationSubtype: string, payload: string): void {
        throw new Error('Method not implemented.')
    }
    launchHelp?(): void {
        throw new Error('Method not implemented.')
    }
    launchScheduleDetailsEditor?(scheduleId: number): void {
        throw new Error('Method not implemented.')
    }
    getLastErrorMessage(): Promise<string> {
        return new Promise((resolve) => resolve(''))
    }
    getUtcOffsetMinutes(unusedCode: string, unusedTime: number): Promise<string> {
        // a few mock values
        if (unusedCode.toLowerCase() === 'yyz') {
            return new Promise((resolve) => {
                resolve(JSON.stringify(-300))
            })
        }
        if (unusedCode.toLowerCase() === 'yvr') {
            return new Promise((resolve) => {
                resolve(JSON.stringify(-420))
            })
        }
        if (unusedCode.toLowerCase() === 'yhz') {
            return new Promise((resolve) => {
                resolve(JSON.stringify(-180))
            })
        }
        return new Promise((resolve) => {
            resolve(JSON.stringify(300))
        })
    }
    updateInsightsDashboardConfig(unused: any): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify({}))
        })
    }
    getScheduleParameters(): Promise<string> {
        const parameters: ScenarioParameters = {
            autoNap: true,
            awakeZoneEnd: '1600',
            awakeZoneStart: '1300',
            bedtime: '2300',
            commute: 60,
            commuteType: 'VariableEvents',
            criterionLevel: 77,
            editSleep: false,
            eventLabel: 'EventLabel',
            ignoreExplicitSleep: false,
            maxRestDaySleep: 480,
            maxWorkDaySleep: 480,
            minimumSleep: 480,
            percentBelowCriterionThreshold: 100,
            plannedWorkSleep: false,
            plannedWorkSleepAutoSleep: false,
            plannedWorkSleepRules: [],
        }
        return new Promise((resolve) => {
            resolve(JSON.stringify(parameters))
        })
    }
    updateScheduleParameters(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    resetExplicitSleep(): Promise<string> {
        throw new Error('Method not implemented.')
    }
    editDuty(unused: any): Promise<string> {
        const response = {
            message: null,
            schedule: mockdata.getSchedules()[0],
        }
        return new Promise((resolve) => {
            resolve(JSON.stringify(response))
        })
    }
    editDutyDates(): Promise<string> {
        const response = {
            message: null,
            schedule: mockdata.getSchedules()[0],
        }
        return new Promise((resolve) => {
            resolve(JSON.stringify(response))
        })
    }
    resetReportsDashboard(): Promise<string> {
        return this.getReports()
    }
    resetInsightsDashboard(): Promise<string> {
        return this.getInsights()
    }
    updateReportsDashboardOrder(unused: any): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify({}))
        })
    }
    updateInsightsDashboardOrder(unused: any): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify({}))
        })
    }
    updatePatternProperties(unused: any): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify({}))
        })
    }
    addLibraryReportsToDashboard(unused: any): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getAddReportFromLibraryResponse))
        })
    }
    addLibraryHazardClassesToDashboard(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    getInsightsLibrary(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getInsightsLibrary()))
        })
    }
    getInsightsLibraryDefaults(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getInsightsLibraryDefaults()))
        })
    }
    updateInsightsLibrary(unused: any): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify({}))
        })
    }
    getReportsLibrary(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getReportsLibrary()))
        })
    }
    getReportDrilldownData(unused: any): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getReportDrilldown()))
        })
    }
    getReportsLibraryDefaults(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getReportsLibraryDefaults()))
        })
    }
    updateReportsLibrary(unused: string): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify({}))
        })
    }
    updateScheduleSettingsForAnalysis(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    createOrUpdateShiftsSchedule(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    updatePatternsShifts(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    updateScheduleSettings(unused: any): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify({}))
        })
    }
    updateLastViewed(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    deleteHazardClass(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    deleteReport(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    updateReport(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    createReport(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    createOrUpdateHazardClass(unused: any): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify({}))
        })
    }
    getReportData(unused: any): Promise<string> {
        // for mocking, simply show the same report all the time
        const firstReport: Report = mockdata.getReports()[0]!
        return new Promise((resolve) => {
            resolve(JSON.stringify(firstReport))
        })
    }
    deleteEvent(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    createEvent(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    updateScheduleAndReanalyze(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    editEvent(unused: any): Promise<string> {
        throw new Error('Method not implemented.')
    }
    isMock: boolean = true
    getPatternsShifts(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getPatternsShiftsJson()))
        })
    }
    getReportsDashboardConfig(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getReportsDashboard()))
        })
    }
    getReportsMetadata(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getReportsMetadata()))
        })
    }
    getInsightsMetadata(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getInsightsMetadata()))
        })
    }
    getReports(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getReports()))
        })
    }
    getInsights(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getInsights()))
        })
    }
    getHazardClass(): Promise<string> {
        const mockHazardClass = mockdata.getInsights()[0] as HazardClass
        mockHazardClass.casesJson = mockdata.getInsightsCases()
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockHazardClass))
        })
    }
    getInsightsDashboardConfig(): Promise<string> {
        return new Promise((resolve) => {
            const reportsDashboard = mockdata.getReportsDashboard()
            resolve(JSON.stringify({ ...reportsDashboard, hazardClassesSort: 'Custom' }))
        })
    }
    getScheduleWithDetails(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getScheduleDetails()))
        })
    }
    deleteScenarios(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(true))
        })
    }
    postNewEventsSchedule(): Promise<string> {
        const response = {
            message: null,
            schedule: mockdata.getSchedules()[0],
        }
        return new Promise((resolve) => {
            resolve(JSON.stringify(response))
        })
    }
    getStationByCode(unusedCode: string): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getStationByCode()))
        })
    }
    getNewScheduleDefaultsForScenario(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getNewScheduleDefaults()))
        })
    }
    getStationsByPartialTextMatch(code: string): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getStationsByPartialTextMatch(code)))
        })
    }
    postImportScenario(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(''))
        })
    }
    getFileProcessingRecords(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getFileProcessingRecords()))
        })
    }
    getAnyRecalculationRecordsForUser(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getRecalculationRequests()))
        })
    }

    getScenarioSchedules(unusedScenarioId: number): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getSchedules()))
        })
    }
    signOut(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(true))
        })
    }
    getUsers(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getUsers()))
        })
    }
    getMetadata(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getMetadata()))
        })
    }

    authenticateToken(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(true))
        })
    }

    authenticateCredentials(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(true))
        })
    }
    getScenariosList(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getScenarios()))
        })
    }
    getScenariosWithMetrics(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getScenarios()))
        })
    }
    getScenarioById(): Promise<string> {
        return new Promise((resolve) => {
            resolve(JSON.stringify(mockdata.getScenarios()[0]))
        })
    }

    getEventFilterBuilderConfig(): Promise<string> {
        const configItems = mockdata.getReportsMetadata().xAxisItems.events as any[]
        configItems.forEach((x) => {
            x.type = x.type.toLowerCase()
        })
        const firstReport: Report = mockdata.getReports()[0]!
        return new Promise((resolve) => {
            resolve(JSON.stringify({ rules: firstReport!.configOptions!.queryBuilderRules!, configItems }))
        })
    }
}

export default SFCMock
