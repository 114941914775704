import dateTimeFormatting from 'services/formatting/dateTimeFormatting'

export const isShiftNameInvalid = (shift: Shift) => !shift.name || shift.name.trim().length === 0
export const isShiftTypeInvalid = (shift: Shift) => shift.duration > 0 && !shift.shiftType

export const sortShifts = (shifts: Shift[]) => {
    shifts.sort((shift1, shift2) => {
        if (shift1.shiftDayNumber < shift2.shiftDayNumber) {
            return -1
        }
        if (shift1.shiftDayNumber > shift2.shiftDayNumber) {
            return 1
        }

        if (shift1.startTime < shift2.startTime) {
            return -1
        }
        if (shift1.startTime > shift2.startTime) {
            return 1
        }
        return 0
    })
}

export const getShiftsHighestDayNumber = (shifts: Shift[]): number => {
    if (shifts.length === 0) {
        return 0
    }
    return Math.max(...shifts.map((x) => x.shiftDayNumber))
}

/**
 * Update all the shifts that are 'checked' in the grid to match the given one.  This is a UI bulk operation.
 * @param shiftSeg
 */
export const updateShiftToMatchSegment = (shift: Shift, shiftSeg: Shift) => {
    shift.name = shiftSeg.name
    shift.startTime = shiftSeg.startTime
    shift.calculatedEndTime = shiftSeg.calculatedEndTime
    shift.duration = shiftSeg.duration
    shift.shiftType = shiftSeg.shiftType
    shift.sourceShiftId = shiftSeg.id
}

export const createShift = (id: number, dayNumber: number): Shift => {
    return {
        id,
        name: '',
        shiftDayNumber: dayNumber,
        startTime: '00:00',
        calculatedEndTime: '00:00',
        calculatedScheduleDateString: '',
        duration: 0,
        shiftType: '',
        sourceShiftId: 0,
    }
}

export const setScheduleShiftDate = (shift: Shift, scheduleDate: Date) => {
    const shiftDate = new Date(scheduleDate.getFullYear(), scheduleDate.getMonth(), scheduleDate.getDay())
    shiftDate.setDate(scheduleDate.getDate() + shift.shiftDayNumber - 1)
    shift.calculatedScheduleDateString =
        shiftDate.toLocaleString('en-us', { weekday: 'short' }) +
        ' ' +
        (shiftDate.getMonth() + 1) +
        '/' +
        shiftDate.getDate()
}

export const parseShift = (data: any, scheduleStart?: Date) => {
    const shift = data as Shift
    if (scheduleStart) {
        setScheduleShiftDate(shift, scheduleStart)
    }
    const startDate = dateTimeFormatting.formatTimeStringAsDate(shift.startTime)
    // calculate the end time from the duration
    const endDate = new Date(startDate.getTime() + shift.duration * 1000 * 60)
    shift.calculatedEndTime = dateTimeFormatting.formatDateAsTimeString(endDate)
    return shift
}

export const parseShiftSchedule = (data: any): ShiftSchedule => {
    const shiftSchedule = data as ShiftSchedule
    shiftSchedule.patternId = parseInt(data.patternId)
    shiftSchedule.lastModified = new Date(Date.parse(data.lastModified))
    // why do we need to change from UTC format time (replacing Z below)?  Came from v5
    shiftSchedule.startDate = new Date(Date.parse(data.startDate.replace('Z', '')))

    const shifts = shiftSchedule.shifts
    for (let i = 0; i < shifts.length; i++) {
        shifts[i] = parseShift(shifts[i], shiftSchedule.startDate)
    }
    return shiftSchedule
}

export interface PatternsShifts {
    patterns: Pattern[]
    shiftSegments: Shift[]
    shiftTypeList: string[]
}

export interface ShiftSchedule {
    scenarioId: number
    scheduleName: string
    scheduleId: number
    baseCode: string
    startDate: Date
    patternId: number
    duration: number
    lastModified: Date
    shifts: Shift[]
}

export interface Pattern {
    id: number
    uuid: string
    name: string
    duration: number
    shifts: Shift[]
}

export interface Shift {
    id: number
    startTime: string
    name: string
    duration: number
    shiftDayNumber: number
    shiftType: ShiftType | string
    sourceShiftId: number
    calculatedScheduleDateString: string
    calculatedEndTime: string
}

export type ShiftType = 'Crewing' | 'Non-Crewing' | 'Marker' | 'Critical-Marker' | 'Sleep'
