import { Suspense } from 'react'
import { Route, Switch } from 'react-router'
import { isDesktopMode } from 'services/axios/axios-sfc'
import Login from 'views/Login/LoginPage'
import ResetPassword from 'views/ResetPassword/ResetPassword'

const PublicRoutes = (props: {
    isLoggedIn: boolean | null
    loginHandler: (emailAddress: string, password: string, keepMeLoggedIn: boolean) => void
}) => {
    const login = isDesktopMode() || props.isLoggedIn === null ? null : <Login onLogin={props.loginHandler} />
    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Switch>
                <Route exact path="/setpasswordrequest">
                    <ResetPassword mode="RequestReset" />
                </Route>
                <Route path="/setpassword/:token">
                    <ResetPassword mode="SetNewPassword" />
                </Route>
                <Route path="*">{login}</Route>
            </Switch>
        </Suspense>
    )
}

export default PublicRoutes
