import { isDesktopMode } from 'services/axios/axios-sfc'
import InfoIcon from 'views/Common/Widget/InfoIcon'
import { ButtonCustomProps } from './ButtonCustom'
import IconButton from './IconButton'

export interface RefreshButtonProps extends ButtonCustomProps {
    isRefreshing: boolean
    requiresRefresh: boolean
    /**
     * The recalculation has just finished, but data has not yet been reloaded, so don't show
     * the ui state indicating refresh is required
     */
    refreshJustFinished?: boolean
    useSynchronousRefreshOnly: boolean
    isIconButton?: boolean
}

const NeedsRefreshTooltip = isDesktopMode() ? 'Refresh Data' : 'Data needs to be refreshed. Click to refresh now.'

const RefreshButton = (props: RefreshButtonProps) => {
    const NeedsRefresh = isDesktopMode() || props.requiresRefresh
    // this iconButton mode is a small (!) icon that appears on report thumbnails
    if (props.isIconButton) {
        if (props.requiresRefresh && !props.refreshJustFinished) {
            const iconVariant = props.isRefreshing ? 'blinkingGreenIcon' : 'text-warning'
            return (
                <button
                    type="button"
                    style={{}}
                    className="transparentButton pointerCursor"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault()
                        props.onClick!(e)
                    }}
                    disabled={props.isRefreshing}
                >
                    <InfoIcon
                        tooltip={NeedsRefresh ? NeedsRefreshTooltip : ''}
                        icon="bi-exclamation-circle-fill"
                        variant={iconVariant}
                        style={{ marginRight: '4px', color: 'rgba(224,224,224,0.356)' }}
                    />
                </button>
            )
        }
        // hide it
        return <></>
    }

    const color = props.requiresRefresh && !props.isRefreshing ? '#e0a800' : ''
    const blinkingClass = props.isRefreshing ? 'blinkingGreenButton' : ''
    return (
        <>
            <IconButton
                tooltip={NeedsRefresh ? NeedsRefreshTooltip : ''}
                toolbarLeftMargin
                disabled={
                    !enableRefreshButton(props.isRefreshing, props.useSynchronousRefreshOnly, props.requiresRefresh)
                }
                className={blinkingClass}
                style={{ backgroundColor: color }}
                onClick={props.onClick}
                icon="bi-arrow-clockwise"
            />
        </>
    )
}

const enableRefreshButton = (isRefreshing: boolean, useSynchronousRefreshOnly: boolean, requiresRefresh: boolean) =>
    !isRefreshing && (isDesktopMode() || useSynchronousRefreshOnly || requiresRefresh)

export { enableRefreshButton }
export default RefreshButton
