import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CSSProperties } from 'react'
import { Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import globals from 'services/global/globals'
import { RootState } from 'store/store'
import ReportingMetadata from 'types/ReportingMetadata'
import { Report } from 'types/Reports'
import ReportDashboardThumbnail from './ReportDashboardThumbnail'

const DraggableReportDashboardThumbnail = (props: {
    report: Report
    reportingMetadata: ReportingMetadata
    isDraggingReportId: number
    onDeleteClick: () => void
    onEditClick: () => void
    isRefreshing: boolean
}) => {
    const dispatch = useDispatch()
    const isPrintPreview = useSelector<RootState, boolean>((x) => x.app.isPrintPreview)
    const processingApi = globals.getApi().getProcessingApi()

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.report.id.toString() })

    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <Col
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            key={props.report.id.toString()}
            xs="12"
            lg={isPrintPreview ? 12 : 6}
            className="pl-0 pr-3"
        >
            <ReportDashboardThumbnail
                isRefreshing={props.isRefreshing}
                isDraggingReportId={props.isDraggingReportId}
                report={props.report}
                metadata={props.reportingMetadata}
                onRefreshClick={() => {
                    processingApi.beginItemRefresh([props.report.id], 'Report', dispatch)
                }}
                onDeleteClick={props.onDeleteClick}
                onEditClick={props.onEditClick}
            />
        </Col>
    )
}

export default DraggableReportDashboardThumbnail
