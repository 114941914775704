import { ChangeEvent } from 'react'
import { FormCheck } from 'react-bootstrap'
import { ScenarioSelection } from 'types/ScenarioSelection'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'

export type ScenarioSelectionOptions = {
    override: boolean
    combineScenarios: boolean
    separateHazardClassPerScenario: boolean
    selection: ScenarioSelection
}

export type ScenarioSelectorProps = {
    options: ScenarioSelectionOptions
    showCombineScenariosOption?: boolean
    showSeparateHazardClassPerScenarioOption?: boolean
    updateSelections: (options: ScenarioSelectionOptions) => void
    selectScenariosClicked: () => void
}
const ScenarioSelectorButton = ({
    options,
    showCombineScenariosOption,
    showSeparateHazardClassPerScenarioOption,
    updateSelections: updateScenarioSelection,
    selectScenariosClicked: showDialogClicked,
}: ScenarioSelectorProps) => {
    let descriptiveText = 'Scenario selection is currently controlled at the Dashboard level'
    const scenarioSelection = options.selection
    if (options.override) {
        const selectedScenariosCount = scenarioSelection.selectedScenarioIds.split(',').filter((x) => x).length
        descriptiveText = 'Override selection: '
        if (scenarioSelection.includeAllMyScenarios && scenarioSelection.includeAllScenariosSharedwMe) {
            descriptiveText += 'All current & future scenarios created by and shared with me'
        } else if (
            scenarioSelection.includeAllMyScenarios &&
            scenarioSelection.includeAllScenariosSharedwMe === false
        ) {
            descriptiveText += 'All current & future scenarios created by me'
        } else if (
            scenarioSelection.includeAllMyScenarios === false &&
            scenarioSelection.includeAllScenariosSharedwMe
        ) {
            descriptiveText += 'All current & future scenarios shared with me'
        } else if (selectedScenariosCount > 0) {
            descriptiveText += selectedScenariosCount + ' scenarios currently selected'
        } else {
            descriptiveText += "No scenarios currently selected (must 'Select Scenarios' before saving)"
        }
    }
    return (
        <>
            <FormCheck
                style={{ marginBottom: '5px' }}
                id="chkOverrideDashboardSelection"
                label="Override Dashboard Scenario Selection"
                type="checkbox"
                checked={options.override}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateScenarioSelection({ ...options, override: e.target.checked })
                }}
            />
            <ButtonCustom
                type="button"
                variant="white"
                size="sm"
                disabled={!options.override}
                onClick={() => showDialogClicked()}
                tooltip="Select Scenarios for this Report Only"
            >
                Select Scenarios
            </ButtonCustom>

            <div>
                <small className={`${options.override ? '' : 'text-muted'}`}>{descriptiveText}</small>
            </div>
            {showCombineScenariosOption && (
                <FormCheck
                    id="chkCombine"
                    label="Combine Scenarios into one data set"
                    checked={options.combineScenarios}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        updateScenarioSelection({ ...options, combineScenarios: e.target.checked })
                    }}
                />
            )}

            {showSeparateHazardClassPerScenarioOption && (
                <FormCheck
                    id="chkSeparateHazardClasses"
                    label="Separate Hazard Class per Scenario"
                    checked={options.separateHazardClassPerScenario}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        updateScenarioSelection({ ...options, separateHazardClassPerScenario: e.target.checked })
                    }}
                />
            )}
        </>
    )
}

export default ScenarioSelectorButton
