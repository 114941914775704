export type ActivityType = 'awake' | 'sleep' | 'work/sleep' | 'work/marker' | 'work' | 'marker' | 'automarker'

export type DetailDataTime = Pick<DetailData, 'locationName' | 'tz' | 'tzBase' | 'utcTime'>

export interface DetailDataWithPosition extends DetailData {
    pixelPos: number
}

export interface DetailData {
    locationName: string
    lightPhase: number
    utcTime: Date
    activity: ActivityType
    crewing: boolean
    critical: boolean
    effectiveness: number
    tz: number
    tzBase: number
    lowStandardDeviation: number
    highStandardDeviation: number
    isNewActivityBoundary: boolean
    sleepReservoir: number
    goalPhase: number
    relativePhase: number
    relativeGoalPhase: number
    sleepIntensity: number
    lapseIndex: number
    workload: number
    sleep24Mins: number
    sleepDebtMins: number
    minsAwake: number
    currentPhase: number
    outOfPhaseHrs: number
    cog: number
    samnPerelli: number
    kss: number
    isWocl: boolean
    woclStart: number
    woclEnd: number
    reactionTime: number
    workLabel: string
    markerLabel: string
    sleepLabel: string
}

export const isAwake = (d: DetailData) => d.activity === 'awake'
export const isNotCrewing = (d: DetailData) => d.activity === 'work' && !d.crewing
export const isCrewing = (d: DetailData) => d.activity === 'work' && d.crewing
export const isMarker = (d: DetailData) => d.activity === 'marker'
export const isCriticalMarker = (d: DetailData) => d.activity === 'marker' && d.critical
export const isAutoMarker = (d: DetailData) => d.activity === 'automarker' && !d.critical
export const isCriticalAutoMarker = (d: DetailData) => d.activity === 'automarker' && d.critical
export const isSleep = (d: DetailData) => d.activity === 'sleep'
export const isWorkSleep = (d: DetailData) => d.activity === 'work/sleep'
export const isWorkMarker = (d: DetailData) => d.activity === 'work/marker'
