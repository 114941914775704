import { GridCellProps } from '@progress/kendo-react-grid'
import { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'
import { isDesktopMode } from 'services/axios/axios-sfc'
import { User } from 'types/interfaces'
import { Report } from 'types/Reports'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { getClickableSharingIcon, isSharedItem } from 'views/Common/Kendo/FormattedNameCell'

export type DialogMode = 'Library' | 'ReportSelection'

/**
 * Get the columns for the kendo grid in the report library dialog
 */
const getColumns = (
    user: User,
    dialogMode: DialogMode,
    reportClicked: (report: Report) => void,
    sharingIconClicked: (report: Report) => void,
    updateIncludeAsDefault: (reportId: number, checked: boolean) => void,
) => {
    let columnDefinitions: KendoGridColumn[] = [
        {
            field: 'name',
            title: 'Name',
            width: '400px',
        },
        {
            field: 'includeAsDefault',
            title: 'Is Dashboard Default',
            cell: (props: GridCellProps) => {
                const report = props.dataItem as Report
                return (
                    <td style={{ textAlign: 'center' }}>
                        <Form.Check
                            type="checkbox"
                            defaultChecked={report.includeAsDefault}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                updateIncludeAsDefault(report.id, e.target.checked)
                            }}
                        />
                    </td>
                )
            },
        },
        {
            field: 'createdByName',
            title: 'Owner',
            cellClassName: 'center-aligned',
        },
    ]

    // Library mode needs reports to be editable, so make a link-style button
    if (dialogMode === 'Library') {
        columnDefinitions.find((x) => x.field === 'name')!.cell = (props: GridCellProps) => {
            const report = props.dataItem as Report
            const isUserOwner = report.createdById === user!.id
            const isSfc = isDesktopMode(true)
            // Enabled when the user has Edit permission OR
            // am I owner of the shared item OR
            // is the current user the owner of the report
            const isEnabled =
                report.permission.sharedWithMePermission === 'Edit' ||
                report.permission.amOwnerOfSharedItem ||
                isUserOwner ||
                isSfc
            const isShared = isSharedItem(report.permission)
            return (
                <td>
                    <TransparentButton
                        style={{ textAlign: 'left' }}
                        onClick={() => {
                            if (isEnabled) {
                                reportClicked(report)
                            }
                        }}
                    >
                        {report.name}
                        {isShared &&
                            getClickableSharingIcon(report.permission, 'report', (e: React.MouseEvent<HTMLElement>) => {
                                e.preventDefault()
                                e.stopPropagation()
                                sharingIconClicked(report)
                            })}
                    </TransparentButton>
                </td>
            )
        }
    }

    if (dialogMode === 'ReportSelection') {
        columnDefinitions = columnDefinitions.filter((x) => x.field !== 'includeAsDefault')
    }

    if (isDesktopMode()) {
        columnDefinitions = columnDefinitions.filter((x) => x.field !== 'createdByName')
    }

    return columnDefinitions
}

export default getColumns
