import '@progress/kendo-theme-default/dist/all.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
// We use react-bootstrap, but it doesn't provide its own copy of BS.  As per
// docs, we install BS separately and add reference like so.
import 'bootstrap/dist/css/bootstrap.min.css'
import 'hammerjs' // needed for Kendo charts
import useSessionStatus from 'hooks/useSessionStatus'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AuthenticatedRoutes from 'routing/AuthenticatedRoutes'
import PublicRoutes from 'routing/PublicRoutes'
import checkExistingRecalculationRequests from 'services/api/checkExistingRecalculationRequests'
import SFApi from 'services/api/sfApi'
import { isDesktopMode } from 'services/axios/axios-sfc'
import SessionControl from 'services/axios/SessionControl'
import * as cookies from 'services/cookies/cookies'
import globals from 'services/global/globals'
import { handleApiError } from 'services/utilities/toastrUtils'
import { globalActions } from 'store/globalStore'
import { RootState } from 'store/store'
import MetaData from 'types/Metadata'
import ErrorDialog from 'views/Common/GenericDialogs/ErrorDialog'
import LoadingSpinner from 'views/Common/GenericDialogs/LoadingSpinner'
import Navbar from 'views/Common/Layout/Navbar'
import ToastCustom from 'views/Common/Widget/ToastCustom'
import 'views/Common/Widget/ToastifyOverrides.css'

// react lazy loading
// const Scenarios = React.lazy(() => import('views/Scenarios/ScenariosPage'))
// const SchedulesPage = React.lazy(() => import('views/Schedules/SchedulesPage'))
// const ScheduleDetailsPage = React.lazy(() => import('views/Schedules/ScheduleDetails/ScheduleDetailsPage'))

const App = () => {
    const history = useHistory()
    const showLoadingModal = useSelector<RootState>((x) => x.app.showLoadingModal)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [, setNeedsRerender] = useState<Date | null>(null)
    const [sfMetadata, setSfMetadata] = useState<MetaData>()
    const userState = useSessionStatus()
    const dispatch = useDispatch()

    const sessionControl: SessionControl = useMemo(() => {
        const sessionExpiredHandler = (sessionExpiredError: string) => {
            dispatch(globalActions.setIsSessionExpired())
            cookies.deleteSessionCookie()
            toast.error(sessionExpiredError)
        }

        const isLoggedInHandler = (loggedIn: boolean) => {
            if (loggedIn) {
                dispatch(globalActions.setIsLoggedIn())
            } else {
                dispatch(globalActions.setIsLoggedOut())
            }
        }

        const passwordExpiredHandler = () => {
            history.push('/setpasswordrequest')
            setNeedsRerender(new Date())
        }

        const sessionControlObj = new SessionControl(isLoggedInHandler, sessionExpiredHandler, passwordExpiredHandler)

        return sessionControlObj
    }, [dispatch, setNeedsRerender, history])

    const api = useMemo(() => new SFApi(sessionControl), [sessionControl])
    globals.setApi(api)

    // If Desktop mode, check to see what page we are going to.
    useEffect(() => {
        if (isDesktopMode()) {
            api.notifyDesktopLoadingComplete()
        }
    }, [api])

    const getMetadata = useCallback(() => {
        async function fetchData() {
            const metatadata = await api.getMetadata()
            setSfMetadata(metatadata)
            dispatch(globalActions.setMetadata(metatadata))
            if (!isDesktopMode()) {
                checkExistingRecalculationRequests(dispatch)
            }
        }
        fetchData()
    }, [dispatch, api])

    const loginHandler = async (emailAddress: string, password: string, keepMeLoggedIn: boolean) => {
        try {
            const authToken = await api.authenticateCredentials(emailAddress, password, keepMeLoggedIn)
            dispatch(globalActions.setUser(authToken.user))
            await getMetadata()
        } catch (error: any) {
            setErrorMessage(error.message)
        }
    }

    const logoutHandler = async () => {
        try {
            await api.signOut()
            setNeedsRerender(new Date())
        } catch (error: any) {
            setErrorMessage(error.message)
        }
    }

    useEffect(() => {
        const authenticateSession = async () => {
            try {
                const sessionToken = cookies.getSessionCookie()
                if (!sessionToken) {
                    dispatch(globalActions.setIsLoggedOut())
                } else {
                    const token = await api.authenticateToken(sessionToken)
                    if (token) {
                        await getMetadata()
                        dispatch(globalActions.setUser(token.user))
                    } else {
                        dispatch(globalActions.setIsLoggedOut())
                    }
                }
            } catch (err: any) {
                handleApiError(err)
            }
        }

        const isSfc = isDesktopMode(true)
        if (isSfc) {
            getMetadata()
            dispatch(globalActions.setIsLoggedIn())
        } else {
            authenticateSession()
        }
    }, [api, sessionControl, setNeedsRerender, getMetadata, dispatch])

    const errorDialog = errorMessage && (
        <ErrorDialog closeCallback={() => setErrorMessage(null)} message={errorMessage} />
    )

    if (userState === 'Unknown') {
        // when the user first hits the site and we haven't yet determined if they
        // have a session cookie still; don't want to show the login page for a brief second as
        // it looks flickery.  So return nothing.
        return <></>
    }

    // if (userState === '' !isLoggedIn || isSessionExpired) {
    if (userState === 'LoggedOut' || userState === 'SessionExpired') {
        return (
            <>
                {errorDialog}
                <PublicRoutes isLoggedIn={false} loginHandler={loginHandler} />
                <ToastCustom />
            </>
        )
    }

    if (!sfMetadata) {
        // just show the loading modal, the app hasn't loaded yet
        return <LoadingSpinner />
    }
    return (
        <>
            {showLoadingModal && <LoadingSpinner />}
            {!isDesktopMode() && <Navbar />}
            <AuthenticatedRoutes logoutHandler={logoutHandler} />
            <ToastCustom />
        </>
    )
}

export default App
