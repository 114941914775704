import { useEffect } from 'react'
import { useHistory } from 'react-router'

function Logout(props: { logoutHandler: () => void }) {
    const history = useHistory()
    useEffect(() => {
        const logout = async () => {
            await props.logoutHandler()
            history.replace('/')
        }
        logout()
    }, [props, history])

    return null
}

export default Logout
