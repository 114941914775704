import { ReactElement } from 'react'
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import InfoIcon from 'views/Common/Widget/InfoIcon'
import classes from './EllipsisDropdown.module.css'

const EllipsisDropdownHeader = (props: { bootstrapIconClass?: string; children: React.ReactNode }) => {
    const icon = props.bootstrapIconClass ? <i className={props.bootstrapIconClass} style={{ marginRight: 5 }} /> : null
    return (
        <Dropdown.Header>
            {icon}
            {props.children}
        </Dropdown.Header>
    )
}

const DropdownItemElement = (props: any) => {
    return <div {...props}>{props.children}</div>
}

export const EllipsisDropdownItem = (props: {
    onClick: (e: React.MouseEvent<HTMLElement>) => void
    checked?: boolean
    children: React.ReactNode
    disabled?: boolean
    tooltip?: string
}) => {
    const checkAndSpace = props.checked ? <i className="bi-dot" style={{ fontSize: '1.1em' }} /> : null

    return (
        <div style={{ cursor: props.disabled ? 'default' : 'pointer' }} title={props.tooltip}>
            <Dropdown.Item as={DropdownItemElement} onClick={props.onClick} disabled={props.disabled}>
                {checkAndSpace}
                {props.children}
            </Dropdown.Item>
        </div>
    )
}

export const ItemWithIcon = (props: { children: string; bootstrapIconClass: string }) => {
    return (
        <div style={{ fontSize: '14px' }}>
            <i className={props.bootstrapIconClass} style={{ marginRight: '5px', color: '#444' }} />
            {props.children}
        </div>
    )
}

export const IndentedDropdownItem = (props: { children: string; checked?: boolean; checkBootstrapIcon?: string }) => {
    const indentPx = !props.checked ? 24 : 6
    const checkAndSpace = props.checked ? (
        <i className={`${props.checkBootstrapIcon || 'bi-dot'}`} style={{ fontSize: '1.1em' }} />
    ) : null
    return (
        <div style={{ marginLeft: indentPx, fontSize: '14px' }}>
            {checkAndSpace}
            {props.children}
        </div>
    )
}

const EllipsisDropdown = (props: { children: ReactElement<DropdownItem> | ReactElement<DropdownItem>[] }) => {
    return (
        <span title="More Options..." style={{ marginLeft: 6 }}>
            <DropdownButton
                as={ButtonGroup}
                size="sm"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault()
                }}
                variant="white"
                className={classes.dropdown}
                style={{ borderRadius: 4, height: 30, width: 32 }}
                title={<InfoIcon icon="bi-three-dots-vertical" />}
            >
                <span title="">{props.children}</span>
            </DropdownButton>
        </span>
    )
}

export { EllipsisDropdownHeader }
export default EllipsisDropdown
