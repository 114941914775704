import { FormEvent, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'

const SingleInputDialog = (props: {
    dialogTitle: string
    inputPlaceholder: string
    inputType: 'number' | 'text'
    defaultValue: string
    closeCallback: (result: DialogResultEnum, newValue?: string) => void
}) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [validated, setValidated] = useState(false)

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()
        const form = event.target as HTMLFormElement

        if (form.checkValidity() === false) {
            setValidated(true)
            // keep the form open, let the user fix the issues
            return
        }

        props.closeCallback(DialogResultEnum.Completed, inputRef.current!.value)
    }

    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <Form noValidate validated={validated} onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.dialogTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body key="dialogBody">
                    <Form.Control
                        ref={inputRef}
                        id="txtInput"
                        name="txtInput"
                        autoFocus
                        autoComplete="off"
                        type={props.inputType}
                        defaultValue={props.defaultValue}
                        placeholder={props.inputPlaceholder}
                        required
                    />
                    <Form.Control.Feedback type="invalid">Please provide value</Form.Control.Feedback>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCustom isLarge type="submit" variant="primary">
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </ModalWrapper>
    )
}

export default SingleInputDialog
