import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'store/store'
import ReportingMetadata from 'types/ReportingMetadata'
import { Report } from 'types/Reports'
import DashboardThumbnailControlButtons from 'views/Common/Buttons/DashboardThumbnailControlButtons'
import ReportChart from './ReportChart'

const ReportDashboardThumbnail = ({
    report,
    metadata,
    isRefreshing,
    isDraggingReportId,
    onEditClick,
    onDeleteClick,
    onRefreshClick,
}: {
    report: Report
    metadata: ReportingMetadata
    isRefreshing: boolean
    isDraggingReportId: number
    onEditClick: () => void
    onDeleteClick: () => void
    onRefreshClick: () => void
}) => {
    const isPrintPreview = useSelector<RootState, boolean>((x) => x.app.isPrintPreview)
    const thumbnailHeight = 300
    let linkClassName = 'reportsInsightsDashboardThumbnail mb-2'
    if (isPrintPreview) {
        linkClassName = 'printPreview reportsInsightsDashboardThumbnail mb-4'
    }
    return (
        <Link
            to={`/reports/${report.id}`}
            className={linkClassName}
            style={{
                height: `${thumbnailHeight}px`,
                pointerEvents: isDraggingReportId === report.id ? 'none' : 'all',
                opacity: isDraggingReportId === report.id ? '0.9' : '1',
            }}
        >
            {isPrintPreview !== true && (
                <DashboardThumbnailControlButtons
                    isRefreshing={isRefreshing}
                    onClickBeginRefresh={onRefreshClick}
                    itemType="Report"
                    showOverrideInfoButton={report.overrideDashboardScenarioSelection}
                    showRefreshButton={report.requiresRecalculation}
                    onEditClick={onEditClick}
                    onDeleteClick={onDeleteClick}
                />
            )}
            <ReportChart report={report} metadata={metadata} chartHeight={thumbnailHeight} />
        </Link>
    )
}

export default ReportDashboardThumbnail
