import { findNext8AmEarlierOrLater } from 'services/utilities/timeUtils'
import { EventBase, NumberOrNull, OwnerType, SleepQualityEnum } from './interfaces'
import { TagValues } from './TagValues'

export type ScheduleEventType = 'Work' | 'AutoSleep' | 'ExplicitSleep' | 'Marker' | 'Wake' | 'Duty' | 'AutoMarker'

export type WorkType =
    | 'Default'
    | 'Brief'
    | 'Debrief'
    | 'CommuteToWork'
    | 'CommuteFromWork'
    | 'Sit'
    | 'SplitDuty'
    | 'SplitDutySleep'
    | 'Preparation'
    | 'Unwind'

export type ScheduleEventDataType =
    | 'duty'
    | 'marker'
    | 'crewing'
    | 'notCrewing'
    | 'criticalMarker'
    | 'autoMarker'
    | 'criticalAutoMarker'
    | 'sleep'
    | 'explicitSleep'

export type EventFundamentalType = 'Work' | 'Duty' | 'Sleep' | 'Marker'

export type TagKeyValue = {
    id: number
    index: number // only needed to satisfy the api until we phase out the 5.0 ui
    name: string
    value: string
}

/**
 * This interface is designed to match up with the EventDTO on the server-side.
 */
export interface ScheduleEventRecord {
    scheduleId?: number
    label: string
    from: string
    to: string
    start: Date
    duration: number
    scheduleModified?: Date
    crewing: boolean
    critical: boolean
    uuid?: string
    dutyUuid?: string
    quality: SleepQualityEnum | null
    sleepCode: string | null
    plannedWorkSleepOwner: OwnerType | null
    plannedWorkSleepRuleId: number | null
    plannedWorkSleepQuality: SleepQualityEnum | null
    eventType: ScheduleEventDataType
    type: ScheduleEventType
    eventTags: TagKeyValue[]
    workType: WorkType
}

class ScheduleEvent extends EventBase implements ScheduleEventRecord {
    public scheduleModified?: Date
    public uuid?: string
    public dutyUuid?: string
    public scheduleId?: number
    public crewing: boolean
    public critical: boolean
    public quality: SleepQualityEnum | null = null
    public sleepCode: string | null = null
    public eventOwner: OwnerType | null = null
    public plannedWorkSleepOwner: OwnerType | null = null
    public plannedWorkSleepRuleId: number | null = null
    public plannedWorkSleepQuality: SleepQualityEnum | null = null
    public eventTags: TagKeyValue[] = []
    public tagValues?: TagValues

    public continuousSleepDuration: number | null = null
    public timeUntilNextNonContigEvent: number | null = null
    public timeSincePreviousNonContigEvent: number | null = null
    public dutyLabel: string | null = null

    constructor(
        public id: number,
        public readonly label: string,
        public start: Date,
        public readonly from: string,
        public readonly to: string,
        public tzFromMinutes: number,
        public tzToMinutes: number,
        public tzBaseMinutes: number,
        public duration: number,
        public eventType: ScheduleEventDataType,
        public type: ScheduleEventType,
        public workType: WorkType,
        public effectivenessAvg: number,
        public effectivenessMin: number,
        public effectivenessCriticalMin: number,
        public reservoirCriticalMin: number,
        public percentBelowCriterionCritical: number,
        public percentFHACritical: number,
        public workloadMax: number,
        public dutyNumber: number,
    ) {
        super()
        this.crewing = eventType === 'crewing'
        this.critical = eventType === 'criticalAutoMarker' || eventType === 'criticalMarker'
        this.plannedWorkSleepOwner = 'System'
        this.eventOwner = 'User'
        this.sleepCode = 'NA'
    }

    static newScheduleEventByType(
        dutyUuid: string | undefined,
        eventType: ScheduleEventType,
        scheduleEvents: ScheduleEvent[],
        scheduleId: number,
        scheduleModified: Date,
        parametersDefaultEventLabel: string,
        timeLocation?: {
            from: string
            to: string
            time: Date
            tzFromMinutes: number
            tzToMinutes: number
            tzBaseMinutes: number
        },
    ): ScheduleEvent {
        let eventLabel: string = `New ${parametersDefaultEventLabel || 'Event'}`
        let scheduleEventDataType: ScheduleEventDataType = 'duty'
        if (eventType === 'Work') {
            scheduleEventDataType = 'crewing'
        } else if (eventType === 'Marker') {
            scheduleEventDataType = 'marker'
            eventLabel = 'Marker'
        } else if (eventType === 'ExplicitSleep') {
            scheduleEventDataType = 'explicitSleep'
            eventLabel = 'Explicit Sleep'
        }

        let selectedFrom = timeLocation?.from || ''
        let selectedTo = timeLocation?.to || ''
        let selectedTime = timeLocation?.time || new Date()

        let tzFromMinutes = timeLocation?.tzFromMinutes || 0
        let tzToMinutes = timeLocation?.tzToMinutes || 0
        let tzBaseMinutes = timeLocation?.tzBaseMinutes || 0

        if (!timeLocation) {
            // use the end of the schedule
            const workEvents = scheduleEvents.filter((x) => !x.isAutoSleep())
            const lastWorkEvent = workEvents[workEvents.length - 1]

            selectedFrom = lastWorkEvent.to
            selectedTo = lastWorkEvent.to

            tzBaseMinutes = lastWorkEvent.tzBaseMinutes
            tzFromMinutes = lastWorkEvent.tzToMinutes
            tzToMinutes = lastWorkEvent.tzToMinutes

            // use 8am of the day following the last event
            selectedTime = findNext8AmEarlierOrLater(lastWorkEvent, lastWorkEvent.tzToMinutes, 'end', false)
        }

        const newEvent = ScheduleEvent.newScheduleEvent(
            eventLabel,
            selectedTime,
            selectedFrom,
            selectedTo,
            60,
            scheduleEventDataType,
            eventType,
            'Default',
        )

        newEvent.tzBaseMinutes = tzBaseMinutes
        newEvent.tzFromMinutes = tzFromMinutes
        newEvent.tzToMinutes = tzToMinutes

        if (scheduleEventDataType === 'duty') {
            newEvent.crewing = true
        }

        newEvent.dutyUuid = dutyUuid
        newEvent.scheduleModified = scheduleModified
        newEvent.scheduleId = scheduleId

        return newEvent
    }

    static newScheduleEvent(
        label: string,
        start: Date,
        from: string,
        to: string,
        duration: number,
        eventType: ScheduleEventDataType,
        type: ScheduleEventType,
        workType: WorkType,
    ): ScheduleEvent {
        return new this(0, label, start, from, to, 0, 0, 0, duration, eventType, type, workType, 0, 0, 0, 0, 0, 0, 0, 0)
    }

    static newScheduleEventWithTzOffsets(
        label: string,
        start: Date,
        from: string,
        to: string,
        tzFromMinutes: number,
        tzToMinutes: number,
        tzBaseMinutes: number,
        duration: number,
        eventType: ScheduleEventDataType,
        type: ScheduleEventType,
        workType: WorkType,
    ): ScheduleEvent {
        return new this(
            0,
            label,
            start,
            from,
            to,
            tzFromMinutes,
            tzToMinutes,
            tzBaseMinutes,
            duration,
            eventType,
            type,
            workType,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        )
    }

    getBasicEventType(): 'interval' | 'scheduleEvent' {
        return 'scheduleEvent'
    }

    get validationErrors(): string {
        return ''
    }

    isWorkTypeInArray = (workTypesLowercase: string[]) => {
        if (!this.workType) {
            return false
        }
        return workTypesLowercase.filter((x) => x === this.workType.toLowerCase()).length > 0
    }

    // getDurationInMinutes = () => this.durationMinutes
    getEndMs = (): number => this.getStartMs() + this.duration * 60000
    getStartMs = (): number => this.start.getTime()
    getFundamentalType = (): EventFundamentalType => {
        if (this.isEditedSleep()) {
            return 'Sleep'
        }
        switch (this.getDataType()) {
            case 'sleep':
            case 'explicitSleep':
                return 'Sleep'
            case 'marker':
            case 'autoMarker':
            case 'criticalMarker':
            case 'criticalAutoMarker':
                return 'Marker'
            case 'crewing':
            case 'notCrewing':
                return 'Work'
            case 'duty':
                return 'Duty'
            default:
                throw Error('Unexpected type:' + this.getDataType())
        }
    }

    getDataType = (): ScheduleEventDataType => this.eventType
    getDataTypeDescription = (): string => {
        switch (this.getDataType()) {
            case 'duty':
                return 'Duty'
            case 'marker':
                return 'Marker'
            case 'crewing':
                return 'Crewing'
            case 'notCrewing':
                return 'Non-Crewing'
            case 'criticalMarker':
                return 'Critical Marker'
            case 'autoMarker':
                return 'Auto-Marker'
            case 'criticalAutoMarker':
                return 'Critical Auto-Marker'
            case 'sleep':
                return 'Auto-Sleep'
            case 'explicitSleep':
                return 'Explicit Sleep'
            default:
                throw Error('Unknown event type:' + this.getDataType())
        }
    }
    getSleepCodeDescription = (): string => {
        switch (this.sleepCode) {
            case 'AS1':
                return 'Post-Work Extended'
            case 'AS2':
                return 'Post-Work Delayed'
            case 'AS3':
                return 'Regular'
            case 'AS4':
                return 'Regular Shifted'
            case 'AS5':
            case 'AS6':
                return 'Planned at Work'
            case 'AS7':
                return 'Pre-Work Nap'
            case 'AS8':
                return 'Advanced Bedtime'
            case 'AS9':
                return 'Duty Break'
            case 'AS10':
                return 'Post-Work Recovery'
            default:
                throw Error('Unknown sleep code:' + this.sleepCode)
        }
    }
    isAnyWork = (): boolean => this.getFundamentalType() === 'Work'
    isAnySleep = (): boolean => this.getFundamentalType() === 'Sleep'
    isAnyMarker = (): boolean => this.getFundamentalType() === 'Marker'
    isLocationSetAutomatically = (): boolean => this.isAnySleep() || this.isAnyMarker()
    isEditedSleep = (): boolean => this.getDataType() === 'explicitSleep' && this.label === 'Edited Sleep'
    isExplicitSleep = (): boolean => this.getDataType() === 'explicitSleep' && this.isEditedSleep() === false
    isAutoSleep = (): boolean => this.getDataType() === 'sleep'
    isEditableSleep = (): boolean => this.isExplicitSleep() || this.isEditedSleep()
    isAutoMarker = (): boolean => this.getDataType() === 'autoMarker' || this.getDataType() === 'criticalAutoMarker'
    isMarker = (): boolean => this.getDataType() === 'marker' || this.getDataType() === 'criticalMarker'
    isDutyRollup = (): boolean => this.getDataType() === 'duty'
    isEditable = (): boolean =>
        (this.isEditableSleep() || (this.isAnyMarker() && !this.isAutoMarker()) || this.isWorkEvent()) &&
        !this.isDutyBreakEvent()
    isSameEvent = (other: ScheduleEvent): boolean =>
        this.getDataType() === other.getDataType() &&
        this.getStartMs() === other.getStartMs() &&
        this.duration === other.duration
    hasCriticalTime = () => this.crewing || this.critical

    isDutyBreakEvent = () => {
        return this.isWorkTypeInArray(['sit', 'splitduty', 'splitdutysleep'])
    }
    /**
     * Event type is one of the auto-duty types that come before flight legs.
     */
    isPostFlightAutoDutyEvent = () => {
        return this.isWorkTypeInArray(['debrief', 'commutefromwork', 'unwind'])
    }
    /**
     * Event type is one of the auto-duty types that come before flight legs.
     */
    isPreFlightAutoDutyEvent = () => {
        return this.isWorkTypeInArray(['preparation', 'commutetowork', 'brief'])
    }

    shouldTimeChangesNotCascadeToNeighbours = () => {
        return this.isMarker() || this.isEditedSleep()
    }
    isGeneratedEvent = () => this.isAutoSleep() || this.isAutoMarker() || this.isDutyRollup()

    /**
     * Is it a regular work event (not auto-event);
     */
    isDefaultWorkEvent = () => {
        return (
            this.isDutyBreakEvent() === false &&
            this.isPreFlightAutoDutyEvent() === false &&
            this.isPostFlightAutoDutyEvent() === false &&
            this.isWorkEvent() === true
        )
    }

    /**
     * Get all the events in the same duty as this one.
     */
    getDutyEvents = (allScheduleEvents: ScheduleEvent[]) => {
        // not sure why would this be "null"
        if (!this.dutyUuid || this.dutyUuid === 'null') {
            return []
        }

        return allScheduleEvents.filter((evt) => {
            return evt.dutyUuid === this.dutyUuid
        })
    }

    getLastWorkEventInDuty = (allScheduleEvents: ScheduleEvent[]) => {
        const workEvents = this.getDutyEvents(allScheduleEvents).filter((x) => x.isDefaultWorkEvent())
        return workEvents[workEvents.length - 1]
    }

    isLastEventInDuty = (allScheduleEvents: ScheduleEvent[]): boolean => {
        const dutyEvents = this.getDutyEvents(allScheduleEvents)
        if (dutyEvents.length === 0) {
            return true
        }
        return dutyEvents[dutyEvents.length - 1].uuid === this.uuid
    }

    isFirstEventInDuty = (allScheduleEvents: ScheduleEvent[]): boolean => {
        return this.getDutyEvents(allScheduleEvents)[0].uuid === this.uuid
    }

    /**
     * Is the item part of a duty? Meaning, should it be indented in the table view.
     * @returns
     */
    isPartOfDuty = (allScheduleEvents: ScheduleEvent[]) => {
        return this.getDutyEvents(allScheduleEvents).length > 1
    }

    /**
     * Any work event (including auto-events).
     */
    isWorkEvent = () => {
        // what is the difference between this and isAnyWork?
        return this.isAnySleep() === false && this.isAnyMarker() === false && this.isDutyRollup() === false
    }

    shouldHaveWaypointMarkersDisplayed = () => {
        const type = this.getDataType()
        return (type === 'crewing' || type === 'notCrewing') && this.from !== this.to
    }
}

const parseEventType = (data: any): ScheduleEventDataType => {
    if (data.type === 'Work') {
        return data.crewing === true ? 'crewing' : 'notCrewing'
    }

    if (data.type === 'AutoSleep') {
        return 'sleep'
    }
    if (data.type === 'ExplicitSleep') {
        return 'explicitSleep'
    }

    if (data.type === 'Marker') {
        return data.critical === true ? 'criticalMarker' : 'marker'
    }

    if (data.type === 'AutoMarker') {
        return data.critical === true ? 'criticalAutoMarker' : 'autoMarker'
    }

    if (data.type === 'Duty') {
        return 'duty'
    }

    throw Error('Unknown event type:' + JSON.stringify(data.type))
}

const convertEventDateStringToDate = (dateString: string): Date => {
    // code from v5
    const dateFormatRegex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/
    let formattedTime = dateString.match(dateFormatRegex)![0]
    formattedTime += 'Z'
    return new Date(formattedTime)
}

export const parseScheduleEvent = (data: any, scheduleLastModified: Date): ScheduleEvent => {
    const dataType = parseEventType(data)
    const evt = new ScheduleEvent(
        Number(data.id),
        String(data.label),
        convertEventDateStringToDate(data.start),
        String(data.from),
        String(data.to),
        Number(data.tzFrom) * 60,
        Number(data.tzTo) * 60,
        Number(data.tzBase) * 60,
        Number(data.duration),
        dataType,
        data.type,
        data.workType as WorkType,
        Number(data.effectivenessAvg),
        Number(data.effectivenessMin),
        Number(data.effectivenessCriticalMin),
        Number(data.reservoirCriticalMin),
        Number(data.percentBelowCriterionCritical),
        Number(data.percentFHACritical),
        Number(data.workloadMax),
        Number(data.dutyIndex),
    )

    evt.uuid = String(data.uuid)
    evt.dutyUuid = String(data.dutyUUID)
    evt.scheduleId = Number(data.scheduleId)
    evt.scheduleModified = scheduleLastModified

    if (data.eventTags) {
        evt.eventTags = (data.eventTags as any[]).map((x) => {
            return {
                id: x.id,
                name: x.name,
                value: x.value,
                index: 0,
            }
        })
    }

    if (data.quality) {
        evt.quality = SleepQualityEnum[data.quality as keyof typeof SleepQualityEnum]
    }
    evt.sleepCode = String(data.sleepCode)
    evt.plannedWorkSleepRuleId = NumberOrNull(data.plannedWorkSleepRuleId)
    if (data.plannedWorkSleepQuality) {
        evt.plannedWorkSleepQuality = SleepQualityEnum[data.plannedWorkSleepQuality as keyof typeof SleepQualityEnum]
    }
    evt.plannedWorkSleepOwner = data.plannedWorkSleepOwner
    evt.eventOwner = data.eventOwner

    // flatten tags so the kendo grid can consume them
    evt.tagValues = {}
    for (let i = 0; i < evt.eventTags.length; i++) {
        const tagEntry = evt.eventTags[i]
        evt.tagValues[tagEntry.name] = tagEntry.value
    }

    return evt
}

export default ScheduleEvent
