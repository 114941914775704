import ReactDOMServer from 'react-dom/server'
import ReactTooltip from 'react-tooltip'
import { isDesktopMode } from 'services/axios/axios-sfc'
import Schedule from 'types/Schedule'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import { getClickableSharingIcon, isSharedItem } from 'views/Common/Kendo/FormattedNameCell'
import InfoIcon from 'views/Common/Widget/InfoIcon'
import classes from './ScheduleDetailsHeading.module.css'
import getTipContent from './ScheduleWarningContent'

const ScheduleDetailsHeading = (props: { schedule: Schedule; onClick: () => void; sharingIconClicked: () => void }) => {
    const { schedule } = props
    let baseSection = <></>
    if (schedule.baseLocation !== '') {
        baseSection = <span className={classes.scheduleHeadingBase}>({schedule.baseLocation})</span>
    }

    let scenarioNameSection = <></>
    if (isDesktopMode()) {
        scenarioNameSection = (
            <p className="text-muted" style={{ marginBottom: '0px', fontSize: '0.7em', fontWeight: 'normal' }}>
                {schedule.scenarioName}
            </p>
        )
    }
    const scheduleNameSection = (
        <TransparentButton id="scheduleDetailsScheduleName" onClick={props.onClick} color="black">
            {schedule.name}
        </TransparentButton>
    )

    const sharingIcon = isSharedItem(schedule.permission)
        ? getClickableSharingIcon(schedule.permission, 'schedule', (e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault()
              e.stopPropagation()
              props.sharingIconClicked()
          })
        : null

    let baseStationWarning: React.ReactNode | null = null
    if (schedule.warning.length > 0) {
        const tipContent = getTipContent(schedule)
        baseStationWarning = (
            <span data-tip={ReactDOMServer.renderToString(tipContent)}>
                <InfoIcon
                    icon="bi-exclamation-circle-fill"
                    variant="text-warning"
                    className="grid-button"
                    style={{ marginLeft: '8px', fontSize: '1.4em' }}
                />
                <ReactTooltip
                    html
                    place="bottom"
                    effect="solid"
                    overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
                        if (node) {
                            const d = document.documentElement
                            /* eslint-disable no-param-reassign */
                            left = Math.max(0, Math.min(d.clientWidth - node.clientWidth, left)) + 10
                            top = Math.max(0, Math.min(d.clientHeight - node.clientHeight, top)) + 10
                            /* eslint-enable */
                        }
                        return { top, left }
                    }}
                />
            </span>
        )
    }
    return (
        <div style={{ textAlign: 'left' }}>
            {scheduleNameSection}
            {baseSection}
            <div style={{ display: 'inline-block', fontSize: '0.6em' }}>{sharingIcon}</div>
            <div style={{ display: 'inline-block', fontSize: '0.6em' }}>{baseStationWarning}</div>
            {scenarioNameSection}
        </div>
    )
}

export default ScheduleDetailsHeading
