import { CSSProperties, useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { ButtonProps } from 'react-bootstrap/esm/Button'

export interface ButtonCustomProps extends ButtonProps {
    toolbarLeftMargin?: boolean
    isLarge?: boolean
    width?: number
    image?: string
    autoFocus?: boolean
    tooltip?: string
}

// There is duplication between here and ButtonCustom.module.css.  That could be cleaned up.
export const WhiteButtonBorder = '1px solid #ccc'
export const ToolbarButtonStyle: CSSProperties = {
    height: '30px',
    border: WhiteButtonBorder,
    fontSize: '19px',
    paddingTop: '0',
    paddingBottom: '0',
}

const ButtonCustom = (props: ButtonCustomProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        if (props.autoFocus === true) {
            buttonRef.current?.focus()
        }
    }, [props])

    const styleOverride = props.style || {}
    if (props?.width) {
        styleOverride.minWidth = props.width + 'px'
    }

    if (!props.variant || props.variant === 'white') {
        // for "white" buttons add a border
        styleOverride.border = WhiteButtonBorder
    }

    if (props.isLarge) {
        styleOverride.paddingTop = '4px'
        styleOverride.paddingBottom = '4px'
    }

    const content = props.children ?? <img src={props.image} alt="img" style={{ width: '16px' }} />
    /* eslint-disable */
    const { width, image, isLarge, toolbarLeftMargin, ...requiredProps } = props
    /* eslint-enable */

    return (
        <span title={props.tooltip} style={{ marginLeft: props.toolbarLeftMargin ? '6px' : undefined }}>
            <Button
                {...requiredProps}
                ref={buttonRef}
                className={`${props.className || ''}`}
                variant={props.variant ?? 'white'}
                style={styleOverride}
            >
                {content}
            </Button>
        </span>
    )
}

export default ButtonCustom
