class SessionControl {
    constructor(
        private setIsLoggedInHandler?: (isLoggedIn: boolean) => void,
        private sessionExpiredHandler?: (errorMessage: string) => void,
        private passwordExpiredHandler?: () => void,
    ) {}

    setSessionExpired(errorMessage: string) {
        this.sessionExpiredHandler?.(errorMessage)
    }

    setPasswordExpired() {
        this.passwordExpiredHandler?.()
    }

    setIsLoggedIn(value: boolean) {
        this.setIsLoggedInHandler?.(value)
    }
}

export default SessionControl
