import useFileProcessingRecords from 'hooks/useFileProcessingRecords'
import { useEffect, useState } from 'react'
import PageLayout from 'views/Common/Layout/PageLayout'
import FileProcessingCard from 'views/FileProcessing/Components/FileProcessingCard'
import classes from 'views/FileProcessing/Page/FileProcessingPage.module.css'

const LogEntries = () => {
    // fetch data every few seconds
    const fileProcessingData = useFileProcessingRecords(5000)
    if (fileProcessingData.error) {
        return <p style={{ color: 'red' }}>{fileProcessingData.error.message}</p>
    }
    return (
        <>
            {fileProcessingData.records.map((fp) => (
                <FileProcessingCard key={fp.id} fileProcessingInfo={fp} />
            ))}
        </>
    )
}

const FileProcessingPage = () => {
    const [contentHeightStyle, setHeightStyle] = useState(0)
    const contentHeightChanged = (newHeight: number) => {
        const captionHeight = (document.getElementsByClassName(classes.caption)[0]! as HTMLElement).offsetHeight
        setHeightStyle(newHeight - captionHeight)
    }

    useEffect(() => {
        document.title = 'File Processing'
    })

    return (
        <PageLayout headingContent="File Processing" onMainContentHeightChange={contentHeightChanged}>
            <small className={`text-muted ${classes.caption}`}>
                A maximum of 50 of the most recent entries are displayed. The page auto-refreshes every 5 seconds
            </small>
            <div className={classes.content} style={{ maxHeight: `${contentHeightStyle}px` }}>
                <div className="mt-2" />
                <LogEntries />
            </div>
        </PageLayout>
    )
}

export default FileProcessingPage
