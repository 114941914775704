import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import Draggable from 'react-draggable'

export type ModalWrapperClassName = 'ShiftsDialog' | 'DutiesListDialog' | 'DialogMediumWidth' | 'DialogSmallWidth'

const ModalWrapper = (props: {
    size?: 'sm' | 'lg'
    className?: ModalWrapperClassName
    /**
     * Body of the dialog
     */
    children?: React.ReactElement
    /**
     * Fires when the dialog is closed (confirm or cancel, etc)
     */
    closeCallback?: () => void

    hide?: boolean
}) => {
    const [show, setShow] = useState(true)

    const handleClose = () => {
        setShow(false)
        if (props.closeCallback) props.closeCallback()
    }

    const nodeRef = React.useRef(null)

    const DraggableDialogComponent = (props1: any) => (
        <Draggable handle=".modal-header" nodeRef={nodeRef}>
            <ModalDialog ref={nodeRef} {...props1} />
        </Draggable>
    )

    return (
        <Modal
            scrollable
            show={show && !props.hide}
            size={props.size}
            backdrop="static"
            onHide={handleClose}
            dialogClassName={props.className}
            animation={false}
            centered
            enforceFocus={false} // https://www.telerik.com/forums/datepicker-closes-directly-when-used-in-material-ui-dialog
            dialogAs={DraggableDialogComponent}
        >
            {props.children}
        </Modal>
    )
}

export default ModalWrapper
