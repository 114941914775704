const CookieIdSession = 'session_token'

function getCookie(cookieName: string): string | null {
    const name = cookieName + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        const c = ca[i].trim()
        if (c.indexOf(name) === 0) {
            const cookieValue = c.substring(name.length, c.length)
            return cookieValue === 'null' ? null : cookieValue
        }
    }

    return null
}

function setCookie(cookieName: string, cookieValue: string | null, expiresInDays: number) {
    const d = new Date()
    d.setTime(d.getTime() + expiresInDays * 24 * 60 * 60 * 1000)
    const expiresString = expiresInDays > 0 ? 'expires=' + d.toUTCString() : ''
    document.cookie = cookieName + '=' + cookieValue + '; ' + expiresString + '; path=/'
}

function deleteCookie(cname: string): void {
    setCookie(cname, null, -1)
}

function setSessionCookie(token: string, keepMeLoggedIn: boolean): void {
    const expiryDays = keepMeLoggedIn ? 3600 : 0
    setCookie(CookieIdSession, token, expiryDays)
}

function deleteSessionCookie(): void {
    deleteCookie(CookieIdSession)
}
function getSessionCookie(): string | null {
    return getCookie(CookieIdSession)
}

export { getCookie, setCookie, deleteCookie, setSessionCookie, getSessionCookie, deleteSessionCookie }
