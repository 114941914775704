import React, { FormEvent, memo, useState } from 'react'
import { Alert, Form, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import globals from 'services/global/globals'
import { RootState } from 'store/store'
import MetaData from 'types/Metadata'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import simpleDialogClasses from 'views/Common/GenericDialogs/SimpleDialog.module.css'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'
import ProgressBar from 'views/Common/Widget/ProgressBar'

/**
 * Separate conent component from the dialog component to avoid re-rendering the entire dialog during state changes.
 * @param props
 * @returns
 */
const ImportScenarioDialogContent = (props: { closeCallback: (status: DialogResultEnum) => void }) => {
    const [uploadProgress, setUploadProgress] = useState(0)
    const [fileUploadPath, setFileUploadPath] = useState('Browse to your schedules file...')
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [validated, setValidated] = useState(false)
    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)
    const api = globals.getApi()
    const processingApi = api.getProcessingApi()

    const inputTypes = metadata.importInputTypes

    const uploadProgressFeedbackHandler = (progressEvent: any) => {
        const progressAmount = (progressEvent.loaded as number) / (progressEvent.total as number)
        setUploadProgress(progressAmount)
    }

    const fileUploaderChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileBrowseInput = e.target as HTMLInputElement // $('#fileUpload', this.el)
        let fieldVal = fileBrowseInput.value

        // Change the node's value by removing the fake path (Chrome)
        fieldVal = fieldVal.replace('C:\\fakepath\\', '')

        if (fieldVal !== undefined || fieldVal !== '') {
            setFileUploadPath(fieldVal)
        }
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()
        const form = event.target as HTMLFormElement

        if (form.checkValidity() === false) {
            setValidated(true)
            // keep the form open, let the user fix the issues
            return
        }

        const formData = new FormData(form)

        try {
            await processingApi.importScenario(formData, uploadProgressFeedbackHandler)
            props.closeCallback(DialogResultEnum.Completed)
        } catch (err: any) {
            setUploadProgress(0)
            setErrorMessage(err.message)
        }
    }

    return (
        <Form id="importScenarioDialog" noValidate validated={validated} onSubmit={submitHandler}>
            <Modal.Header closeButton>
                <Modal.Title>Import Scenario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <FormLabelCustom htmlFor="txtScenarioName">Scenario Name</FormLabelCustom>
                    <Form.Control
                        id="txtScenarioName"
                        name="scenarioName"
                        autoFocus
                        autoComplete="off"
                        type="text"
                        placeholder="Scenario Name"
                        required
                    />
                    <Form.Control.Feedback type="invalid">Please enter a scenario name</Form.Control.Feedback>
                    <Form.Text className="text-muted">{metadata.scenarioNameCaption ?? ''}</Form.Text>
                </Form.Group>
                <Form.Group>
                    <FormLabelCustom>Schedules File Selector</FormLabelCustom>

                    <Form.File id="fileUpload" custom>
                        <Form.File.Input name="schedulesFile" required onChange={fileUploaderChangeHandler} />
                        <Form.File.Label data-browse="Browse">{fileUploadPath}</Form.File.Label>
                        <Form.Control.Feedback type="invalid">Please select a file</Form.Control.Feedback>
                    </Form.File>
                </Form.Group>

                <Form.Group>
                    <FormLabelCustom>File Type</FormLabelCustom>
                    <Form.Control as="select" name="fileType" custom>
                        {inputTypes.map((x) => (
                            <option key={x} value={x}>
                                {x}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <FormLabelCustom>Non-Crewing Event Code Filter</FormLabelCustom>
                    <div className={simpleDialogClasses.checkBoxContainer + ' mb-2'}>
                        <Form.Check
                            type="radio"
                            id="radioEventCodesIgnore"
                            name="rdoEventCodeFilter"
                            label="Ignore these:"
                            defaultChecked
                            value="ignore"
                        />
                        <Form.Check
                            type="radio"
                            id="radioEventCodesInclude"
                            name="rdoEventCodeFilter"
                            label="Include only these:"
                            value="include"
                        />
                    </div>
                    <Form.Control
                        id="txtEventCodeFilter"
                        name="eventCodesFilter"
                        type="text"
                        placeholder="Comma-separated event codes. Eg: training,vacation"
                    />
                </Form.Group>
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            </Modal.Body>

            <Modal.Footer>
                {uploadProgress > 0 && (
                    <ProgressBar textDescription="File upload progress" progressValue={uploadProgress} />
                )}
                <ButtonCustom isLarge type="submit" variant="primary" disabled={uploadProgress > 0}>
                    Import
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    variant="secondary"
                    onClick={() => {
                        props.closeCallback(DialogResultEnum.Cancelled)
                    }}
                    disabled={uploadProgress > 0}
                >
                    Cancel
                </ButtonCustom>
            </Modal.Footer>
        </Form>
    )
}

const ImportScenarioDialog = (props: { closeCallback: (status: DialogResultEnum) => void }) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <ImportScenarioDialogContent closeCallback={props.closeCallback} />
        </ModalWrapper>
    )
}

export default memo(ImportScenarioDialog)
