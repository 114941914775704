const OverlappingEventDescription = (props: { children: string }) => {
    const textColor = props.children?.length > 0 ? 'red' : 'green'
    const messageText = props.children || 'Overlap fixed!'
    return (
        <p role="alert" style={{ color: textColor, fontWeight: 'bold', textAlign: 'center' }}>
            {messageText}
        </p>
    )
}

export default OverlappingEventDescription
