import { SortDescriptor } from '@progress/kendo-data-query'

export const parseHazardClassCases = (casesJson: any): HazardClassCase[] => {
    const parsedCases = JSON.parse(casesJson) as HazardClassCase[]
    parsedCases.forEach((thisCase) => {
        Object.keys(thisCase.dutyDescriptions).forEach((objKey: string) => {
            const dutyDesc = thisCase.dutyDescriptions[objKey]
            dutyDesc.startTimeSummary = `${dutyDesc.startTimeMin}-${dutyDesc.startTimeMax}`
            dutyDesc.durationSummary = `${dutyDesc.durationMin}-${dutyDesc.durationMax}`
            let dutyRest = ''
            if (
                dutyDesc.durationMax &&
                dutyDesc.durationMin &&
                dutyDesc.priorRestMin > 0 &&
                dutyDesc.priorRestMax > 0
            ) {
                dutyRest = dutyDesc.priorRestMin + '-' + dutyDesc.priorRestMax
            } else {
                dutyRest = 'Unknown'
            }
            dutyDesc.restSummary = dutyRest
        })
    })
    return parsedCases
}

interface HazardClassCase {
    id: number
    parentId: number
    index: number
    name: string
    hidden: boolean
    dutyDescriptions: IndexedDutyDescriptions
    match: Match
    truePositive: TruePositive
    truePositiveRate: TruePositiveRate
    positivePredictedValue: TruePositiveRate
    duties: Duty[]

    /**
     * Generated for HazardClassView pattern grids state
     */
    dutiesGridSort?: SortDescriptor
}

export interface Duty {
    scenarioName: string
    scheduleUniqueId: number
    scheduleId: number
    scheduleName: string
    dutyId: string
    route: string
    dutyIndex: number
    dutyDay: number
    startTimeString: string
    duration: number
    priorRest?: number
    insightsScoreEffectiveness: number
    insightsScoreReservoir?: any
    insightsScoreFHA?: any
    insightsScoreWorkload?: any
    insightsScore: number
    woclDuration: number
    priorSleep24: number
    priorDaySleep24: number
}

export interface IndexedDutyDescriptions {
    [key: string]: DutyDescription
}
interface TruePositiveRate {
    lt70: number
    lt75: number
    lt80: number
    gt80: number
}

interface TruePositive {
    lt70: number
    lt75: number
    lt80: number
    gt80: number
}

export interface Match {
    scheduleCount: number
    dutyCount: number
    lt50: number
    btw5055: number
    btw5560: number
    btw6065: number
    btw6570: number
    btw7075: number
    btw7580: number
    gt80: number
}

export interface DutyDescription {
    startTimeMin: string
    startTimeMax: string
    durationMin: number
    durationMax: number
    priorRestMin?: any
    priorRestMax?: any

    // calculated fields for the ui cases table
    startTimeSummary: string
    durationSummary: string
    restSummary: string

    // calculated fields for the ui duty visualization
    startMinuteMin: number
    startMinuteMax: number
    profileName: string
    index: number
}

export default HazardClassCase
