import React, { CSSProperties } from 'react'

const SeperatorVertical = () => {
    const separatorStyles: CSSProperties = {
        borderLeft: '1px solid #ccc',
        marginLeft: '8px',
        marginRight: '2px',
        paddingBottom: '5px',
    }

    return <span style={separatorStyles} />
}

export default SeperatorVertical
