import { TimePicker, TimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { Col, Form, FormCheck } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import checkExistingRecalculationRequests from 'services/api/checkExistingRecalculationRequests'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import globals from 'services/global/globals'
import { handleApiError } from 'services/utilities/toastrUtils'
import { globalActions } from 'store/globalStore'
import {
    changeRequiresReorderPatterns,
    changeRequiresRunInsights,
    getNewHazardClassDialogConfig,
    HazardClassDialogConfig,
} from 'types/HazardClass'
import BigButton from 'views/Common/Buttons/BigButton'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import PageLayout from 'views/Common/Layout/PageLayout'
import InputValidationWrapper from 'views/Common/Validation/InputValidationWrapper'
import classes from './HazardClassConfiguration.module.css'

const hhmmssToDate = (hhmm: string): Date => {
    const hrs = hhmm.substring(0, 2)
    const mins = hhmm.substring(3, 5)
    const d = new Date(2000, 0, 1)
    d.setHours(parseInt(hrs))
    d.setMinutes(parseInt(mins))
    return d
}
const dateToHhmmss = (d: Date): string => dateTimeFormatting.formatDateAsTimeString(d)

const HazardClassConfiguration = (props: { fromDashboard?: boolean; isCopy?: boolean }) => {
    const reportingApi = globals.getApi().getReportingApi()
    const params = useParams() as any
    const history = useHistory()
    const dispatch = useDispatch()
    const hazardClassId = Number(params.id) || 0
    const [originalHazardClass, setOriginalHazardClass] = useState<HazardClassDialogConfig | null>(null)
    const [hazardClass, setHazardClass] = useState<HazardClassDialogConfig | null>(null)
    const [configHeight, setConfigHeight] = useState(0)
    const [validatedForm, setValidatedForm] = useState(false)
    // const [invalidPatternSelection, setInvalidPatternSelection] = useState(false) /* TODO: SFC-3358 */
    const [invalidScoreCalculation, setInvalidScoreCalculation] = useState(false)

    useEffect(() => {
        const loadData = async () => {
            const hc = await reportingApi.getHazardClass(hazardClassId)
            if (props.isCopy === true) {
                hc.id = 0
                hc.description = `Copy of ${hc.name}`
                hc.name = ''
            }
            setOriginalHazardClass(hc)
            setHazardClass(hc)
        }

        if (hazardClassId) {
            loadData()
        } else {
            // must be new HC creation
            setHazardClass(getNewHazardClassDialogConfig())
        }
    }, [props.isCopy, reportingApi, hazardClassId])

    if (!hazardClass) {
        return <p>Loading...</p>
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        let invalid = false
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }

        /* TODO: SFC-3358
        const hasInvalidPatternSelection =
            !hazardClass.patternDurationEnable &&
            !hazardClass.patternStartEnable &&
            !hazardClass.patternPriorRestEnable &&
            !hazardClass.patternRouteEnable
        setInvalidPatternSelection(hasInvalidPatternSelection)

        if (hasInvalidPatternSelection) {
            invalid = true
        }
        */

        const hasInvalidScoreCalculation =
            hazardClass.metricEffectivenessWeight <= 0 &&
            hazardClass.metricReservoirWeight <= 0 &&
            hazardClass.metricFHAWeight <= 0 &&
            hazardClass.metricWorkloadWeight <= 0
        setInvalidScoreCalculation(hasInvalidScoreCalculation)
        if (hasInvalidScoreCalculation) {
            invalid = true
        }

        if (invalid) {
            return
        }

        const requiresInsightsRun = hazardClass.id === 0 || changeRequiresRunInsights(originalHazardClass!, hazardClass)
        const requiresReorderPatterns =
            hazardClassId !== 0 && changeRequiresReorderPatterns(originalHazardClass!, hazardClass)

        dispatch(globalActions.showLoadingModal())
        setTimeout(async () => {
            try {
                const saved = await reportingApi.saveHazardClass(
                    hazardClass,
                    requiresInsightsRun,
                    requiresReorderPatterns,
                )
                if (requiresInsightsRun) {
                    await checkExistingRecalculationRequests(dispatch)
                }

                let navUrl = '/insights/'
                if (props.fromDashboard !== true) {
                    navUrl += saved.id
                }
                history.push(navUrl)
            } catch (err: any) {
                handleApiError(err)
            } finally {
                dispatch(globalActions.hideLoadingModal())
            }
        }, 0)
    }

    const title = hazardClassId && !props.isCopy ? 'Edit Hazard Class Configuration' : 'Create New Hazard Class'
    return (
        <PageLayout headingContent={title} onMainContentHeightChange={setConfigHeight}>
            <Form key="form" aria-label="form" noValidate validated={validatedForm} onSubmit={submitHandler}>
                <ConfigPageFormBody configHeight={configHeight}>
                    <IndentedBlock headingText="Details">
                        <ConfigurationRow labelText="Name">
                            <Form.Control
                                type="text"
                                autoFocus
                                placeholder="Hazard Class name"
                                className={classes.input}
                                value={hazardClass.name}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setHazardClass((prev) => ({ ...prev!, name: e.target.value }))
                                }
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a hazard class name
                            </Form.Control.Feedback>
                        </ConfigurationRow>
                        <ConfigurationRow labelText="Description">
                            <Form.Control
                                as="textarea"
                                placeholder="Optional Description..."
                                className={classes.input}
                                value={hazardClass.description ?? ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setHazardClass((prev) => ({ ...prev!, description: e.target.value }))
                                }
                            />
                        </ConfigurationRow>
                    </IndentedBlock>
                    <IndentedBlock
                        headingText="Insights Score"
                        subheadingText="The combination of metrics to use as insights score"
                    >
                        <InputValidationWrapper
                            invalid={invalidScoreCalculation}
                            invalidText="You must configure at least 1 non-zero metric value"
                        >
                            <ConfigurationRow labelText="Effectiveness">
                                <Form.Control
                                    type="number"
                                    min={0}
                                    max={100}
                                    className={classes.input}
                                    value={hazardClass.metricEffectivenessWeight}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setHazardClass((prev) => ({
                                            ...prev!,
                                            metricEffectivenessWeight: parseInt(e.target.value),
                                        }))
                                    }
                                />
                            </ConfigurationRow>
                            <ConfigurationRow labelText="Sleep Reservoir">
                                <Form.Control
                                    type="number"
                                    min={0}
                                    max={100}
                                    className={classes.input}
                                    value={hazardClass.metricReservoirWeight}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setHazardClass((prev) => ({
                                            ...prev!,
                                            metricReservoirWeight: parseInt(e.target.value),
                                        }))
                                    }
                                />
                            </ConfigurationRow>
                            <ConfigurationRow labelText="% Fatigue Hazard Area">
                                <Form.Control
                                    type="number"
                                    min={0}
                                    max={100}
                                    className={classes.input}
                                    value={hazardClass.metricFHAWeight}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setHazardClass((prev) => ({
                                            ...prev!,
                                            metricFHAWeight: parseInt(e.target.value),
                                        }))
                                    }
                                />
                            </ConfigurationRow>
                            <ConfigurationRow
                                labelText="Workload"
                                subText="Workload will be rescaled before combining with other metrics"
                            >
                                <Form.Control
                                    type="number"
                                    min={0}
                                    max={100}
                                    className={classes.input}
                                    value={hazardClass.metricWorkloadWeight}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setHazardClass((prev) => ({
                                            ...prev!,
                                            metricWorkloadWeight: parseInt(e.target.value),
                                        }))
                                    }
                                />
                            </ConfigurationRow>
                        </InputValidationWrapper>
                    </IndentedBlock>
                    {/* TODO: SFC-3358
                        <IndentedBlock headingText="Patterns" subheadingText="Duty properties to look for patterns in">
                        <Form.Group>
                            <InputValidationWrapper
                                invalid={invalidPatternSelection}
                                invalidText="Please select at least one option"
                            >
                                <BigButton
                                    activated={hazardClass.patternStartEnable}
                                    onClick={() => {
                                        setHazardClass((prev) => ({
                                            ...prev!,
                                            patternStartEnable: !prev!.patternStartEnable,
                                        }))
                                    }}
                                >
                                    Duty Start
                                </BigButton>
                                <BigButton
                                    activated={hazardClass.patternDurationEnable}
                                    onClick={() => {
                                        setHazardClass((prev) => ({
                                            ...prev!,
                                            patternDurationEnable: !prev!.patternDurationEnable,
                                        }))
                                    }}
                                >
                                    Duty Duration
                                </BigButton>
                                <BigButton
                                    activated={hazardClass.patternPriorRestEnable}
                                    onClick={() => {
                                        setHazardClass((prev) => ({
                                            ...prev!,
                                            patternPriorRestEnable: !prev!.patternPriorRestEnable,
                                        }))
                                    }}
                                >
                                    Duty Prior Rest
                                </BigButton>
                                <BigButton
                                    activated={hazardClass.patternRouteEnable}
                                    onClick={() => {
                                        setHazardClass((prev) => ({
                                            ...prev!,
                                            patternRouteEnable: !prev!.patternRouteEnable,
                                        }))
                                    }}
                                >
                                    Duty Route
                                </BigButton>
                            </InputValidationWrapper>
                        </Form.Group>
                    </IndentedBlock> */}
                    <IndentedBlock headingText="Work Context" subheadingText="The context of work time to focus on">
                        <Form.Group>
                            <BigButton
                                activated={hazardClass.workContext === 'Overall'}
                                onClick={() => {
                                    setHazardClass((prev) => ({
                                        ...prev!,
                                        workContext: 'Overall',
                                    }))
                                }}
                            >
                                All Work
                            </BigButton>
                            <BigButton
                                activated={hazardClass.workContext === 'Crewing'}
                                onClick={() => {
                                    setHazardClass((prev) => ({
                                        ...prev!,
                                        workContext: 'Crewing',
                                    }))
                                }}
                            >
                                Crewing Work
                            </BigButton>
                            <BigButton
                                activated={hazardClass.workContext === 'Critical'}
                                onClick={() => {
                                    setHazardClass((prev) => ({
                                        ...prev!,
                                        workContext: 'Critical',
                                    }))
                                }}
                            >
                                Critical Work
                            </BigButton>
                        </Form.Group>
                    </IndentedBlock>
                    <IndentedBlock headingText="Pattern Matching Options">
                        <ConfigurationRow labelText="Duty Start Interval" subText="The duration of duty start ranges">
                            <TimePicker
                                value={hhmmssToDate(hazardClass.startTimeStep)}
                                onChange={(e: TimePickerChangeEvent) => {
                                    setHazardClass((previous) => {
                                        return { ...previous!, startTimeStep: dateToHhmmss(e.target.value!) }
                                    })
                                }}
                                format="HH:mm"
                                nowButton={false}
                            />
                        </ConfigurationRow>
                        <ConfigurationRow
                            labelText="Duty Duration Interval"
                            subText="The duration of duty duration ranges"
                        >
                            <Form.Control
                                type="number"
                                min="0"
                                required
                                value={hazardClass.durationStep}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setHazardClass((previous) => {
                                        const newValue = parseInt(e.target.value) || previous!.durationStep
                                        return { ...previous!, durationStep: newValue }
                                    })
                                }}
                            />
                        </ConfigurationRow>
                        <ConfigurationRow labelText="Prior Rest Interval" subText="The duration of prior rest ranges">
                            <Form.Control
                                type="number"
                                min="0"
                                required
                                value={hazardClass.priorRestStep}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setHazardClass((previous) => {
                                        const newValue = parseInt(e.target.value) || previous!.priorRestStep
                                        return { ...previous!, priorRestStep: newValue }
                                    })
                                }}
                            />
                        </ConfigurationRow>

                        <ConfigurationRow labelText="Maximum duties" subText="The maximum number of consecutive duties">
                            <Form.Control
                                type="number"
                                required
                                value={hazardClass.maxDuties}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setHazardClass((previous) => {
                                        const newValue = parseInt(e.target.value) || previous!.maxDuties
                                        return { ...previous!, maxDuties: newValue }
                                    })
                                }}
                            />
                        </ConfigurationRow>

                        <ConfigurationRow
                            labelText="Maximum Lookback"
                            subText="The maximum duration prior to the hazard duty"
                        >
                            <Form.Control
                                type="number"
                                required
                                value={hazardClass.maxDutyLookback}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setHazardClass((previous) => {
                                        const newValue = parseInt(e.target.value) || previous!.maxDutyLookback
                                        return { ...previous!, maxDutyLookback: newValue }
                                    })
                                }}
                            />
                        </ConfigurationRow>
                    </IndentedBlock>
                    <IndentedBlock headingText="Pattern Generation">
                        <ConfigurationRow
                            labelText="Combine Patterns"
                            subText="Combine patterns that have sequential duty descriptions"
                        >
                            <FormCheck
                                id="chkCombinePatterns"
                                label="Combine"
                                type="checkbox"
                                checked={hazardClass.startTimeCombine}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setHazardClass((previous) => ({ ...previous!, startTimeCombine: e.target.checked }))
                                }
                            />
                        </ConfigurationRow>
                        <ConfigurationRow
                            labelText="Refine To"
                            subText="The threshold to target when generating patterns.  Lower thresholds may result in more valuable patterns, but will increase run time."
                        >
                            <Form.Group>
                                <BigButton
                                    activated={hazardClass.refineTo === 'LT70'}
                                    onClick={() => {
                                        setHazardClass((prev) => ({
                                            ...prev!,
                                            refineTo: 'LT70',
                                        }))
                                    }}
                                >
                                    &lt; 70
                                </BigButton>
                                <BigButton
                                    activated={hazardClass.refineTo === 'LT75'}
                                    onClick={() => {
                                        setHazardClass((prev) => ({
                                            ...prev!,
                                            refineTo: 'LT75',
                                        }))
                                    }}
                                >
                                    &lt; 75
                                </BigButton>
                                <BigButton
                                    activated={hazardClass.refineTo === 'LT80'}
                                    onClick={() => {
                                        setHazardClass((prev) => ({
                                            ...prev!,
                                            refineTo: 'LT80',
                                        }))
                                    }}
                                >
                                    &lt; 80
                                </BigButton>
                            </Form.Group>
                        </ConfigurationRow>
                    </IndentedBlock>
                    <IndentedBlock headingText="Pattern Filter">
                        <ConfigurationRow
                            labelText="Minimum Matches"
                            subText="The minimum number of matching duties for a pattern to be included"
                        >
                            <Form.Control
                                type="number"
                                min="1"
                                required
                                value={hazardClass.filterTPC80}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setHazardClass((previous) => {
                                        const newValue = parseInt(e.target.value) || previous!.filterTPC80
                                        return { ...previous!, filterTPC80: newValue }
                                    })
                                }}
                            />
                        </ConfigurationRow>
                        <ConfigurationRow
                            labelText="Minimum Match Rate"
                            subText="The minimum match rate for duties at a low risk level for a pattern to be included"
                        >
                            <Form.Control
                                type="number"
                                min="0"
                                required
                                value={hazardClass.filterTPR80}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setHazardClass((previous) => {
                                        const newValue = parseFloat(e.target.value) || previous!.filterTPR80
                                        return { ...previous!, filterTPR80: newValue }
                                    })
                                }}
                            />
                        </ConfigurationRow>
                    </IndentedBlock>
                </ConfigPageFormBody>
                <Footer hazardClassId={hazardClassId} fromDashboard={props.fromDashboard} />
            </Form>
        </PageLayout>
    )
}

export const PaddedCenteredContent = (props: { children: React.ReactNode }) => {
    // centers content in the page with extra padding
    const paddingLeftright = 20
    return <div style={{ paddingLeft: paddingLeftright, paddingRight: paddingLeftright }}>{props.children}</div>
}

export const ConfigPageFormBody = (props: { configHeight: number; children: React.ReactNode }) => {
    return (
        <div
            style={{
                marginBottom: 10,
                borderTop: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
                height: props.configHeight - 40,
                overflowY: 'scroll',
                overflowX: 'hidden',
            }}
        >
            <PaddedCenteredContent>{props.children}</PaddedCenteredContent>
        </div>
    )
}

const Footer = (props: { fromDashboard?: boolean; hazardClassId: number }) => {
    const history = useHistory()
    // extra padding keeps the buttons right-aligned with the scrolling content above because the scrollbar takes up space
    const extraRightPadding = 25
    return (
        <div style={{ display: 'flex', justifyContent: 'end', paddingRight: extraRightPadding }}>
            <PaddedCenteredContent>
                <ButtonCustom isLarge type="submit" variant="primary">
                    {props.hazardClassId ? 'Save' : 'Create'}
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    toolbarLeftMargin
                    variant="secondary"
                    onClick={() => {
                        let navUrl = '/Insights/'
                        if (props.hazardClassId && props.fromDashboard !== true) {
                            navUrl += props.hazardClassId.toString()
                        }
                        history.replace(navUrl)
                    }}
                >
                    Cancel
                </ButtonCustom>
            </PaddedCenteredContent>
        </div>
    )
}

export const IndentedBlock = (props: { headingText: string; subheadingText?: string; children: React.ReactNode }) => {
    return (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
            <h5>{props.headingText}</h5>
            {props.subheadingText && <p>{props.subheadingText}</p>}
            <div style={{ marginLeft: 40, marginTop: 20 }}>{props.children}</div>
        </div>
    )
}

export const ConfigurationRow = (props: {
    labelColWidth?: number
    labelText: string
    labelId?: string
    subText?: string
    children: React.ReactNode
}) => {
    const labelColWidth = props.labelColWidth || 5
    return (
        <Form.Group>
            <Form.Row>
                <Col xs={labelColWidth}>
                    <Form.Label className={classes.label} id={props.labelId}>
                        {props.labelText}
                    </Form.Label>
                    <br />
                    <small>{props.subText}</small>
                </Col>
                <Col>{props.children}</Col>
            </Form.Row>
        </Form.Group>
    )
}

export default HazardClassConfiguration
