import { Card, Col, Row } from 'react-bootstrap'
import { TagKeyValue } from 'types/ScheduleEvent'

/**
 * List of Tags in a schedule shown in Schedule Details print preview
 */
const PrintableTagsList = (props: { tags: TagKeyValue[] }) => {
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col xs={2}>
                        <strong>Tag Name</strong>
                    </Col>
                    <Col xs={10}>
                        <strong>Tag Value</strong>
                    </Col>
                </Row>
                {props.tags.map((tag) => {
                    return (
                        <Row>
                            <Col xs={2}>{tag.name}</Col>
                            <Col xs={2}>{tag.value}</Col>
                        </Row>
                    )
                })}
            </Card.Body>
        </Card>
    )
}

export default PrintableTagsList
