import React, { CSSProperties } from 'react'

export interface InfoIconProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
    tooltip?: string
    icon?: string
    variant?: string
    style?: CSSProperties
    className?: string
}

const InfoIcon = (props: InfoIconProps) => {
    const classNames = [props.className, props.icon, props.variant].join(' ')

    return (
        <span>
            <span title={props.tooltip} className={classNames} style={props.style} />
        </span>
    )
}

export default InfoIcon
