import classes from './GraphDashboardSummary.module.css'
import LargeMetricNumber from './LargeMetricNumber'

const GraphDashboardSummary = (props: {
    metricValue: number
    metricColor: string
    metricTime: string
    utcOffset: number
}) => {
    return (
        <div className={classes.summaryRow}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <LargeMetricNumber value={props.metricValue} color={props.metricColor} />
                <p className={classes.metricTime}>
                    {props.metricTime} ({props.utcOffset < 0 ? '' : '+'}
                    {props.utcOffset})
                </p>
            </div>
        </div>
    )
}

export default GraphDashboardSummary
