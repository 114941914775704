import formatDecimal from 'services/formatting/numberFormatter'
import classes from './LargeMetricNumber.module.css'

const LargeMetricNumber = (props: { value: number; color: string }) => {
    const decimalPlaces = props.value < 100 ? 1 : 0
    const formattedMetric = formatDecimal(props.value, decimalPlaces)
    return (
        <div className={classes.container} style={{ backgroundColor: props.color }}>
            <p className={classes.metricValue}>{formattedMetric}</p>
        </div>
    )
}

export default LargeMetricNumber
