import { GridCellProps } from '@progress/kendo-react-grid'
import _ from 'lodash'
import ScheduleEvent from 'types/ScheduleEvent'

const CrewingCell = (props: GridCellProps) => {
    const scheduleEvent = props.dataItem as ScheduleEvent
    const classCrewing = `${scheduleEvent.crewing ? 'labelCrewing' : ''}`
    // use the spread operator on props to add
    // onto any already existing css classes
    // that may be present
    const newProps = {
        ...props,
        className: classCrewing,
    }
    return BasicCell(newProps)
}

const BasicCell = (props: GridCellProps) => {
    const value = _.get(props.dataItem, props.field!)

    return (
        <td
            colSpan={props.colSpan}
            className={props.className}
            role="gridcell"
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            data-grid-col-index={props.columnIndex}
        >
            {value}
        </td>
    )
}

export { BasicCell, CrewingCell }
