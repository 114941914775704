import { GridCellProps } from '@progress/kendo-react-grid'
import { CSSProperties } from 'react'

const CustomValueCell = (props: GridCellProps, value: React.ReactNode, style?: CSSProperties) => {
    return (
        <td
            className={props.className}
            colSpan={props.colSpan}
            role="gridcell"
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            data-grid-col-index={props.columnIndex}
            style={style}
        >
            {value}
        </td>
    )
}

export default CustomValueCell
