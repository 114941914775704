import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import { ActivityType, DetailData } from './DetailItem'

export type OwnerType = 'System' | 'User'

export interface DetailDataWithTimelineTicks {
    detailData: DetailData[]
    localTick: Date[]
    localOffset: number[]
    baseTick: Date[]
    baseOffset: number[]
}

export type RightAxisType =
    | 'SleepReservoir'
    | 'CircadianPhase'
    | 'GoalPhase'
    | 'OutOfPhase'
    | 'SleepIntensity'
    | 'LapseIndex'
    | 'Workload'
    | 'BAC'

export interface GraphRightAxisSelections {
    rightAxis: RightAxisType | null
    standardDeviation: boolean
}

export interface TableOptionsFilterSelections {
    includeCrewing: boolean
    includeNonCrewing: boolean
    includeMarker: boolean
    includeExplicitSleep: boolean
    includeAutoSleep: boolean
}

export enum SleepQualityEnum {
    Excellent = 1,
    Good = 0.8,
    Fair = 0.67,
    Poor = 0.5,
}

export const StringOrNull = (data: any): string | null => {
    if (data === null) return data
    return String(data)
}
export const NumberOrNull = (data: any): number | null => {
    if (data === null) return data
    return Number(data)
}

export const DateOrNull = (data: any): Date | null => {
    if (data === null) return data
    return new Date(Date.parse(data))
}

export enum TimeModeEnum {
    UTC,
    Local,
    Base,
}

export enum ScheduleDetailsViewMode {
    Graph,
    Table,
    Both,
}

export abstract class EventBase {
    abstract label: string
    abstract duration: number
    abstract tzFromMinutes: number
    abstract tzToMinutes: number
    abstract tzBaseMinutes: number
    abstract getStartMs(): number
    abstract getEndMs(): number
    abstract getBasicEventType(): 'interval' | 'scheduleEvent'

    getUtcOffset = (timeMode: TimeModeEnum, location: 'start' | 'end') => {
        if (timeMode === TimeModeEnum.Base) {
            return this.tzBaseMinutes
        }
        if (timeMode === TimeModeEnum.Local) {
            return location === 'start' ? this.tzFromMinutes : this.tzToMinutes
        }
        return 0
    }
    getStartMsForDisplay = (timeMode: TimeModeEnum, startTimeOverride?: number): number => {
        const utcOffset = this.getUtcOffset(timeMode, 'start')
        return dateTimeFormatting.getLocalizedTime(startTimeOverride ?? this.getStartMs(), utcOffset).getTime()
    }
    getEndMsForDisplay = (timeMode: TimeModeEnum, endTimeOverride?: number): number => {
        const utcOffset = this.getUtcOffset(timeMode, 'end')
        return dateTimeFormatting.getLocalizedTime(endTimeOverride ?? this.getEndMs(), utcOffset).getTime()
    }
}
export interface Station {
    code: string
    label: string
    value: string
    codeIATA: string
    codeICAO: string
    codeRegion: string
    description: string
    cityName: string
    codeType?: any
}

export enum FileProcessingType {
    Import,
    Export,
}
export interface NewScheduleDefaults {
    scheduleName: string
    scenarioName: string
    scenarioId: number
    baseCode: string
    userId: number
}

export interface FileProcessing {
    id: number
    userId: number | null
    scenarioId: number | null
    description: string | null
    filename: string | null
    fileSizeInKb: number
    timeStarted: Date
    timeCompleted: Date | null
    type: FileProcessingType | string
    exportFileResult: ExportFileResult | null
    // scenario?: any;
    // user?: any;
    logEntries: LogEntry[]
}

export interface ExportFileResult {
    id: number
    filename: string
}

export interface LogEntry {
    id: number
    parentRecordId: number
    time: Date
    level: string
    scheduleId: number | null
    logDetail: string
}

export interface ViewSettings {
    overlays: GraphRightAxisSelections
    tableFilters: TableOptionsFilterSelections
    timeMode: TimeModeEnum
    viewMode: ScheduleDetailsViewMode
    columnOverrides: ColumnOverride[]
}

export interface ColumnOverride {
    isChecked: boolean
    isShown: boolean
    field: string
}

export interface Permission {
    itemId: number
    amOwnerOfSharedItem: boolean
    isSharedThroughChildItem: boolean
    sharedWithMePermission?: any
}

export interface AccountLicense {
    activeUsersCount: number
    activeUsersLimit: number
}

export type UserEditable = Pick<
    User,
    'id' | 'loginId' | 'name' | 'state' | 'isAdministrator' | 'field1' | 'field2' | 'field3' | 'lastLogin'
>

export interface User {
    id: number
    loginId: string
    name: string
    accountId: number
    accountName: string
    refreshReportsSynchronously: boolean
    refreshInsightsSynchronously: boolean
    accountEnabledDetailCsvExport: boolean
    accountEnableReports: boolean
    accountEnableInsights: boolean
    maxConcurrentLoginsPerUserId: number
    accountIndustry: string
    state: 'Pending' | 'Active' | 'Deactivated'
    isAdministrator: boolean
    rolesDescription: string
    field1?: any
    field2?: any
    field3?: any
    lastLogin?: Date
}

export const parseStation = (data: any): Station => {
    return data as Station
}

export const parseNewScheduleDefaults = (data: any): NewScheduleDefaults => {
    return {
        userId: Number(data.userId),
        scenarioId: Number(data.scenarioId),
        baseCode: String(data.baseCode),
        scenarioName: String(data.scenarioName),
        scheduleName: String(data.scheduleName),
    }
}

export function parseUser(data: any): User {
    return {
        id: data.id,
        loginId: data.loginId,
        name: data.name,
        accountId: data.accountId,
        accountName: data.accountName,
        refreshReportsSynchronously: data.refreshReportsSynchronously,
        refreshInsightsSynchronously: data.refreshInsightsSynchronously,
        accountEnabledDetailCsvExport: data.accountEnabledDetailCsvExport,
        accountEnableReports: data.accountEnableReports,
        accountEnableInsights: data.accountEnableInsights,
        maxConcurrentLoginsPerUserId: data.maxConcurrentLoginsPerUserId,
        accountIndustry: data.accountIndustry,
        state: data.state,
        isAdministrator: data.isAdministrator,
        field1: data.field1,
        field2: data.field2,
        field3: data.field3,
        lastLogin: data.lastLogin && new Date(Date.parse(data.lastLogin)),
        rolesDescription: data.isAdministrator === true ? 'Administrator' : 'General',
    }
}

const parseFileProcessingLogEntries = (data: Array<any>): Array<LogEntry> => {
    return data.map((x) => {
        return {
            id: Number(x.id),
            level: String(x.level),
            logDetail: String(x.logDetail),
            parentRecordId: Number(x.parentRecordId),
            scheduleId: Number(x.scheduleId),
            time: new Date(Date.parse(x.time)),
        }
    })
}

const parseExportFileResultOrNull = (data: any): ExportFileResult | null => {
    if (data.exportFileResult === null) {
        return null
    }
    return {
        filename: data.filename,
        id: data.id,
    }
}

export const parseFileProcessing = (data: any): FileProcessing => {
    return {
        id: Number(data.id),
        fileSizeInKb: Number(data.fileSizeInKb),
        description: StringOrNull(data.description),
        filename: StringOrNull(data.filename),
        scenarioId: NumberOrNull(data.scenarioId),
        timeStarted: new Date(Date.parse(data.timeStarted)),
        type: data.type === 'Import' ? FileProcessingType.Import : FileProcessingType.Export,
        exportFileResult: parseExportFileResultOrNull(data),
        timeCompleted: DateOrNull(data.timeCompleted),
        userId: NumberOrNull(data.userId),
        logEntries: parseFileProcessingLogEntries(data.logEntries as Array<any>),
    }
}

export interface Palette {
    threshold: number
    color: string
}

export interface ColorPalettes {
    effectiveness: Palette[]
    reservoir: Palette[]
    percentage: Palette[]
    insights: Palette[]
    excessive: Palette[]
}

export interface NamedItem {
    id: number
    name: string
}

export function parseColorPalette(data: any): ColorPalettes {
    function toPaletteEntry(d: any): Palette {
        return {
            color: String(d.color),
            threshold: Number(d.threshold),
        }
    }
    return {
        effectiveness: (data.effectiveness as Array<any>).map(toPaletteEntry),
        percentage: (data.percentage as Array<any>).map(toPaletteEntry),
        reservoir: (data.reservoir as Array<any>).map(toPaletteEntry),
        insights: Array<any>(),
        excessive: Array<any>(),
    }
}

export const parseScheduleDetail = (data: any): DetailData => {
    let activityParsed: ActivityType
    if (data.activity.includes('work/sleep')) {
        activityParsed = 'work/sleep'
    } else if (data.activity.includes('sleep')) {
        activityParsed = 'sleep'
    } else {
        activityParsed = data.activity
    }

    const result: DetailData = {
        locationName: data.locationName,
        lightPhase: data.lightPhase,
        utcTime: dateTimeFormatting.convertDateStringsToDates(data.utcTime),
        activity: activityParsed,
        crewing: data.crewing,
        critical: data.critical,
        effectiveness: data.effectiveness,
        tz: data.tz,
        tzBase: data.tzBase,
        lowStandardDeviation: data.lowStandardDeviation,
        isNewActivityBoundary: data.isNewActivityBoundary,
        highStandardDeviation: data.highStandardDeviation,
        sleepReservoir: data.reservoir,
        goalPhase: data.goalPhase,
        relativeGoalPhase: data.relativeGoalPhase,
        relativePhase: data.relativePhase,
        sleepIntensity: data.sleepIntensity,
        lapseIndex: data.lapseIndex,
        workload: data.workload,
        sleep24Mins: data.sleep24,
        sleepDebtMins: data.sleepDebt,
        minsAwake: data.hrsAwake,
        currentPhase: data.currentPhase,
        outOfPhaseHrs: data.outPhase,
        cog: data.cog,
        samnPerelli: data.samnPerelli,
        kss: data.kss,
        isWocl: data.wocl,
        woclStart: data.woclStart,
        woclEnd: data.woclEnd,
        reactionTime: data.rt,
        sleepLabel: data.sleepLabel,
        markerLabel: data.markerLabel,
        workLabel: data.workLabel,
    }

    return result
}
