/**
 * "Loading" spinner, which is a Bootstrap modal dialog.  Has default text that you can override.
 */
import loadingGif from 'assets/loading.gif'
import classes from './LoadingSpinner.module.css'

const LoadingSpinner = (props: { textOverride?: string }) => {
    // text can be optionally overridden
    const displayText = props.textOverride ?? 'Working...'
    return (
        <div className={classes.modal}>
            <div className={classes.modalContent}>
                <img alt="loadingGif" className={classes.loadingGif} src={loadingGif} />
                <h4 className={classes.textContent}>{displayText}</h4>
            </div>
        </div>
    )
}

export default LoadingSpinner
