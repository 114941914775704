import { useEffect, useState } from 'react'
import globals from 'services/global/globals'
import { FileProcessing } from 'types/interfaces'
import useSessionStatus from './useSessionStatus'

type FileProcessingResult = {
    records: FileProcessing[]
    error: any
}
const useFileProcessingRecords = (intervalInMs: number): FileProcessingResult => {
    const [records, setRecords] = useState<FileProcessing[]>([])
    const [error, setError] = useState<any | null>(null)
    const userState = useSessionStatus()
    const api = globals.getApi()
    useEffect(() => {
        async function loadData() {
            try {
                const data = await api.getFileProcessingRecords()
                setRecords(data)
            } catch (e: any) {
                setError(e)
            }
        }

        // auto-refresh the data
        const intervalId = window.setInterval(() => {
            if (userState === 'LoggedIn') {
                loadData()
            }
        }, intervalInMs)

        loadData()
        return () => window.clearInterval(intervalId)
    }, [userState, api, intervalInMs])

    return { records, error }
}

export default useFileProcessingRecords
