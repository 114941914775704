import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'

export interface BreadcrumbEntry {
    url?: string
    label: string
}
const RowBreadcrumbs = (props: { entries: Array<BreadcrumbEntry> }) => {
    const content = props.entries.map((x, i) => {
        if (x.url) {
            return (
                <span key={i}>
                    <Link to={x.url}>{x.label}</Link>
                    <span> \ </span>
                </span>
            )
        }
        return <span key={i}>{x.label}</span>
    })
    return (
        <Row>
            <Col lg={12} className="mt-1">
                <small className="text-muted">{content}</small>
            </Col>
        </Row>
    )
}

export default RowBreadcrumbs
