const formatDecimal = (num: number, desiredDecimalPlaces: number, allowInteger?: boolean): string => {
    if (desiredDecimalPlaces === 0) {
        return Math.round(num).toString()
    }
    if (allowInteger && num.toString().indexOf('.') === -1) {
        return num.toString()
    }
    let val = num
    let valString = ''
    val = Math.round(val * desiredDecimalPlaces * 10) / (desiredDecimalPlaces * 10)
    valString = val.toString()
    if (val.toString().indexOf('.') === -1) {
        // must be a whole number, so add the required number of decimal places
        const zeros = []
        for (let i = 0; i < desiredDecimalPlaces; i++) {
            zeros.push(0)
        }
        valString = val.toString() + '.' + zeros.join('')
    } else {
        const decimalSection = valString.substring(valString.indexOf('.') + 1)
        const remainingZeros = desiredDecimalPlaces - decimalSection.length
        for (let i = 0; i < remainingZeros; i++) {
            valString += '0'
        }
    }
    return valString
}

export default formatDecimal
