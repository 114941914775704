import { toast } from 'react-toastify'
import { Dispatch } from 'redux'
import { insightsRecalculationActions, reportRecalculationActions } from 'store/recalculationStore'
import { ResponseMessage } from 'types/ResponseMessage'

export const showResponseMessage = (message?: ResponseMessage) => {
    if (!message) {
        return
    }

    // these response messages come from the server; they
    // often have a little more information, so give a longer
    // delay before closing
    toast[message.messageType](message.messageContent, { autoClose: 5000 })
}

/**
 * is the message about session being expired?
 * @param message
 * @returns
 */
const isSessionExpiredMessage = (message: string) => message.match(/your session.*has expired/i)

export const handleApiError = (err: any) => {
    let msgString = err.message as string
    if (msgString[0] === '{') {
        // json
        const msgJson = JSON.parse(msgString)
        if (msgJson.error) {
            msgString = msgJson.error
        } else if (msgJson.message) {
            msgString = msgJson.message
        }
    }

    // we assume this "session expired" message will be displayed elsewhere, so this particular
    // function filters them out
    if (!isSessionExpiredMessage(msgString)) {
        toast.error(msgString)
    }
}

export const handleRecalculationError = (
    recalculationErrorMessage: string | null,
    dispatch: Dispatch<any>,
    type: 'report' | 'insights',
) => {
    if (recalculationErrorMessage) {
        // could use actual error message here, but like in v5 we will produce a generic message in case the error is too technical.
        // example: toast.error(recalculationErrorMessage)
        toast.error(
            'An error occurred while performing data processing.  Please try refreshing the data again and contact your system administrator if the problem continues.',
        )

        // clear the error message from the store so it doesn't appear again
        if (type === 'report') {
            dispatch(reportRecalculationActions.clearRecalculationError())
        }
        if (type === 'insights') {
            dispatch(insightsRecalculationActions.clearRecalculationError())
        }
    }
}
