import ReportingMetadata, { ReportItemDefinition } from 'types/ReportingMetadata'
import { Report, YAxisSeries } from 'types/Reports'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { Option } from 'views/Common/Widget/ReactSelectTypes'
import DataGroupItem from 'views/Reports/Page/ReportConfigurationTypes'

const getScenarioName = (scenarioId: number, metadata: ReportingMetadata) => {
    return metadata.scenarios.find((x) => x.id === scenarioId)?.name ?? 'REMOVED_SCENARIO'
}

interface ReportGridRecord {
    [key: string]: number | string | null
}

export type SeriesKeyTitle = {
    key: string
    title: string
    seriesIds: number[]
}

/**
 * Get default hardcoded report titles for hardcoded reports.
 */
const getDefaultChartTitlesForHardCodedReport = (report: Report) => {
    if (report.type.indexOf('ReportWorkTimeEffectiveness') >= 0) {
        return {
            dataGroupTitle: 'Effectiveness',
            dataSeriesTitle: 'Percent of Crewing Event Time',
        }
    }

    throw Error(`getDefaultChartTitlesForHardCodedReport unknown report type:${report.type}`)
}

// these aggregation options only used for the Data Category item
const complexAggregations: Option[] = [
    { label: 'Count', value: 'Count' },
    { label: 'Percent of Data Count', value: 'PercentOfOverallCount' },
    { label: 'Percent of Series Sum', value: 'PercentOfOverallSum' },
]

const standardAggregations: Option[] = [
    { label: 'Average', value: 'Average' },
    { label: 'Minimum', value: 'Minimum' },
    { label: 'Maximum', value: 'Maximum' },
    { label: 'Sum', value: 'Sum' },
]

export const getAggregationsForDataItem = (
    seriesDataItem: string,
    xAxisItem: string,
    reportDataType: 'schedules' | 'events',
    metadata: ReportingMetadata,
) => {
    let aggregationsForSeriesItem: Option[] = []
    if (seriesDataItem === DataGroupItem) {
        const dataType = getDataItemDefinitionByDataItem(xAxisItem, reportDataType, metadata).type
        aggregationsForSeriesItem = complexAggregations.filter(
            (x) => x.value === 'Count' || dataType.toLowerCase() !== 'string',
        )
    } else {
        aggregationsForSeriesItem = seriesDataItem ? standardAggregations : []
    }

    return aggregationsForSeriesItem
}

/**
 * Get item "definition" from metadata
 * @param dataItem
 * @param reportDataType
 * @param metadata
 * @returns
 */
export const getDataItemDefinitionByDataItem = (
    dataItem: string,
    reportDataType: 'schedules' | 'events',
    metadata: ReportingMetadata,
) => {
    const itemDef = metadata.seriesDataItems[reportDataType].find((x) => x.dataItem === dataItem)
    if (!itemDef) {
        if (dataItem.indexOf('Tags.') === 0) {
            throw Error(
                'Could not find the Tag named "' +
                    dataItem +
                    '".  If this Tag has been removed from the system, then this report will no longer work. You can remove the report, modify it, or reintroduce the Tag to your schedules',
            )
        } else {
            throw Error('Could not find data item named "' + dataItem + '"')
        }
    }
    return itemDef
}

export const getDataItemDisplayName = (
    report: Report,
    metadata: ReportingMetadata,
    dataItem: string,
    scenarioName?: string,
    aggregation?: string,
): string => {
    if (!report.configOptions) {
        return dataItem
    }

    if (!report.configOptions) {
        throw new Error('Not supported')
    }
    const itemDef = getDataItemDefinitionByDataItem(dataItem, report.configOptions.dataType, metadata)
    const scenarioPrefix = scenarioName ? `${scenarioName} - ` : ''
    const aggregationSuffix = aggregation ? ` [${aggregation}]` : ''
    return `${scenarioPrefix}${itemDef.displayName}${aggregationSuffix}`
}

export const getDataItemDisplayNameForSeries = (
    report: Report,
    metadata: ReportingMetadata,
    yAxisSeries: YAxisSeries,
): string => {
    if (!report.configOptions) {
        return report.overlayScenarios ? getScenarioName(yAxisSeries.seriesId, metadata) : 'Combined Scenarios'
    }
    const seriesConfig = report.configOptions.series.find((x) => x.seriesId === yAxisSeries.seriesId)!
    if (!seriesConfig) {
        // user has just removed this series from the config, and the report data hasn't yet been regenerated
        // so just leave the name blank for now.
        return ''
    }
    const scenarioName = report.overlayScenarios
        ? getScenarioName(seriesConfig.scenarioId, metadata)
        : 'Combined Scenarios'
    return getDataItemDisplayName(report, metadata, seriesConfig.dataItem, scenarioName, seriesConfig.aggregation)
}

/**
 * Figure out the name of the y-axis this data item belongs to.
 * @param {*} dataItemDefintion
 */
const getYAxisNameForDataItemDefinition = (definition: ReportItemDefinition, dataItem: string, aggregation: string) => {
    let axisName = definition.category ? definition.category : dataItem

    // axisName needs to be unique, but we can have multiple y-axes for the same category
    if (['Count', 'Sum', 'PercentOfOverallCount', 'PercentOfOverallSum'].includes(aggregation)) {
        axisName += '_' + aggregation
    }
    return axisName
}

/**
 * Get chart x axis title
 * @param report
 * @param metadata
 * @returns
 */
export const getKendoChartXAxisTitle = (report: Report, metadata: ReportingMetadata): string => {
    // start with the override
    if (report.xAxisLabel) {
        return report.xAxisLabel
    }

    // then lookup the data item display name
    if (!report.configOptions) {
        // WT eff report
        return getDefaultChartTitlesForHardCodedReport(report).dataGroupTitle
    }

    return getDataItemDisplayName(report, metadata, report.configOptions.xAxisItem)
}

/**
 * Get the series configs for the Chart based on report data.
 * @param report
 * @param metadata
 * @returns
 */
export const getKendoChartYAxisSeries = (report: Report, metadata: ReportingMetadata): SeriesKeyTitle[] => {
    if (!report.yAxisSeries) {
        throw Error('No yAxis Series available!')
    }
    if (!report.configOptions) {
        // non-configurable report, like WT Effectiveness
        // NB: assumes only 1 series
        return [
            {
                key: 'only-series',
                title: report.yAxisLabel || 'Percent of Crewing Event Time',
                seriesIds: report.yAxisSeries.map((x) => x.seriesId),
            },
        ]
    }

    const categoryToDescriptiveTextMap = [
        { key: 'Effectiveness', value: 'Effectiveness' },
        { key: 'Reservoir', value: 'Reservoir (%)' },
        { key: 'BelowCriterionMinutes', value: 'Below Criterion (minutes)' },
        { key: 'BelowCriterionPercent', value: 'Below Criterion (%)' },
        { key: 'Phase', value: 'Phase (hours)' },
    ]

    return report.configOptions.series.reduce<SeriesKeyTitle[]>((sum, cur) => {
        const definition = getDataItemDefinitionByDataItem(cur.dataItem, report.configOptions!.dataType, metadata)
        if (!definition) {
            throw Error(`Unable to find data item "${cur.dataItem}"`)
        }
        const axisId = getYAxisNameForDataItemDefinition(definition, cur.dataItem, cur.aggregation)

        // lookup the data category to use as the y-axis label,
        // or fall back to the displayName
        const commonAxisTitle = categoryToDescriptiveTextMap.find((x) => x.key === axisId)?.value
        const descriptiveAxisText = commonAxisTitle || definition.displayName

        const dataTypeDescriptive = report.configOptions?.dataType === 'events' ? 'Events' : 'Schedules'
        // Determine the y-axis label
        let title = ''
        if (report.yAxisLabel) {
            // user-override in the report config dialog take precendence over everything else
            title = report.yAxisLabel
        } else if (cur.aggregation === 'Count') {
            title = 'Count of ' + dataTypeDescriptive
        } else if (cur.aggregation === 'Sum') {
            title = 'Sum of ' + definition.displayName
        } else if (cur.aggregation === 'PercentOfOverallCount') {
            title = 'Percent of ' + dataTypeDescriptive
        } else if (cur.aggregation === 'PercentOfOverallSum') {
            title = 'Percent of Sum of ' + definition.displayName
        } else if (descriptiveAxisText) {
            title = descriptiveAxisText
        } else {
            title = axisId
        }

        const existingSeriesEntry = sum.find((x) => x.key === axisId)
        if (existingSeriesEntry) {
            existingSeriesEntry.seriesIds.push(cur.seriesId)
        } else {
            sum.push({
                key: axisId,
                title,
                seriesIds: [cur.seriesId],
            })
        }
        return sum
    }, [])
}

/**
 * Convert the Report data into a format that can go into the grid
 * Returns both grid-compatible records and column definitions
 */
export const getGridFormattedReportData = (
    report: Report,
    metadata: ReportingMetadata,
): [ReportGridRecord[], KendoGridColumn[]] => {
    const prepareDataItemForKendoField = (field: string) => field.replace(/[^A-Z0-9]/gi, '_')

    if (!report.xAxisValues || !report.yAxisSeries) {
        throw Error('Data is not yet calculated!')
    }

    // map from seriesId to grid field name
    const seriesItemToSafeFieldNameMap: Record<number, string> = {}
    if (!report.configOptions) {
        // use scenario name as data item display name
        report.yAxisSeries.forEach((series) => {
            seriesItemToSafeFieldNameMap[series.seriesId] = series.seriesId.toString()
        })
    } else {
        report.configOptions!.series.forEach((seriesDefinition) => {
            // need a unique field name
            seriesItemToSafeFieldNameMap[seriesDefinition.seriesId] = `scenarioId_${
                seriesDefinition.scenarioId
            }_${prepareDataItemForKendoField(seriesDefinition.dataItem)}_${seriesDefinition.aggregation}`
        })
    }

    const xAxisDataItem = report.configOptions?.xAxisItem || 'Category'
    const xAxisDataItemKendoSafe = prepareDataItemForKendoField(xAxisDataItem)

    const columns: KendoGridColumn[] = []
    const records: ReportGridRecord[] = []

    for (let i = 0; i < report.xAxisValues.length; i++) {
        const record: ReportGridRecord = {}

        // first column is the xaxis label
        record[xAxisDataItemKendoSafe] = report.xAxisValues[i]
        if (i === 0) {
            columns.push({
                field: xAxisDataItemKendoSafe,
                title: getDataItemDisplayName(report, metadata, xAxisDataItem),
                cellClassName: 'left-aligned',
                headerClassName: 'left-aligned',
            })
        }

        // then a dynamic number of additional properties
        report.yAxisSeries.forEach((series) => {
            const seriesDataItemKendoSafe = seriesItemToSafeFieldNameMap[series.seriesId]
            const seriesDataPoint = series.data![i]
            record[seriesDataItemKendoSafe] = seriesDataPoint

            if (i === 0) {
                columns.push({
                    field: seriesDataItemKendoSafe,
                    title: getDataItemDisplayNameForSeries(report, metadata, series),

                    format: '{0:n1}',
                })
            }
        })
        records.push(record)
    }

    return [records, columns]
}
