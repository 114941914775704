import { useEffect, useState } from 'react'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import globals from 'services/global/globals'
import { LogEntry } from 'types/interfaces'
import ErrorDialog from 'views/Common/GenericDialogs/ErrorDialog'

const ScheduleErrorsDialog = (props: { scenarioId: number; closeCallback: () => void }) => {
    const api = globals.getApi()
    const [errorLogs, setErrorLogs] = useState<LogEntry[]>([])

    useEffect(() => {
        // load the errors data
        const loadData = async () => {
            const records = await api.getFileProcessingRecords(props.scenarioId)
            const errorEntries = records[0].logEntries.filter((x) => x.level.toLowerCase() === 'error')
            setErrorLogs(errorEntries)
        }

        loadData()
    }, [props.scenarioId, api])

    // formatting
    const errorsHtmlArray = errorLogs.map((entry) => {
        const dateFormatted = dateTimeFormatting.formatDateTimeLongLocal(entry.time.getTime())
        return dateFormatted + ' (' + entry.level + '): ' + entry.logDetail
    })

    if (errorsHtmlArray.length === 0) {
        errorsHtmlArray.push(
            'No error messages were found.  Please visit the Imports page and view the Log entries for more detail',
        )
    }

    const finalArray: string[] = []
    errorsHtmlArray.forEach((entry) => {
        const multiLineMessage = entry.split('\n')
        multiLineMessage.forEach((msg) => finalArray.push(msg))
    })

    return (
        <ErrorDialog
            dialogClass="DialogMediumWidth"
            headerText="All Scenario Import Errors"
            message={
                <>
                    {finalArray.map((entry) => {
                        return (
                            <>
                                <small>{entry}</small>
                                <br />
                            </>
                        )
                    })}
                </>
            }
            closeCallback={props.closeCallback}
        />
    )
}

export default ScheduleErrorsDialog
