import { SortDescriptor } from '@progress/kendo-data-query'
import { ExcelExport, ExcelExportColumnProps } from '@progress/kendo-react-excel-export'
import { GridDataStateChangeEvent } from '@progress/kendo-react-grid'
import { Duty } from 'types/HazardClassCase'
import KendoGridCustom from 'views/Common/Kendo/KendoGridCustom'
import getColumns from './HazardClassCaseActualDutiesTableColumns'

const HazardClassCaseActualDutiesTable = (props: {
    exportRef: React.MutableRefObject<ExcelExport | null>
    patternExportFilename: string
    sortDescriptor: SortDescriptor
    dataStateChanged: (e: GridDataStateChangeEvent) => void
    duties: Duty[]
}) => {
    const columns = getColumns()
    const exportFormatColumns = columns.map<ExcelExportColumnProps>((col) => ({
        field: col.field,
        title: col.title,
    }))

    return (
        <KendoGridCustom
            scrollable="none"
            noSelection
            localStorageKeyForColumnState="HazardClassDutiesTable"
            localStorageKeyForGridDataState="HazardClassDutiesTableDataState"
            centeredContent
            filterable={false}
            pageable={false}
            data={props.duties}
            columns={columns}
            selectedRowsState={{}}
            onSetSelectedRowsState={() => {}}
            setColumnVisibility={() => {}}
            excelExportRef={props.exportRef}
            excelExportFilename={`${props.patternExportFilename}.xlsx`}
            excelExportColumns={exportFormatColumns}
            dataState={{
                sort: [props.sortDescriptor],
            }}
            onDataStateChange={props.dataStateChanged}
        />
    )
}

export default HazardClassCaseActualDutiesTable
