import React, { CSSProperties, ReactNode, useState } from 'react'
import { ButtonGroup, Dropdown } from 'react-bootstrap'
import IconButton from 'views/Common/Buttons/IconButton'
import { WhiteButtonBorder } from './ButtonCustom'
import { ToolbarButtonHeightPx } from './ToolbarButton'

export interface ToolbarToggleDropdownProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
    tooltip: string
    tooltipSplit?: string
    icon: string
    children: ReactNode
    isToggled: boolean
    disabled?: boolean
    onToggleChange: (toggled: boolean) => boolean
}

const ToolbarToggleDropdown = (props: ToolbarToggleDropdownProps) => {
    const toggleStyle: CSSProperties = {
        height: `${ToolbarButtonHeightPx}px`,
        width: '20px',
        paddingLeft: '3px',
        paddingBottom: 0,
        paddingTop: 0,
        border: WhiteButtonBorder,
    }

    const [showDropdown, setShowDropdown] = useState<boolean>(false)

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown)
    }

    return (
        <Dropdown as={ButtonGroup} show={showDropdown} onToggle={toggleDropdown}>
            <IconButton
                tooltip={props.isToggled ? `Hide ${props.tooltip}` : `Show ${props.tooltip}`}
                onClick={() => {
                    props.onToggleChange(!props.isToggled)
                }}
                disabled={props.disabled}
                toolbarLeftMargin
                icon={props.icon}
                iconcolor={props.isToggled ? '#fff' : '#444'}
                style={{
                    backgroundColor: props.isToggled ? '#444' : '#eee',
                }}
            />
            <Dropdown.Toggle
                style={toggleStyle}
                split
                variant="white"
                id="dropdown-split-basic"
                disabled={!props.isToggled}
                title={props.tooltipSplit}
            />
            <Dropdown.Menu onMouseLeave={toggleDropdown}>{props.children}</Dropdown.Menu>
        </Dropdown>
    )
}
export default ToolbarToggleDropdown
