import moment from 'moment'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import { DetailDataTime } from 'types/DetailItem'
import { DetailDataWithTimelineTicks, TimeModeEnum } from 'types/interfaces'
import { GraphConfig, XScale } from 'views/Schedules/ScheduleDetails/EffectivenessGraph/EffectivenessGraphTypes'

export interface DateOffset {
    time: Date
    utcOffsetMinutes: number
}

export const generateTickValues = (
    startDate: Date,
    endDate: Date,
    offsetMinutes: number,
    intervalMinutes: number,
): DateOffset[] => {
    const dates = []
    let dt = new Date(startDate.getTime() + offsetMinutes * 60000)

    if (intervalMinutes <= 0) {
        throw new Error('generateTickValues: must specify non-zero, positive interval')
    }

    while (dt <= endDate) {
        dates.push(dt)
        dt = new Date(dt.getTime() + intervalMinutes * 60000)
    }

    return dates.map((x) => ({ time: x, utcOffsetMinutes: 0 }))
}

export const getTickHours = (
    parsedDetailData: DetailDataWithTimelineTicks,
    hourCount: number,
    timeReference: TimeModeEnum,
    xScale: XScale,
) => {
    // what hours should be included on the bottom axis?
    const hoursToInclude = []
    for (let i = 0; i < 24; i += hourCount) {
        hoursToInclude.push(i)
    }

    let tickData
    let offsetData
    if (timeReference === TimeModeEnum.Local) {
        tickData = parsedDetailData.localTick
        offsetData = parsedDetailData.localOffset
    } else if (timeReference === TimeModeEnum.Base) {
        tickData = parsedDetailData.baseTick
        offsetData = parsedDetailData.baseOffset
    } else {
        throw Error('Unexpected time reference:' + timeReference)
    }

    const datesWithOffsets: DateOffset[] = []

    /**
     * Prevent capturing two back-to-back dates that would be too close together on the x-axis.
     */
    function tickHourIsTooCloseToPrevious(dataHour: number) {
        if (datesWithOffsets.length === 0) {
            // there are no "previous" ticks captured
            return false
        }

        const previousData = datesWithOffsets[datesWithOffsets.length - 1]
        const previousDataHour = previousData.time.getUTCHours() + previousData.utcOffsetMinutes
        // only hide the duplicate if the tick granularity is > 1.  At every-hour (hourCount==1),
        // the numbers fit together fine.  Only when you get zoomed out and your granularity is 6 or higher)
        // does it become a problem.
        return dataHour === previousDataHour && hourCount > 1 && dataHour !== 0
    }

    for (let i = 0; i < tickData.length; i++) {
        if (tickData[i] >= xScale.domain()[0] && tickData[i] <= xScale.domain()[1]) {
            let dataHour = tickData[i].getUTCHours() + offsetData[i]
            // for cases of UTC offset not being a whole number
            dataHour = Math.round(dataHour)
            // 0-based hour scale
            dataHour = (dataHour + 24) % 24 // Modulus 24 wraps things above 24hrs and + 24 wraps things below 0hrs with any offsets, SFC-2147

            if (hoursToInclude.includes(dataHour) && tickHourIsTooCloseToPrevious(dataHour) === false) {
                datesWithOffsets.push({ time: tickData[i], utcOffsetMinutes: offsetData[i] })
            }
        }
    }
    return datesWithOffsets
}

export const getGraphMidnightDates = (
    detailData: DetailDataTime[],
    startDate: Date,
    endDate: Date,
    timeMode: TimeModeEnum,
) => {
    const startTime = startDate.getTime()
    const endTime = endDate.getTime()
    const days = (endTime - startTime) / (60 * 60 * 1000 * 24)
    let tickCount: number
    if (days < 15) {
        tickCount = 1
    } else if (days < 35) {
        tickCount = 2
    } else {
        tickCount = 7
    }

    const dt = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1)

    const minutesDiff = (dt.getTime() - startDate.getTime()) / (1000 * 60)
    const minutesOffset =
        minutesDiff === 60 * 24 ? -startDate.getTimezoneOffset() : minutesDiff - startDate.getTimezoneOffset()

    // timezoneOffsetType => timeMode

    // this is the data that will, after formatting, be the dates along the top
    const midnightDatesWithUtcOffsets =
        timeMode === TimeModeEnum.Local || timeMode === TimeModeEnum.Base
            ? getLocalMidnights(detailData, timeMode, startDate, endDate, tickCount)
            : generateTickValues(startDate, endDate, minutesOffset, 60 * 24 * tickCount)

    return midnightDatesWithUtcOffsets
}

/**
 * Get an array of date/offsets for the bottom x-axis
 */
export const getDatesAndOffsetsForXAxis = (
    detailDataWithTicks: DetailDataWithTimelineTicks,
    timeMode: TimeModeEnum,
    config: GraphConfig,
    xScale: XScale,
) => {
    const start = xScale.domain()[0]
    const end = xScale.domain()[1]
    const startTime = start.getTime()
    const endTime = end.getTime()
    const days = (endTime - startTime) / (60 * 60 * 1000 * 24)

    // this determines how many x-axis labels & vertical lines up through the graph there are
    let hourCount = 24
    if (days < 2) {
        hourCount = 1
    } else if (days < 4) {
        hourCount = 2
    } else if (days < 7) {
        hourCount = 6
    }

    // SFC-2906 if the graph is too narrow we need to override above logic based on days showing
    if (config.graphWidth < 400) {
        // this will only happen after adding side pinned dash and making it the smallest width possible
        if (days < 2) hourCount = 6
        else if (days < 4) hourCount = 12
    } else if (config.graphWidth < 750 && days < 2) {
        hourCount = 2
    } else if (config.graphWidth / days < 225) {
        // graphWidth / days should be date header cell approx width
        if (days < 2) hourCount = 2
        else if (days < 8) hourCount = 6
        else if (days < 15) hourCount = 12
    }

    if (hourCount >= 24 && config.graphWidth / days < 20) {
        return []
    }

    if (timeMode === TimeModeEnum.Local || timeMode === TimeModeEnum.Base) {
        return getTickHours(detailDataWithTicks, hourCount, timeMode, xScale)
    }
    // utc
    const startDate = xScale.domain()[0]
    const offsetDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
    while (offsetDate.getTime() - startDate.getTimezoneOffset() * 60 * 1000 < startDate.getTime()) {
        offsetDate.setMinutes(offsetDate.getMinutes() + 60 * hourCount)
    }
    const minutesOffset = (offsetDate.getTime() - startDate.getTime()) / (1000 * 60) - startDate.getTimezoneOffset()
    return generateTickValues(xScale.domain()[0], xScale.domain()[1], minutesOffset, 60 * hourCount)
}

const ignoreMidnight = (detailData: DetailDataTime[], i: number, localMidnight: Date) => {
    let done = false
    let localMidnightIsInvalid = false
    for (let j = i + 1; !done && j < detailData.length; j++) {
        const nextDetail = detailData[j]
        if (nextDetail.utcTime.getTime() >= localMidnight.getTime()) {
            // we have gone past the calculated local midnight and not found
            // any tz change, so it is valid
            done = true
        } else if (nextDetail.tz !== detailData[i].tz) {
            // between the calculated local midnight and actual midnight time,
            // the tz changed, so that midnight is not valid
            localMidnightIsInvalid = true
            done = true
        }
    }
    return localMidnightIsInvalid
}

export const getLocalMidnights = (
    detailData: DetailDataTime[],
    offsetType: TimeModeEnum,
    startDate: Date,
    endDate: Date,
    tickCount: number,
) => {
    let d: Date = new Date(0)
    const midnights: DateOffset[] = []

    for (let i = 0; i < detailData.length; i++) {
        const detailTimeUtc = detailData[i].utcTime
        if (detailTimeUtc >= startDate && detailTimeUtc < endDate) {
            const utcOffsetMinutes = 60 * (offsetType === TimeModeEnum.Local ? detailData[i].tz : detailData[i].tzBase)
            const msUntilMidnight = dateTimeFormatting.getMsUntilLocalMidnight(detailTimeUtc, utcOffsetMinutes)
            const minutesUntilMidnight = msUntilMidnight / 60000

            // need a 15m threshold for looking for nearest midnight because we don't always have detail data every 1 minute.
            // data may be as coarse as every 15 minutes; see AnalysisDetails.cs ComputeGranularity.
            if (Math.abs(minutesUntilMidnight) <= 15) {
                const localMidnight = new Date(detailTimeUtc.getTime() + msUntilMidnight)

                let localMidnightIsInvalid = false
                if (minutesUntilMidnight > 0) {
                    // check if this midnight, which occurs later than this detail time
                    // is actually in the same timezone
                    localMidnightIsInvalid = ignoreMidnight(detailData, i, localMidnight)
                }

                const alreadyFoundLocalMidnight = midnights.find((x) => x.time.getTime() === localMidnight.getTime())
                if (!alreadyFoundLocalMidnight && !localMidnightIsInvalid) {
                    if (localMidnight.getTime() + utcOffsetMinutes * 60000 >= d.getTime()) {
                        midnights.push({ time: localMidnight, utcOffsetMinutes })
                        d = new Date(localMidnight.getTime() + utcOffsetMinutes * 60000 + 60 * 23 * tickCount * 60000)
                    }
                }
            }
        }
    }
    return midnights
}

export const formatDateWithTZOffset = (dt: Date, format: string, tzOffset: number): string =>
    moment(dt).utcOffset(tzOffset).format(format)
