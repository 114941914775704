import {
    Config,
    Fields,
    ImmutableTree,
    JsonGroup,
    JsonRule,
    RuleProperties,
    Utils as QbUtils,
} from 'react-awesome-query-builder'
import AntdConfig from 'react-awesome-query-builder/lib/config/antd'
import { ReportItemDefinition, ReportItemDefinitionDataType } from 'types/ReportingMetadata'
import { QueryBuilder, Rule, RuleOrQueryBuilder } from 'types/Reports'

type DataTypeDefinition = {
    type: string
    operators: string[]
    input?: string
    values?: any
}

const numericOperators = ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal']

/**
 * Data types and what operators are applicable. Maps from type in the c# code to a type known by this component
 */
const dataTypeMap: Record<ReportItemDefinitionDataType, DataTypeDefinition> = {
    string: {
        type: 'text',
        operators: ['equal', 'not_equal', 'like', 'not_like', 'starts_with', 'ends_with', 'is_empty'],
    },
    boolean: {
        type: 'boolean',
        input: 'boolean',
        operators: ['equal'],
        values: {
            true: 'True',
            false: 'False',
        },
    },
    single: {
        type: 'number',
        operators: numericOperators,
    },
    double: {
        type: 'number',
        operators: numericOperators,
    },
    integer: {
        type: 'number',
        operators: numericOperators,
    },
    int32: {
        type: 'number',
        operators: numericOperators,
    },
    datetime: {
        type: 'datetime',
        operators: ['equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
    },
}

/**
 * Data Item definitions from SafteFast to format needed for display in this component's list
 * @param dataItemDefinitions
 * @returns
 */
const convertSfDataItemsToQueryFormat = (dataItemDefinitions: ReportItemDefinition[]) => {
    return dataItemDefinitions.reduce<Fields>((sum, cur) => {
        const typeObj = dataTypeMap[cur.type]
        sum[cur.dataItem] = {
            type: typeObj.type,
            label: cur.displayName,
            operators: typeObj.operators,
        }
        return sum
    }, {})
}

/**
 * Convert from QueryBuilder rule to ReactAwesomeQueryBuilder rule format
 */
const convertQueryBuilderRuleToRAJsonRule = (rule: Rule): JsonRule => {
    /* eslint-disable */
    const { id, input, type, ...coreProperties } = rule
    /* eslint-enable */

    // const valueType = rule.input === 'select' ? 'boolean' : rule.input
    let valueType = rule.type
    if (valueType === 'string') valueType = 'text'
    if (valueType === 'single' || valueType === 'double' || valueType === 'integer' || valueType === 'int32') {
        valueType = 'number'
    }

    let operator = coreProperties.operator
    if (operator === 'in') operator = 'select_any_in'
    if (operator === 'not_in') operator = 'select_not_any_in'
    if (operator === 'contains') operator = 'like'
    if (operator === 'not_contains') operator = 'not_like'
    if (operator === 'begins_with') operator = 'starts_with'

    const properties: RuleProperties = {
        ...coreProperties,
        operator,
        valueSrc: ['value'],
        valueType: [valueType],
    }

    return {
        type: 'rule',
        properties,
    }
}

/**
 * Convert from QueryBuilder (jquery) format to ReactAwesomeQueryBuilder
 * @param query
 * @returns
 */
const convertQueryBuilderToRAJsonGroup = (query: QueryBuilder): JsonGroup => {
    const convertedChildren = query.rules
        .map((rule) => {
            if ('condition' in rule) {
                // recursive!
                return convertQueryBuilderToRAJsonGroup(rule as QueryBuilder)
            }
            return convertQueryBuilderRuleToRAJsonRule(rule as Rule)
        })
        .reduce<any>((sum, cur) => {
            sum[QbUtils.uuid()] = cur
            return sum
        }, {})

    const formattedQuery: JsonGroup = {
        type: 'group',
        properties: {
            conjunction: query.condition,
        },
        children1: convertedChildren,
    }
    return formattedQuery
}

/**
 * Go from ReactAwesome format to original QueryBuilder
 */
const convertRAToQueryBuilder = (
    tree: ImmutableTree,
    config: Config,
    dataItems: ReportItemDefinition[],
): QueryBuilder => {
    // const originalFieldTypes = original

    const toQueryBuilderRule = (raFormatRule: any) => {
        // align the rule format the comes out of the RA component with our
        // QueryBuilder format (from original jquery QueryBuilder and our c# filtering code)
        const ruleRaw = raFormatRule as any
        let type = dataItems.find((x) => x.dataItem === ruleRaw.fieldName)!.type
        // C#-side Castle.DynamicLinqQueryBuilder seems to need this.
        if (type === 'single') type = 'double'
        if (type === 'int32') type = 'integer'

        // C#-side Castle.DynamicLinqQueryBuilder needs this as it is only compatible with jquery QueryBuilder
        let operator = raFormatRule.operator
        if (operator === 'select_any_in') operator = 'in'
        if (operator === 'select_not_any_in') operator = 'not_in'
        if (operator === 'like') operator = 'contains'
        if (operator === 'not_like') operator = 'not_contains'
        if (operator === 'starts_with') operator = 'begins_with'

        return {
            ...raFormatRule,
            field: ruleRaw.fieldName,
            id: ruleRaw.fieldName,
            type,
            operator,
            value: [ruleRaw.values[0].value],
        }
    }

    const convertRule = (rule: RuleOrQueryBuilder) => {
        if ('condition' in rule) {
            // is QueryBuilder format, needs no adjusting.
            // SFC-3531: rules could nest group, so uses recursion to handle nested rule group
            const convertedGroup = { ...rule }
            convertedGroup.rules = convertedGroup.rules.map((r) => {
                if ('condition' in r) return convertRule(r)
                return toQueryBuilderRule(r)
            })
            return convertedGroup
        }

        return toQueryBuilderRule(rule)
    }

    const convertRules = (rules: RuleOrQueryBuilder[]) => rules.map(convertRule)

    const raFormatRaw = QbUtils.queryBuilderFormat(tree, config)
    if (!raFormatRaw) {
        // no query at all
        return {
            condition: 'AND',
            rules: [],
            valid: true,
        }
    }
    // expand arrays that are not in standard array format
    const query = JSON.parse(JSON.stringify(raFormatRaw)) as QueryBuilder
    query.rules = convertRules(query.rules)
    query.valid = true

    return query
}

/**
 * Convert from queryBuilder format to JsonGroup
 */
const convertQueryBuilderToSql = (query: QueryBuilder, dataItems: ReportItemDefinition[]): string => {
    if (!query) {
        return ''
    }
    const { convertedQuery, config } = convertQueryBuilderToRAConfiguration(dataItems, query)
    const tree = QbUtils.checkTree(QbUtils.loadTree(convertedQuery), config)
    return QbUtils.sqlFormat(tree, config) || ''
}

/**
 * Convert from queryBuilder format to JsonGroup
 */

const convertQueryBuilderToRAConfiguration = (
    dataItems: ReportItemDefinition[],
    query?: QueryBuilder,
): { convertedQuery: JsonGroup; config: Config } => {
    // Choose your skin (ant/material/vanilla):

    const InitialConfig = AntdConfig // or MaterialConfig or BasicConfig

    // SFC-3546: Change default operator's lable same with V5 lable style
    InitialConfig.operators = {
        ...InitialConfig.operators,
        equal: { ...InitialConfig.operators.equal, label: 'equal' },
        not_equal: { ...InitialConfig.operators.not_equal, label: 'not equal' },
        less: { ...InitialConfig.operators.less, label: 'less' },
        less_or_equal: { ...InitialConfig.operators.less_or_equal, label: 'less or equal' },
        greater: { ...InitialConfig.operators.greater, label: 'greater' },
        greater_or_equal: { ...InitialConfig.operators.greater_or_equal, label: 'greater or equal' },
        like: { ...InitialConfig.operators.like, label: 'contains' },
        not_like: { ...InitialConfig.operators.not_like, label: "doesn't contain" },
        starts_with: { ...InitialConfig.operators.starts_with, label: 'begins with' },
        ends_with: { ...InitialConfig.operators.ends_with, label: 'ends with' },
        is_empty: { ...InitialConfig.operators.is_empty, label: 'is empty' },
        select_any_in: { ...InitialConfig.operators.select_any_in, label: 'in' },
        select_not_any_in: { ...InitialConfig.operators.select_not_any_in, label: 'not in' },
    }
    const config: Config = {
        ...InitialConfig,
        fields: convertSfDataItemsToQueryFormat(dataItems),
    }

    // v5 doesn't have the "not", and not sure how to easily convert into the formatted needed by the back-end
    config.settings.showNot = false

    let convertedQuery: JsonGroup = {
        type: 'group',
    }
    if (!query) {
        // blank
        convertedQuery = { id: QbUtils.uuid(), type: 'group' }
    } else {
        convertedQuery = convertQueryBuilderToRAJsonGroup(query)
    }

    convertedQuery.id = QbUtils.uuid()
    return { convertedQuery, config }
}

export { convertQueryBuilderToRAConfiguration, convertQueryBuilderToSql, convertRAToQueryBuilder }
