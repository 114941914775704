import { memo } from 'react'
import { HazardClass } from 'types/HazardClass'
import HazardClassCase from 'types/HazardClassCase'
import PatternsPieChart from '../PatternPanel/PatternsPieChart'
import DutiesPercentOfTotalBarChart from './DutiesPercentOfTotalBarChart'
import DutiesStackedBarChart from './DutiesStackedBarChart'
import HazardClassLabelledMetrics from './HazardClassLabelledMetrics'
import classes from './HazardClassPanel.module.css'

export const HazardClassPanelOverallHeight = 200

const HazardClassPanel = ({
    hazardClass,
    patternSeriesClicked,
    isPrintPreview,
}: {
    hazardClass: HazardClass
    patternSeriesClicked?: (hcCase: HazardClassCase) => void
    isPrintPreview?: boolean
}) => {
    const bottomMargin = 10
    const height = HazardClassPanelOverallHeight - bottomMargin
    const patterns = hazardClass.casesParsed || []
    const patternsForBarCharts = patterns.slice(0, hazardClass.patternsLimit).filter((x) => !x.hidden)
    const containerClasses = `${classes.container} ${isPrintPreview ? classes.containerPrint : ''}`
    const chartClasses = `${classes.clickableChart} ${isPrintPreview ? classes.chartPrint : ''}`
    return (
        <div
            className={containerClasses}
            style={{
                height,
                marginBottom: bottomMargin,
            }}
        >
            <HazardClassLabelledMetrics hazardClass={hazardClass} />
            <PatternsPieChart
                diameter={height}
                hazardClass={hazardClass}
                title="All Duties by Insights Score"
                useLargeTitle
                showLegend
            />
            <DutiesStackedBarChart
                height={height}
                isPrintPreview={isPrintPreview === true}
                patterns={patternsForBarCharts}
                patternSeriesClicked={patternSeriesClicked}
                className={chartClasses}
            />
            <DutiesPercentOfTotalBarChart
                height={height}
                isPrintPreview={isPrintPreview === true}
                patterns={patternsForBarCharts}
                patternSeriesClicked={patternSeriesClicked}
                className={chartClasses}
            />
        </div>
    )
}

export default memo(HazardClassPanel)
