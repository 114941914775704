import { parseStation, Station } from 'types/interfaces'
import Axios from '../axios/axios-sfc'

class SFStationsApi {
    constructor(private axios: Axios) {}

    getStationMatches = async (searchString: string): Promise<Station[]> => {
        const data = (await this.axios.get(`/Api/Stations/GetStationsByPartialTextMatch?term=${searchString}`))
            .data as Array<any>
        return data.map(parseStation)
    }

    getUtcOffsetAtTime = async (stationCode: string, timeUtc: Date): Promise<number> => {
        const offset = (
            await this.axios.get(
                `/Api/Stations/GetUtcOffsetMinutes?stationCode=${stationCode}&time=${timeUtc.getTime()}`,
            )
        ).data
        return offset as number
    }
}

export default SFStationsApi
