import { GridCellProps } from '@progress/kendo-react-grid'
import ScheduleEvent from 'types/ScheduleEvent'
import AutoEventIcon from '../Widget/AutoEventIcon'
import DutyRollupIconButton from '../Widget/DutyRollupIconButton'
import EventTypeColorIcon from '../Widget/EventTypeColorIcon'
import { FormattedNameCell } from './FormattedNameCell'
import classes from './FormattedNameCell.module.css'

const EventLabelCell = (
    props: GridCellProps,
    isCollapsed: boolean,
    allEvents: ScheduleEvent[],
    clicked: (scheduleEvent: ScheduleEvent) => void,
    clickDutyToggle: (operation: 'expand' | 'collapse', dutyId: string) => void,
) => {
    const scheduleEvent = props.dataItem as ScheduleEvent
    let eventLabel = scheduleEvent.label
    if (scheduleEvent.isDutyRollup()) {
        eventLabel = `Duty ${scheduleEvent.dutyNumber} - ${scheduleEvent.from} to ${scheduleEvent.to}`
    }

    const labelClassCrewing = `${scheduleEvent.crewing ? classes.labelCrewing : ''}`
    const dutyIndentPixels = scheduleEvent.isPartOfDuty(allEvents) && !scheduleEvent.isDutyRollup() ? 30 : 0

    // used in the kendo custom code to bypass row selection
    const gridButtonClass = 'grid-button'
    const nonInteractiveEventLabelClass =
        scheduleEvent.isEditable() || scheduleEvent.isDutyRollup() ? 'linkStyle' : classes.labelButtonNonInteractive

    let sleepCodeDescription = null
    if (scheduleEvent.isAnySleep()) {
        if (scheduleEvent.isAutoSleep()) {
            eventLabel = scheduleEvent.getSleepCodeDescription()
            sleepCodeDescription = `${scheduleEvent.sleepCode}`
        }
    }

    return FormattedNameCell(
        props,
        <span style={{ marginLeft: `${dutyIndentPixels}px`, whiteSpace: 'nowrap' }}>
            {!scheduleEvent.isDutyRollup() && <AutoEventIcon scheduleEvent={scheduleEvent} />}
            {!scheduleEvent.isDutyRollup() && <EventTypeColorIcon scheduleEvent={scheduleEvent} />}
            <DutyRollupIconButton
                scheduleEvent={scheduleEvent}
                isCollapsed={isCollapsed}
                clickDutyToggle={clickDutyToggle}
            />
            <button
                type="button"
                className={`transparentButton ${classes.transparentButton} ${nonInteractiveEventLabelClass} ${gridButtonClass}`}
                onClick={() => {
                    if (scheduleEvent.isEditable() || scheduleEvent.isDutyRollup()) {
                        clicked(scheduleEvent)
                    }
                }}
            >
                <span className={`grid-button ${labelClassCrewing}`} style={{ marginLeft: '4px' }}>
                    {eventLabel} {sleepCodeDescription != null && <span> - {sleepCodeDescription}</span>}
                </span>
            </button>
        </span>,
    )
}

export default EventLabelCell
