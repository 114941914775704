import { EventBase, SleepQualityEnum } from './interfaces'

export enum EventDataType {
    work,
    sleep,
    marker,
}

export type IntervalType = 'daylight' | 'dark' | 'twilight'

class Interval extends EventBase {
    public label: string
    public duration: number
    public eventType: EventDataType | null
    public tzToMinutes: number
    public tzFromMinutes: number
    public tzBaseMinutes: number
    public uniqueName: string | null

    constructor(
        public start: Date,
        public end: Date | null,
        public tzFrom: number,
        public tzBase: number | null,
        public isLightInterval: boolean,
        public type: IntervalType,
    ) {
        super()
        this.start = start
        this.end = end
        this.isLightInterval = isLightInterval
        this.type = type
        this.tzBase = tzBase
        this.tzFrom = tzFrom

        this.eventType = null
        this.duration = 0
        this.tzToMinutes = 0
        this.tzFromMinutes = 0
        this.tzBaseMinutes = 0
        this.uniqueName = null

        switch (this.type) {
            case 'dark':
                this.label = 'Night'
                break
            case 'daylight':
                this.label = 'Day'
                break
            case 'twilight':
                this.label = 'Twilight'
                break
            default:
                throw Error('unexpected type:' + this.type)
        }
    }

    getLabel(): string {
        switch (this.type) {
            case 'dark':
                return 'Night'
            case 'daylight':
                return 'Day'
            case 'twilight':
                return 'Twilight'
            default:
                throw Error('unexpected type:' + this.type)
        }
    }
    getDurationInMinutes(): number {
        return this.duration || 0
    }
    isAutoSleep(): boolean {
        return false
    }
    isAnySleep(): boolean {
        return false
    }
    isWorkEvent(): boolean {
        return false
    }
    isAnyMarker(): boolean {
        return false
    }
    getSleepQuality(): SleepQualityEnum | null {
        return null
    }
    getSleepCode(): string | null {
        return null
    }
    getDutyNumber(): number {
        return 0
    }
    shouldHaveWaypointMarkersDisplayed(): boolean {
        return false
    }
    getBasicEventType(): 'interval' | 'scheduleEvent' {
        return 'interval'
    }

    getDataType(): string {
        return this.type
    }

    getStartMs(): number {
        return this.start.getTime()
    }

    getEndMs(): number {
        return this.end?.getTime() ?? 0
    }
}

export default Interval
