import { ChangeEvent, FormEvent, useState } from 'react'
import { Alert, Form, Modal } from 'react-bootstrap'
import { isDesktopMode } from 'services/axios/axios-sfc'
import Scenario from 'types/Scenario'
import { ScenarioSelection } from 'types/ScenarioSelection'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import KendoGridCustom, { SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import classes from './ScenarioSelectionDialog.module.css'
import columnDefinitions from './ScenarioSelectorDialogGridColumns'

export type ScenarioSelectionDialogProps = {
    scenarios: Scenario[]
    initialSelection: ScenarioSelection
    closeCallback: (status: DialogResultEnum, scenarioSelection?: ScenarioSelection) => void
}

const ScenarioSelectorDialogContent = (props: ScenarioSelectionDialogProps) => {
    const [scenarioSelection, setScenarioSelection] = useState<ScenarioSelection>(props.initialSelection)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [validationError, setValidationError] = useState<string | null>(null)

    // kendo grid state
    const [stateColumns, setStateColumns] = useState<Array<KendoGridColumn>>(() => {
        const webOnlyFields = ['modifiedDate', 'scenarioOwnerName']
        return columnDefinitions.filter((x) => !isDesktopMode() || !webOnlyFields.includes(x.field!))
    })
    const defaultSelectionState = scenarioSelection.selectedScenarioIds
        .split(',')
        .filter((x) => x)
        .map((x) => parseInt(x))
        .reduce((sum: any, cur): SelectionState => {
            sum[cur] = true
            return sum
        }, {})

    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>(defaultSelectionState)
    const selectedScenarioIds = Object.keys(selectedRowsState)
        .map((key) => selectedRowsState[key])
        .filter((x) => x)

    const usesIndividualSelection =
        isDesktopMode() || (!scenarioSelection.includeAllMyScenarios && !scenarioSelection.includeAllScenariosSharedwMe)

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        if (
            !scenarioSelection.includeAllMyScenarios &&
            !scenarioSelection.includeAllScenariosSharedwMe &&
            scenarioSelection.selectedScenarioIds.split(',').filter((x) => x).length === 0
        ) {
            setValidationError('Must have at least 1 Scenario selected')
            return
        }

        // set the name if it is a single selected scenario
        if (usesIndividualSelection) {
            const singleSelectedScenario = props.scenarios.find(
                (x) => x.id.toString() === scenarioSelection.selectedScenarioIds,
            )
            // shouldn't really mutate the state variable, but we're done with it at this point
            if (singleSelectedScenario) {
                scenarioSelection.selectedScenarioName = singleSelectedScenario.name
            }
        }

        try {
            const scenarioSelectionValue: ScenarioSelection = isDesktopMode()
                ? { ...scenarioSelection, includeAllMyScenarios: false, includeAllScenariosSharedwMe: false }
                : scenarioSelection
            props.closeCallback(DialogResultEnum.Completed, scenarioSelectionValue)
        } catch (err: any) {
            setErrorMessage(err.message)
        }
    }

    const selectedRowsStateToCommaSeparatedString = (selectionState: SelectionState) => {
        return Object.keys(selectionState)
            .filter((x) => selectionState[x])
            .join(',')
    }

    return (
        <>
            <Form id="importScenarioDialog" onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Scenario Selection ${
                            usesIndividualSelection ? `[ ${selectedScenarioIds.length} selected ]` : ''
                        }`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isDesktopMode() && (
                        <Form.Group>
                            <Form.Check
                                id="chkIncludeAllMy"
                                label="Include All My Current &amp; Future Scenarios"
                                type="checkbox"
                                checked={scenarioSelection.includeAllMyScenarios}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setScenarioSelection((previous) => {
                                        const gridSelectedRows =
                                            selectedRowsStateToCommaSeparatedString(selectedRowsState)
                                        return {
                                            ...previous,
                                            includeAllMyScenarios: e.target.checked,
                                            selectedScenarioIds: !e.target.checked ? gridSelectedRows : '',
                                        }
                                    })
                                }}
                            />
                            <Form.Check
                                id="chkIncludeAllShared"
                                label="Include All Current &amp; Future Scenarios Shared With Me"
                                type="checkbox"
                                checked={scenarioSelection.includeAllScenariosSharedwMe}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setScenarioSelection((previous) => {
                                        const gridSelectedRows =
                                            selectedRowsStateToCommaSeparatedString(selectedRowsState)
                                        return {
                                            ...previous,
                                            includeAllScenariosSharedwMe: e.target.checked,
                                            selectedScenarioIds: !e.target.checked ? gridSelectedRows : '',
                                        }
                                    })
                                }}
                            />

                            <small className="text-muted">
                                Enable manual selection of scenarios by unchecking the Include All options above.
                            </small>
                            {validationError && <p className={classes.validationError}>{validationError}</p>}
                        </Form.Group>
                    )}

                    <Form.Group>
                        <KendoGridCustom
                            centeredContent
                            filterable={usesIndividualSelection}
                            localStorageKeyForColumnState="scenarioSelectionDialogGridColumn"
                            localStorageKeyForGridDataState="scenarioSelectionDialogGridData"
                            height={isDesktopMode() ? '400px' : '500px'}
                            noSelection={!usesIndividualSelection}
                            data={props.scenarios}
                            defaultSort={{ field: 'modifiedDate', dir: 'desc' }}
                            columns={stateColumns}
                            selectedRowsState={selectedRowsState}
                            onSetSelectedRowsState={(newSelectionState: SelectionState) => {
                                setSelectedRowsState(newSelectionState)
                                const newlySelectedScenarioIds =
                                    selectedRowsStateToCommaSeparatedString(newSelectionState)
                                setScenarioSelection((previous) => {
                                    return {
                                        ...previous,
                                        selectedScenarioIds: newlySelectedScenarioIds,
                                    }
                                })
                            }}
                            setColumnVisibility={(newColumnState: KendoGridColumn[]) => setStateColumns(newColumnState)}
                        />
                    </Form.Group>

                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom isLarge type="submit" variant="primary">
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const ScenarioSelectorDialog = (props: ScenarioSelectionDialogProps) => {
    return (
        <ModalWrapper
            className="DialogMediumWidth"
            closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}
        >
            <ScenarioSelectorDialogContent {...props} />
        </ModalWrapper>
    )
}

export default ScenarioSelectorDialog
