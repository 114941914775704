import React, { CSSProperties } from 'react'

const InputValidationWrapper = (props: { invalid: boolean; invalidText?: string; children: React.ReactNode }) => {
    const styling: CSSProperties = props.invalid ? { border: '1px solid red', padding: 4 } : {}
    return (
        <>
            <div style={styling}>{props.children}</div>
            {props.invalid && <small style={{ color: 'red' }}>{props.invalidText}</small>}
        </>
    )
}

export default InputValidationWrapper
