import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns'
import { GridFilterCellProps } from '@progress/kendo-react-grid'

interface DropdownFilterCellProps extends GridFilterCellProps {
    defaultItem: string
    data: string[]
}

const DropdownFilterCell = (props: DropdownFilterCellProps) => {
    let hasValue: any = (value: any) => Boolean(value && value !== props.defaultItem)

    const onChange = (event: DropDownListChangeEvent) => {
        hasValue = hasValue(event.target.value)
        props.onChange({
            value: hasValue ? event.target.value : '',
            operator: hasValue ? 'eq' : '',
            syntheticEvent: event.syntheticEvent,
        })
    }

    const onClearButtonClick = (event: any) => {
        event.preventDefault()
        props.onChange({
            value: '',
            operator: '',
            syntheticEvent: event,
        })
    }
    return (
        <div className="k-filtercell">
            <DropDownList
                data={props.data}
                onChange={onChange}
                value={props.value || props.defaultItem}
                defaultItem={props.defaultItem}
            />
            <button
                type="button"
                className="k-button k-button-icon k-clear-button-visible"
                title="Clear"
                disabled={!hasValue(props.value)}
                onClick={onClearButtonClick}
            >
                <span className="k-icon k-i-filter-clear" />
            </button>
        </div>
    )
}

export default DropdownFilterCell
