import React from 'react'
import { useSelector } from 'react-redux'
import { RecalculateItemType } from 'services/api/sfProcessingApi'
import { RootState } from 'store/store'
import classes from './DashboardThumbnailControlButtons.module.css'
import EllipsisDropdown, { EllipsisDropdownItem, ItemWithIcon } from './EllipsisDropdown'
import InsightsRefreshButton from './InsightsRefreshButton'
import ReportRefreshButton from './ReportRefreshButton'
import TransparentButton from './TransparentButton'

const DashboardThumbnailControlButtons = (props: {
    showRefreshButton: boolean
    isRefreshing: boolean
    showOverrideInfoButton: boolean
    itemType: RecalculateItemType
    onClickBeginRefresh: () => void
    onEditClick: () => void
    onCopyClick?: () => void
    onDeleteClick: () => void
}) => {
    const reportsRefreshJustFinished = useSelector<RootState, boolean>(
        (x) => x.reportsRecalculation.isCompletingRecalculation,
    )

    const insightsRefreshJustFinished = useSelector<RootState, boolean>(
        (x) => x.insightsRecalculation.isCompletingRecalculation,
    )

    const itemTypeLabel = props.itemType === 'Insights' ? 'Hazard Class' : 'Report'
    const editLabel = `Edit ${itemTypeLabel} Configuration`
    const copyLabel = `Copy ${itemTypeLabel}`
    const deleteLabel = `Delete ${itemTypeLabel}`

    const scenarioOverridden = !props.isRefreshing && props.showOverrideInfoButton

    return (
        <div className={classes.container}>
            {props.showRefreshButton && props.itemType === 'Report' && (
                <ReportRefreshButton
                    isRefreshing={props.isRefreshing}
                    onClick={props.onClickBeginRefresh}
                    isIconButton
                    refreshJustFinished={reportsRefreshJustFinished}
                    requiresRefresh={props.showRefreshButton}
                />
            )}
            {props.showRefreshButton && props.itemType === 'Insights' && (
                <InsightsRefreshButton
                    isRefreshing={props.isRefreshing}
                    onClick={props.onClickBeginRefresh}
                    isIconButton
                    refreshJustFinished={insightsRefreshJustFinished}
                    requiresRefresh={props.showRefreshButton}
                />
            )}

            <TransparentButton
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault()
                    props.onEditClick()
                }}
                tooltip={scenarioOverridden ? 'Dashboard scenario selection is overridden' : ''}
            >
                {scenarioOverridden && <i className="bi-info-circle text-primary" />}
            </TransparentButton>

            <EllipsisDropdown>
                <EllipsisDropdownItem onClick={props.onEditClick} disabled={props.isRefreshing}>
                    <ItemWithIcon bootstrapIconClass="bi-pencil">{editLabel}</ItemWithIcon>
                </EllipsisDropdownItem>
                <>
                    {props.itemType === 'Insights' && (
                        <EllipsisDropdownItem onClick={() => props.onCopyClick?.()} disabled={props.isRefreshing}>
                            <ItemWithIcon bootstrapIconClass="bi-files">{copyLabel}</ItemWithIcon>
                        </EllipsisDropdownItem>
                    )}
                </>
                <EllipsisDropdownItem onClick={props.onDeleteClick} disabled={props.isRefreshing}>
                    <ItemWithIcon bootstrapIconClass="bi-trash">{deleteLabel}</ItemWithIcon>
                </EllipsisDropdownItem>
            </EllipsisDropdown>
        </div>
    )
}

export default DashboardThumbnailControlButtons
