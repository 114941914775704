import { State } from '@progress/kendo-data-query'
import { parseFilterFieldsFromGridDataState } from 'services/utilities/kendoGridUtils'
import useLocalStorage, { UpdateMode } from './useLocalStorage'

const useGridDataStateFromLocalStorage = (
    dataStateStorageKey: string,
    filteringEnabledStorageKey: string,
): [
    boolean,
    string[],
    State,
    (value: State, updateMode: UpdateMode) => void,
    (value: boolean, updateMode: UpdateMode) => void,
] => {
    const [filteringEnabled, setFilteringEnabled] = useLocalStorage<boolean>(filteringEnabledStorageKey, false)
    const [gridDataState, setGridDataState] = useLocalStorage<State>(dataStateStorageKey, {})
    const filterFields = parseFilterFieldsFromGridDataState(gridDataState)
    return [filteringEnabled, filterFields, gridDataState, setGridDataState, setFilteringEnabled]
}

export default useGridDataStateFromLocalStorage
