import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import RefreshButton, { RefreshButtonProps } from './RefreshButton'

const InsightsRefreshButton = (props: Omit<RefreshButtonProps, 'recalculationState' | 'useSynchronousRefreshOnly'>) => {
    const synchronousOnly = useSelector<RootState, boolean | undefined>((x) => x.app.user?.refreshInsightsSynchronously)
    return <RefreshButton {...props} useSynchronousRefreshOnly={Boolean(synchronousOnly)} />
}

export default InsightsRefreshButton
