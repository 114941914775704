import { GridCellProps, GridRowProps } from '@progress/kendo-react-grid'
import React from 'react'

interface CellRenderProps {
    originalProps: GridCellProps
    td: React.ReactElement<HTMLTableCellElement>
    enterEdit: (dataItem: any, field: string | undefined) => void
    editField: string | undefined
}

interface RowRenderProps {
    originalProps: GridRowProps
    tr: React.ReactElement<HTMLTableRowElement>
    exitEdit: () => void
    editField: string | undefined
}

export const CellRender = (props: CellRenderProps) => {
    const dataItem = props.originalProps.dataItem
    const cellField = props.originalProps.field
    const inEditField = dataItem[props.editField || '']
    const additionalProps =
        cellField && cellField === inEditField
            ? {
                  ref: (td: any) => {
                      const input = td && td.querySelector('input')
                      const activeElement = document.activeElement

                      if (!input || !activeElement || input === activeElement || !activeElement.contains(input)) {
                          return
                      }

                      if (input.type === 'checkbox') {
                          input.focus()
                      } else {
                          input.select()
                      }
                  },
              }
            : {
                  onClick: (e: React.MouseEvent<HTMLElement>) => {
                      const target = e.target as HTMLElement
                      if (target.nodeName !== 'TD') {
                          // if another type, it must already be in edit mode
                          // possibly a dropdown list that the user is trying to open.
                          return
                      }
                      props.enterEdit(dataItem, cellField)
                  },
              }

    const clonedProps: any = { ...props.td.props, ...additionalProps }
    return React.cloneElement(props.td, clonedProps, props.td.props.children)
}

export const RowRender = (props: RowRenderProps) => {
    const trProps = {
        ...props.tr.props,
        onBlur: () => {
            props.exitEdit()
        },
    }
    return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children)
}
