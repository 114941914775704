import SimpleDialog from 'views/Common/GenericDialogs/SimpleDialog'
import { ModalWrapperClassName } from './ModalWrapper'

const ErrorDialog = (props: {
    dialogClass?: ModalWrapperClassName
    headerText?: string
    message: string | JSX.Element | null
    closeCallback: () => void
}) => {
    if (!props.message) {
        return <></>
    }

    return props.message ? (
        <SimpleDialog
            dialogClass={props.dialogClass}
            isError
            headerText={props.headerText ?? 'Error'}
            closeCallback={props.closeCallback}
        >
            {props.message}
        </SimpleDialog>
    ) : (
        <></>
    )
}

export default ErrorDialog
