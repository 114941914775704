export const sortItemsByStringField = <T, K extends keyof T>(items: T[], sortField: K) => {
    items.sort((item1, item2) => {
        const item1Lower = (item1[sortField] as unknown as string).toLowerCase()
        const item2Lower = (item2[sortField] as unknown as string).toLowerCase()
        if (item1Lower < item2Lower) return -1
        if (item1Lower > item2Lower) return 1
        return 0
    })
}
export const sortItems = <T, K extends keyof T>(items: T[], sortField: K, descending?: boolean) => {
    const isLessVal = descending === true ? 1 : -1
    const isGreaterVal = descending === true ? -1 : 1
    items.sort((item1, item2) => {
        const item1Field = item1[sortField]
        const item2Field = item2[sortField]
        if (item1Field < item2Field) return isLessVal
        if (item1Field > item2Field) return isGreaterVal
        return 0
    })
}

// can we make this generic to find the highest of any numeric property?
export const getHighestId = (items: { id: number }[]) => Math.max(...items.map((x) => x.id))
export const getLowestId = (items: { id: number }[]) => Math.min(0, Math.min(...items.map((x) => x.id)))
