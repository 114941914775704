import {
    Chart,
    ChartArea,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartTooltip,
} from '@progress/kendo-react-charts'
import { HazardClass } from 'types/HazardClass'

export const CircleChartPadding = 10
export const PieChartColorRed = '#ee2a2a'
export const PieChartColorOrange = '#ffc000'
export const PieChartColorYellow = '#ffff00'
export const PieChartColorGreen = '#92d050'

const renderPieTooltip = (context: any) => {
    const { category, value } = context.point || context
    return (
        <div>
            {value} Duties w Score {category}
        </div>
    )
}

export const ChartTitleCustom = ({ titleText }: { titleText: string }) => (
    <ChartTitle text={titleText} position="bottom" font="11.5px sans-serif" align="center" />
)

const PatternsPieChart = ({
    useLargeTitle,
    showLegend,
    diameter,
    hazardClass,
    title,
}: {
    useLargeTitle?: boolean
    showLegend?: boolean
    diameter: number
    hazardClass: HazardClass
    title: string
}) => {
    const pieSeries = [
        {
            category: '< 70',
            value: hazardClass.dutiesCountLT70,
            color: PieChartColorRed,
        },
        {
            category: '70-75',
            value: hazardClass.dutiesCountBTW7075,
            color: PieChartColorOrange,
        },
        {
            category: '75-80',
            value: hazardClass.dutiesCountBTW7580,
            color: PieChartColorYellow,
        },
        {
            category: '> 80',
            value: hazardClass.dutiesCountGT80,
            color: PieChartColorGreen,
        },
    ]

    const titleEl = useLargeTitle ? (
        <ChartTitle text={title} font="15px sans-serif" padding={0} margin={0} />
    ) : (
        <ChartTitleCustom titleText={title} />
    )
    return (
        <Chart transitions={false} style={{ height: '100%' }}>
            {titleEl}
            {showLegend && (
                <ChartLegend position="bottom" orientation="horizontal" labels={{ font: '12px sans-serif' }} />
            )}
            {!showLegend && <ChartLegend visible={false} />}
            <ChartTooltip render={renderPieTooltip} />
            <ChartArea background="none" height={diameter} width={diameter * 1.5} />
            <ChartSeries>
                <ChartSeriesItem
                    type="pie"
                    data={pieSeries}
                    field="value"
                    categoryField="category"
                    padding={CircleChartPadding}
                />
            </ChartSeries>
        </Chart>
    )
}

export default PatternsPieChart
