import { HazardClass } from 'types/HazardClass'

const HazardClassLabelledMetrics = ({ hazardClass }: { hazardClass: HazardClass }) => {
    const cases = hazardClass.casesParsed || []

    return (
        <div style={{ minWidth: 180 }}>
            <MetricValue name="Total Duties" value={hazardClass.dutiesCountAll!} />
            <MetricValue name="Hazard Duties" value={hazardClass.hazardDutyCount!} />
            <MetricValue name="Min Matches" value={hazardClass.filterTPC80} />
            <MetricValue name="Min Match %" value={hazardClass.filterTPR80} units="%" />
            <MetricValue name="Total Patterns" value={cases.length} />
        </div>
    )
}

const MetricValue = (props: { name: string; value: number; units?: string }) => {
    return (
        <div style={{ fontSize: '0.9em' }}>
            <strong style={{ width: 120, display: 'inline-block' }}>{props.name}:</strong>
            <span>
                {props.value}
                {props.units ?? ''}
            </span>
        </div>
    )
}

export default HazardClassLabelledMetrics
