import { CSSProperties, useRef } from 'react'
import Draggable from 'react-draggable'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import formatDecimal from 'services/formatting/numberFormatter'
import { DetailData } from 'types/DetailItem'
import { ColorPalettes, TimeModeEnum } from 'types/interfaces'
import { getColorFromPaletteForMetric, PaletteType } from 'views/Common/Palette/PalettedMetric'
import * as graphTimeUtils from '../EffectivenessGraph/EffectivenessGraphTimeUtils'
import DashboardControlCircle from './DashboardControlCircle'
import classes from './GraphDashboard.module.css'
import GraphDashboardControlButtons from './GraphDashboardControlButtons'
import GraphDashboardMetricsBlock from './GraphDashboardMetricsBlock'
import GraphDashboardSummary from './GraphDashboardSummary'

export interface GraphDashboardProps {
    timeMode: TimeModeEnum
    orientationHorizontal: boolean
    colorPalettes: ColorPalettes
    dashboardNumber: number
    dataItem: DetailData
    leftX?: number
    isPrintPreview: boolean
    closeClick: () => void
    pinClick: () => void
}

const formatHrs = (hrs: number) => dateTimeFormatting.formatHrsDurationHhmm(hrs)
const formatMins = (mins: number) => dateTimeFormatting.formatMinsDurationHhmm(mins)

const GraphDashboard = (props: GraphDashboardProps) => {
    const dataItem = props.dataItem
    const getPaletteColor = (type: PaletteType, value: number) =>
        getColorFromPaletteForMetric(type, value, props.colorPalettes)
    const effectivenessColor = getPaletteColor('effectiveness', dataItem.effectiveness)
    const effectiveness0Color = getPaletteColor('effectiveness', 0)
    const getAlertColor = (fn: () => boolean) => (fn() ? effectiveness0Color : 'white')

    const nodeRef = useRef(null)
    let isPinned = false
    const styleOverrides: CSSProperties = {}
    if (props.leftX) {
        styleOverrides.left = `${props.leftX}px`
    } else {
        // Pinned dashboard
        isPinned = true

        styleOverrides.top = '0px'
        styleOverrides.left = '0px'
        styleOverrides.marginTop = '16px'
        styleOverrides.position = 'relative'
        styleOverrides.boxShadow = 'none'
        styleOverrides.borderRadius = '0px'

        styleOverrides.cursor = 'default'

        if (props.isPrintPreview) {
            styleOverrides.marginTop = '0px'
            styleOverrides.position = 'static'
        }
    }

    const outOfPhaseWarning = Math.abs(dataItem.outOfPhaseHrs) > 3
    const minsAwakeWarning = dataItem.minsAwake > 1020
    const sleepDebtWarning = dataItem.sleepDebtMins > 480
    const sleep24MinsWarning = dataItem.sleep24Mins < 480
    const reservoirWarning = dataItem.sleepReservoir < 75

    const woclValueToTimeString = (woclValue: number): string => {
        // ported from v5
        const n = new Date(0, 0)
        n.setSeconds(+woclValue * 60 * 60)
        return n.toTimeString().slice(0, 5)
    }

    const metricsBlocksClass = props.orientationHorizontal ? classes.metricsBlocksHorizontal : ''
    const dashboardOrientationClass = props.orientationHorizontal ? classes.dashboardHorizontal : ''
    const performanceBlockMarginTop = props.orientationHorizontal ? '' : '10px'

    let tz = dataItem.tz
    if (props.timeMode === TimeModeEnum.Base) {
        tz = dataItem.tzBase
    } else if (props.timeMode === TimeModeEnum.UTC) {
        tz = 0
    }
    return (
        <Draggable nodeRef={nodeRef} disabled={isPinned}>
            <div ref={nodeRef} className={`${classes.dashboard} ${dashboardOrientationClass}`} style={styleOverrides}>
                {!props.isPrintPreview && (
                    <GraphDashboardControlButtons
                        isPinned={isPinned}
                        closeClick={props.closeClick}
                        pinClick={props.pinClick}
                    />
                )}
                <GraphDashboardSummary
                    metricColor={effectivenessColor}
                    metricValue={dataItem.effectiveness}
                    metricTime={graphTimeUtils.formatDateWithTZOffset(
                        dataItem.utcTime,
                        dateTimeFormatting.getAppDateTimeFormat(),
                        tz,
                    )}
                    utcOffset={tz}
                />
                <div className={classes.dashboardNumberContainer}>
                    <DashboardControlCircle tooltip="Line Number">
                        <span style={{ color: '#333' }}>{props.dashboardNumber}</span>
                    </DashboardControlCircle>
                </div>

                <div className={metricsBlocksClass}>
                    <GraphDashboardMetricsBlock
                        title="Fatigue Factors"
                        rows={[
                            {
                                label: 'Sleep 24 Hours',
                                labelTitle: '< 8 hours',
                                value: formatMins(dataItem.sleep24Mins),
                                color: getAlertColor(() => sleep24MinsWarning),
                                flag: sleep24MinsWarning,
                                flagText: 'Less than 8 hours sleep in 24 hours',
                            },
                            {
                                label: 'Sleep Debt',
                                labelTitle: '> 8 hours',
                                value: formatMins(dataItem.sleepDebtMins),
                                color: getAlertColor(() => sleepDebtWarning),
                                flag: sleepDebtWarning,
                                flagText: 'More than 8 hours sleep debt',
                            },
                            {
                                label: 'Hours Awake',
                                labelTitle: '> 17 hours',
                                value: formatMins(dataItem.minsAwake),
                                color: getAlertColor(() => minsAwakeWarning),
                                flag: minsAwakeWarning,
                                flagText: 'More than 17 hours awake',
                            },
                            {
                                label: 'Time of Day (Base)',
                                labelTitle: `${woclValueToTimeString(dataItem.woclStart)} - ${woclValueToTimeString(
                                    dataItem.woclEnd,
                                )}`,
                                value: graphTimeUtils.formatDateWithTZOffset(
                                    dataItem.utcTime,
                                    dateTimeFormatting.getAppTimeFormat(),
                                    dataItem.tzBase,
                                ),
                                color: getAlertColor(() => dataItem.isWocl),
                                flag: dataItem.isWocl,
                                flagText: `During WOCL (${woclValueToTimeString(
                                    dataItem.woclStart,
                                )} - ${woclValueToTimeString(dataItem.woclEnd)})`,
                            },
                            {
                                label: 'Out of Phase',
                                labelTitle: '> 3 hours',
                                value: formatHrs(dataItem.outOfPhaseHrs),
                                color: getAlertColor(() => outOfPhaseWarning),
                                flag: outOfPhaseWarning,
                                flagText: 'Out of Phase by more than 3 hours',
                            },
                            {
                                label: 'Reservoir',
                                labelTitle: '< 75%',
                                value: `${dataItem.sleepReservoir.toFixed(1).toString()}%`,
                                color: getPaletteColor('reservoir', dataItem.sleepReservoir),
                                flag: reservoirWarning,
                                flagText: 'Reservoir is less than 75%',
                            },
                        ]}
                    />
                    <GraphDashboardMetricsBlock
                        marginTop={performanceBlockMarginTop}
                        title="Performance"
                        rows={[
                            {
                                label: 'Effectiveness Upper',
                                value: formatDecimal(dataItem.highStandardDeviation, 1),
                                color: getPaletteColor('effectiveness', dataItem.highStandardDeviation),
                            },
                            {
                                label: 'Effectiveness Lower',
                                value: formatDecimal(dataItem.lowStandardDeviation, 1),
                                color: getPaletteColor('effectiveness', dataItem.lowStandardDeviation),
                            },
                            { label: 'Mean Cognitive', value: formatDecimal(dataItem.cog, 1) },
                            { label: 'Lapse Index', value: formatDecimal(dataItem.lapseIndex, 1) },
                            { label: 'Reaction Time', value: formatDecimal(dataItem.reactionTime, 1) },
                            { label: 'KSS', value: formatDecimal(dataItem.kss, 1), color: effectivenessColor },
                            {
                                label: 'Samn-Perelli',
                                value: formatDecimal(dataItem.samnPerelli, 1),
                                color: effectivenessColor,
                            },
                            { label: 'Workload', value: formatDecimal(dataItem.workload, 1) },
                        ]}
                    />
                </div>
            </div>
        </Draggable>
    )
}

export default GraphDashboard
