import { FormEvent, useEffect, useState } from 'react'
import { Form, Modal, Tab, Tabs } from 'react-bootstrap'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import {
    isShiftNameInvalid,
    isShiftTypeInvalid,
    Pattern,
    PatternsShifts,
    Shift,
    sortShifts,
    updateShiftToMatchSegment,
} from 'types/Shifts'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import PatternsTab from './PatternsTab'
import ShiftsTab from './ShiftsTab'

export interface ShiftDialogProps {
    patternsShifts: PatternsShifts
    closeCallback: (dialogResult: DialogResultEnum, updated?: PatternsShifts) => void
}

const PatternsAndShiftsDialogContent = (props: ShiftDialogProps) => {
    // const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [tabKey, setTabKey] = useState<string>('patterns')
    const [validatedForm, unusedsetValidatedForm] = useState(false)

    const [patterns, setPatterns] = useState<Pattern[]>(props.patternsShifts.patterns)
    const [shifts, setShifts] = useState<Shift[]>(props.patternsShifts.shiftSegments)
    const api = globals.getApi()

    // update patterns per changes to shifts
    useEffect(() => {
        setPatterns((previous) => {
            const updatedPatterns = [...previous]
            updatedPatterns.forEach((pattern) => {
                pattern.shifts.forEach((shift) => {
                    const sourceShift = shifts.find((x) => x.id === shift.sourceShiftId)
                    if (sourceShift) {
                        // found the source shift by id, so make sure the pattern uses the same properties
                        updateShiftToMatchSegment(shift, sourceShift)
                    }
                    if (shift.sourceShiftId && !sourceShift) {
                        // source shift must have been deleted, so we need to mark this shift as unlinked
                        shift.sourceShiftId = 0
                    }
                })
            })
            return updatedPatterns
        })
    }, [shifts])

    const anyInvalidShifts = (shiftsToValidate: Shift[]) =>
        shiftsToValidate.find((x) => isShiftTypeInvalid(x) || isShiftNameInvalid(x))

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()

        // shifts tab validation
        if (anyInvalidShifts(shifts)) {
            toast.error('Some shifts are not configured properly')
            return
        }

        // patterns tab validation
        if (patterns.find((x) => x.shifts.length === 0)) {
            toast.error('Some patterns have no shifts')
            return
        }

        if (patterns.find((pattern) => anyInvalidShifts(pattern.shifts))) {
            toast.error('Some patterns have invalid shifts')
            return
        }

        patterns.forEach((pattern) => {
            // sort shifts chrono
            sortShifts(pattern.shifts)

            // set IDs
            pattern.id = Math.max(0, pattern.id)
            pattern.shifts.forEach((shift) => {
                shift.id = Math.max(0, shift.id)
            })
        })

        const updatedPatternsShifts = await api.updatePatternsShifts({ patterns, shiftSegments: shifts })
        props.closeCallback(DialogResultEnum.Completed, updatedPatternsShifts)
    }

    return (
        <>
            <Form id="scheduleSaveAsForm" noValidate validated={validatedForm} onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Patterns &amp; Shifts</Modal.Title>
                </Modal.Header>
                <Tabs activeKey={tabKey} onSelect={(k: string | null) => setTabKey(k || 'patterns')} transition={false}>
                    <Tab eventKey="patterns" title="Patterns">
                        <Modal.Body style={{ height: '675px' }}>
                            <PatternsTab patterns={patterns} shiftSegments={shifts} setDialogPatterns={setPatterns} />
                        </Modal.Body>
                    </Tab>
                    <Tab eventKey="shifts" title="Shifts">
                        <Modal.Body style={{ height: '675px' }}>
                            <ShiftsTab shifts={shifts} setDialogShifts={setShifts} />
                        </Modal.Body>
                    </Tab>
                </Tabs>

                <Modal.Footer>
                    <ButtonCustom isLarge variant="primary" type="submit">
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => {
                            props.closeCallback(DialogResultEnum.Cancelled)
                        }}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const PatternsAndShiftsDialog = (props: ShiftDialogProps) => {
    return (
        <ModalWrapper className="ShiftsDialog" closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <PatternsAndShiftsDialogContent {...props} />
        </ModalWrapper>
    )
}

export default PatternsAndShiftsDialog
