import React, { FormEvent, memo, useEffect, useRef, useState } from 'react'
import { Alert, Form, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { isDesktopMode } from 'services/axios/axios-sfc'
import globals from 'services/global/globals'
import { showResponseMessage } from 'services/utilities/toastrUtils'
import { RootState } from 'store/store'
import { NewScheduleDefaults } from 'types/interfaces'
import MetaData from 'types/Metadata'
import Schedule, { CreateEventsSchedule } from 'types/Schedule'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import StationAutoSuggestInput from 'views/Common/Inputs/StationAutoSuggestInput'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'

export interface AddScheduleDialogProps {
    closeCallback: (status: DialogResultEnum, newSchedule?: Schedule) => void
    scenarioName?: string
    scenarioId?: number
}

const formDefaults: NewScheduleDefaults = {
    userId: 0,
    baseCode: '',
    scenarioId: 0,
    scenarioName: '',
    scheduleName: '',
}

const AddScheduleDialogContent = (props: AddScheduleDialogProps) => {
    const [defaultDataLoaded, setDefaultDataLoaded] = useState(false)
    const [defaultScenarioName, setDefaultScenarioName] = useState('')
    const [validatedForm, setValidatedForm] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [formValues, setFormValues] = useState(formDefaults)
    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)
    const api = globals.getApi()
    const formRef = useRef<HTMLFormElement>(null)

    useEffect(() => {
        // fetch defaults from the server
        const loadDefaults = async () => {
            try {
                const result = await api.getNewScheduleDefaultsForScenario(props.scenarioId || 0)
                result.scenarioId = props.scenarioId || 0
                setDefaultScenarioName(result.scenarioName)
                setFormValues(result)
            } catch (err: any) {
                setErrorMessage(err.message)
            }
            setDefaultDataLoaded(true)
        }
        loadDefaults()
    }, [props, api])

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        const formInvalid = form.checkValidity() === false

        let invalid = false
        if (formInvalid) {
            setValidatedForm(true)
            invalid = true
        }

        if (invalid) {
            // keep the form open, let the user fix the issues
            return
        }

        const data: CreateEventsSchedule = {
            scenarioId: formValues.scenarioId,
            scenarioName: formValues.scenarioName,
            scheduleName: formValues.scheduleName,
            baseCode: formValues.baseCode,
        }
        try {
            const [newSchedule, message] = await api.createNewEventsSchedule(data)
            showResponseMessage(message)
            props.closeCallback(DialogResultEnum.Completed, newSchedule)
        } catch (err: any) {
            setErrorMessage(err.message)
        }
    }

    if (!defaultDataLoaded) {
        // don't render anything until the data is loaded, to avoid flickering on the form
        return <></>
    }

    const dialogTitle = defaultScenarioName ? 'Add a new Schedule' : 'Add a new Scenario and Schedule'

    return (
        <>
            <Form noValidate validated={validatedForm} onSubmit={submitHandler} ref={formRef}>
                <Modal.Header closeButton>
                    <Modal.Title>{dialogTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {((isDesktopMode() && defaultScenarioName) || props.scenarioName) && (
                        <>
                            <FormLabelCustom htmlFor="txtScenarioName">Scenario Name</FormLabelCustom>
                            <p style={{ paddingLeft: '8px' }}>{defaultScenarioName}</p>
                        </>
                    )}
                    {!isDesktopMode() && !props.scenarioName && (
                        <Form.Group>
                            <FormLabelCustom htmlFor="txtScenarioName">Scenario Name</FormLabelCustom>
                            <Form.Control
                                id="txtScenarioName"
                                name="scenarioName"
                                autoFocus
                                autoComplete="off"
                                type="text"
                                placeholder="Provide a unique scenario name"
                                value={formValues.scenarioName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFormValues((prevState) => ({ ...prevState, scenarioName: e.target.value }))
                                }
                                required
                            />
                            <Form.Control.Feedback type="invalid">Enter a unique scenario name</Form.Control.Feedback>
                            <Form.Text className="text-muted">{metadata.scenarioNameCaption ?? ''}</Form.Text>
                        </Form.Group>
                    )}

                    <Form.Group>
                        <FormLabelCustom htmlFor="txtScheduleName">Schedule Name</FormLabelCustom>
                        <Form.Control
                            id="txtScheduleName"
                            name="scheduleName"
                            autoComplete="off"
                            type="text"
                            placeholder="Provide a unique schedule name"
                            value={formValues.scheduleName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFormValues((prevState) => ({ ...prevState, scheduleName: e.target.value }))
                            }
                            required
                        />
                        <Form.Control.Feedback type="invalid">Enter a unique schedule name</Form.Control.Feedback>
                        <Form.Text className="text-muted">{metadata.scheduleNameCaption ?? ''}</Form.Text>
                    </Form.Group>

                    <Form.Group>
                        <FormLabelCustom htmlFor="txtBaseStation">Base Station</FormLabelCustom>
                        <StationAutoSuggestInput
                            id="txtBaseStation"
                            name="baseStation"
                            placeholder="Start typing to search base stations"
                            value={formValues.baseCode}
                            isInvalidMessage="Please provide a valid base station"
                            onChange={(newValue: string) =>
                                setFormValues((prevState) => ({ ...prevState, baseCode: newValue }))
                            }
                        />
                    </Form.Group>

                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom isLarge type="submit" variant="primary">
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => {
                            props.closeCallback(DialogResultEnum.Cancelled)
                        }}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const AddScheduleDialog = (props: AddScheduleDialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <AddScheduleDialogContent {...props} />
        </ModalWrapper>
    )
}

export default memo(AddScheduleDialog)
