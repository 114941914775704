import { useRef } from 'react'
import { Col, Form, Modal } from 'react-bootstrap'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'

export type AddMultipleShiftsValue = {
    numberOfDays: number
    numberOfShiftsPerDay: number
}
export interface AddMultipleShiftsOptionsDialogProps {
    closeCallback: (dialogResult: DialogResultEnum, value?: AddMultipleShiftsValue) => void
}
const AddMultipleShiftsOptionsDialog = (props: AddMultipleShiftsOptionsDialogProps) => {
    const numDaysRef = useRef<HTMLInputElement>(null)
    const numShiftsPerDayRef = useRef<HTMLInputElement>(null)
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <Form id="scheduleSaveAsForm" noValidate>
                <Modal.Header closeButton>
                    <Modal.Title>Add Multiple Shifts Options</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Col sm="6">
                            <Form.Group>
                                <FormLabelCustom htmlFor="txtNumberOfDays">Number of days to add</FormLabelCustom>
                                <Form.Control ref={numDaysRef} type="number" defaultValue={1} min={1} required />
                            </Form.Group>
                        </Col>
                        <Col sm="6">
                            <Form.Group>
                                <FormLabelCustom htmlFor="txtNumberOfDays">Number of shifts per day</FormLabelCustom>
                                <Form.Control
                                    ref={numShiftsPerDayRef}
                                    type="number"
                                    defaultValue={1}
                                    min={1}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom
                        isLarge
                        variant="primary"
                        onClick={() => {
                            props.closeCallback(DialogResultEnum.Completed, {
                                numberOfDays: parseInt(numDaysRef.current!.value),
                                numberOfShiftsPerDay: parseInt(numShiftsPerDayRef.current!.value),
                            })
                        }}
                    >
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => {
                            props.closeCallback(DialogResultEnum.Cancelled)
                        }}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </ModalWrapper>
    )
}

export default AddMultipleShiftsOptionsDialog
