import React, { CSSProperties } from 'react'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import classes from './IconButton.module.css'

export interface IconButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
    toolbarLeftMargin?: boolean
    disabled?: boolean
    icon: string
    iconcolor?: string
    variant?: string
    text?: string
    tooltip?: string
}

const IconButton = (props: IconButtonProps) => {
    const defaultStyles: CSSProperties = {
        ...props.style,
        paddingLeft: !props.text ? '0px' : '5px',
        paddingRight: !props.text ? '0px' : '5px',
    }
    const buttonClasses = `${classes.button} ${props.className || ''}`

    return (
        <ButtonCustom
            {...props}
            style={defaultStyles}
            className={buttonClasses}
            variant={props.variant ? props.variant : 'white'}
            disabled={props.disabled}
            tooltip={props.tooltip}
        >
            <i
                className={props.icon}
                style={{
                    fontSize: '19px',
                    color: props.disabled ? '#bbb' : props.iconcolor || '#444',
                }}
            />
            {props.text && (
                <span
                    style={{
                        fontSize: '16px',
                        paddingLeft: '3px',
                        verticalAlign: 'text-bottom',
                    }}
                >
                    {' '}
                    {props.text}
                </span>
            )}
        </ButtonCustom>
    )
}

export default IconButton
