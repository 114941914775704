import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RecalculateItemType } from 'services/api/sfProcessingApi'
import { User } from 'types/interfaces'
import MetaData from 'types/Metadata'
import { RecalculationRequestStatus } from 'types/RecalculationStatus'

export type UserState = 'Unknown' | 'LoggedIn' | 'LoggedOut' | 'SessionExpired'

type AppState = {
    user?: User
    userState: UserState
    showLoadingModal: boolean
    toastrMessage?: string
    metadata?: MetaData
    isPrintPreview: boolean
    updatedLocalStorageTime: number
}

// defaults used only for tests
const defaultMetadata: MetaData = {
    disableFeatureExports: false,
    disableFeatureImports: false,
    disableFeatureReports: false,
    disableFeatureInsights: false,
    disableHelpSupportPortal: false,
    disableHelpTrainingAcademy: false,
    industry: 'Aviation',
    dutyAutoWorkTypes: [],
    loginAnnouncement: '',
    plannedWorkSleepQualities: ['Excellent', 'Good', 'Fair', 'Poor'],
    refreshInsightsSynchronously: false,
    refreshReportsSynchronously: false,
    scenarioNameCaption: '',
    scheduleBuilderType: 'events',
    scheduleNameCaption: '',
    sleepQualities: ['Fair', 'Good'],
    trainingAcademyUrl: '',
    userCustomFields: '',
    graphDashboardHorizontalOrientation: false,
    importInputTypes: ['SAFTEFASTXML', 'SAFTEFASTCSV'],
}

const initialGlobalState: AppState = {
    showLoadingModal: false,
    metadata: defaultMetadata,
    isPrintPreview: false,
    updatedLocalStorageTime: 0,
    userState: 'Unknown',
}

export type RecalculateDashboardPayload = {
    isRecalculating: boolean
    type: RecalculateItemType
}
export type RecalculateItemsPayload = {
    // isRecalculating: boolean
    itemIds: number[]
    type: RecalculateItemType
}

export type RecalculationStatusType = {
    statusRecord: RecalculationRequestStatus
    type: RecalculateItemType
}

const globalSlice = createSlice({
    name: 'global',
    initialState: initialGlobalState,
    reducers: {
        setPrintPreview(state, action: PayloadAction<boolean>) {
            state.isPrintPreview = action.payload
            // override the body styling to allow scrolling for printing
            document.body.style.overflowY = state.isPrintPreview ? 'scroll' : 'hidden'
        },
        showLoadingModal(state) {
            state.showLoadingModal = true
        },
        hideLoadingModal(state) {
            state.showLoadingModal = false
        },
        setUser(state, action: PayloadAction<User>) {
            state.user = action.payload
        },
        setIsSessionExpired(state) {
            state.userState = 'SessionExpired'
        },
        setIsLoggedIn(state) {
            state.userState = 'LoggedIn'
        },
        setIsLoggedOut(state) {
            state.userState = 'LoggedOut'
        },
        setMetadata(state, action: PayloadAction<MetaData>) {
            state.metadata = action.payload
        },
        setUpdatedLocalStorageTime(state) {
            state.updatedLocalStorageTime = new Date().getTime()
        },
    },
})

export default globalSlice.reducer
export const globalActions = globalSlice.actions
