import { ReactElement, ReactNode } from 'react'
import { ButtonGroup, DropdownButton } from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import { ToolbarButtonStyle } from './ButtonCustom'
import classes from './ButtonCustom.module.css'

// consider refactoring props to DropdownButtonProps
const ToolbarDropdownButton = (props: {
    title: ReactNode
    children: ReactElement<DropdownItem> | ReactElement<DropdownItem>[]
    className?: string
    disabled?: boolean
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
}) => {
    return (
        <DropdownButton
            as={ButtonGroup}
            onClick={props.onClick}
            variant="white"
            style={ToolbarButtonStyle}
            title={props.title}
            className={`${classes.toolbarButton} ${props.className ?? ''}`}
            disabled={props.disabled}
        >
            {props.children}
        </DropdownButton>
    )
}

export default ToolbarDropdownButton
