import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className="text-center">
            <h3 className="mt-4">Page Not Found</h3>
            <Link to="/scenarios">Go to Scenarios</Link>
        </div>
    )
}

export default NotFound
