import { GridCellProps, GridHeaderCellProps } from '@progress/kendo-react-grid'
import formatting from 'services/formatting/dateTimeFormatting'
import { ColumnMenuWithFilter, KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { FormattedScenarioMetricCell } from 'views/Common/Kendo/FormattedMetricCell'
import { createFormattedMetricHeaderCell, FormattedScenarioNameCell } from 'views/Common/Kendo/FormattedNameCell'
import { CustomHeaderClass, getHeaderCellClass } from 'views/Common/Kendo/KendoGridCustom'

const getColumns = (
    sharingIconClicked: (scenarioId: number) => void,
    enableFiltering: boolean,
    filteredFields: string[],
) => {
    const columns: KendoGridColumn[] = [
        {
            field: 'name',
            title: 'Scenario Name',
            filter: 'text',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('name', filteredFields),
            cell: (props: GridCellProps) => FormattedScenarioNameCell(props, sharingIconClicked),
        },
        {
            field: 'scenarioOwnerName',
            title: 'Owner',
            filter: 'text',
            headerClassName: getHeaderCellClass('scenarioOwnerName', filteredFields),
            columnMenu: ColumnMenuWithFilter,
        },
        {
            field: 'modifiedDate',
            title: 'Modified',
            filter: 'date',
            columnMenu: ColumnMenuWithFilter,
            headerClassName: getHeaderCellClass('modifiedDate', filteredFields),
            format: formatting.getKendoGridDateFormat(),
        },
        {
            field: 'numberOfSchedules',
            title: 'Schedules',
            filter: 'numeric',
            headerClassName: getHeaderCellClass('numberOfSchedules', filteredFields),
            columnMenu: ColumnMenuWithFilter,
        },
        {
            field: 'metrics.effectivenessCrewingMin',
            title: 'Effectiveness Minimum (Crewing)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Effectiveness Minimum', 'Crewing', !enableFiltering),
            filter: 'numeric',
            headerClassName: getHeaderCellClass('metrics.effectivenessCrewingMin', filteredFields, CustomHeaderClass),
            cell: FormattedScenarioMetricCell,
        },
        {
            field: 'metrics.effectivenessCrewingAvg',
            title: 'Effectiveness Average (Crewing)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Effectiveness Average', 'Crewing', !enableFiltering),
            headerClassName: getHeaderCellClass('metrics.effectivenessCrewingAvg', filteredFields, CustomHeaderClass),
            filter: 'numeric',
            columnMenu: ColumnMenuWithFilter,
            cell: FormattedScenarioMetricCell,
        },
        {
            field: 'metrics.effectivenessCriticalMin',
            title: 'Effectiveness Minimum (Critical)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Effectiveness Minimum', 'Critical', !enableFiltering),
            headerClassName: getHeaderCellClass('metrics.effectivenessCriticalMin', filteredFields, CustomHeaderClass),
            filter: 'numeric',
            columnMenu: ColumnMenuWithFilter,
            cell: FormattedScenarioMetricCell,
        },
        {
            title: 'KSS Maximum (Critical)',
            field: 'metrics.kssCriticalMax',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'KSS Maximum', 'Critical', !enableFiltering),
            headerClassName: getHeaderCellClass('metrics.kssCriticalMax', filteredFields, CustomHeaderClass),
            filter: 'numeric',
            cell: FormattedScenarioMetricCell,
            columnMenu: ColumnMenuWithFilter,
            hide: true,
        },
        {
            field: 'metrics.samnPerelliCriticalMax',
            title: 'Samn-Perelli Maximum (Critical)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Samn-Perelli Maximum', 'Critical', !enableFiltering),
            headerClassName: getHeaderCellClass('metrics.samnPerelliCriticalMax', filteredFields, CustomHeaderClass),
            filter: 'numeric',
            cell: FormattedScenarioMetricCell,
            columnMenu: ColumnMenuWithFilter,
            hide: true,
        },
        {
            field: 'metrics.reservoirCrewingMin',
            title: 'Reservoir Minimum (Crewing)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Reservoir Minimum', 'Crewing', !enableFiltering),
            headerClassName: getHeaderCellClass('metrics.reservoirCrewingMin', filteredFields, CustomHeaderClass),
            filter: 'numeric',
            columnMenu: ColumnMenuWithFilter,
            cell: FormattedScenarioMetricCell,
        },
        {
            field: 'metrics.reservoirCriticalMin',
            title: 'Reservoir Minimum (Critical)',
            headerCell: (props: GridHeaderCellProps) =>
                createFormattedMetricHeaderCell(props, 'Reservoir Minimum', 'Critical', !enableFiltering),
            headerClassName: getHeaderCellClass('metrics.reservoirCriticalMin', filteredFields, CustomHeaderClass),
            filter: 'numeric',
            columnMenu: ColumnMenuWithFilter,
            cell: FormattedScenarioMetricCell,
        },
    ]
    return columns
}

export default getColumns
