import formatting from 'services/formatting/dateTimeFormatting'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'

const columns: KendoGridColumn[] = [
    {
        field: 'name',
        title: 'Scenario Name',
        filter: 'text',
        cellClassName: 'left-aligned',
    },
    {
        field: 'numberOfSchedules',
        title: 'Schedules',
        filter: 'numeric',
    },
    {
        field: 'modifiedDate',
        title: 'Modified',
        filter: 'date',
        format: formatting.getKendoGridDateFormat(),
    },
    {
        field: 'scenarioOwnerName',
        filter: 'text',
        title: 'Owner',
    },
]

export default columns
